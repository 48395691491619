import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Header from "../../common/Header";
import axios from "axios";
import { api_url, formatdmY } from "../../common/Helpers";
import "../../common/style.css";
import $ from "jquery";
import Swal from "sweetalert2";
import Footer from "../../common/Footer";
import Highcharts from "highcharts/highstock";
import Select from "react-select";
export default function Contact() {
  const [submit, setsubmit] = useState(false);
  const [key, setKey] = useState("home");
  const [load, setload] = useState(false);
  const [counter, setCounter] = useState(0);
  const [expload, setexpload] = useState(true);
  const [nifyt50expiry, setnifyt50expiry] = useState([
    { value: "", label: "" },
  ]);
  //// THIS SECTION IS FOR BANK NIFTY
  const [bankniftyexpiry, setbankniftyexpiry] = useState([
    { value: "", label: "" },
  ]);
  // THIS SECTION IS FOR FINNIFTY
  // Step 3: Create state for Finnifty data and expiry date
  const [finniftyExpiry, setfinniftyExpiry] = useState([
    { value: "", label: "" },
  ]);
  const fetchexpdatas = async () => {
    await axios.get(api_url + `getalloptionchainexplist`).then(({ data }) => {
      // setexpirevalue(data[0].label)
      // fetchdataslatest(data[0].value)
      let arr = [];
      if (data.FINNIFTY.length > 0) {
        for (let x in data.FINNIFTY) {
          // if(x == 0){
          //   getOptionChainFinniftyLatest(data.FINNIFTY[x])
          // }
          let obj = {
            value: data.FINNIFTY[x],
            label: formatdmY(data.FINNIFTY[x]),
          };
          arr.push(obj);
        }
      }
      setfinniftyExpiry(arr);
      let arr1 = [];
      if (data.NIFTY.length > 0) {
        for (let x in data.NIFTY) {
          // if(x == 0){
          //   getOptionChainnifty50Latest(data.NIFTY[x])
          // }
          let obj = { value: data.NIFTY[x], label: formatdmY(data.NIFTY[x]) };
          arr1.push(obj);
        }
      }
      setnifyt50expiry(arr1);
      let arr2 = [];
      if (data.BANKNIFTY.length > 0) {
        for (let x in data.BANKNIFTY) {
          // if(x == 0){
          //   getoptionchainbankniftyLatest(data.BANKNIFTY[x])
          // }
          let obj = {
            value: data.BANKNIFTY[x],
            label: formatdmY(data.BANKNIFTY[x]),
          };
          arr2.push(obj);
        }
      }
      setbankniftyexpiry(arr2);
    });
    setexpload(false);
  };
  // Step 4: Fetch Finnifty option chain data with a specific expiry date
  const getOptionChainnifty50Latest = async (expirydate) => {
    await axios
      .get(api_url + `getoptionchainlatests/` + expirydate)
      .then(({ data }) => {
        console.log(data);
      });
  };
  const getoptionchainbankniftyLatest = async (expirydate) => {
    await axios
      .get(api_url + `getoptionchainbankniftylatest/` + expirydate)
      .then(({ data }) => {
        console.log(data);
      });
  };
  const getOptionChainFinniftyLatest = async (expirydate) => {
    await axios
      .get(api_url + `getoptionchainfinniftylatest/` + expirydate)
      .then(({ data }) => {
        console.log(data);
      });
  };

  useEffect(() => {
    fetchexpdatas();
  }, []);

  useEffect(() => {
    fetchdata();
    const timer = setTimeout(() => {
      setCounter(counter + 1);
      fetchdata();
    }, 60000);

    return () => {
      clearTimeout(timer);
    };
  }, [counter]);
  const fetchdata = async () => {
    await axios.get(api_url + "getstraddlesnapshotdata").then(({ data }) => {
      Highcharts.chart("containerniftystraddlesnapshot", {
        chart: {
          type: "spline",
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: data.time,
        },
        yAxis: [
          {
            // Primary yAxis
            labels: {
              format: "{value}",
            },
            title: {
              text: "Price",
            },
            opposite: false,
          },
          {
            // Secondary yAxis
            gridLineWidth: 0,
            title: {
              text: "Spot Price",
            },
            labels: {
              format: "{value}",
            },
            opposite: true,
          },
        ],
        tooltip: {
          shared: true,
          useHTML: true, // enable HTML in tooltip
          pointFormatter: function () {
            var seriesName = this.series.name;
            var valuePrefix = "";
            var valueSuffix = "";
            if (seriesName === "Spot Price") {
              valuePrefix = "";
              valueSuffix = "";
            } else {
              valueSuffix = " ";
            }
            return (
              '<tr><td style="color: ' +
              this.color +
              '">' +
              seriesName +
              ': </td><td style="text-align: right"><b>' +
              valuePrefix +
              Highcharts.numberFormat(this.y, 2) +
              valueSuffix +
              "</b></td></tr>"
            );
          },
          footerFormat: "</table>",
          headerFormat: "<table>",
          valueDecimals: 2,
        },
        plotOptions: {
          line: {
            marker: {
              enabled: false,
            },
          },
          spline: {
            marker: {
              enabled: false,
            },
          },
        },
        series: data.nifty50,
      });

      // console.log(allbankniftyspotprice)
      Highcharts.chart("containerbankniftystraddlesnapshot", {
        chart: {
          type: "spline",
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: data.time,
        },
        yAxis: [
          {
            // Primary yAxis
            labels: {
              format: "{value}",
            },
            title: {
              text: "Price",
            },
            opposite: false,
          },
          {
            // Secondary yAxis
            gridLineWidth: 0,
            title: {
              text: "Spot Price",
            },
            labels: {
              format: "{value}",
            },
            opposite: true,
          },
        ],
        tooltip: {
          shared: true,
          useHTML: true, // enable HTML in tooltip
          pointFormatter: function () {
            var seriesName = this.series.name;
            var valuePrefix = "";
            var valueSuffix = "";
            if (seriesName === "Spot Price") {
              valuePrefix = "";
              valueSuffix = "";
            } else {
              valueSuffix = " ";
            }
            return (
              '<tr><td style="color: ' +
              this.color +
              '">' +
              seriesName +
              ': </td><td style="text-align: right"><b>' +
              valuePrefix +
              Highcharts.numberFormat(this.y, 2) +
              valueSuffix +
              "</b></td></tr>"
            );
          },
          footerFormat: "</table>",
          headerFormat: "<table>",
          valueDecimals: 2,
        },
        plotOptions: {
          line: {
            marker: {
              enabled: false,
            },
          },
          spline: {
            marker: {
              enabled: false,
            },
          },
        },
        series: data.banknifty,
      });
      Highcharts.chart("containerfinniftystraddlesnapshot", {
        chart: {
          type: "spline",
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: data.time,
        },
        yAxis: [
          {
            // Primary yAxis
            labels: {
              format: "{value}",
            },
            title: {
              text: "Price",
            },
            opposite: false,
          },
          {
            // Secondary yAxis
            gridLineWidth: 0,
            title: {
              text: "Spot Price",
            },
            labels: {
              format: "{value}",
            },
            opposite: true,
          },
        ],
        tooltip: {
          shared: true,
          useHTML: true, // enable HTML in tooltip
          pointFormatter: function () {
            var seriesName = this.series.name;
            var valuePrefix = "";
            var valueSuffix = "";
            if (seriesName === "Spot Price") {
              valuePrefix = "";
              valueSuffix = "";
            } else {
              valueSuffix = " ";
            }
            return (
              '<tr><td style="color: ' +
              this.color +
              '">' +
              seriesName +
              ': </td><td style="text-align: right"><b>' +
              valuePrefix +
              Highcharts.numberFormat(this.y, 2) +
              valueSuffix +
              "</b></td></tr>"
            );
          },
          footerFormat: "</table>",
          headerFormat: "<table>",
          valueDecimals: 2,
        },
        plotOptions: {
          line: {
            marker: {
              enabled: false,
            },
          },
          spline: {
            marker: {
              enabled: false,
            },
          },
        },
        series: data.finnifty,
      });
    });
  };

  return (
    <>
      <div>
        <Header />
        <main>
          {/* section content begin */}
          <div className="uk-section uk-margin-small-top">
            <div
              className="uk-container"
              style={{ marginTop: "1rem", maxWidth: "100%",maxWidth: '1400px' }}
            >
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
              >
                <Tab eventKey="home" title="NIFTY 50">
                  {load ? (
                    <div className="linear-background w-100"> </div>
                  ) : (
                    <>
                      <div className="row">
                        <div className="col-12">
                          <div
                            className="uk-card uk-card-default uk-card-body uk-border-rounded"
                            style={{
                              margin: 0,
                              padding: 0,
                              border: "1px solid #C17951",
                              borderRadius: "0px",
                            }}
                          >
                            <div style={{ overflow: "hidden" }}>
                              {expload ? (
                                <div
                                  className="linear-background w-100"
                                  style={{ height: "30px" }}
                                >
                                  {" "}
                                </div>
                              ) : (
                                <div
                                  className="row"
                                  style={{ background: "#FDF1CC" }}
                                >
                                  <div className="col-4"></div>
                                  <div className="col-4 text-center">
                                    <h2
                                      style={{
                                        background: "#FDF1CC",
                                        margin: 0,
                                        textAlign: "center",
                                      }}
                                    >
                                      Straddle Snapshot (ATM)
                                    </h2>
                                  </div>
                                  <div className="col-4 text-end">
                                    <div style={{ fontWeight: "600" }}>
                                      <label
                                        htmlFor=""
                                        style={{
                                          display: "inline-block",
                                          marginRight: "10px",
                                        }}
                                      >
                                        Expiry
                                      </label>
                                      <Select
                                        style={{ display: "inline-block" }}
                                        defaultValue={{
                                          label: nifyt50expiry[0].label,
                                          value: nifyt50expiry[0].value,
                                        }}
                                        className="text-dark"
                                        required
                                        name="expiredate"
                                        id="expiredate"
                                        onChange={(e) =>
                                          getOptionChainnifty50Latest(e.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <figure class="highcharts-figure">
                              <div
                                id="containerniftystraddlesnapshot"
                                style={{ height: "500px" }}
                              ></div>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </Tab>
                <Tab eventKey="profile" title="BANK NIFTY">
                  {load ? (
                    <div className="linear-background w-100"> </div>
                  ) : (
                    <>
                      <div className="row">
                        <div className="col-12">
                          <div
                            className="uk-card uk-card-default uk-card-body uk-border-rounded"
                            style={{
                              margin: 0,
                              padding: 0,
                              border: "1px solid #C17951",
                              borderRadius: "0px",
                            }}
                          >
                            <div style={{overflow: 'hidden'}}>
                          
                          {expload ? (
                          <div
                            className="linear-background w-100"
                            style={{ height: "30px" }}
                          >
                            {" "}
                          </div>
                        ) : (
                          <div className="row" style={{background: "#FDF1CC"}}>
                            <div className="col-4"></div>
                            <div className="col-4 text-center">
                              
                          <h2
                            style={{
                              margin: 0,
                              textAlign: "center",
                            }}
                          >
                            Straddle Snapshot (ATM)
                          </h2>
                            </div>
                            <div className="col-4 text-end">
                              <div style={{ fontWeight: "600" }}>
                                <label
                                  htmlFor=""
                                  style={{
                                    display: "inline-block",
                                    marginRight: "10px",
                                  }}
                                >
                                  Expiry
                                </label>
                                <Select
                                  style={{ display: "inline-block" }}
                                  defaultValue={{
                                    label: bankniftyexpiry[0].label,
                                    value: bankniftyexpiry[0].value,
                                  }}
                                  className="text-dark"
                                  required
                                  name="expiredate"
                                  id="expiredate"
                                  onChange={(e) =>
                                    getoptionchainbankniftyLatest(e.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        )}
                          </div>
                            <figure class="highcharts-figure">
                              <div
                                id="containerbankniftystraddlesnapshot"
                                style={{ height: "500px" }}
                              ></div>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </Tab>
                <Tab eventKey="finnifty" title="FIN NIFTY">
                  {load ? (
                    <div className="linear-background w-100"></div>
                  ) : (
                    <>
                      <div className="row">
                        <div className="col-12">
                          <div
                            className="uk-card uk-card-default uk-card-body uk-border-rounded"
                            style={{
                              margin: 0,
                              padding: 0,
                              border: "1px solid #C17951",
                              borderRadius: "0px",
                            }}
                          >
                            <div style={{overflow: 'hidden'}}>
                          
                          {expload ? (
                          <div
                            className="linear-background w-100"
                            style={{ height: "30px" }}
                          >
                            {" "}
                          </div>
                        ) : (
                          <div className="row" style={{background: "#FDF1CC"}}>
                            <div className="col-4"></div>
                            <div className="col-4 text-center">
                              
                        <h2
                          style={{
                            background: "#FDF1CC",
                            margin: 0,
                            textAlign: "center",
                          }}
                        >
                          Straddle Snapshot (ATM)
                        </h2>
                            </div>
                            <div className="col-4 text-end">
                              <div style={{ fontWeight: "600" }}>
                                <label
                                  htmlFor=""
                                  style={{
                                    display: "inline-block",
                                    marginRight: "10px",
                                  }}
                                >
                                  Expiry
                                </label>
                                <Select
                                style={{ display: "inline-block" }}
                                defaultValue={{
                                  label: finniftyExpiry[0].label,
                                  value: finniftyExpiry[0].value,
                                }}
                                className="text-dark"
                                required
                                name="expiredate"
                                id="expiredate"
                                onChange={(e) =>
                                  getOptionChainFinniftyLatest(e.value)
                                }
                              />
                              </div>
                            </div>
                          </div>
                        )}
                          </div>
                          
                            <figure class="highcharts-figure">
                              <div
                                id="containerfinniftystraddlesnapshot"
                                style={{ height: "500px" }}
                              ></div>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </Tab>
              </Tabs>
            </div>
          </div>

          {/* section content end */}
        </main>
        <Footer />
      </div>
    </>
  );
}
