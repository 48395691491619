import React, { useEffect,useState } from 'react'
import {Link,useNavigate} from 'react-router-dom';
import Header from '../common/Header';
import axios from 'axios';
import {app_url,asseturl,api_url,api_asseturl} from '../common/Helpers';
import Select from "react-select";
import Slider from "react-slick";
import $ from 'jquery';
import Swal from 'sweetalert2';
import Footer from './Footer';
import {Pagination} from 'react-laravel-paginex'
export default function Contact(){
  const [products, setProducts] = useState([])  
  const [submit, setsubmit] = useState(false)
  const [load, setload] = useState(false)
  const [shownow, setshownow] = useState(false)
  useEffect(()=>{
    fetchdatas();
    // setInterval(fetchdatas, 60000);
  },[])
  const fetchdatas = async () =>{
    setload(true);
    await axios.get(api_url+'wfetchrssfeednewslist?page=' + 1).then(({data})=>{
      setProducts(data)
      setload(false);
      setshownow(true);
    })
  }
const getData = (data)=>{
  setload(true);
  axios.get(api_url+'wfetchrssfeednewslist?page=' + data.page).then(({data})=>{
    setProducts(data)
    setload(false);
    setshownow(true);
  })
  $("html, body").animate({scrollTop: 0}, 1000);
}
const options = {
  containerClass: "uk-pagination uk-flex-center uk-margin-medium-top",
  prevButtonClass: "prev-button-class",
}
return (
      <>
     <div>
  <Header />
 <main>
  {/* section content begin */}
  <div className="uk-section uk-margin-small-top">
    <div className="uk-container">
      <div className="uk-grid" data-uk-grid>
        <div className="uk-width-2-3@m uk-first-column">
          <div className="in-blog-1 uk-grid uk-grid-stack" data-uk-grid>
          {load ?
                      <div className="linear-background w-100"> </div>
                        :
                        <>
                        {
                                    shownow == true && (
                                        products.data.map((row, key)=>(
                                                <div className="in-stretch uk-grid-margin uk-first-column">
                                                <article className="uk-card uk-card-default uk-border-rounded">
                                                {row.linkimg?
                                                  <div className="uk-card-media-top">
                                                          <img src={api_asseturl+'news/'+row.linkimg} alt="News Img" />
                                                  </div>
                                                  :
                                                  <></>
                                                  }
                                                  <div className="uk-card-body">
                                                    <h3>
                                                      <a href={row.singlelink}>{row.singletitle}</a>
                                                    </h3>
                                                    <p>{row.singledes}</p>
                                                    <div className="uk-flex">
                                                      <div className="uk-margin-small-right">
                                                        <img className="uk-border-pill uk-background-muted" src={asseturl+'img/user/author-lybqnhew0wc.jpg'} alt="image-team" width={32} height={32} />
                                                      </div>
                                                      <div className="uk-flex uk-flex-middle">
                                                        <p className="uk-text-small uk-text-muted uk-margin-remove">
                                                          Adiba Bachtiar
                                                          <span className="uk-margin-small-left uk-margin-small-right">•</span>
                                                        </p>
                                                      </div>
                                                      <div className="uk-flex uk-flex-middle">
                                                        <p className="uk-text-small uk-text-muted uk-margin-remove">
                                                          October 19, 2021
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="uk-card-footer uk-clearfix">
                                                    <div className="uk-float-left">
                                                      <span className="uk-label uk-label-warning in-label-small">Health</span>
                                                    </div>
                                                    <div className="uk-float-right">
                                                      <a href={row.singlelink} className="uk-button uk-button-text">Read more<i className="fas fa-arrow-circle-right uk-margin-small-left" /></a>
                                                    </div>
                                                  </div>
                                                </article>
                                              </div>
                                        ))
                                    )
}
                        </>
          }



          </div> {/* pagination begin */}
          <Pagination changePage={getData} data={products} options={options}/>
          {/* pagination end */}
        </div>
        <div className="uk-width-expand@m">
          {/* widget categories begin */}
          <aside className="uk-margin-medium-bottom">
            <div className="uk-card uk-card-default uk-card-body uk-border-rounded">
              <h5 className="uk-heading-bullet uk-text-uppercase uk-margin-remove-bottom">Categories</h5>
              <ul className="uk-list in-widget-category"><li><a href="find.html?category=crypto">Crypto<span className="uk-label in-label-small uk-float-right">3</span></a></li><li><a href="find.html?category=health">Health<span className="uk-label in-label-small uk-float-right">2</span></a></li><li><a href="find.html?category=markets">Markets<span className="uk-label in-label-small uk-float-right">4</span></a></li><li><a href="find.html?category=politics">Politics<span className="uk-label in-label-small uk-float-right">2</span></a></li><li><a href="find.html?category=technology">Technology<span className="uk-label in-label-small uk-float-right">3</span></a></li></ul>
            </div>
          </aside>
          {/* widget categories end */}
          {/* widget popular begin */}
          <aside className="uk-margin-medium-bottom">
            <div className="uk-card uk-card-default uk-card-body uk-border-rounded">
              <h5 className="uk-heading-bullet uk-text-uppercase uk-margin-remove-bottom">Latest News</h5>
              <ul className="uk-list uk-list-divider uk-list-large in-widget-latest">
                <li>
                  <a href="blackrock-responds-to-george-soros-criticism-over.html">BlackRock responds to George Soros’ criticism over China ...</a>
                  <span className="uk-article-meta uk-text-small"><br /><i className="fas fa-clock fa-sm uk-margin-small-right" />October 19, 2021</span>
                </li>
                <li>
                  <a href="india-is-better-equipped-to-deal-with-a-potential.html">India is ‘better equipped’ to deal with a potential Covid ...</a>
                  <span className="uk-article-meta uk-text-small"><br /><i className="fas fa-clock fa-sm uk-margin-small-right" />October 19, 2021</span>
                </li>
                <li>
                  <a href="china-portrays-the-us-as-weak-and-unreliable-to.html">China portrays the U.S. as ‘weak and unreliable’ to draw ...</a>
                  <span className="uk-article-meta uk-text-small"><br /><i className="fas fa-clock fa-sm uk-margin-small-right" />October 19, 2021</span>
                </li>
              </ul>
            </div>
          </aside>
          {/* widget popular end */}
          {/* widget tags begin */}
          <aside className="uk-margin-medium-bottom">
            <div className="uk-card uk-card-default uk-card-body uk-border-rounded">
              <h5 className="uk-heading-bullet uk-text-uppercase uk-margin-remove-bottom">Tags</h5>
              <div className="uk-margin-top in-widget-tag"><a href="find.html?tag=asia"><span className="uk-label uk-border-pill">asia</span></a><a href="find.html?tag=crypto"><span className="uk-label uk-border-pill">crypto</span></a><a href="find.html?tag=economics"><span className="uk-label uk-border-pill">economics</span></a><a href="find.html?tag=europe"><span className="uk-label uk-border-pill">europe</span></a><a href="find.html?tag=health"><span className="uk-label uk-border-pill">health</span></a><a href="find.html?tag=investment"><span className="uk-label uk-border-pill">investment</span></a><a href="find.html?tag=politics"><span className="uk-label uk-border-pill">politics</span></a><a href="find.html?tag=technology"><span className="uk-label uk-border-pill">technology</span></a><a href="find.html?tag=usa"><span className="uk-label uk-border-pill">usa</span></a></div>
            </div>
          </aside>
          {/* widget tags end */}
        </div>
      </div>
    </div>
  </div>
  {/* section content end */}
</main>



  <Footer />
  </div>
      </>
    )
  }
