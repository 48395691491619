import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../common/Header";
import axios from "axios";
import { app_url, asseturl, api_url, fullappurl } from "../../common/Helpers";
import Select from "react-select";
import Slider from "react-slick";
import $ from "jquery";
import Swal from "sweetalert2";
import Footer from "../../common/Footer";
import { CSVLink, CSVDownload } from "react-csv";
import FeatherIcon from "feather-icons-react";
import { Chart } from "react-google-charts";
import { ShareSocial } from "react-share-social";
export default function Contact() {
  const [submit, setsubmit] = useState(false);
  const [load, setload] = useState(false);
  const [allloopdata, setallloopdata] = useState([]);

  useEffect(() => {
    fetchdatas();
  }, []);
  const fetchdatas = async () => {
    setload(true);
    await axios.get(api_url + `get52weeklow`).then(({ data }) => {
      setallloopdata(data);
      setload(false);
      fetchdata();
    });
  };
  const fetchdata = async () => {
    await axios.get(api_url + `get52weeklowlatest`).then(({ data }) => {
      setallloopdata(data);
    });
  };
  return (
    <>
      <div>
        <Header />
        <main>
          {/* section content begin */}
          <div className="uk-section uk-margin-small-top">
            <div className="uk-container">
              <div className="uk-container" style={{ marginTop: "1.4rem" }}>
                <div className="row">
                  <div className="col-12">
                    <div
                      className="uk-card uk-card-default uk-card-body uk-border-rounded"
                      style={{
                        margin: 0,
                        padding: 0,
                        border: "1px solid #C17951",
                        borderRadius: "0px",
                      }}
                    >
                      <div className="uk-text-center">
                        <h2 style={{ background: "#FDD965", margin: 0 }}>
                          52-Week Low
                        </h2>
                      </div>
                      {load ? (
                        <div className="linear-background w-100"> </div>
                      ) : (
                        <>
                          <div className="table-responsive">
                            <table
                              class="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center"
                              style={{ margin: 0 }}
                            >
                              <thead>
                                <tr>
                                  <th class="uk-text-center">Company</th>
                                  <th class="uk-text-center">Last Price</th>
                                  <th class="uk-text-center">Today's High</th>
                                  <th class="uk-text-center">Today's Low</th>
                                  <th class="uk-text-center">Chg</th>
                                  <th class="uk-text-center">%Chg</th>
                                </tr>
                              </thead>
                              <tbody>
                                {allloopdata.length > 0 &&
                                  allloopdata.map((row, key) => (
                                    <tr>
                                      <td className="text-center pe-0">
                                        <div>{row.company}</div>
                                      </td>
                                      <td
                                        className="text-center pe-0"
                                        style={{
                                          color:
                                            row.lastprice < 0 ? "red" : "green",
                                        }}
                                      >
                                        {row.lastprice}
                                      </td>
                                      <td className="text-center pe-0">
                                        <div>{row.todayhigh}</div>
                                      </td>
                                      <td className="text-center pe-0">
                                        <div>{row.todaylow}</div>
                                      </td>
                                      <td
                                        className="text-center pe-0"
                                        style={{
                                          color:
                                            row.change < 0 ? "red" : "green",
                                        }}
                                      >
                                        {row.change}
                                      </td>
                                      <td
                                        className="text-center pe-0"
                                        style={{
                                          color:
                                            row.percentchange < 0 ? "red" : "green",
                                        }}
                                      >
                                        {row.percentchange}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <Footer />
      </div>
    </>
  );
}
