import React, {useState,useEffect} from 'react'
import {useNavigate,Link} from 'react-router-dom';
import {app_url, asseturl} from '../common/Helpers';
export default function Footer() {
const uid = localStorage.getItem("uid");
const [submit, setsubmit] = useState(false);
const [load, setload] = useState(false);
const [products, setProducts] = useState([])
const [fromdate, setfromdate] = useState("")
const [todate, settodate] = useState("")
const navigate = useNavigate(); 
// function auth(){
//   if(!uid){
//     navigate(app_url);
//   }
// }
// useEffect(()=>{
// auth()
// },[])
    return (
      <footer>
      <div className="uk-section">
        <div className="uk-container uk-margin-top">
          <div className="uk-grid">
            <div className="uk-width-2-3@m">
              <div className="uk-child-width-1-2@s uk-child-width-1-4@m" data-uk-grid>
                <div style={{paddingLeft: '0px',width: '12%'}} id='first'>
                  <h5>Instruments</h5>
                  <ul className="uk-list uk-link-text">
                    <li><a href="https://www.investing.com/currencies/streaming-forex-rates-majors" target="_blank">Forex</a></li>
                    <li><a href="https://www.investing.com/indices/major-indices" target="_blank">Indices</a></li>
                    <li><a href="https://www.investing.com/etfs/world-etfs" target="_blank">ETFs</a></li>
                    <li><a href="https://www.investing.com/commodities/metals" target="_blank">Metals</a></li>
                    <li><a href="https://www.investing.com/rates-bonds/" target="_blank">Bonds</a></li>
                    <li><a href="https://www.investing.com/commodities/energy" target="_blank">Energies</a></li>
                  </ul>
                </div>
                <div style={{width: '30%',paddingLeft: '72px'}} id='second'>
                  <h5>Analytics</h5>
                  <ul className="uk-list uk-link-text">
                    <li>
                      <Link to={app_url+'futurevsoi'}>Future Price Vs OI</Link>
                    </li>
                    <li>
                      <Link to={app_url+'oianalysis'}>OI Analysis</Link>
                    </li>
                    <li>
                      <Link to={app_url+'optionchain'}>Option Chain</Link>
                    </li>
                    <li><Link to={app_url+'trendingoi'}>Multi-Strike Trending OI</Link></li>
                    <li><Link to={app_url+'pcr'}>PCR</Link></li>
                    <li><Link to={app_url+'impliedvolatility'}>Implied Volatility</Link></li>
                    <li><Link to={app_url+'cepevsoi'}>CE & PE Price Vs Ol</Link></li>
                    <li><Link to={app_url+'maxpain'}>Max Pain</Link></li>
                  </ul>
                </div>
                <div style={{width: '30%'}} id='third'>
                  <h5>Strategies</h5>
                  <ul className="uk-list uk-link-text">
                    <li><Link to={app_url+'straddle'}>Straddle</Link></li>
                    <li><Link to={app_url+'strangle'}>Strangle</Link></li>
                    <li><Link to={app_url+'straddlesnapshot'}>Straddle Snapshot</Link></li>
                    <li><Link to={app_url+'straddleweekdays'}>Straddle Weekdays Price</Link></li>
                  </ul>
                </div>
                <div className="in-margin-top-60@s" id='fourth'>
                  <h5>News & Analysis</h5>
                  <ul className="uk-list uk-link-text">
                    <li>
                    <Link to={app_url+'markets'}>Markets</Link>
                    </li>
                    <li>
                    <Link to={app_url+'money'}>Money</Link>
                    </li>
                    <li>
                    <Link to={app_url+'analysis'}>Analysis</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
    <div className="uk-width-1-3@m uk-flex justify-content-around" style={{paddingLeft: '10px'}} id='fifth'>
      <div className='subfifth'>
        <h5>Legal</h5>
        <ul className="uk-list uk-link-text">
        <li><Link to={app_url+'terms'}>Terms and Conditions</Link></li>
          <li><Link to={app_url+'refund'}>Refund and Cancellation Policy</Link></li>
          <li><Link to={app_url+'privacy'}>Privacy Policy</Link></li>
          <li><Link to={app_url+'disclaimer'}>Disclaimer</Link></li>
          <li><Link to={app_url+'cookies'}>Cookies Policy</Link></li>
        </ul>
      </div>
      <div>
      <ul className="uk-list uk-link-text in-footer-socials">
        <li><a href="https://www.facebook.com/finakin.data" target={'_blank'} className="fb-bg"><i className="fab fa-facebook-square" />Facebook</a></li>
        <li><a href="https://twitter.com/Fin_Akin" target={'_blank'} className="tw-bg"><i className="fab fa-twitter" />Twitter</a></li>
        <li><a href="https://www.youtube.com/@finakin" target={'_blank'} className="yt-bg"><i className="fab fa-youtube" />Youtube</a></li>
        <li><a href="https://www.instagram.com/fin_akin/" target={'_blank'} className="ig-bg"><i className="fab fa-instagram" />Instagram</a></li>
        <li><a href="https://t.me/Fin_Akin" target={'_blank'} className="tl-bg"><i className="fab fa-telegram-plane" />Telegram</a></li>
      </ul>
      </div>
    </div>
    </div>
    </div>
    <div style={{height: '50px'}}></div>
      <div className="uk-container">
        <div className="uk-grid uk-flex uk-flex-middle">
          <div className="uk-width-2-3@m uk-text-small" style={{color: '#999'}}>
            <span className="text-muted fw-semibold me-1">©2023</span>
              <a href="#" target="_blank" className="text-gray-800 text-hover-primary" style={{color: '#999'}}>
                FinAkin.com All Rights Reserved.
              </a>
          </div>
        </div>
      </div>
    </div>
    <div className="uk-visible@m">
        <a href="#" className="in-totop fas fa-chevron-up" data-uk-scroll />
    </div>
    </footer>
    )
  }
