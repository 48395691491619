import React, { useEffect,useState } from 'react'
import Header from '../common/Header';
import {Link,useParams} from 'react-router-dom';
import axios from 'axios';
import {app_url,asseturl,api_url,api_asseturl,fullappurl} from '../common/Helpers';
import $ from 'jquery';
import Footer from '../common/Footer';
import {Pagination} from 'react-laravel-paginex'
import {ShareSocial} from 'react-share-social' 
export default function Contact(){
  const urlid = useParams('eduid')
  const [products, setProducts] = useState([])  
  const [submit, setsubmit] = useState(false)
  const [load, setload] = useState(false)
  const [shownow, setshownow] = useState(false)
  useEffect(()=>{
    fetchdatas();
  },[])
  const fetchdatas = async () =>{
    setload(true);
    await axios.get(api_url+'editeducation/'+urlid.eduid).then(({data})=>{
      setProducts(data)
      setload(false);
      // setshownow(true);
    })
  }
const options = {
  containerClass: "uk-pagination uk-flex-center uk-margin-medium-top",
  prevButtonClass: "prev-button-class",
}
const socialstyle = {
  // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  borderRadius: 10,
  border: 0,
  color: 'white',
  padding: '0',
  // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  zIndex: 1,
  width: '250px',
  marginTop: '15px',
  marginLeft: '-212px',
};
function removeTags(str) {
  if ((str===null) || (str===''))
      return false;
  else
      str = str.toString();
  return str.replace( /(<([^>]+)>)/ig, '');
}
return (
      <>
     <div>
  <Header />
 <main>
  {/* section content begin */}
  <div className="uk-section uk-margin-small-top">
    <div className="uk-container">
    {/* <h1 class="uk-margin-small-top uk-text-center"><span class="in-highlight">Education View</span></h1> */}
      <div className="uk-grid" data-uk-grid style={{marginTop: '50px'}}>
        <div className="uk-width-2-4@m uk-first-column">
          <div className="in-blog-1 uk-grid uk-grid-stack" data-uk-grid>
          {load ?
                      <div className="linear-background w-100"> </div>
                        :
                        <>
                        <div className="uk-section in-offset-top-60 in-offset-top-50@s" style={{width: '100%'}}>
  <div className="uk-container">
    <div className="uk-grid uk-child-width-1-2@m in-testimonial-7" data-uk-grid style={{flexDirection: 'column',alignItems: 'center'}}>
      
                                          <div className="uk-first-column" style={{position: 'relative'}}>
                                            <div className="uk-card uk-card-default uk-box-shadow-small uk-border-rounded" style={{paddingTop: '0',height: '100%'}}>
                                              {products.linkimg != null && products.haverssimg == 0?
                                                  <div className="uk-card-media-top">
                                                  <img src={api_asseturl+'education/'+products.linkimg} alt="Market Img" style={{width: '100%'}} />
                                                  </div>
                                                  :
                                                  <>
                                                  {products.haverssimg == 1 ?
                                                    <>
                                                    <img src={products.linkimg} alt="image" style={{width: '100%'}}/>
                                                    </>
                                                    :
                                                    <>
                                                    <img src={app_url+'assets/noimagefound.png'} alt="image" style={{width: '100%'}}/>
                                                    </>
                                                  }
                                                  </>
                                                  }
                                                <div className="uk-card-body" style={{padding: '0.5rem'}}>
                                                <h5>
                                              <a href={products.singlelink} style={{textDecoration: 'none'}} className="fontnunito" target="_blank">{products.singletitle}</a>
                                              </h5>
                                              <div className="uk-flex uk-flex-middle">
                                              <div>
                                                <i className="fas fa-clock fa-xs" />
                                              </div>
                                              <div>
                                                <span className="uk-text-small uk-text-uppercase uk-text-muted uk-margin-small-left">Date: {products.date}</span>
                                              </div>
                                            </div>
                                                </div>
                                                <div className="uk-card-footer" style={{padding: '0.5rem'}}>
                                                    <footer>{products.singledes}</footer>
                                                </div>
                                            </div>
                                                  {/* <div style={{position: 'absolute',top: '5px',right: '5px'}}>
                                                    <div className="social-share">
                                                      <label className="toggle" htmlFor={'toggle'+1}>
                                                        <input type="checkbox" id={'toggle'+1} />
                                                        <div className="sharebtn">
                                                          <i className="fas fa-share-alt" />
                                                          <i className="fas fa-times" />
                                                          <div className="sharesocial">
                                                          <ShareSocial 
                                                            style={socialstyle}
                                                            url ={fullappurl+'marketnews'}
                                                            socialTypes={['facebook','twitter','reddit','linkedin','line']}
                                                          />
                                                          </div>
                                                        </div>
                                                      </label>
                                                    </div>
                                                  </div> */}

                                                  {/* <div style={{position: 'absolute',top: '5px',left: '50px'}}>
                                                    <div className="social-share">
                                                      <label className="toggle" htmlFor={'toggle'+1}>
                                                        <input type="checkbox" id={'toggle'+1} />
                                                        <div className="sharebtn" style={{borderRadius: '5px',width: '100px',height: '40px'}}>
                                                            <div style={{fontSize: '9px'}}>
                                                              <span>Date: 02-12-2022</span>
                                                              <br />
                                                              <span>Time: 7:00 PM</span>
                                                            </div>
                                                        </div>
                                                      </label>
                                                    </div>
                                                  </div> */}

                                          </div>

    </div>
  </div>
</div></>
          }



        </div>
          {/* <Pagination changePage={getData} data={products} options={options}/> */}
        </div>
        {/* <div className="uk-width-expand@m">
          <aside className="uk-margin-medium-bottom">
            <div className="uk-card uk-card-default uk-card-body uk-border-rounded">
              <h5 className="uk-heading-bullet uk-text-uppercase uk-margin-remove-bottom">Categories</h5>
              <ul className="uk-list in-widget-category"><li><a href="find.html?category=crypto">Crypto<span className="uk-label in-label-small uk-float-right">3</span></a></li><li><a href="find.html?category=health">Health<span className="uk-label in-label-small uk-float-right">2</span></a></li><li><a href="find.html?category=markets">Markets<span className="uk-label in-label-small uk-float-right">4</span></a></li><li><a href="find.html?category=politics">Politics<span className="uk-label in-label-small uk-float-right">2</span></a></li><li><a href="find.html?category=technology">Technology<span className="uk-label in-label-small uk-float-right">3</span></a></li></ul>
            </div>
          </aside>
          <aside className="uk-margin-medium-bottom">
            <div className="uk-card uk-card-default uk-card-body uk-border-rounded">
              <h5 className="uk-heading-bullet uk-text-uppercase uk-margin-remove-bottom">Latest News</h5>
              <ul className="uk-list uk-list-divider uk-list-large in-widget-latest">
                <li>
                  <a href="blackrock-responds-to-george-soros-criticism-over.html">BlackRock responds to George Soros’ criticism over China ...</a>
                  <span className="uk-article-meta uk-text-small"><br /><i className="fas fa-clock fa-sm uk-margin-small-right" />October 19, 2021</span>
                </li>
                <li>
                  <a href="india-is-better-equipped-to-deal-with-a-potential.html">India is ‘better equipped’ to deal with a potential Covid ...</a>
                  <span className="uk-article-meta uk-text-small"><br /><i className="fas fa-clock fa-sm uk-margin-small-right" />October 19, 2021</span>
                </li>
                <li>
                  <a href="china-portrays-the-us-as-weak-and-unreliable-to.html">China portrays the U.S. as ‘weak and unreliable’ to draw ...</a>
                  <span className="uk-article-meta uk-text-small"><br /><i className="fas fa-clock fa-sm uk-margin-small-right" />October 19, 2021</span>
                </li>
              </ul>
            </div>
          </aside>
          <aside className="uk-margin-medium-bottom">
            <div className="uk-card uk-card-default uk-card-body uk-border-rounded">
              <h5 className="uk-heading-bullet uk-text-uppercase uk-margin-remove-bottom">Tags</h5>
              <div className="uk-margin-top in-widget-tag"><a href="find.html?tag=asia"><span className="uk-label uk-border-pill">asia</span></a><a href="find.html?tag=crypto"><span className="uk-label uk-border-pill">crypto</span></a><a href="find.html?tag=economics"><span className="uk-label uk-border-pill">economics</span></a><a href="find.html?tag=europe"><span className="uk-label uk-border-pill">europe</span></a><a href="find.html?tag=health"><span className="uk-label uk-border-pill">health</span></a><a href="find.html?tag=investment"><span className="uk-label uk-border-pill">investment</span></a><a href="find.html?tag=politics"><span className="uk-label uk-border-pill">politics</span></a><a href="find.html?tag=technology"><span className="uk-label uk-border-pill">technology</span></a><a href="find.html?tag=usa"><span className="uk-label uk-border-pill">usa</span></a></div>
            </div>
          </aside>
        </div> */}
      </div>
    </div>
  </div>
</main>



  <Footer />
  </div>
      </>
    )
  }
