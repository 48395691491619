import React, { useEffect,useState } from 'react'
import Header from '../../common/Header';
import axios from 'axios';
import {api_url} from '../../common/Helpers';
import Footer from '../../common/Footer';
export default function Contact(){
  const [load, setload] = useState(false)
  const [allgainerdata, setallgainerdata] = useState([])

  useEffect(()=>{
    fetchdatas();
  },[])
  const fetchdatas = async () =>{
    setload(true)
    await axios.get(api_url+`getnifty50looser`).then(({data})=>{
      setallgainerdata(data)
      setload(false)
      // fetchdata()
    })
  }
  const fetchdata = async () =>{
    await axios.get(api_url+`getnifty50looserrlatest`).then(({data})=>{
      setallgainerdata(data)
    })
  }
return (
      <>
     <div>
  <Header />
 <main>
  <div className="uk-section uk-margin-small-top">
    <div className="uk-container">
    <div className="uk-container" style={{marginTop: '1.4rem'}}>
      <div className="row">
        <div className="col-12">
        <div className="uk-card uk-card-default uk-card-body uk-border-rounded" style={{
          margin: 0,
          padding: 0,
          border: '1px solid #C17951',
          borderRadius: '0px',
          }}>
        <div className='uk-text-center'>
        <h2 style={{background: '#FDD965',margin: 0}}>Top Losers</h2>
        </div>
        {load ?
        <div className="linear-background w-100"> </div>
          :
          <>
        <div className="table-responsive">
        <table class="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center" style={{margin: 0}}>
          <thead>
              <tr>
                  <th class="uk-text-center">Company Name</th>
                  <th class="uk-text-center">Last Price</th>
                  <th class="uk-text-center">High</th>
                  <th class="uk-text-center">Low</th>
                  <th class="uk-text-center">Prev Close</th>
                  <th class="uk-text-center">Change</th>
                  <th class="uk-text-center">% Loss</th>
              </tr>
          </thead>
          <tbody>
          {
                                        allgainerdata.length > 0 && (
                                          allgainerdata.map((row, key)=>(
                                              <tr>
                                              <td className="text-center pe-0">
                                                <div>{ row.company }</div>
                                              </td>
                                              <td className="text-center pe-0">
                                              <div>{ row.lastprice}</div>
                                              </td>
                                              <td className="text-center pe-0">
                                              <div>{ row.high}</div>
                                              </td>
                                              <td className="text-center pe-0">
                                              <div>{ row.low}</div>
                                              </td>
                                              <td className="text-center pe-0">
                                              <div>{ row.prevclose}</div>
                                              </td>
                                              {Math.sign(row.change) == -1 ?
                                              <td className="text-center pe-0">
                                                <div style={{color: 'red'}}>{ row.change }</div>
                                              </td> 
                                              :
                                              <td className="text-center pe-0">
                                                <div style={{color: 'green'}}>{ row.change }</div>
                                              </td>
                                              }
                                              {Math.sign(row.gain) == -1 ?
                                              <td className="text-center pe-0">
                                                <div style={{color: 'red'}}>{ row.gain }</div>
                                              </td> 
                                              :
                                              <td className="text-center pe-0">
                                                <div style={{color: 'green'}}>{ row.gain }</div>
                                              </td>
                                              }
                                              
                                            </tr>
                                            ))
                                        )
    }
          </tbody>
        </table>
        </div>
          </>
          }
        </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</main>



  <Footer />
  </div>
      </>
    )
  }
