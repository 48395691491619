import React, { useEffect,useState } from 'react'
import {Link,useNavigate} from 'react-router-dom';
import Header from './Header';
import axios from 'axios';
import {app_url,asseturl} from './Helpers';
import Select from "react-select";
import Slider from "react-slick";
import $ from 'jquery';
import Swal from 'sweetalert2';
import Footer from './Footer';
export default function Contact(){
  const [submit, setsubmit] = useState(false)
  useEffect(()=>{
    },[])
return (
      <>
     <div>
  <Header />
<main>
  {/* section content begin */}
  <div className="uk-section uk-padding-remove-vertical uk-margin-medium-bottom">
    <div className="uk-container">
      <div className="uk-grid">
        <div className="uk-width-1-1">
          <div className="uk-card uk-card-default uk-border-rounded uk-background-center uk-background-contain uk-background-image@m" style={{backgroundImage: 'url(img/blockit/in-team-background-1.png)'}} data-uk-parallax="bgy: -100">
            <div className="uk-card-body">
              <div className="uk-grid uk-flex uk-flex-center">
                <div className="uk-width-3-4@m uk-text-left">
                  <h2>PRIVACY POLICY</h2>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>Introduction</h3>
                  <p>
                  At Finakin, our mission is to empower traders and investors by providing cutting-edge data and analytics tools that enable them to make informed decisions in the financial markets. We aim to democratize access to market insights and facilitate financial literacy by offering cost-effective, innovative solutions to our clients. Our ultimate goal is to become the leading provider of data-driven trading solutions in India and beyond.
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>Information We Collect</h3>
                  <h6>We may collect the following types of information when you use our website, products, or services:</h6>
                  <p>
                  •	Personal information, such as your name, email address, and phone number <br />
                  •	Billing information, such as your credit card details <br />
                  •	Usage information, such as your IP address, browser type, and operating system <br />
                  •	Demographic information, such as your age, gender, and location <br />
                  •	Use of Information <br />
                  •	We may use your personal information for the following purposes: <br />
                  •	To provide you with our products and services <br />
                  •	To process your payments and communicate with you about your orders <br />
                  •	To improve our website, products, and services <br />
                  •	To send you marketing communications and other promotional materials <br />
                  •	To comply with legal and regulatory requirements <br />
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>Disclosure of Information</h3>
                  <h6>We may disclose your personal information to third parties in the following circumstances:</h6>
                  <p>
                  •	To our service providers, such as payment processors and hosting providers, who assist us in providing our products and services <br />
                  •	To our business partners and affiliates for marketing and promotional purposes <br />
                  •	To comply with legal and regulatory requirements, such as court orders and government investigations <br />
                  •	To protect our rights, property, or safety, or the rights, property, or safety of others <br />
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>Data Security</h3>
                  <p>
                  We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, we cannot guarantee the security of your information, as no method of transmission over the internet or electronic storage is completely secure.
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>Cookies</h3>
                  <p>
                  We may use cookies and other similar technologies to collect and store information about your preferences and usage of our website. You may disable cookies in your browser settings, but this may affect your ability to use our website and access certain features.
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>Children's Privacy</h3>
                  <p>
                  Our website, products, and services are not intended for use by children under the age of 18. We do not knowingly collect personal information from children under the age of 18.
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>Changes to Privacy Policy</h3>
                  <p>
                  We may update this privacy policy from time to time. Any changes will be posted on our website, and your continued use of our website, products, or services after such changes constitutes your acceptance of the revised privacy policy.
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>Contact Information</h3>
                  <p>
                  If you have any questions or concerns regarding our privacy policy, please contact us at support@finakin.com.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* section content end */}
  {/* section content begin */}
  {/* <div className="uk-section in-counter-2">
    <div className="uk-container">
      <div className="uk-grid uk-flex uk-flex-left">
        <div className="uk-width-3-4@m">
          <div className="uk-grid uk-flex uk-flex-middle" data-uk-grid>
            <div className="uk-width-1-2@m">
              <h4 className="uk-text-muted">Number speaks</h4>
              <h1 className="uk-margin-medium-bottom">We always ready<br />for a challenge.</h1>
              <a href="#" className="uk-button uk-button-primary uk-border-rounded">Learn more<i className="fas fa-arrow-circle-right uk-margin-small-left" /></a>
            </div>
            <div className="uk-width-1-2@m">
              <div className="uk-margin-large" data-uk-grid>
                <div className="uk-width-1-3@m">
                  <h1 className="uk-text-primary uk-text-right@m">
                    <span className="count" data-counter-end={410}>0</span>
                  </h1>
                  <hr className="uk-divider-small uk-text-right@m" />
                </div>
                <div className="uk-width-expand@m">
                  <h3>Trading instruments</h3>
                  <p>Reprehenderit qui in svoluptate velit esse quam molestiae vel illum qui dolorem eum fugiat quo.</p>
                </div>
              </div>
              <div className="uk-margin-large" data-uk-grid>
                <div className="uk-width-1-3@m">
                  <h1 className="uk-text-primary uk-text-right@m">
                    <span className="count" data-counter-end={27}>0</span>
                  </h1>
                  <hr className="uk-divider-small uk-text-right@m" />
                </div>
                <div className="uk-width-expand@m">
                  <h3>Countries covered</h3>
                  <p>At vero eos et accusamus iusto dignissimos ducimus qui blanditiis praesentium voluta deleniti.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> */}
  {/* section content end */}
</main>


  <Footer />
  </div>
      </>
    )
  }
