import React, { useEffect,useState } from 'react'
import {Link,useNavigate} from 'react-router-dom';
import Header from '../../common/Header';
import axios from 'axios';
import {app_url,asseturl,api_url} from '../../common/Helpers';
import Footer from '../../common/Footer';
export default function Contact(){
  const [globalmarketfeatures, setglobalmarketfeatures] = useState([])
  const [submit, setsubmit] = useState(false)
  const [load, setload] = useState(false)
  const [prevData, setPrevData] = useState([
    {
        "change": "-47.00",
        "changepercent": "-0.27%",
        "high": "17,190.00",
        "last": "17,115.00",
        "low": "17,002.00",
        "name": "SGX NIFTY"
    },
    {
        "change": "-251.60",
        "changepercent": "-0.78%",
        "high": "32,340.00",
        "last": "31,995.00",
        "low": "31,986.00",
        "name": "DOW JONES"
    },
    {
        "change": "-45.20",
        "changepercent": "-0.36%",
        "high": "12,633.20",
        "last": "12,536.20",
        "low": "12,532.20",
        "name": "NASDAQ"
    },
    {
        "change": "-28.40",
        "changepercent": "-0.72%",
        "high": "3,975.90",
        "last": "3,931.90",
        "low": "3,931.90",
        "name": "S&P"
    },
    {
        "change": "-34.00",
        "changepercent": "-0.46%",
        "high": "7,506.00",
        "last": "7,369.50",
        "low": "7,369.00",
        "name": "FTSE"
    },
    {
        "change": "-105.80",
        "changepercent": "-1.49%",
        "high": "7,114.20",
        "last": "6,976.20",
        "low": "6,975.20",
        "name": "CAC"
    },
    {
        "change": "-132.00",
        "changepercent": "-0.87%",
        "high": "15,312.00",
        "last": "15,018.00",
        "low": "15,016.00",
        "name": "DAX"
    },
    {
        "change": "-98.00",
        "changepercent": "-2.38%",
        "high": "4,110.00",
        "last": "4,027.00",
        "low": "3,955.00",
        "name": "EURO STOXX"
    },
    {
        "change": "+112.50",
        "changepercent": "+0.42%",
        "high": "27,147.50",
        "last": "26,852.50",
        "low": "26,802.50",
        "name": "NIKKEI"
    },
    {
        "change": "+76.50",
        "changepercent": "+0.40%",
        "high": "19,635.50",
        "last": "19,441.50",
        "low": "19,054.00",
        "name": "HANG SENG"
    }
]);
  useEffect(()=>{
    fetchdatas();
  },[])
  const fetchdatas = async () =>{
    setload(true)
    await axios.get(api_url+`getglobalmarketfeatures`).then(({data})=>{
      let filter = []
      for (let x in data) {
        if (!prevData[x] || prevData[x].last !== data[x].last) {
          data[x].highlight = true;
        }
        filter.push(data[x]);
      }
      setglobalmarketfeatures(filter);
      setPrevData(data);
      setload(false)
    })
  }
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    const timer = setTimeout(() => {
      setCounter(counter + 1);
      fetchdata()
    }, 10000);
    
    return () => {
      clearTimeout(timer);
    };
  }, [counter]);
  const fetchdata = async () => {
    await axios.get(api_url + `getglobalmarketfeatures`).then(({ data }) => {
      let filter = []
      for (let x in data) {
        console.log(prevData[x].last +' '+ data[x].last)
        if (!prevData[x] || prevData[x].last !== data[x].last) {
          data[x].highlight = true;
        }
        filter.push(data[x]);
      }
      setglobalmarketfeatures(filter);
      setPrevData(data);
    });
  };
return (
      <>
     <div>
  <Header />
 <main>
  {/* section content begin */}
  <div className="uk-section uk-margin-small-top">
    <div className="uk-container" style={{marginTop: '1rem'}}>
    <div className="uk-card uk-card-default uk-card-body uk-border-rounded" style={{
      margin: 0,
      padding: 0,
      border: '1px solid #C17951',
      borderRadius: '0px',
      }}>
    <div className='uk-text-center'>
    <h2 style={{background: '#FDD965',margin: 0}}>Global Markets Futures</h2>
    </div>
    {load?
    <div className="linear-background w-100"> </div>
    :
    <div className="table-responsive">
      <table class="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center" style={{marginTop: '0'}}>
      <thead>
          <tr>
              <th class="uk-text-center">Market</th>
              <th class="uk-text-center">Last Value</th>
              <th class="uk-text-center">High</th>
              <th class="uk-text-center">Low</th>
              <th class="uk-text-center">Change</th>
              <th class="uk-text-center">% Change</th>
          </tr>
      </thead>
      <tbody>
      {
                                    globalmarketfeatures.length > 0 && (
                                      globalmarketfeatures.map((row, key)=>(
                                          <tr>
                                          <td className="text-center pe-0">
                                            <div>{ row.name }</div>
                                          </td>
                                          {Math.sign(parseFloat(row.last)) == -1 ?
                                          <td className="text-center pe-0">
                                            <div style={{ fontWeight: row.highlight ? 900 : 'normal',color: 'red' }}>{row.last}</div>
                                          </td> 
                                          :
                                          <td className="text-center pe-0">
                                            <div style={{ fontWeight: row.highlight ? 900 : 'normal',color: 'green' }}>{row.last}</div>
                                          </td>
                                          }
                                          {Math.sign(parseFloat(row.high)) == -1 ?
                                          <td className="text-center pe-0">
                                            <div style={{color: 'red'}}>{ row.high }</div>
                                          </td> 
                                          :
                                          <td className="text-center pe-0">
                                            <div style={{color: 'green'}}>{ row.high }</div>
                                          </td>
                                          }
                                          {Math.sign(parseFloat(row.low)) == -1 ?
                                          <td className="text-center pe-0">
                                            <div style={{color: 'red'}}>{ row.low }</div>
                                          </td> 
                                          :
                                          <td className="text-center pe-0">
                                            <div style={{color: 'green'}}>{ row.low }</div>
                                          </td>
                                          }
                                          {Math.sign(parseFloat(row.change)) == -1 ?
                                          <td className="text-center pe-0">
                                            <div style={{color: 'red'}}>{ row.change }</div>
                                          </td> 
                                          :
                                          <td className="text-center pe-0">
                                            <div style={{color: 'green'}}>{ row.change }</div>
                                          </td>
                                          }
                                          
                                          {Math.sign(parseFloat(row.changepercent)) == -1 ?
                                          <td className="text-center pe-0">
                                            <div style={{color: 'red'}}>{ row.changepercent }</div>
                                          </td> 
                                          :
                                          <td className="text-center pe-0">
                                            <div style={{color: 'green'}}>{ row.changepercent }</div>
                                          </td>
                                          }
                                        </tr>
                                        ))
                                    )
}
      </tbody>
    </table>
    </div>
    }
    </div>
    </div>
  </div>
  {/* section content end */}
</main>



  <Footer />
  </div>
      </>
    )
  }
