import React, { useEffect, useState } from "react";
import Header from "../../common/Header";
import axios from "axios";
import { api_url, MasterTimeline } from "../../common/Helpers";
import "../../common/style.css";
import $ from "jquery";
import Swal from "sweetalert2";
import Footer from "../../common/Footer";
import Highcharts from "highcharts/highstock";
import Select from "react-select";
export default function Contact() {
  const [submit, setsubmit] = useState(false);
  const [load, setload] = useState(false);
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    fetchdata();
    const timer = setTimeout(() => {
      setCounter(counter + 1);
      fetchdata();
    }, 50000);

    return () => {
      clearTimeout(timer);
    };
  }, [counter]);
  const fetchdata = async () => {
    await axios.get(api_url + "getfutureoi").then(({ data }) => {
      let alloichangenifty = [];
      let allpricenifty = [];
      let alloiniftybank = [];
      let allpricebanknifty = [];
      let timestamp = [];
      let banktimestamp = [];
      for (let x in data.nifty50) {
        timestamp.push(data.nifty50[x].time);
        alloichangenifty.push(Number(data.nifty50[x].oichange));
        allpricenifty.push(Number(data.nifty50[x].price));
      }
      for (let y in data.banknifty) {
        banktimestamp.push(data.banknifty[y].time);
        alloiniftybank.push(Number(data.banknifty[y].oichange));
        allpricebanknifty.push(Number(data.banknifty[y].price));
      }
      Highcharts.chart("containerniftyfuture", {
        chart: {
          type: "spline",
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: timestamp,
        },
        yAxis: [
          {
            // Primary yAxis
            labels: {
              format: "{value}",
            },
            title: {
              text: "OI",
            },
            opposite: false,
          },
          {
            // Secondary yAxis
            gridLineWidth: 0,
            title: {
              text: "Future Price",
            },
            labels: {
              format: "{value}",
            },
            opposite: true,
          },
        ],
        tooltip: {
          shared: true,
          useHTML: true, // enable HTML in tooltip
          pointFormatter: function () {
            var seriesName = this.series.name;
            var valuePrefix = "";
            var valueSuffix = "";
            if (seriesName === "Spot Price") {
              valuePrefix = "";
              valueSuffix = "";
            } else {
              valueSuffix = " ";
            }
            return (
              '<tr><td style="color: ' +
              this.color +
              '">' +
              seriesName +
              ': </td><td style="text-align: right"><b>' +
              valuePrefix +
              Highcharts.numberFormat(this.y, 2) +
              valueSuffix +
              "</b></td></tr>"
            );
          },
          footerFormat: "</table>",
          headerFormat: "<table>",
          valueDecimals: 2,
        },
        plotOptions: {
          line: {
            marker: {
              enabled: false,
            },
          },
          spline: {
            marker: {
              enabled: false,
            },
          },
        },
        series: [
          {
            name: "OI",
            type: "spline",
            yAxis: 0,
            data: alloichangenifty,
            color: data.niftycolor,
            tooltip: {
              valueSuffix: " %",
            },
          },
          {
            name: "Future Price",
            type: "line",
            yAxis: 1,
            data: allpricenifty,
            color: "#000",
            tooltip: {
              valuePrefix: "$",
            },
          },
        ],
      });
      Highcharts.chart("containerbankfuture", {
        chart: {
          type: "spline",
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: banktimestamp,
        },
        yAxis: [
          {
            // Secondary yAxis
            gridLineWidth: 0,
            title: {
              text: "Future Price",
            },
            labels: {
              format: "{value}",
            },
            opposite: true,
          },
          {
            // Primary yAxis
            labels: {
              format: "{value}",
            },
            title: {
              text: "OI",
            },
          },
        ],
        tooltip: {
          shared: true,
          useHTML: true, // enable HTML in tooltip
          pointFormatter: function () {
            var seriesName = this.series.name;
            var valuePrefix = "";
            var valueSuffix = "";
            if (seriesName === "Spot Price") {
              valuePrefix = "";
              valueSuffix = "";
            } else {
              valueSuffix = " ";
            }
            return (
              '<tr><td style="color: ' +
              this.color +
              '">' +
              seriesName +
              ': </td><td style="text-align: right"><b>' +
              valuePrefix +
              Highcharts.numberFormat(this.y, 2) +
              valueSuffix +
              "</b></td></tr>"
            );
          },
          footerFormat: "</table>",
          headerFormat: "<table>",
          valueDecimals: 2,
        },
        plotOptions: {
          line: {
            marker: {
              enabled: false,
            },
          },
          spline: {
            marker: {
              enabled: false,
            },
          },
        },
        series: [
          {
            name: "OI",
            type: "spline",
            yAxis: 1,
            data: alloiniftybank,
            color: data.bankniftycolor,
            tooltip: {
              valueSuffix: " %",
            },
          },
          {
            name: "Future Price",
            type: "line",
            yAxis: 0,
            data: allpricebanknifty,
            color: "#000",
            tooltip: {
              valuePrefix: "$",
            },
          },
        ],
      });
    });
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (
      /android/i.test(userAgent) ||
      (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
    ) {
      setIsMobile(true);
    }
  }, []);
  return (
    <>
      <div>
        <Header />
        <main>
          {/* section content begin */}
          <div className="uk-section uk-margin-small-top">
            <div
              className="uk-container"
              style={{ marginTop: "1.4rem", maxWidth: "100%",maxWidth: '1400px' }}
            >
              <div className="row">
                <div className="col-12 mb-1">
                  <div className="uk-text-center">
                    <h2
                      style={{
                        background: "#FDD965",
                        padding: "3px",
                        marginBottom: "0px",
                      }}
                    >
                      Future Price Vs OI
                    </h2>
                  </div>
                </div>
                <div className="col-12">
                  {load ? (
                    <div className="linear-background w-100"> </div>
                  ) : (
                    <div
                      className="uk-card uk-card-default uk-card-body uk-border-rounded"
                      style={{
                        margin: 0,
                        padding: 0,
                        border: "1px solid #C17951",
                        borderRadius: "0px",
                      }}
                    >
                      <h2
                        style={{
                          background: "#FDF1CC",
                          margin: 0,
                          textAlign: "center",
                        }}
                      >
                        NIFTY 50
                      </h2>
                      <figure class="highcharts-figure">
                        <div
                          id="containerniftyfuture"
                          style={{ height: "500px" }}
                        ></div>
                      </figure>
                    </div>
                  )}
                </div>
                <div className="col-12 mt-5">
                  {load ? (
                    <div className="linear-background w-100"> </div>
                  ) : (
                    <div
                      className="uk-card uk-card-default uk-card-body uk-border-rounded"
                      style={{
                        margin: 0,
                        padding: 0,
                        border: "1px solid #C17951",
                        borderRadius: "0px",
                      }}
                    >
                      <h2
                        style={{
                          background: "#FDF1CC",
                          margin: 0,
                          textAlign: "center",
                        }}
                      >
                        BANK NIFTY
                      </h2>
                      <figure class="highcharts-figure">
                        <div
                          id="containerbankfuture"
                          style={{ height: "500px" }}
                        ></div>
                      </figure>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
}
