import React, { useEffect,useState } from 'react'
import {Link,useNavigate} from 'react-router-dom';
import Header from '../common/Header';
import axios from 'axios';
import {app_url,asseturl} from '../common/Helpers';
import Select from "react-select";
import Slider from "react-slick";
import $ from 'jquery';
import Swal from 'sweetalert2';
import Footer from './Footer';
export default function Contact(){
  const [submit, setsubmit] = useState(false)
  useEffect(()=>{
    },[])
return (
      <>
     <div>
  <Header />
<main>
  {/* section content begin */}
  <div className="uk-section uk-padding-remove-vertical uk-margin-medium-bottom">
    <div className="uk-container">
      <div className="uk-grid">
        <div className="uk-width-1-1">
          <div className="uk-card uk-card-default uk-border-rounded uk-background-center uk-background-contain uk-background-image@m" style={{backgroundImage: 'url(img/blockit/in-team-background-1.png)'}} data-uk-parallax="bgy: -100">
            <div className="uk-card-body">
              <div className="uk-grid uk-flex uk-flex-center">
                <div className="uk-width-3-4@m uk-text-center">
                  <h2>About Us</h2>
                  <p>Finakin commenced providing technical analysis products and educational services to the burgeoning cohort of Indian traders who sought cost-efficient tools, data, and charting solutions.</p>
                </div>
                <div className="uk-width-3-4@m uk-text-center mt-4">
                  <p>Finakin is a company that was envisaged as a provider of data and analytics. Currently, the data tools we offer reach thousands of investors, traders, and institutions every day through our web portal, https://www.finakin.com, across India. Presently, Finakin has an expanding toolset for option traders and has gained a reputation for democratizing options data analysis. We persist in augmenting our offerings by introducing additional tools, extending from NSE equity F&O to NSE Currency F&O today.</p>
                </div>
                <div className="uk-width-3-4@m uk-text-center mt-4">
                  <p>You can entrust us to perpetually expand and develop innovative trading tools that are cost-effective, facilitated by a dynamic team of analysts, developers, and client service representatives. Contact us today to learn how we can assist you and discuss partnerships and tie-ups.</p>
                </div>
                <div className="uk-width-3-4@m uk-text-center mt-4">
                  <h2>Mission Statement</h2>
                  <p>
                  At Finakin, our mission is to empower traders and investors by providing cutting-edge data and analytics tools that enable them to make informed decisions in the financial markets. We aim to democratize access to market insights and facilitate financial literacy by offering cost-effective, innovative solutions to our clients. Our ultimate goal is to become the leading provider of data-driven trading solutions in India and beyond.
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-center mt-4">
                  <h2>Vision Statement</h2>
                  <p>
                    Our vision at Finakin is to revolutionize the way traders and investors approach financial markets by leveraging advanced technology and data analytics. We strive to be the go-to resource for market insights and education and to empower individuals and institutions to navigate the complexities of the financial world with confidence. Our long-term goal is to transform the financial industry by promoting transparency, accessibility, and inclusivity through our innovative solutions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* section content end */}
  {/* section content begin */}
  {/* <div className="uk-section in-counter-2">
    <div className="uk-container">
      <div className="uk-grid uk-flex uk-flex-center">
        <div className="uk-width-3-4@m">
          <div className="uk-grid uk-flex uk-flex-middle" data-uk-grid>
            <div className="uk-width-1-2@m">
              <h4 className="uk-text-muted">Number speaks</h4>
              <h1 className="uk-margin-medium-bottom">We always ready<br />for a challenge.</h1>
              <a href="#" className="uk-button uk-button-primary uk-border-rounded">Learn more<i className="fas fa-arrow-circle-right uk-margin-small-left" /></a>
            </div>
            <div className="uk-width-1-2@m">
              <div className="uk-margin-large" data-uk-grid>
                <div className="uk-width-1-3@m">
                  <h1 className="uk-text-primary uk-text-right@m">
                    <span className="count" data-counter-end={410}>0</span>
                  </h1>
                  <hr className="uk-divider-small uk-text-right@m" />
                </div>
                <div className="uk-width-expand@m">
                  <h3>Trading instruments</h3>
                  <p>Reprehenderit qui in svoluptate velit esse quam molestiae vel illum qui dolorem eum fugiat quo.</p>
                </div>
              </div>
              <div className="uk-margin-large" data-uk-grid>
                <div className="uk-width-1-3@m">
                  <h1 className="uk-text-primary uk-text-right@m">
                    <span className="count" data-counter-end={27}>0</span>
                  </h1>
                  <hr className="uk-divider-small uk-text-right@m" />
                </div>
                <div className="uk-width-expand@m">
                  <h3>Countries covered</h3>
                  <p>At vero eos et accusamus iusto dignissimos ducimus qui blanditiis praesentium voluta deleniti.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> */}
  {/* section content end */}
</main>


  <Footer />
  </div>
      </>
    )
  }
