import React, { useEffect, useState,useRef } from "react";
import { useReactToPrint } from "react-to-print";
import Header from "../../common/Header";
import axios from "axios";
import { app_url, asseturl, api_url, fullappurl } from "../../common/Helpers";
import Select from "react-select";
import Slider from "react-slick";
import $ from "jquery";
import Swal from "sweetalert2";
import Footer from "../../common/Footer";
import copy from "copy-to-clipboard";
import FeatherIcon from "feather-icons-react";
import { Chart } from "react-google-charts";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export default function Contact() {
  const [copyText, setCopyText] = useState("");
  // const copyToClipboard = () => {
  //   copy(fullappurl + "flldlldata");
  //   setCopyText(fullappurl + "flldlldata");
  // };
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    fileName: 'my-document.pdf',
    removeAfterPrint: true,
    options: {
      color: true,
    },
    onAfterPrint: () => {
      const link = document.createElement('a');
      link.href = 'my-document.pdf';
      link.download = 'my-document.pdf';
      link.click();
    },
  });
  const exportPDF = () => {
    const input = document.getElementById('divId'); // Replace 'divId' with the id of your div element
  
    const options = {
      scrollX: 0, // Set scrollX to 0 to disable horizontal scrolling
      scrollY: 0, // Set scrollY to 0 to disable vertical scrolling
    };
  
    html2canvas(input, options)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight() * 1; // Increase the height by multiplying with a factor (e.g., 2)
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight-4);
        pdf.save('FinAkin.pdf');
      })
      .catch((error) => {
        console.log('An error occurred:', error);
      });
  };
  
  
  
  const [statistics, setstatistics] = useState([]);
  const [statisticsold, setstatisticsold] = useState([]);
  const [flldlllatest, setflldlllatest] = useState([]);
  const [submit, setsubmit] = useState(false);
  const [flldlllall, setflldlllall] = useState([]);
  const [load, setload] = useState(false);
  const [showgraph, setshowgraph] = useState(false);
  const [totalbuyvalue, settotalbuyvalue] = useState();
  const [totalsellvalue, settotalsellvalue] = useState();
  const [totalnetvalue, settotalnetvalue] = useState();
  const [datadate, setdatadate] = useState();

  const [clientdata, setclientdata] = useState([]);
  const [dlldata, setdlldata] = useState([]);
  const [flldata, setflldata] = useState([]);
  const [prodata, setprodata] = useState([]);

  const [graphdata, setgraphdata] = useState([]);
  

  useEffect(() => {
    fetchdatas();
  }, []);
  const fetchdatas = async () => {
    await axios.get(api_url + `getflldlldata`).then(({ data }) => {
      setflldlllatest(data.latest);
      setstatistics(data.dstatistics)
      setstatisticsold(data.dstatisticsold)
      for (let x in data.latest) {
        setdatadate(data.latest[x]["date"]);
      }
      let filterdata = []; 
      for (let x in data.all) {
        const netvalue = parseFloat(data.all[x]["netvalue"].replace(',', ''));
        filterdata.push({...data.all[x], netvalue});
      }
      setflldlllall(filterdata);

      let allgraphdata = [];
      let arr = [
        "Date",
        "DII NET",
        { role: "style" },
        "FII NET",
        { role: "style" },
      ];
      allgraphdata.push(arr);
      for (let k in data.graphdata) {
        var svalue = data.graphdata[k]["netvalue"];
        svalue = svalue.replace(/\,/g, "");
        svalue = parseInt(svalue, 10);
        var bvalue = data.graphdata[k]["extranetvalue"];
        bvalue = bvalue.replace(/\,/g, "");
        bvalue = parseInt(bvalue, 10);
        let arr = [
          data.graphdata[k]["date"],
          Number(svalue),
          "color: #fcb42d",
          Number(bvalue),
          "color: #1DA1F2",
        ];
        allgraphdata.push(arr);
      }
      setgraphdata(allgraphdata);
      let totalbuyAmt = 0;
      let totalsellAmt = 0;
      let totalnetAmt = 0;
      for (let x in data.all) {
        var a = data.all[x].buyvalue;
        a = a.replace(/\,/g, "");
        totalbuyAmt = Number(totalbuyAmt) + Number(a);
        var b = data.all[x].sellvalue;
        b = b.replace(/\,/g, "");
        totalsellAmt = Number(totalsellAmt) + Number(b);
        var c = data.all[x].netvalue;
        c = c.replace(/\,/g, "");
        totalnetAmt = Number(totalnetAmt) + Number(c);
      }
      settotalbuyvalue(Math.round(totalbuyAmt));
      settotalsellvalue(Math.round(totalsellAmt));
      settotalnetvalue(Math.round(totalnetAmt));
    });
    await axios.get(api_url + `getfaoparticipentupdate`).then(({ data }) => {
      // FLL DATA START
      const flldataArr = [
        {
          subcategory: "INDEX - Future",
          longcontract: calculateContract(
            data.twodayminus.fll.FutureIndexLong,
            data.onedayminus.fll.FutureIndexLong
          ),
          shortcontract: calculateContract(
            data.twodayminus.fll.FutureIndexShort,
            data.onedayminus.fll.FutureIndexShort
          ),
          netcontract: calculateNetContract(
            data.onedayminus.fll.FutureIndexLong -
              data.twodayminus.fll.FutureIndexLong,
            data.onedayminus.fll.FutureIndexShort -
              data.twodayminus.fll.FutureIndexShort
          ),
          previousday: calculateNetContract(
            data.twodayminus.fll.FutureIndexLong -
              data.threedayminus.fll.FutureIndexLong,
            data.twodayminus.fll.FutureIndexShort -
              data.threedayminus.fll.FutureIndexShort
          ),
        },
        {
          subcategory: "STOCK - Future",
          longcontract: calculateContract(
            data.twodayminus.fll.FutureStockLong,
            data.onedayminus.fll.FutureStockLong
          ),
          shortcontract: calculateContract(
            data.twodayminus.fll.FutureStockShort,
            data.onedayminus.fll.FutureStockShort
          ),
          netcontract: calculateNetContract(
            data.onedayminus.fll.FutureStockLong -
              data.twodayminus.fll.FutureStockLong,
            data.onedayminus.fll.FutureStockShort -
              data.twodayminus.fll.FutureStockShort
          ),
          previousday: calculateNetContract(
            data.twodayminus.fll.FutureStockLong -
              data.threedayminus.fll.FutureStockLong,
            data.twodayminus.fll.FutureStockShort -
              data.threedayminus.fll.FutureStockShort
          ),
        },
        {
          subcategory: "INDEX - OPT CALL",
          longcontract: calculateContract(
            data.twodayminus.fll.OptionIndexCallLong,
            data.onedayminus.fll.OptionIndexCallLong
          ),
          shortcontract: calculateContract(
            data.twodayminus.fll.OptionIndexCallShort,
            data.onedayminus.fll.OptionIndexCallShort
          ),
          netcontract: calculateNetContract(
            data.onedayminus.fll.OptionIndexCallLong -
              data.twodayminus.fll.OptionIndexCallLong,
            data.onedayminus.fll.OptionIndexCallShort -
              data.twodayminus.fll.OptionIndexCallShort
          ),
          previousday: calculateNetContract(
            data.twodayminus.fll.OptionIndexCallLong -
              data.threedayminus.fll.OptionIndexCallLong,
            data.twodayminus.fll.OptionIndexCallShort -
              data.threedayminus.fll.OptionIndexCallShort
          ),
        },
        {
          subcategory: "INDEX - OPT PUT",
          longcontract: calculateContract(
            data.twodayminus.fll.OptionIndexPutLong,
            data.onedayminus.fll.OptionIndexPutLong
          ),
          shortcontract: calculateContract(
            data.twodayminus.fll.OptionIndexPutShort,
            data.onedayminus.fll.OptionIndexPutShort
          ),
          netcontract: calculateNetContract(
            data.onedayminus.fll.OptionIndexPutLong -
              data.twodayminus.fll.OptionIndexPutLong,
            data.onedayminus.fll.OptionIndexPutShort -
              data.twodayminus.fll.OptionIndexPutShort
          ),
          previousday: calculateNetContract(
            data.twodayminus.fll.OptionIndexPutLong -
              data.threedayminus.fll.OptionIndexPutLong,
            data.twodayminus.fll.OptionIndexPutShort -
              data.threedayminus.fll.OptionIndexPutShort
          ),
        },
        {
          subcategory: "STOCK - OPT CALL",
          longcontract: calculateContract(
            data.twodayminus.fll.OptionStockCallLong,
            data.onedayminus.fll.OptionStockCallLong
          ),
          shortcontract: calculateContract(
            data.twodayminus.fll.OptionStockCallShort,
            data.onedayminus.fll.OptionStockCallShort
          ),
          netcontract: calculateNetContract(
            data.onedayminus.fll.OptionStockCallLong -
              data.twodayminus.fll.OptionStockCallLong,
            data.onedayminus.fll.OptionStockCallShort -
              data.twodayminus.fll.OptionStockCallShort
          ),
          previousday: calculateNetContract(
            data.twodayminus.fll.OptionStockCallLong -
              data.threedayminus.fll.OptionStockCallLong,
            data.twodayminus.fll.OptionStockCallShort -
              data.threedayminus.fll.OptionStockCallShort
          ),
        },
        {
          subcategory: "STOCK - OPT PUT",
          longcontract: calculateContract(
            data.twodayminus.fll.OptionStockPutLong,
            data.onedayminus.fll.OptionStockPutLong
          ),
          shortcontract: calculateContract(
            data.twodayminus.fll.OptionStockPutShort,
            data.onedayminus.fll.OptionStockPutShort
          ),
          netcontract: calculateNetContract(
            data.onedayminus.fll.OptionStockPutLong -
              data.twodayminus.fll.OptionStockPutLong,
            data.onedayminus.fll.OptionStockPutShort -
              data.twodayminus.fll.OptionStockPutShort
          ),
          previousday: calculateNetContract(
            data.twodayminus.fll.OptionStockPutLong -
              data.threedayminus.fll.OptionStockPutLong,
            data.twodayminus.fll.OptionStockPutShort -
              data.threedayminus.fll.OptionStockPutShort
          ),
        },
      ];
      function calculateContract(yesterday, today) {
        return today < yesterday
          ? (today - yesterday).toString()
          : (-1 * (yesterday - today)).toString();
      }
      function calculateNetContract(netlong, netshort) {
        return netlong - netshort;
      }
      setflldata(flldataArr);
      // DLL DATA
      const dlldataArr = [
        {
          subcategory: "INDEX - Future",
          longcontract: calculateContract(
            data.twodayminus.dll.FutureIndexLong,
            data.onedayminus.dll.FutureIndexLong
          ),
          shortcontract: calculateContract(
            data.twodayminus.dll.FutureIndexShort,
            data.onedayminus.dll.FutureIndexShort
          ),
          netcontract: calculateNetContract(
            data.onedayminus.dll.FutureIndexLong -
              data.twodayminus.dll.FutureIndexLong,
            data.onedayminus.dll.FutureIndexShort -
              data.twodayminus.dll.FutureIndexShort
          ),
          previousday: calculateNetContract(
            data.twodayminus.dll.FutureIndexLong -
              data.threedayminus.dll.FutureIndexLong,
            data.twodayminus.dll.FutureIndexShort -
              data.threedayminus.dll.FutureIndexShort
          ),
        },
        {
          subcategory: "STOCK - Future",
          longcontract: calculateContract(
            data.twodayminus.dll.FutureStockLong,
            data.onedayminus.dll.FutureStockLong
          ),
          shortcontract: calculateContract(
            data.twodayminus.dll.FutureStockShort,
            data.onedayminus.dll.FutureStockShort
          ),
          netcontract: calculateNetContract(
            data.onedayminus.dll.FutureStockLong -
              data.twodayminus.dll.FutureStockLong,
            data.onedayminus.dll.FutureStockShort -
              data.twodayminus.dll.FutureStockShort
          ),
          previousday: calculateNetContract(
            data.twodayminus.dll.FutureStockLong -
              data.threedayminus.dll.FutureStockLong,
            data.twodayminus.dll.FutureStockShort -
              data.threedayminus.dll.FutureStockShort
          ),
        },
        {
          subcategory: "INDEX - OPT CALL",
          longcontract: calculateContract(
            data.twodayminus.dll.OptionIndexCallLong,
            data.onedayminus.dll.OptionIndexCallLong
          ),
          shortcontract: calculateContract(
            data.twodayminus.dll.OptionIndexCallShort,
            data.onedayminus.dll.OptionIndexCallShort
          ),
          netcontract: calculateNetContract(
            data.onedayminus.dll.OptionIndexCallLong -
              data.twodayminus.dll.OptionIndexCallLong,
            data.onedayminus.dll.OptionIndexCallShort -
              data.twodayminus.dll.OptionIndexCallShort
          ),
          previousday: calculateNetContract(
            data.twodayminus.dll.OptionIndexCallLong -
              data.threedayminus.dll.OptionIndexCallLong,
            data.twodayminus.dll.OptionIndexCallShort -
              data.threedayminus.dll.OptionIndexCallShort
          ),
        },
        {
          subcategory: "INDEX - OPT PUT",
          longcontract: calculateContract(
            data.twodayminus.dll.OptionIndexPutLong,
            data.onedayminus.dll.OptionIndexPutLong
          ),
          shortcontract: calculateContract(
            data.twodayminus.dll.OptionIndexPutShort,
            data.onedayminus.dll.OptionIndexPutShort
          ),
          netcontract: calculateNetContract(
            data.onedayminus.dll.OptionIndexPutLong -
              data.twodayminus.dll.OptionIndexPutLong,
            data.onedayminus.dll.OptionIndexPutShort -
              data.twodayminus.dll.OptionIndexPutShort
          ),
          previousday: calculateNetContract(
            data.twodayminus.dll.OptionIndexPutLong -
              data.threedayminus.dll.OptionIndexPutLong,
            data.twodayminus.dll.OptionIndexPutShort -
              data.threedayminus.dll.OptionIndexPutShort
          ),
        },
        {
          subcategory: "STOCK - OPT CALL",
          longcontract: calculateContract(
            data.twodayminus.dll.OptionStockCallLong,
            data.onedayminus.dll.OptionStockCallLong
          ),
          shortcontract: calculateContract(
            data.twodayminus.dll.OptionStockCallShort,
            data.onedayminus.dll.OptionStockCallShort
          ),
          netcontract: calculateNetContract(
            data.onedayminus.dll.OptionStockCallLong -
              data.twodayminus.dll.OptionStockCallLong,
            data.onedayminus.dll.OptionStockCallShort -
              data.twodayminus.dll.OptionStockCallShort
          ),
          previousday: calculateNetContract(
            data.twodayminus.dll.OptionStockCallLong -
              data.threedayminus.dll.OptionStockCallLong,
            data.twodayminus.dll.OptionStockCallShort -
              data.threedayminus.dll.OptionStockCallShort
          ),
        },
        {
          subcategory: "STOCK - OPT PUT",
          longcontract: calculateContract(
            data.twodayminus.dll.OptionStockPutLong,
            data.onedayminus.dll.OptionStockPutLong
          ),
          shortcontract: calculateContract(
            data.twodayminus.dll.OptionStockPutShort,
            data.onedayminus.dll.OptionStockPutShort
          ),
          netcontract: calculateNetContract(
            data.onedayminus.dll.OptionStockPutLong -
              data.twodayminus.dll.OptionStockPutLong,
            data.onedayminus.dll.OptionStockPutShort -
              data.twodayminus.dll.OptionStockPutShort
          ),
          previousday: calculateNetContract(
            data.twodayminus.dll.OptionStockPutLong -
              data.threedayminus.dll.OptionStockPutLong,
            data.twodayminus.dll.OptionStockPutShort -
              data.threedayminus.dll.OptionStockPutShort
          ),
        },
      ];
      setdlldata(dlldataArr);
      // DLL DATA END
      // CLIENT DATA START FROM HERE
      const clientdataArr = [
        {
          subcategory: "INDEX - Future",
          longcontract: calculateContract(
            data.twodayminus.client.FutureIndexLong,
            data.onedayminus.client.FutureIndexLong
          ),
          shortcontract: calculateContract(
            data.twodayminus.client.FutureIndexShort,
            data.onedayminus.client.FutureIndexShort
          ),
          netcontract: calculateNetContract(
            data.onedayminus.client.FutureIndexLong -
              data.twodayminus.client.FutureIndexLong,
            data.onedayminus.client.FutureIndexShort -
              data.twodayminus.client.FutureIndexShort
          ),
          previousday: calculateNetContract(
            data.twodayminus.client.FutureIndexLong -
              data.threedayminus.client.FutureIndexLong,
            data.twodayminus.client.FutureIndexShort -
              data.threedayminus.client.FutureIndexShort
          ),
        },
        {
          subcategory: "STOCK - Future",
          longcontract: calculateContract(
            data.twodayminus.client.FutureStockLong,
            data.onedayminus.client.FutureStockLong
          ),
          shortcontract: calculateContract(
            data.twodayminus.client.FutureStockShort,
            data.onedayminus.client.FutureStockShort
          ),
          netcontract: calculateNetContract(
            data.onedayminus.client.FutureStockLong -
              data.twodayminus.client.FutureStockLong,
            data.onedayminus.client.FutureStockShort -
              data.twodayminus.client.FutureStockShort
          ),
          previousday: calculateNetContract(
            data.twodayminus.client.FutureStockLong -
              data.threedayminus.client.FutureStockLong,
            data.twodayminus.client.FutureStockShort -
              data.threedayminus.client.FutureStockShort
          ),
        },
        {
          subcategory: "INDEX - OPT CALL",
          longcontract: calculateContract(
            data.twodayminus.client.OptionIndexCallLong,
            data.onedayminus.client.OptionIndexCallLong
          ),
          shortcontract: calculateContract(
            data.twodayminus.client.OptionIndexCallShort,
            data.onedayminus.client.OptionIndexCallShort
          ),
          netcontract: calculateNetContract(
            data.onedayminus.client.OptionIndexCallLong -
              data.twodayminus.client.OptionIndexCallLong,
            data.onedayminus.client.OptionIndexCallShort -
              data.twodayminus.client.OptionIndexCallShort
          ),
          previousday: calculateNetContract(
            data.twodayminus.client.OptionIndexCallLong -
              data.threedayminus.client.OptionIndexCallLong,
            data.twodayminus.client.OptionIndexCallShort -
              data.threedayminus.client.OptionIndexCallShort
          ),
        },
        {
          subcategory: "INDEX - OPT PUT",
          longcontract: calculateContract(
            data.twodayminus.client.OptionIndexPutLong,
            data.onedayminus.client.OptionIndexPutLong
          ),
          shortcontract: calculateContract(
            data.twodayminus.client.OptionIndexPutShort,
            data.onedayminus.client.OptionIndexPutShort
          ),
          netcontract: calculateNetContract(
            data.onedayminus.client.OptionIndexPutLong -
              data.twodayminus.client.OptionIndexPutLong,
            data.onedayminus.client.OptionIndexPutShort -
              data.twodayminus.client.OptionIndexPutShort
          ),
          previousday: calculateNetContract(
            data.twodayminus.client.OptionIndexPutLong -
              data.threedayminus.client.OptionIndexPutLong,
            data.twodayminus.client.OptionIndexPutShort -
              data.threedayminus.client.OptionIndexPutShort
          ),
        },
        {
          subcategory: "STOCK - OPT CALL",
          longcontract: calculateContract(
            data.twodayminus.client.OptionStockCallLong,
            data.onedayminus.client.OptionStockCallLong
          ),
          shortcontract: calculateContract(
            data.twodayminus.client.OptionStockCallShort,
            data.onedayminus.client.OptionStockCallShort
          ),
          netcontract: calculateNetContract(
            data.onedayminus.client.OptionStockCallLong -
              data.twodayminus.client.OptionStockCallLong,
            data.onedayminus.client.OptionStockCallShort -
              data.twodayminus.client.OptionStockCallShort
          ),
          previousday: calculateNetContract(
            data.twodayminus.client.OptionStockCallLong -
              data.threedayminus.client.OptionStockCallLong,
            data.twodayminus.client.OptionStockCallShort -
              data.threedayminus.client.OptionStockCallShort
          ),
        },
        {
          subcategory: "STOCK - OPT PUT",
          longcontract: calculateContract(
            data.twodayminus.client.OptionStockPutLong,
            data.onedayminus.client.OptionStockPutLong
          ),
          shortcontract: calculateContract(
            data.twodayminus.client.OptionStockPutShort,
            data.onedayminus.client.OptionStockPutShort
          ),
          netcontract: calculateNetContract(
            data.onedayminus.client.OptionStockPutLong -
              data.twodayminus.client.OptionStockPutLong,
            data.onedayminus.client.OptionStockPutShort -
              data.twodayminus.client.OptionStockPutShort
          ),
          previousday: calculateNetContract(
            data.twodayminus.client.OptionStockPutLong -
              data.threedayminus.client.OptionStockPutLong,
            data.twodayminus.client.OptionStockPutShort -
              data.threedayminus.client.OptionStockPutShort
          ),
        },
      ];
      setclientdata(clientdataArr);

      // CLIENT END HERE
      // PRO START
      const prodataArr = [
        {
          subcategory: "INDEX - Future",
          longcontract: calculateContract(
            data.twodayminus.pro.FutureIndexLong,
            data.onedayminus.pro.FutureIndexLong
          ),
          shortcontract: calculateContract(
            data.twodayminus.pro.FutureIndexShort,
            data.onedayminus.pro.FutureIndexShort
          ),
          netcontract: calculateNetContract(
            data.onedayminus.pro.FutureIndexLong -
              data.twodayminus.pro.FutureIndexLong,
            data.onedayminus.pro.FutureIndexShort -
              data.twodayminus.pro.FutureIndexShort
          ),
          previousday: calculateNetContract(
            data.twodayminus.pro.FutureIndexLong -
              data.threedayminus.pro.FutureIndexLong,
            data.twodayminus.pro.FutureIndexShort -
              data.threedayminus.pro.FutureIndexShort
          ),
        },
        {
          subcategory: "STOCK - Future",
          longcontract: calculateContract(
            data.twodayminus.pro.FutureStockLong,
            data.onedayminus.pro.FutureStockLong
          ),
          shortcontract: calculateContract(
            data.twodayminus.pro.FutureStockShort,
            data.onedayminus.pro.FutureStockShort
          ),
          netcontract: calculateNetContract(
            data.onedayminus.pro.FutureStockLong -
              data.twodayminus.pro.FutureStockLong,
            data.onedayminus.pro.FutureStockShort -
              data.twodayminus.pro.FutureStockShort
          ),
          previousday: calculateNetContract(
            data.twodayminus.pro.FutureStockLong -
              data.threedayminus.pro.FutureStockLong,
            data.twodayminus.pro.FutureStockShort -
              data.threedayminus.pro.FutureStockShort
          ),
        },
        {
          subcategory: "INDEX - OPT CALL",
          longcontract: calculateContract(
            data.twodayminus.pro.OptionIndexCallLong,
            data.onedayminus.pro.OptionIndexCallLong
          ),
          shortcontract: calculateContract(
            data.twodayminus.pro.OptionIndexCallShort,
            data.onedayminus.pro.OptionIndexCallShort
          ),
          netcontract: calculateNetContract(
            data.onedayminus.pro.OptionIndexCallLong -
              data.twodayminus.pro.OptionIndexCallLong,
            data.onedayminus.pro.OptionIndexCallShort -
              data.twodayminus.pro.OptionIndexCallShort
          ),
          previousday: calculateNetContract(
            data.twodayminus.pro.OptionIndexCallLong -
              data.threedayminus.pro.OptionIndexCallLong,
            data.twodayminus.pro.OptionIndexCallShort -
              data.threedayminus.pro.OptionIndexCallShort
          ),
        },
        {
          subcategory: "INDEX - OPT PUT",
          longcontract: calculateContract(
            data.twodayminus.pro.OptionIndexPutLong,
            data.onedayminus.pro.OptionIndexPutLong
          ),
          shortcontract: calculateContract(
            data.twodayminus.pro.OptionIndexPutShort,
            data.onedayminus.pro.OptionIndexPutShort
          ),
          netcontract: calculateNetContract(
            data.onedayminus.pro.OptionIndexPutLong -
              data.twodayminus.pro.OptionIndexPutLong,
            data.onedayminus.pro.OptionIndexPutShort -
              data.twodayminus.pro.OptionIndexPutShort
          ),
          previousday: calculateNetContract(
            data.twodayminus.pro.OptionIndexPutLong -
              data.threedayminus.pro.OptionIndexPutLong,
            data.twodayminus.pro.OptionIndexPutShort -
              data.threedayminus.pro.OptionIndexPutShort
          ),
        },
        {
          subcategory: "STOCK - OPT CALL",
          longcontract: calculateContract(
            data.twodayminus.pro.OptionStockCallLong,
            data.onedayminus.pro.OptionStockCallLong
          ),
          shortcontract: calculateContract(
            data.twodayminus.pro.OptionStockCallShort,
            data.onedayminus.pro.OptionStockCallShort
          ),
          netcontract: calculateNetContract(
            data.onedayminus.pro.OptionStockCallLong -
              data.twodayminus.pro.OptionStockCallLong,
            data.onedayminus.pro.OptionStockCallShort -
              data.twodayminus.pro.OptionStockCallShort
          ),
          previousday: calculateNetContract(
            data.twodayminus.pro.OptionStockCallLong -
              data.threedayminus.pro.OptionStockCallLong,
            data.twodayminus.pro.OptionStockCallShort -
              data.threedayminus.pro.OptionStockCallShort
          ),
        },
        {
          subcategory: "STOCK - OPT PUT",
          longcontract: calculateContract(
            data.twodayminus.pro.OptionStockPutLong,
            data.onedayminus.pro.OptionStockPutLong
          ),
          shortcontract: calculateContract(
            data.twodayminus.pro.OptionStockPutShort,
            data.onedayminus.pro.OptionStockPutShort
          ),
          netcontract: calculateNetContract(
            data.onedayminus.pro.OptionStockPutLong -
              data.twodayminus.pro.OptionStockPutLong,
            data.onedayminus.pro.OptionStockPutShort -
              data.twodayminus.pro.OptionStockPutShort
          ),
          previousday: calculateNetContract(
            data.twodayminus.pro.OptionStockPutLong -
              data.threedayminus.pro.OptionStockPutLong,
            data.twodayminus.pro.OptionStockPutShort -
              data.threedayminus.pro.OptionStockPutShort
          ),
        },
      ];
      setprodata(prodataArr);
    });
    await axios.get(api_url + `faoparticipentupdate`).then(({ data }) => {
    console.log(data)  
    });
  };
  const options = {
    title: "Graph",
    chartArea: { width: "60%", align: "center" },
    legend: { position: "none", textStyle: { color: "green", fontSize: 16 } },
    isStacked: true,
    hAxis: {
      title: "Total Amount in crores",
      minValue: 0,
    },
    vAxis: {
      title: "Date",
    },
  };
  const getshowgraph = () => {
    if (showgraph) {
      setshowgraph(false);
    } else {
      setshowgraph(true);
    }
  };
  return (
    <>
      <div>
        <Header />
        <main>
          {/* section content begin */}
          <div className="uk-section uk-margin-small-top">
            <div className="uk-container">
              <div className="d-flex justify-content-end">
                <div>
                <button className="btn btn-sm btn-light" onClick={e=>{exportPDF()}}>
                  <i class="fa fa-print" aria-hidden="true"></i>
                </button>
                </div>
              </div>
            </div>
            <div className="uk-container" id="divId" ref={componentRef}>
            
            <div className="uk-container" style={{ marginTop: "1.4rem" }}>
                <div className="row">
                <div className="col-12" style={{padding: '0px'}}>
                <div className="uk-text-center">
                      <h2
                        style={{
                          background: "#FDD965",
                          padding: "3px",
                          marginBottom: "0px",
                        }}
                      >
                        FII/FPI & DII Trading Activity || Date :{ datadate}
                      </h2>
                    </div>
                  </div>
                  <div className="col-12" style={{padding: '0px'}}>
                    <div
                      className="uk-card uk-card-default uk-card-body uk-border-rounded"
                      style={{
                        margin: 0,
                        padding: 0,
                        border: "1px solid #C17951",
                        borderRadius: "0px",
                      }}
                    >
                      <div className="uk-text-center">
                      <h3
                        style={{
                          background: "#FDD965",
                          padding: "3px",
                          marginBottom: "0px",
                        }}
                      >
                        Cash Market  (Amount in Crores)
                      </h3>
                    </div>
                      <div className="table-responsive">
                      <table
                        class="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center"
                        style={{ margin: 0 }}
                      >
                        <thead>
                        <tr>
                          <th class="uk-text-center">CATEGORY</th>
                          <th class="uk-text-center">BUY AMOUNT</th>
                          <th class="uk-text-center">SELL AMOUNT</th>
                          <th class="uk-text-center">NET AMOUNT</th>
                          <th class="uk-text-center">PREVIOUS DAY</th>
                        </tr>
                        </thead>
                        <tbody>
                    {flldlllatest.length > 0 &&
                      flldlllatest.map((row, key) => (
                        <tr>
                          <td className="text-center pe-0">
                            <div>{row.category}</div>
                          </td>
                          <td className="text-center pe-0">
                            <div>{row.buyvalue}</div>
                          </td>
                          <td className="text-center pe-0">
                            <div>{row.sellvalue}</div>
                          </td>
                          {Math.sign(parseFloat(row.netvalue)) == -1 ? (
                            <td className="text-center pe-0">
                              <div style={{ color: "red" }}>{row.netvalue}</div>
                            </td>
                          ) : (
                            <td className="text-center pe-0">
                              <div style={{ color: "green" }}>
                                {row.netvalue}
                              </div>
                            </td>
                          )}
                          <td className="text-center pe-0">
                            <div style={{ color: "#4176C5" }}>
                              {row.previousday}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                      </table>
                      </div>
                    </div>
                    </div>
                    </div>
            </div>
            <div className="uk-container">
                <div className="row">
                  <div className="col-12 mt-3" style={{padding: '0px'}}>
                    <div
                      className="uk-card uk-card-default uk-card-body uk-border-rounded"
                      style={{
                        margin: 0,
                        padding: 0,
                        border: "1px solid #C17951",
                        borderRadius: "0px",
                      }}
                    >
                      <div className="uk-text-center">
                      <h3
                        style={{
                          background: "#FDD965",
                          padding: "3px",
                          marginBottom: "0px",
                        }}
                      >
                        FII Derivative Statistics (Amount in Crores)
                      </h3>
                    </div>
                      <div className="table-responsive">
                      <table
                        class="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center"
                        style={{ margin: 0 }}
                      >
                        <thead>
                          <tr>
                            <th class="uk-text-center">CATEGORY</th>
                            <th class="uk-text-center">Buy Amount</th>
                            <th class="uk-text-center">Sell Amount</th>
                            <th class="uk-text-center">Net Amount</th>
                            <th class="uk-text-center">Previous Day</th>
                          </tr>
                        </thead>
                        <tbody>
                          {statistics.length > 0 &&
                            statistics.map((row, key) => (
                              <tr>
                                <td className="text-center pe-0">
                                  <div>{row.category}</div>
                                </td>
                                <td className="text-center pe-0">
                                  <div>{row.buy}</div>
                                </td>
                                <td className="text-center pe-0">
                                  <div>{row.sell}</div>
                                </td>
                                {Math.sign(parseFloat(row.buy - row.sell)) == -1 ? (
                                <td className="text-center pe-0">
                                  <div style={{ color: "red" }}>{(parseFloat(row.buy - row.sell)).toFixed(2)}</div>
                                </td>
                              ) : (
                                <td className="text-center pe-0">
                                  <div style={{ color: "green" }}>
                                  {(parseFloat(row.buy - row.sell)).toFixed(2)}
                                  </div>
                                </td>
                              )}
                                <td className="text-center pe-0">
                                  <div style={{ color: "#4176C5" }}>
                                  <div>{(parseFloat(statisticsold[key].buy - statisticsold[key].sell)).toFixed(2)}</div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      </div>
                    </div>
                    </div>
                    </div>
                    </div>
              <div className="uk-container" style={{ marginTop: "1.4rem" }}>
                <div className="row">
                  <div className="col-12" style={{border: '1px solid rgb(193, 121, 81)',padding: '0px'}}>
                    <div className="uk-text-center">
                      <h3
                        style={{
                          background: "#FDD965",
                          padding: "3px",
                          marginBottom: "0px",
                        }}
                      >
                        Participant Wise Open Interest (Unit Contracts)
                      </h3>
                    </div>
                    <div
                      className="uk-card uk-card-default uk-card-body uk-border-rounded"
                      style={{
                        margin: 0,
                        padding: 0,
                        border: "1px solid #C17951",
                        borderRadius: "0px",
                      }}
                    >
                      <div className="uk-text-center">
                        <h2 style={{ background: "#FDF1CC", margin: 0 }}>
                          FII
                        </h2>
                      </div>
                      <div className="table-responsive">
                      <table
                        class="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center"
                        style={{ margin: 0 }}
                      >
                        <thead>
                          <tr>
                            <th class="uk-text-center">CATEGORY</th>
                            <th class="uk-text-center">Long Contract</th>
                            <th class="uk-text-center">Short Contract</th>
                            <th class="uk-text-center">Net Contract</th>
                            <th class="uk-text-center">Previous Day</th>
                            <th class="uk-text-center">INTERPRETATION</th>
                          </tr>
                        </thead>
                        <tbody>
                          {flldata.length > 0 &&
                            flldata.map((row, key) => (
                              <tr>
                                <td className="text-center pe-0">
                                  <div>{row.subcategory}</div>
                                </td>
                                <td className="text-center pe-0">
                                  <div>{row.longcontract}</div>
                                </td>
                                <td className="text-center pe-0">
                                  <div>{row.shortcontract}</div>
                                </td>
                                {row.subcategory == "INDEX - OPT PUT" ||
                                row.subcategory == "STOCK - OPT PUT" ? (
                                  <>
                                    {Math.sign(row.netcontract) == -1 ? (
                                      <td className="text-center pe-0">
                                        <div style={{ color: "green" }}>
                                          {row.netcontract}
                                        </div>
                                      </td>
                                    ) : (
                                      <td className="text-center pe-0">
                                        <div style={{ color: "red" }}>
                                          {row.netcontract}
                                        </div>
                                      </td>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {Math.sign(row.netcontract) == -1 ? (
                                      <td className="text-center pe-0">
                                        <div style={{ color: "red" }}>
                                          {row.netcontract}
                                        </div>
                                      </td>
                                    ) : (
                                      <td className="text-center pe-0">
                                        <div style={{ color: "green" }}>
                                          {row.netcontract}
                                        </div>
                                      </td>
                                    )}
                                  </>
                                )}
                                <td className="text-center pe-0">
                                  <div style={{ color: "#4176C5" }}>
                                    {row.previousday}
                                  </div>
                                </td>
                                {row.subcategory == "INDEX - OPT PUT" ||
                                row.subcategory == "STOCK - OPT PUT" ? (
                                  <>
                                    <td className="text-center pe-0">
                                      {Math.sign(row.netcontract) == -1 ? (
                                        <span
                                          class="uk-label uk-margin-small-left in-label-small"
                                          style={{
                                            backgroundColor: "green",
                                            color: "#fff",
                                          }}
                                        >
                                          Bullish
                                        </span>
                                      ) : (
                                        <span
                                          class="uk-label uk-margin-small-left in-label-small"
                                          style={{
                                            backgroundColor: "#CD0F0F",
                                            color: "#fff",
                                          }}
                                        >
                                          Bearish
                                        </span>
                                      )}
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td className="text-center pe-0">
                                      {Math.sign(row.netcontract) == -1 ? (
                                        <span
                                          class="uk-label uk-margin-small-left in-label-small"
                                          style={{
                                            backgroundColor: "#CD0F0F",
                                            color: "#fff",
                                          }}
                                        >
                                          Bearish
                                        </span>
                                      ) : (
                                        <span
                                          class="uk-label uk-margin-small-left in-label-small"
                                          style={{
                                            backgroundColor: "green",
                                            color: "#fff",
                                          }}
                                        >
                                          Bullish
                                        </span>
                                      )}
                                    </td>
                                  </>
                                )}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      </div>
                    </div>
                    <div
                      className="uk-card uk-card-default uk-card-body uk-border-rounded"
                      style={{
                        margin: 0,
                        padding: 0,
                        border: "1px solid #C17951",
                        borderRadius: "0px",
                        marginTop: "10px",
                      }}
                    >
                      <div className="text-"></div>
                      <div className="uk-text-center">
                        <h2 style={{ background: "#FDF1CC", margin: 0 }}>
                          DII
                        </h2>
                      </div>
                      <div className="table-responsive">
                      <table
                        class="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center"
                        style={{ margin: 0 }}
                      >
                        <thead>
                          <tr>
                            <th class="uk-text-center">CATEGORY</th>
                            <th class="uk-text-center">Long Contract</th>
                            <th class="uk-text-center">Short Contract</th>
                            <th class="uk-text-center">Net Contract</th>
                            <th class="uk-text-center">Previous Day</th>
                            <th class="uk-text-center">INTERPRETATION</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dlldata.length > 0 &&
                            dlldata.map((row, key) => (
                              <tr>
                                <td className="text-center pe-0">
                                  <div>{row.subcategory}</div>
                                </td>
                                <td className="text-center pe-0">
                                  <div>{row.longcontract}</div>
                                </td>
                                <td className="text-center pe-0">
                                  <div>{row.shortcontract}</div>
                                </td>
                                {row.subcategory == "INDEX - OPT PUT" ||
                                row.subcategory == "STOCK - OPT PUT" ? (
                                  <>
                                    {Math.sign(row.netcontract) == -1 ? (
                                      <td className="text-center pe-0">
                                        <div style={{ color: "green" }}>
                                          {row.netcontract}
                                        </div>
                                      </td>
                                    ) : (
                                      <td className="text-center pe-0">
                                        <div style={{ color: "red" }}>
                                          {row.netcontract}
                                        </div>
                                      </td>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {Math.sign(row.netcontract) == -1 ? (
                                      <td className="text-center pe-0">
                                        <div style={{ color: "red" }}>
                                          {row.netcontract}
                                        </div>
                                      </td>
                                    ) : (
                                      <td className="text-center pe-0">
                                        <div style={{ color: "green" }}>
                                          {row.netcontract}
                                        </div>
                                      </td>
                                    )}
                                  </>
                                )}
                                <td className="text-center pe-0">
                                  <div style={{ color: "#4176C5" }}>
                                    {row.previousday}
                                  </div>
                                </td>
                                {row.subcategory == "INDEX - OPT PUT" ||
                                row.subcategory == "STOCK - OPT PUT" ? (
                                  <>
                                    <td className="text-center pe-0">
                                      {Math.sign(row.netcontract) == -1 ? (
                                        <span
                                          class="uk-label uk-margin-small-left in-label-small"
                                          style={{
                                            backgroundColor: "green",
                                            color: "#fff",
                                          }}
                                        >
                                          Bullish
                                        </span>
                                      ) : (
                                        <span
                                          class="uk-label uk-margin-small-left in-label-small"
                                          style={{
                                            backgroundColor: "#CD0F0F",
                                            color: "#fff",
                                          }}
                                        >
                                          Bearish
                                        </span>
                                      )}
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td className="text-center pe-0">
                                      {Math.sign(row.netcontract) == -1 ? (
                                        <span
                                          class="uk-label uk-margin-small-left in-label-small"
                                          style={{
                                            backgroundColor: "#CD0F0F",
                                            color: "#fff",
                                          }}
                                        >
                                          Bearish
                                        </span>
                                      ) : (
                                        <span
                                          class="uk-label uk-margin-small-left in-label-small"
                                          style={{
                                            backgroundColor: "green",
                                            color: "#fff",
                                          }}
                                        >
                                          Bullish
                                        </span>
                                      )}
                                    </td>
                                  </>
                                )}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      </div>
                    </div>
                    <div
                      className="uk-card uk-card-default uk-card-body uk-border-rounded"
                      style={{
                        margin: 0,
                        padding: 0,
                        border: "1px solid #C17951",
                        borderRadius: "0px",
                        marginTop: "10px",
                      }}
                    >
                      <div className="text-"></div>
                      <div className="uk-text-center">
                        <h2 style={{ background: "#FDF1CC", margin: 0 }}>
                          CLIENT
                        </h2>
                      </div>
                      <div className="table-responsive">
                      <table
                        class="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center"
                        style={{ margin: 0 }}
                      >
                        <thead>
                          <tr>
                            <th class="uk-text-center">CATEGORY</th>
                            <th class="uk-text-center">Long Contract</th>
                            <th class="uk-text-center">Short Contract</th>
                            <th class="uk-text-center">Net Contract</th>
                            <th class="uk-text-center">Previous Day</th>
                            <th class="uk-text-center">INTERPRETATION</th>
                          </tr>
                        </thead>
                        <tbody>
                          {clientdata.length > 0 &&
                            clientdata.map((row, key) => (
                              <tr>
                                <td className="text-center pe-0">
                                  <div>{row.subcategory}</div>
                                </td>
                                <td className="text-center pe-0">
                                  <div>{row.longcontract}</div>
                                </td>
                                <td className="text-center pe-0">
                                  <div>{row.shortcontract}</div>
                                </td>
                                {row.subcategory == "INDEX - OPT PUT" ||
                                row.subcategory == "STOCK - OPT PUT" ? (
                                  <>
                                    {Math.sign(row.netcontract) == -1 ? (
                                      <td className="text-center pe-0">
                                        <div style={{ color: "green" }}>
                                          {row.netcontract}
                                        </div>
                                      </td>
                                    ) : (
                                      <td className="text-center pe-0">
                                        <div style={{ color: "red" }}>
                                          {row.netcontract}
                                        </div>
                                      </td>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {Math.sign(row.netcontract) == -1 ? (
                                      <td className="text-center pe-0">
                                        <div style={{ color: "red" }}>
                                          {row.netcontract}
                                        </div>
                                      </td>
                                    ) : (
                                      <td className="text-center pe-0">
                                        <div style={{ color: "green" }}>
                                          {row.netcontract}
                                        </div>
                                      </td>
                                    )}
                                  </>
                                )}
                                <td className="text-center pe-0">
                                  <div style={{ color: "#4176C5" }}>
                                    {row.previousday}
                                  </div>
                                </td>
                                {row.subcategory == "INDEX - OPT PUT" ||
                                row.subcategory == "STOCK - OPT PUT" ? (
                                  <>
                                    <td className="text-center pe-0">
                                      {Math.sign(row.netcontract) == -1 ? (
                                        <span
                                          class="uk-label uk-margin-small-left in-label-small"
                                          style={{
                                            backgroundColor: "green",
                                            color: "#fff",
                                          }}
                                        >
                                          Bullish
                                        </span>
                                      ) : (
                                        <span
                                          class="uk-label uk-margin-small-left in-label-small"
                                          style={{
                                            backgroundColor: "#CD0F0F",
                                            color: "#fff",
                                          }}
                                        >
                                          Bearish
                                        </span>
                                      )}
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td className="text-center pe-0">
                                      {Math.sign(row.netcontract) == -1 ? (
                                        <span
                                          class="uk-label uk-margin-small-left in-label-small"
                                          style={{
                                            backgroundColor: "#CD0F0F",
                                            color: "#fff",
                                          }}
                                        >
                                          Bearish
                                        </span>
                                      ) : (
                                        <span
                                          class="uk-label uk-margin-small-left in-label-small"
                                          style={{
                                            backgroundColor: "green",
                                            color: "#fff",
                                          }}
                                        >
                                          Bullish
                                        </span>
                                      )}
                                    </td>
                                  </>
                                )}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      </div>
                    </div>
                    <div
                      className="uk-card uk-card-default uk-card-body uk-border-rounded"
                      style={{
                        margin: 0,
                        padding: 0,
                        border: "1px solid #C17951",
                        borderRadius: "0px",
                        marginTop: "10px",
                      }}
                    >
                      <div className="uk-text-center">
                        <h2 style={{ background: "#FDF1CC", margin: 0 }}>
                          Pro
                        </h2>
                      </div>
                      <div className="table-responsive">
                      <table
                        class="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center"
                        style={{ margin: 0 }}
                      >
                        <thead>
                          <tr>
                            <th class="uk-text-center">CATEGORY</th>
                            <th class="uk-text-center">Long Contract</th>
                            <th class="uk-text-center">Short Contract</th>
                            <th class="uk-text-center">Net Contract</th>
                            <th class="uk-text-center">Previous Day</th>
                            <th class="uk-text-center">INTERPRETATION</th>
                          </tr>
                        </thead>
                        <tbody>
                          {prodata.length > 0 &&
                            prodata.map((row, key) => (
                              <tr>
                                <td className="text-center pe-0">
                                  <div>{row.subcategory}</div>
                                </td>
                                <td className="text-center pe-0">
                                  <div>{row.longcontract}</div>
                                </td>
                                <td className="text-center pe-0">
                                  <div>{row.shortcontract}</div>
                                </td>
                                {row.subcategory == "INDEX - OPT PUT" ||
                                row.subcategory == "STOCK - OPT PUT" ? (
                                  <>
                                    {Math.sign(row.netcontract) == -1 ? (
                                      <td className="text-center pe-0">
                                        <div style={{ color: "green" }}>
                                          {row.netcontract}
                                        </div>
                                      </td>
                                    ) : (
                                      <td className="text-center pe-0">
                                        <div style={{ color: "red" }}>
                                          {row.netcontract}
                                        </div>
                                      </td>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {Math.sign(row.netcontract) == -1 ? (
                                      <td className="text-center pe-0">
                                        <div style={{ color: "red" }}>
                                          {row.netcontract}
                                        </div>
                                      </td>
                                    ) : (
                                      <td className="text-center pe-0">
                                        <div style={{ color: "green" }}>
                                          {row.netcontract}
                                        </div>
                                      </td>
                                    )}
                                  </>
                                )}
                                <td className="text-center pe-0">
                                  <div style={{ color: "#4176C5" }}>
                                    {row.previousday}
                                  </div>
                                </td>
                                {row.subcategory == "INDEX - OPT PUT" ||
                                row.subcategory == "STOCK - OPT PUT" ? (
                                  <>
                                    <td className="text-center pe-0">
                                      {Math.sign(row.netcontract) == -1 ? (
                                        <span
                                          class="uk-label uk-margin-small-left in-label-small"
                                          style={{
                                            backgroundColor: "green",
                                            color: "#fff",
                                          }}
                                        >
                                          Bullish
                                        </span>
                                      ) : (
                                        <span
                                          class="uk-label uk-margin-small-left in-label-small"
                                          style={{
                                            backgroundColor: "#CD0F0F",
                                            color: "#fff",
                                          }}
                                        >
                                          Bearish
                                        </span>
                                      )}
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td className="text-center pe-0">
                                      {Math.sign(row.netcontract) == -1 ? (
                                        <span
                                          class="uk-label uk-margin-small-left in-label-small"
                                          style={{
                                            backgroundColor: "#CD0F0F",
                                            color: "#fff",
                                          }}
                                        >
                                          Bearish
                                        </span>
                                      ) : (
                                        <span
                                          class="uk-label uk-margin-small-left in-label-small"
                                          style={{
                                            backgroundColor: "green",
                                            color: "#fff",
                                          }}
                                        >
                                          Bullish
                                        </span>
                                      )}
                                    </td>
                                  </>
                                )}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="uk-container" style={{ marginTop: "1.4rem" }}>
                <div className="row">
                  <div className="col-12" style={{padding: '0px'}}>
                    <div
                      className="uk-card uk-card-default uk-card-body uk-border-rounded"
                      style={{
                        margin: 0,
                        padding: 0,
                        border: "1px solid #C17951",
                        borderRadius: "0px",
                      }}
                    >
                      <div
                className="uk-card uk-card-default uk-card-body uk-border-rounded"
                style={{padding: "0rem" }}
              >
                <div className="d-flex justify-content-between m-3">
                  <div className="d-flex align-items-center">
                  <h5
                  class="uk-heading-bullet uk-text-uppercase uk-margin-remove-bottom"
                  style={{ display: "inline-block" }}
                >
                  FII/FPI & DII trading activity Total(Cash Market)
                </h5>
                  </div>
                  <div>
                  <button
                    className="uk-button uk-button-primary uk-border-rounded"
                    style={{ float: "right" }}
                    onClick={(e) => {
                      getshowgraph(e);
                    }}
                  >
                    Graph
                    <FeatherIcon
                      icon="bar-chart-2"
                      style={{ height: 19, marginBottom: 4 }}
                    />
                  </button>
                  </div>
                </div>
                {showgraph ? (
                  <Chart
                    chartType="BarChart"
                    width="100%"
                    height="1000px"
                    data={graphdata}
                    options={options}
                  />
                ) : (
                  <>
                    <div className="table-responsive">
                    <table class="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center">
                      <thead>
                        <tr>
                          <th class="uk-text-center">CATEGORY</th>
                          <th class="uk-text-center">DATE</th>
                          <th class="uk-text-center">Buy value</th>
                          <th class="uk-text-center">Sell Value</th>
                          <th class="uk-text-center">NET VALUE</th>
                        </tr>
                      </thead>
                      <tbody>
                        {flldlllall.length > 0 &&
                          flldlllall.map((row, key) => (
                            <tr>
                              <td className="text-center pe-0">
                                <div>{row.category}</div>
                              </td>
                              <td className="text-center pe-0">
                                <div>{row.date}</div>
                              </td>
                              <td className="text-center pe-0">
                                <div>{row.buyvalue}</div>
                              </td>
                              <td className="text-center pe-0">
                                <div>{row.sellvalue}</div>
                              </td>
                              <td className="text-center pe-0">
                                <div
                                  style={{
                                    color:
                                      Math.sign(row.netvalue) == -1
                                        ? "red"
                                        : "green",
                                  }}
                                >
                                  {row.netvalue}
                                </div>
                              </td>
                            </tr>
                          ))}
                        <tr>
                          <td></td>
                          <td>
                            <h4 class="fw-bold text-dark-700 m-0">Total</h4>
                          </td>
                          <td>
                            <h4 class="fw-bold text-dark-700 m-0">
                              {totalbuyvalue}
                            </h4>
                          </td>
                          <td>
                            <h4 class="fw-bold text-dark-700 m-0">
                              {totalsellvalue}
                            </h4>
                          </td>
                          <td>
                            <h4 class="fw-bold text-dark-700 m-0">
                              {totalnetvalue}
                            </h4>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    </div>
                  </>
                )}
              </div>
                    </div>
                    </div>
                    </div>
            </div>
          </div>
        </main>

        <Footer />
      </div>
    </>
  );
}
