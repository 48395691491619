import axios from 'axios'
import $ from 'jquery';

// ESSANE 
// export const api_url = 'https://essaneinfotech.com/mfinakin/api/';
// export const api_asseturl = 'https://essaneinfotech.com/mfinakin/storage/app/public/';
// export const app_url = '/finakin/';
// export const fullappurl = 'https://essaneinfotech.com/finakin/';
// export const asseturl = '/finakin/assets/';
// FINAKIN
export const api_url = 'https://finakin.com/mfinakin/api/';
export const api_asseturl = 'https://finakin.com/mfinakin/storage/app/public/';
export const app_url = '/';
export const fullappurl = 'https://finakin.com';
export const asseturl = '/assets/';
export function formatdmY(myDate) {
  // Assuming myDate is a string in the format "YYYY-MM-DD"
  const parts = myDate.split('-'); // Split the date into parts
  const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`; // Rearrange the parts
  return formattedDate;
}

export async function getfunction(url){
  axios.get(api_url + url, {
    headers: {
      Referer: 'http://localhost:3000'
    }
  }).then(({data}) => {
    return data
  })
}
export async function getstrikedata(stikeprice,type){
  let responseData = [];
  await axios.get(api_url+'getdatabystrike/'+stikeprice).then(({data})=>{
    if(type == 'CE'){
      for(let x in data){
        responseData.push(Number(data[x].coi))
      }
    }else{
      for(let x in data){
        responseData.push(Number(data[x].poi))
      }
    }
  }).catch(({response})=>{
    console.log(response)
  })
  return responseData;
}

export async function postMethod(parameters,endpoin){
  let responseData;
  await axios.post(api_url+endpoin,parameters).then(({data})=>{
    responseData = data;
  }).catch(({response})=>{
    responseData = 1;
  })   
  return responseData;
}
export function isEmail(email) {
  var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return regex.test(email);
}
export function MultiIndexTimeline(dataLength) {
  const startTime = new Date();
  startTime.setHours(9);
  startTime.setMinutes(15);
  startTime.setSeconds(0);
  
  const endTime = new Date();
  endTime.setHours(15);
  endTime.setMinutes(30);
  endTime.setSeconds(0);

  // const dataLength = 99;
  const timeIncrement = (endTime - startTime) / (dataLength - 1);
  const newArray = [];

  for (let i = 0; i < dataLength; i++) {
    const time = new Date(startTime.getTime() + i * timeIncrement);
    const hours = time.getHours();
    const minutes = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHour = hours % 12 === 0 ? 12 : hours % 12;
    const formattedTime = `${formattedHour}:${minutes} ${ampm}`;    
    newArray.push(formattedTime);
  }
  return newArray;
}
// export function MasterTimeline(dataLength) {
//   const startTime = new Date();
//   const currentTime = new Date();
  
//   if (currentTime < startTime.setHours(9, 15, 0, 0)) {
//     // If the current time is before 9:15 AM, use a start time of 9:15 AM minus the total time range
//     startTime.setHours(9, 15 - dataLength + 1, 0, 0);
//   } else {
//     startTime.setHours(9, 15, 0, 0);
//   }

//   const endTime = new Date();
//   endTime.setHours(15);
//   endTime.setMinutes(30);
//   endTime.setSeconds(0);

//   const timeRangeInMinutes = Math.min((currentTime - startTime) / (1000 * 60), (endTime - startTime) / (1000 * 60)); // Calculate the total time range in minutes, but limit it to the current time if it's before 3:30 PM
//   const timeIncrement = timeRangeInMinutes / (dataLength - 1); // Calculate the time increment in minutes

//   const newArray = [];
//   for (let i = 0; i < dataLength; i++) {
//     const time = new Date(startTime.getTime() + i * timeIncrement * 60 * 1000);
//     const hours = time.getHours();
//     const minutes = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes();
//     const ampm = hours >= 12 ? 'PM' : 'AM';
//     const formattedHour = hours % 12 === 0 ? 12 : hours % 12;
//     const formattedTime = `${formattedHour}:${minutes} ${ampm}`;
//     newArray.push(formattedTime);
//   }
//   return newArray;
// }
export function MasterTimeline(dataLength) {
  let startTime, endTime;
  const currentTime = new Date();
    if ((currentTime.getDay() === 6 || currentTime.getDay() === 0) && ((currentTime.getHours() < 9 || (currentTime.getHours() === 9 && currentTime.getMinutes() < 15)) || (currentTime.getHours() >= 0 && currentTime.getHours() < 9)) || (currentTime.getHours() > 15 || (currentTime.getHours() === 15 && currentTime.getMinutes() >= 30))) {    
    startTime = new Date();
    startTime.setHours(9);
    startTime.setMinutes(15);
    startTime.setSeconds(0);
    endTime = new Date();
    endTime.setHours(15);
    endTime.setMinutes(30);
    endTime.setSeconds(0);

    // Generate timeline from start time to end time
    const timeRangeInMinutes = (endTime - startTime) / (1000 * 60);
    const timeIncrement = timeRangeInMinutes / (dataLength - 1);
    const newArray = [];
    for (let i = 0; i < dataLength; i++) {
      const time = new Date(startTime.getTime() + i * timeIncrement * 60 * 1000);
      const hours = time.getHours();
      const minutes = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const formattedHour = hours % 12 === 0 ? 12 : hours % 12;
      const formattedTime = `${formattedHour}:${minutes} ${ampm}`;
      newArray.push(formattedTime);
    }
    return newArray;
  } else {
  const startTime = new Date();
  const currentTime = new Date();
  
  if (currentTime < startTime.setHours(9, 15, 0, 0)) {
    // If the current time is before 9:15 AM, use a start time of 9:15 AM minus the total time range
    startTime.setHours(9, 15 - dataLength + 1, 0, 0);
  } else {
    startTime.setHours(9, 15, 0, 0);
  }

  const endTime = new Date();
  endTime.setHours(15);
  endTime.setMinutes(30);
  endTime.setSeconds(0);

  const timeRangeInMinutes = Math.min((currentTime - startTime) / (1000 * 60), (endTime - startTime) / (1000 * 60)); // Calculate the total time range in minutes, but limit it to the current time if it's before 3:30 PM
  const timeIncrement = timeRangeInMinutes / (dataLength - 1); // Calculate the time increment in minutes

  const newArray = [];
  for (let i = 0; i < dataLength; i++) {
    const time = new Date(startTime.getTime() + i * timeIncrement * 60 * 1000);
    const hours = time.getHours();
    const minutes = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHour = hours % 12 === 0 ? 12 : hours % 12;
    const formattedTime = `${formattedHour}:${minutes} ${ampm}`;
    newArray.push(formattedTime);
  }
  return newArray;
}
}



export function PcrTimeline(dataLength) {
  const startTime = new Date();
  startTime.setHours(9);
  startTime.setMinutes(15);
  startTime.setSeconds(0);
  
  const endTime = new Date();
  endTime.setHours(15);
  endTime.setMinutes(30);
  endTime.setSeconds(0);

  // const dataLength = 99;
  const timeIncrement = (endTime - startTime) / (dataLength - 1);
  const newArray = [];

  for (let i = 0; i < dataLength; i++) {
    const time = new Date(startTime.getTime() + i * timeIncrement);
    const hours = time.getHours();
    const minutes = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHour = hours % 12 === 0 ? 12 : hours % 12;
    const formattedTime = `${formattedHour}:${minutes} ${ampm}`;    
    newArray.push(formattedTime);
  }
  return newArray;
}






export function getCurrentDate(Date){
  let separator = '-';
  let newDate = Date;
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  let datenow =  `${date<10?`0${date}`:`${date}`}${separator}${month<10?`0${month}`:`${month}`}${separator}${year}`
  return datenow;
  // let datenow =  `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
}
export async function getMethod(parameters="",fetchendpoin){}
export async function getloanname(loantypeid = ""){
  return "Personal Loan";
}
export const manageallstep = (index) => {
  if(index == 1){  
    $('#tabnav1').removeClass("current");
    $('#tabcontent1').removeClass("current");
    $('#tabnav2').addClass("current");
    $('#tabcontent2').addClass("current");
    $('#tabnav3').removeClass("current");
    $('#tabcontent3').removeClass("current");
    $('#tabnav4').removeClass("current"); 
    $('#tabcontent4').removeClass("current");
    $('#tabnav5').removeClass("current");
    $('#tabcontent5').removeClass("current");
    $('#tabnav6').removeClass("current");
    $('#tabcontent6').removeClass("current");
  }else if(index == 2){
    $('#tabnav1').removeClass("current");
    $('#tabcontent1').removeClass("current");
    $('#tabnav2').removeClass("current");
    $('#tabcontent2').removeClass("current");
    $('#tabnav3').addClass("current");
    $('#tabcontent3').addClass("current");
    $('#tabnav4').removeClass("current"); 
    $('#tabcontent4').removeClass("current");
    $('#tabnav5').removeClass("current");
    $('#tabcontent5').removeClass("current");
    $('#tabnav6').removeClass("current");
    $('#tabcontent6').removeClass("current");
  }else if(index == 3){
    $('#tabnav1').removeClass("current");
    $('#tabcontent1').removeClass("current");
    $('#tabnav2').removeClass("current");
    $('#tabcontent2').removeClass("current");
    $('#tabnav3').removeClass("current");
    $('#tabcontent3').removeClass("current");
    $('#tabnav4').addClass("current"); 
    $('#tabcontent4').addClass("current");
    $('#tabnav5').removeClass("current");
    $('#tabcontent5').removeClass("current");
    $('#tabnav6').removeClass("current");
    $('#tabcontent6').removeClass("current");
  }
  else if(index == 4){
    $('#tabnav1').removeClass("current");
    $('#tabcontent1').removeClass("current");
    $('#tabnav2').removeClass("current");
    $('#tabcontent2').removeClass("current");
    $('#tabnav3').removeClass("current");
    $('#tabcontent3').removeClass("current");
    $('#tabnav4').removeClass("current"); 
    $('#tabcontent4').removeClass("current");
    $('#tabnav5').addClass("current");
    $('#tabcontent5').addClass("current");
    $('#tabnav6').removeClass("current");
    $('#tabcontent6').removeClass("current");
  }
  else if(index == 5){
    $('#tabnav1').removeClass("current");
    $('#tabcontent1').removeClass("current");
    $('#tabnav2').removeClass("current");
    $('#tabcontent2').removeClass("current");
    $('#tabnav3').removeClass("current");
    $('#tabcontent3').removeClass("current");
    $('#tabnav4').removeClass("current"); 
    $('#tabcontent4').removeClass("current");
    $('#tabnav5').removeClass("current");
    $('#tabcontent5').removeClass("current");
    $('#tabnav6').addClass("current");
    $('#tabcontent6').addClass("current");
  }
  else if(index == 6){
    
  }else{
    $('#tabnav1').addClass("current");
    $('#tabcontent1').addClass("current");
    $('#tabnav2').removeClass("current");
    $('#tabcontent2').removeClass("current");
    $('#tabnav3').removeClass("current");
    $('#tabcontent3').removeClass("current");
    $('#tabnav4').removeClass("current"); 
    $('#tabcontent4').removeClass("current");
    $('#tabnav5').removeClass("current");
    $('#tabcontent5').removeClass("current");
    $('#tabnav6').removeClass("current");
    $('#tabcontent6').removeClass("current");
  }
}
export const adduserstep = (index) => {
  if(index == 1){
    $('#tabnav1').removeClass("current");
    $('#tabcontent1').removeClass("current");
    $('#tabnav3').removeClass("current");
    $('#tabcontent3').removeClass("current");
    $('#tabnav4').removeClass("current"); 
    $('#tabcontent4').removeClass("current");
    $('#tabnav5').removeClass("current");
    $('#tabcontent5').removeClass("current");
    $('#tabnav2').addClass("current");
    $('#tabcontent2').addClass("current");
  }else if(index == 2){
    $('#tabnav2').removeClass("current");
    $('#tabcontent2').removeClass("current");
    $('#tabnav4').removeClass("current"); 
    $('#tabcontent4').removeClass("current");
    $('#tabnav5').removeClass("current");
    $('#tabcontent5').removeClass("current"); 
    $('#tabnav3').addClass("current");
    $('#tabcontent3').addClass("current");
  }else if(index == 3){
    $('#tabnav2').removeClass("current");
    $('#tabcontent2').removeClass("current");
    $('#tabnav3').removeClass("current");
    $('#tabcontent3').removeClass("current");
    $('#tabnav5').removeClass("current");
    $('#tabcontent5').removeClass("current"); 
    $('#tabnav4').addClass("current");
    $('#tabcontent4').addClass("current");
  }else if(index == 4){
    $('#tabnav2').removeClass("current");
    $('#tabcontent2').removeClass("current");
    $('#tabnav3').removeClass("current");
    $('#tabcontent3').removeClass("current");
    $('#tabnav4').removeClass("current"); 
    $('#tabcontent4').removeClass("current"); 
    $('#tabnav5').addClass("current");
    $('#tabcontent5').addClass("current");
  }
  else{
    $('#tabnav1').addClass("current");
    $('#tabcontent1').addClass("current");
    $('#tabnav2').removeClass("current");
    $('#tabcontent2').removeClass("current");
    $('#tabnav3').removeClass("current");
    $('#tabcontent3').removeClass("current");
    $('#tabnav4').removeClass("current"); 
    $('#tabcontent4').removeClass("current");
    $('#tabnav5').removeClass("current");
    $('#tabcontent5').removeClass("current");
  }
}