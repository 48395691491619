import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../common/Header";
import axios from "axios";
import { app_url, asseturl, api_url, api_asseturl } from "../common/Helpers";
import Select from "react-select";
import Slider from "react-slick";
import $ from "jquery";
import Swal from "sweetalert2";
import Footer from "./Footer";
import Lottie from "lottie-react";
import helpyou from "../lottie/helpyou.json";
import demataccount from "../lottie/demataccount.json";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
export default function Home() {
  const [show, setShow] = useState(false);
  const [validateshow, setvalidateshow] = useState(false);
  const [supportname, setsupportname] = useState("");
  const [supportemail, setsupportemail] = useState("");
  const [supportmobile, setsupportmobile] = useState("");
  const [supportmessage, setsupportmessage] = useState("");
  const [checkcontact, setcheckcontact] = useState(false);
  const hidemodal = () => {
    setShow(false);
  };
  const handleClose = () => {
    if (!supportmobile.match("[0-9]{10}") || supportmobile.length > 10) {
      // Swal.fire({
      //   icon:"error",
      //   text:"Contact Only 10 Digit Allow",
      // })
      setvalidateshow(true);
      setcheckcontact(true);
      return false;
    } else if (supportname == "" || supportemail == "") {
      setvalidateshow(true);
      return false;
    } else {
      const formData = new FormData();
      formData.append("supportname", supportname);
      formData.append("supportemail", supportemail);
      formData.append("supportmobile", supportmobile);
      formData.append("supportmessage", supportmessage);
      axios
        .post(api_url + `addsupport`, formData)
        .then(({ data }) => {
          setsupportname("");
          setsupportemail("");
          setsupportmobile("");
          Swal.fire({
            icon: "success",
            text: "Thank you for your interest, we will get back to you very soon !",
          });
        })
        .catch(({ response }) => {
          if (response.status === 422) {
            console.log(response.data.errors);
          } else {
            Swal.fire({
              text: response.data.message,
              icon: "error",
            });
          }
        });
      setShow(false);
    }
  };
  // FREE DEMAT ACCOUNT HANDLE
  const [dematvalidateshow, setdematvalidateshow] = useState(false);
  const [dematshow, setdematShow] = useState(false);
  const [dematemail, setdematemail] = useState("");
  const [dematmobile, setdematmobile] = useState("");
  const [dematcheckcontact, setdematcheckcontact] = useState(false);
  const demathidemodal = () => {
    setdematShow(false);
  };
  const demathandleClose = () => {
    if (!dematmobile.match("[0-9]{10}") || dematmobile.length > 10) {
      // Swal.fire({
      //   icon:"error",
      //   text:"Contact Only 10 Digit Allow",
      // })
      setdematvalidateshow(true);
      setdematcheckcontact(true);
      return false;
    } else if (dematemail == "" || dematmobile == "") {
      setdematvalidateshow(true);
      return false;
    } else {
      const formData = new FormData();
      formData.append("supportemail", dematemail);
      formData.append("supportmobile", dematmobile);
      axios
        .post(api_url + `addsupport`, formData)
        .then(({ data }) => {
          setdematemail("");
          setdematmobile("");
          Swal.fire({
            icon: "success",
            text: "Thank you for your interest, we will get back to you very soon !",
          });
        })
        .catch(({ response }) => {
          if (response.status === 422) {
            console.log(response.data.errors);
          } else {
            Swal.fire({
              text: response.data.message,
              icon: "error",
            });
          }
        });
      setdematShow(false);
    }
  };

  const handleShow = () => setShow(true);
  const demathandleShow = () => setdematShow(true);

  const [marketdata, setmarketdata] = useState([]);
  const [moneydata, setmoneydata] = useState([]);
  const [analysisdata, setanalysisdata] = useState([]);
  const [homedata, sethomedata] = useState([]);
  const [sensexbold, setsensexbold] = useState(false);
  const [nifty50bold, setnifty50bold] = useState(false);
  const [bankbold, setbankbold] = useState(false);
  useEffect(() => {
    fetchdatas();
  }, []);
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    const timer = setTimeout(() => {
      setCounter(counter + 1);
      fetchdatalatest();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [counter]);

  const fetchdatalatest = async () => {
    await axios.get(api_url + "gethomedata").then(({ data }) => {
      // NIFTY 50
      let newnifty50 = parseFloat(data.nifty50.replace(/,/g, ""));
      let oldnifty50 = parseFloat(homedata.nifty50.replace(/,/g, ""));
      // BANK NIFTY
      let newbank = parseFloat(data.banknifty.replace(/,/g, ""));
      let oldbank = parseFloat(homedata.banknifty.replace(/,/g, ""));
      // SENSEX
      let newsensex = parseFloat(data.sensex.replace(/,/g, ""));
      let oldsensex = parseFloat(homedata.sensex.replace(/,/g, ""));
      if (newnifty50 != oldnifty50) {
        setnifty50bold(true);
        setbankbold(false);
        setsensexbold(false);
      }
      if (newbank != oldbank) {
        setbankbold(true);
        setnifty50bold(false);
        setsensexbold(false);
      }
      if (newsensex != oldsensex) {
        setsensexbold(true);
        setnifty50bold(false);
        setbankbold(false);
      }
      sethomedata(data);
    });
  };
  const fetchdatas = async () => {
    await axios.get(api_url + "gethomedata").then(({ data }) => {
      sethomedata(data);
    });
    await axios.get(api_url + "getmarket").then(({ data }) => {
      setmarketdata(data);
    });
    await axios.get(api_url + "getmoney").then(({ data }) => {
      setmoneydata(data);
    });
    await axios.get(api_url + "getanalysis").then(({ data }) => {
      setanalysisdata(data);
    });
    getlatestdata();
  };
  const getlatestdata = async () => {
    axios.get(api_url + "gethomedata").then(({ data }) => {
      sethomedata(data);
    });
    await axios.get(api_url + "getmarket").then(({ data }) => {
      setmarketdata(data);
    });
    await axios.get(api_url + "getmoney").then(({ data }) => {
      console.log(data);
      setmoneydata(data);
    });
    await axios.get(api_url + "getanalysis").then(({ data }) => {
      setanalysisdata(data);
    });
    await axios.get(api_url + "gethomedatalatest").then(({ data }) => {
      console.log(data);
    });
  };
  const w300 = {
    width: 300,
    maxWidth: "100%",
    display: "block",
  };
  const w30 = {
    width: 100,
    maxWidth: "100%",
    display: "block",
  };
  function removeTags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();

    return str.replace(/(<([^>]+)>)/gi, "");
  }
  return (
    <>
      <div>
        <Header />
        <main>
          <div className="uk-section uk-padding-remove-vertical in-slideshow-gradient">
            <div
              id="particles-js"
              className="uk-light in-slideshow uk-background-contain uk-slideshow"
              data-src="img/in-equity-decor-1.svg"
              data-uk-img
              data-uk-slideshow
              style={{
                backgroundImage:
                  'url("https://www.indonez.com/html-demo/Equity/img/in-equity-decor-1.svg")',
                backgroundSize: "contain",
                backgroundPositionX: "950px",
              }}
            >
              <hr />
              <ul className="uk-slideshow-items">
                <li className="uk-flex uk-flex-middle" tabIndex={-1} style={{}}>
                  <div className="uk-container">
                    <div
                      className="uk-grid-large uk-flex-middle uk-grid uk-grid-stack justify-content-end"
                      data-uk-grid
                    >
                      <div
                        className="in-slide-img uk-first-column"
                        style={{ paddingLeft: "0px", marginLeft: "-50px" }}
                      >
                        <img
                          src={app_url + "assets/img/in-equity-slide-1.png"}
                          alt="image-slide"
                          width={652}
                          height={746}
                        />
                      </div>
                      <div
                        className="uk-width-1-2@s in-slide-text uk-first-column"
                        style={{ paddingRight: "0px", paddingLeft: "29px" }}
                      >
                        <h1 className="uk-heading-small">
                          Reach out to a new Trading{" "}
                          <span className="in-highlight">experience</span>.
                        </h1>
                        <div
                          className="uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s uk-margin-medium-top uk-visible@s uk-grid uk-grid-stack"
                          data-uk-grid
                          data-market="MCD,AMZN,MSFT"
                        >
                          <div
                            className="uk-visible@m"
                            style={{ paddingLeft: "5px" }}
                          >
                            <div className="uk-card uk-card-small uk-card-secondary uk-card-body uk-border-rounded uk-flex uk-flex-middle">
                              <div className="in-symbol-logo">
                                <img
                                  src={asseturl + "sensex.png"}
                                  alt="ticker"
                                  width={28}
                                  height={28}
                                />
                              </div>
                              {Math.sign(parseFloat(homedata.schangepersent)) ==
                              -1 ? (
                                <div className="in-price down">
                                  {Math.sign(
                                    parseFloat(homedata.schangepersent)
                                  ) == -1 ? (
                                    <h6 className="uk-margin-remove">
                                      Sensex
                                      <span className="uk-text-small uk-text-danger">
                                        {homedata.schange}(
                                        {homedata.schangepersent + "%"})
                                      </span>
                                    </h6>
                                  ) : (
                                    <h6 className="uk-margin-remove">
                                      Sensex
                                      <span className="uk-text-small">
                                        {homedata.schange}(
                                        {homedata.schangepersent + "%"})
                                      </span>
                                    </h6>
                                  )}
                                  {sensexbold ? (
                                    <p
                                      className="uk-margin-remove"
                                      style={{ fontWeight: "900" }}
                                    >
                                      <span className="fas fa-arrow-circle-right fa-xs" />
                                      {homedata.sensex}
                                    </p>
                                  ) : (
                                    <p
                                      className="uk-margin-remove"
                                      style={{ fontWeight: "400" }}
                                    >
                                      <span className="fas fa-arrow-circle-right fa-xs" />
                                      {homedata.sensex}
                                    </p>
                                  )}
                                </div>
                              ) : (
                                <div className="in-price up">
                                  {Math.sign(
                                    parseFloat(homedata.schangepersent)
                                  ) == -1 ? (
                                    <h6 className="uk-margin-remove">
                                      Sensex
                                      <span className="uk-text-small uk-text-danger">
                                        {homedata.schange}(
                                        {homedata.schangepersent + "%"})
                                      </span>
                                    </h6>
                                  ) : (
                                    <h6 className="uk-margin-remove">
                                      Sensex
                                      <span className="uk-text-small">
                                        {homedata.schange}(
                                        {homedata.schangepersent + "%"})
                                      </span>
                                    </h6>
                                  )}
                                  {sensexbold ? (
                                    <p
                                      className="uk-margin-remove"
                                      style={{ fontWeight: "900" }}
                                    >
                                      <span className="fas fa-arrow-circle-right fa-xs" />
                                      {homedata.sensex}
                                    </p>
                                  ) : (
                                    <p
                                      className="uk-margin-remove"
                                      style={{ fontWeight: "400" }}
                                    >
                                      <span className="fas fa-arrow-circle-right fa-xs" />
                                      {homedata.sensex}
                                    </p>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                          <div
                            className="uk-first-column"
                            style={{ paddingLeft: "5px" }}
                          >
                            <div className="uk-card uk-card-small uk-card-secondary uk-card-body uk-border-rounded uk-flex uk-flex-middle">
                              <div className="in-symbol-logo">
                                <img
                                  src={asseturl + "Nifty50.png"}
                                  alt="ticker"
                                  width={28}
                                  height={28}
                                />
                              </div>
                              {Math.sign(parseFloat(homedata.nchangepersent)) ==
                              -1 ? (
                                <div className="in-price down">
                                  {Math.sign(
                                    parseFloat(homedata.nchangepersent)
                                  ) == -1 ? (
                                    <h6 className="uk-margin-remove">
                                      Nifty 50
                                      <span className="uk-text-small uk-text-danger">
                                        {homedata.nchange}(
                                        {homedata.nchangepersent + "%"})
                                      </span>
                                    </h6>
                                  ) : (
                                    <h6 className="uk-margin-remove">
                                      Nifty 50
                                      <span className="uk-text-small">
                                        {homedata.nchange}(
                                        {homedata.nchangepersent + "%"})
                                      </span>
                                    </h6>
                                  )}
                                  {nifty50bold ? (
                                    <p
                                      className="uk-margin-remove"
                                      style={{ fontWeight: "900" }}
                                    >
                                      <span className="fas fa-arrow-circle-right fa-xs" />
                                      {homedata.nifty50}
                                    </p>
                                  ) : (
                                    <p
                                      className="uk-margin-remove"
                                      style={{ fontWeight: "400" }}
                                    >
                                      <span className="fas fa-arrow-circle-right fa-xs" />
                                      {homedata.nifty50}
                                    </p>
                                  )}
                                </div>
                              ) : (
                                <div className="in-price up">
                                  {Math.sign(
                                    parseFloat(homedata.nchangepersent)
                                  ) == -1 ? (
                                    <h6 className="uk-margin-remove">
                                      Nifty 50
                                      <span className="uk-text-small uk-text-danger">
                                        {homedata.nchange}(
                                        {homedata.nchangepersent + "%"})
                                      </span>
                                    </h6>
                                  ) : (
                                    <h6 className="uk-margin-remove">
                                      Nifty 50
                                      <span className="uk-text-small">
                                        {homedata.nchange}(
                                        {homedata.nchangepersent + "%"})
                                      </span>
                                    </h6>
                                  )}
                                  {nifty50bold ? (
                                    <p
                                      className="uk-margin-remove"
                                      style={{ fontWeight: "900" }}
                                    >
                                      <span className="fas fa-arrow-circle-right fa-xs" />
                                      {homedata.nifty50}
                                    </p>
                                  ) : (
                                    <p
                                      className="uk-margin-remove"
                                      style={{ fontWeight: "400" }}
                                    >
                                      <span className="fas fa-arrow-circle-right fa-xs" />
                                      {homedata.nifty50}
                                    </p>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                          <div style={{ paddingLeft: "5px" }}>
                            <div className="uk-card uk-card-small uk-card-secondary uk-card-body uk-border-rounded uk-flex uk-flex-middle">
                              <div className="in-symbol-logo">
                                <img
                                  src={asseturl + "banknifty.png"}
                                  alt="ticker"
                                  width={28}
                                  height={28}
                                />
                              </div>

                              {Math.sign(
                                parseFloat(homedata.bnchangepersent)
                              ) == -1 ? (
                                <div className="in-price down">
                                  {Math.sign(
                                    parseFloat(homedata.bnchangepersent)
                                  ) == -1 ? (
                                    <h6 className="uk-margin-remove">
                                      Bank Nifty
                                      <span
                                        className="uk-text-small uk-text-danger"
                                        style={{ marginLeft: "0px" }}
                                      >
                                        {homedata.bnchange}(
                                        {homedata.bnchangepersent + "%"})
                                      </span>
                                    </h6>
                                  ) : (
                                    <h6 className="uk-margin-remove">
                                      Bank Nifty
                                      <span className="uk-text-small">
                                        {homedata.bnchange}(
                                        {homedata.bnchangepersent + "%"})
                                      </span>
                                    </h6>
                                  )}
                                  {bankbold ? (
                                    <p
                                      className="uk-margin-remove"
                                      style={{ fontWeight: "900" }}
                                    >
                                      <span className="fas fa-arrow-circle-right fa-xs" />
                                      {homedata.banknifty}
                                    </p>
                                  ) : (
                                    <p
                                      className="uk-margin-remove"
                                      style={{ fontWeight: "400" }}
                                    >
                                      <span className="fas fa-arrow-circle-right fa-xs" />
                                      {homedata.banknifty}
                                    </p>
                                  )}
                                </div>
                              ) : (
                                <div className="in-price up">
                                  {Math.sign(
                                    parseFloat(homedata.bnchangepersent)
                                  ) == -1 ? (
                                    <h6 className="uk-margin-remove">
                                      Bank Nifty
                                      <span
                                        className="uk-text-small uk-text-danger"
                                        style={{ marginLeft: "0px" }}
                                      >
                                        {homedata.bnchange}(
                                        {homedata.bnchangepersent + "%"})
                                      </span>
                                    </h6>
                                  ) : (
                                    <h6 className="uk-margin-remove">
                                      Bank Nifty
                                      <span className="uk-text-small">
                                        {homedata.bnchange}(
                                        {homedata.bnchangepersent + "%"})
                                      </span>
                                    </h6>
                                  )}
                                  {bankbold ? (
                                    <p
                                      className="uk-margin-remove"
                                      style={{ fontWeight: "900" }}
                                    >
                                      <span className="fas fa-arrow-circle-right fa-xs" />
                                      {homedata.banknifty}
                                    </p>
                                  ) : (
                                    <p
                                      className="uk-margin-remove"
                                      style={{ fontWeight: "400" }}
                                    >
                                      <span className="fas fa-arrow-circle-right fa-xs" />
                                      {homedata.banknifty}
                                    </p>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div className="uk-container">
                <div
                  className="uk-position-relative uk-grid uk-grid-stack"
                  data-uk-grid
                >
                  <ul className="uk-slideshow-nav uk-dotnav uk-position-bottom-right uk-flex uk-flex-middle uk-first-column">
                    <li uk-slideshow-item={0} className="uk-active">
                      <a href />
                    </li>
                    <li uk-slideshow-item={1} className>
                      <a href />
                    </li>
                  </ul>
                </div>
              </div>
              <canvas
                className="particles-js-canvas-el"
                width={1349}
                height={580}
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
          <div
            className="uk-section in-equity-11 uk-background-contain uk-background-top-right"
            style={{
              backgroundImage:
                'url("https://www.indonez.com/html-demo/Equity/img/in-equity-11-bg.png")',
              paddingTop: "10rem",
              backgroundImage: "none",
            }}
          >
            <div className="uk-container uk-margin-top uk-margin-bottom">
              <div className="row">
                <div className="col-12">
                  <div
                    className="uk-grid-medium uk-grid-match uk-child-width-1-3@m uk-child-width-1-2@s uk-grid"
                    data-uk-grid
                  >
                    <div className="uk-width-1-1 uk-first-column d-flex justify-content-center">
                      <h1
                        className="uk-margin-small-bottom text-center"
                        style={{ letterSpacing: "3px" }}
                      >
                        Find your next trade with access to a wide range of
                      </h1>
                      <h1
                        className="mt-0"
                        style={{ letterSpacing: "3px", paddingLeft: "10px" }}
                      >
                        Global markets.
                      </h1>
                    </div>
                    <a
                      href="https://www.investing.com/currencies/streaming-forex-rates-majors"
                      style={{ textDecoration: "none" }}
                      target="_blank"
                    >
                      <div className="uk-grid-margin uk-first-column">
                        <div
                          className="uk-card uk-card-default uk-card-body uk-border-rounded uk-box-shadow-medium h-100"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <div className="uk-flex uk-flex-middle">
                            <div className="uk-margin-right">
                              <img
                                src={
                                  app_url + "assets/img/in-equity-11-icon-1.svg"
                                }
                                alt="icon-1"
                                width={100}
                                data-height
                                data-uk-img
                              />
                            </div>
                            <div>
                              <h2 style={{ marginBottom: "0px" }}>Forex</h2>
                              {/* <p className="uk-text-small uk-margin-remove">Trade 40+ major, minor, and exotic currency pairs</p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                    <a
                      href="https://www.investing.com/indices/major-indices"
                      style={{ textDecoration: "none" }}
                      target="_blank"
                    >
                      <div className="uk-grid-margin">
                        <div
                          className="uk-card uk-card-default uk-card-body uk-border-rounded uk-box-shadow-medium h-100"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <div className="uk-flex uk-flex-middle">
                            <div className="uk-margin-right">
                              <img
                                src={
                                  app_url + "assets/img/in-equity-11-icon-2.svg"
                                }
                                alt="icon-2"
                                width={100}
                                data-height
                                data-uk-img
                              />
                            </div>
                            <div>
                              <h2 style={{ marginBottom: "0px" }}>Indices</h2>
                              {/* <p className="uk-text-small uk-margin-remove">Trade 15 of the most famous global indices as CFDs</p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                    <a
                      href="https://www.investing.com/etfs/world-etfs"
                      style={{ textDecoration: "none" }}
                      target="_blank"
                    >
                      <div className="uk-grid-margin">
                        <div
                          className="uk-card uk-card-default uk-card-body uk-border-rounded uk-box-shadow-medium h-100"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <div className="uk-flex uk-flex-middle">
                            <div className="uk-margin-right">
                              <img
                                src={
                                  app_url + "assets/img/in-equity-11-icon-3.svg"
                                }
                                alt="icon-3"
                                width={100}
                                data-height
                                data-uk-img
                              />
                            </div>
                            <div>
                              <h2 style={{ marginBottom: "0px" }}>ETFs</h2>
                              {/* <p className="uk-text-small uk-margin-remove">Trade the most covered &amp; highest-profile asset classes</p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                    <a
                      href="https://www.investing.com/commodities/metals"
                      style={{ textDecoration: "none" }}
                      target="_blank"
                    >
                      <div className="uk-grid-margin uk-first-column">
                        <div
                          className="uk-card uk-card-default uk-card-body uk-border-rounded uk-box-shadow-medium h-100"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <div className="uk-flex uk-flex-middle">
                            <div className="uk-margin-right">
                              <img
                                src={
                                  app_url + "assets/img/in-equity-11-icon-4.svg"
                                }
                                alt="icon-4"
                                width={100}
                                data-height
                                data-uk-img
                              />
                            </div>
                            <div>
                              <h2 style={{ marginBottom: "0px" }}>Metals</h2>
                              {/* <p className="uk-text-small uk-margin-remove">Trade metals including Gold and Silver</p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                    <a
                      href="https://www.investing.com/rates-bonds/"
                      style={{ textDecoration: "none" }}
                      target="_blank"
                    >
                      <div className="uk-grid-margin">
                        <div
                          className="uk-card uk-card-default uk-card-body uk-border-rounded uk-box-shadow-medium h-100"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <div className="uk-flex uk-flex-middle">
                            <div className="uk-margin-right">
                              <img
                                src={asseturl + "bonds.png"}
                                alt="icon-5"
                                width={100}
                              />
                            </div>
                            <div>
                              <h2 style={{ marginBottom: "0px" }}>Bonds</h2>
                              {/* <p className="uk-text-small uk-margin-remove">Trade Bitcoin, Ethereum, Ripple and others.</p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                    <a
                      href="https://www.investing.com/commodities/energy"
                      style={{ textDecoration: "none" }}
                      target="_blank"
                    >
                      <div className="uk-grid-margin">
                        <div
                          className="uk-card uk-card-default uk-card-body uk-border-rounded uk-box-shadow-medium h-100"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <div className="uk-flex uk-flex-middle">
                            <div className="uk-margin-right">
                              <img
                                src={
                                  app_url + "assets/img/in-equity-11-icon-6.svg"
                                }
                                alt="icon-6"
                                width={100}
                                data-height
                                data-uk-img
                              />
                            </div>
                            <div>
                              <h2 style={{ marginBottom: "0px" }}>Energies</h2>
                              {/* <p className="uk-text-small uk-margin-remove">Trade Brent Crude Oil, WTI, Natural Gas and Coal</p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                    {/* <div className="uk-width-1-1 uk-grid-margin uk-first-column">
            <p className="uk-text-small uk-text-muted"><span className="uk-label uk-label-warning uk-margin-small-right in-label-small">Warning</span>All trading involves risk. It is possible to lose all your capital.</p>
          </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-section in-equity-13">
            <div className="uk-container uk-margin-medium-bottom">
              <div className="uk-grid uk-flex uk-flex-center">
                <div className="uk-width-3-4@m uk-text-center">
                  <h1 className="uk-margin-small-bottom">
                    Daily market{" "}
                    <span className="in-highlight">News &amp; Analysis</span>
                  </h1>
                  <p className="uk-text-lead uk-margin-remove-top">
                    Get timely news &amp; analysis from top financial experts
                  </p>
                </div>
                <div className="uk-width-5-6@m">
                  <div
                    className="uk-child-width-1-2@s uk-child-width-1-3@m uk-margin-top uk-grid"
                    data-uk-grid
                  >
                    <div className="uk-first-column">
                      <span className="uk-label in-label-small uk-margin-remove-bottom">
                        Markets
                      </span>
                      {marketdata.length > 0 &&
                        marketdata.map((row, key) => (
                          <>
                            {key > 2 ? (
                              <></>
                            ) : (
                              <article>
                                {row.linkimg != null &&
                                row.haverssimg == 0 &&
                                row.havetwitterimg == 0 ? (
                                  <div className="uk-card-media-top">
                                    <img
                                      src={
                                        api_asseturl + "market/" + row.linkimg
                                      }
                                      style={{ width: "100%", height: "200px" }}
                                    />
                                  </div>
                                ) : (
                                  <>
                                    {row.istwitter == 1 &&
                                    row.havetwitterimg == 1 ? (
                                      <>
                                        <div className="uk-card-media-top">
                                          <img
                                            src={row.linkimg}
                                            style={{
                                              width: "100%",
                                              height: "200px",
                                            }}
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {row.haverssimg == 1 ? (
                                          <>
                                            <div className="uk-card-media-top">
                                              <img
                                                src={row.linkimg}
                                                style={{
                                                  width: "100%",
                                                  height: "200px",
                                                }}
                                              />
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            {row.istwitter == 1 &&
                                            row.havetwitterimg == 0 ? (
                                              <div className="uk-card-media-top">
                                                <img
                                                  src={
                                                    app_url +
                                                    "assets/twitterbackground.png"
                                                  }
                                                  style={{
                                                    width: "100%",
                                                    height: "200px",
                                                  }}
                                                />
                                              </div>
                                            ) : (
                                              <div className="uk-card-media-top">
                                                <img
                                                  src={
                                                    app_url +
                                                    "assets/noimagefound.png"
                                                  }
                                                  style={{
                                                    width: "100%",
                                                    height: "200px",
                                                  }}
                                                />
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                                <h5>
                                  <a
                                    href={row.singlelink}
                                    style={{ textDecoration: "none" }}
                                    target="_blank"
                                  >
                                    {removeTags(row.singletitle)}
                                  </a>
                                  <Link to={app_url + "market"}></Link>
                                </h5>
                                <div className="uk-flex uk-flex-middle">
                                  <div>
                                    <i className="fas fa-clock fa-xs" />
                                  </div>
                                  <div>
                                    <span className="uk-text-small uk-text-uppercase uk-text-muted uk-margin-small-left">
                                      Date: {row.date}
                                    </span>
                                  </div>
                                </div>
                              </article>
                            )}
                          </>
                        ))}
                    </div>
                    <div className="uk-visible@m">
                      <span className="uk-label in-label-small uk-margin-remove-bottom">
                        Money
                      </span>

                      {moneydata.length > 0 &&
                        moneydata.map((row, key) => (
                          <>
                            {key > 2 ? (
                              <></>
                            ) : (
                              <article>
                                {row.linkimg != null &&
                                row.haverssimg == 0 &&
                                row.havetwitterimg == 0 ? (
                                  <div className="uk-card-media-top">
                                    <img
                                      src={
                                        api_asseturl + "money/" + row.linkimg
                                      }
                                      style={{ width: "100%", height: "200px" }}
                                    />
                                  </div>
                                ) : (
                                  <>
                                    {row.istwitter == 1 &&
                                    row.havetwitterimg == 1 ? (
                                      <>
                                        <div className="uk-card-media-top">
                                          <img
                                            src={row.linkimg}
                                            style={{
                                              width: "100%",
                                              height: "200px",
                                            }}
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {row.haverssimg == 1 ? (
                                          <>
                                            <div className="uk-card-media-top">
                                              <img
                                                src={row.linkimg}
                                                style={{
                                                  width: "100%",
                                                  height: "200px",
                                                }}
                                              />
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            {row.istwitter == 1 &&
                                            row.havetwitterimg == 0 ? (
                                              <div className="uk-card-media-top">
                                                <img
                                                  src={
                                                    app_url +
                                                    "assets/twitterbackground.png"
                                                  }
                                                  style={{
                                                    width: "100%",
                                                    height: "200px",
                                                  }}
                                                />
                                              </div>
                                            ) : (
                                              <div className="uk-card-media-top">
                                                <img
                                                  src={
                                                    app_url +
                                                    "assets/noimagefound.png"
                                                  }
                                                  style={{
                                                    width: "100%",
                                                    height: "200px",
                                                  }}
                                                />
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                                <h5>
                                  <a
                                    href={row.singlelink}
                                    style={{ textDecoration: "none" }}
                                    target="_blank"
                                  >
                                    {removeTags(row.singletitle)}
                                  </a>
                                </h5>
                                <div className="uk-flex uk-flex-middle">
                                  <div>
                                    <i className="fas fa-clock fa-xs" />
                                  </div>
                                  <div>
                                    <span className="uk-text-small uk-text-uppercase uk-text-muted uk-margin-small-left">
                                      Date: {row.date}
                                    </span>
                                  </div>
                                </div>
                              </article>
                            )}
                          </>
                        ))}
                    </div>
                    <div>
                      <span className="uk-label in-label-small uk-margin-remove-bottom">
                        Analysis
                      </span>
                      {analysisdata.length > 0 &&
                        analysisdata.map((row, key) => (
                          <>
                            {key > 2 ? (
                              <></>
                            ) : (
                              <article>
                                {row.linkimg != null &&
                                row.haverssimg == 0 &&
                                row.havetwitterimg == 0 ? (
                                  <div className="uk-card-media-top">
                                    <img
                                      src={
                                        api_asseturl + "analysis/" + row.linkimg
                                      }
                                      style={{ width: "100%", height: "200px" }}
                                    />
                                  </div>
                                ) : (
                                  <>
                                    {row.istwitter == 1 &&
                                    row.havetwitterimg == 1 ? (
                                      <>
                                        <div className="uk-card-media-top">
                                          <img
                                            src={row.linkimg}
                                            style={{
                                              width: "100%",
                                              height: "200px",
                                            }}
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {row.haverssimg == 1 ? (
                                          <>
                                            <div className="uk-card-media-top">
                                              <img
                                                src={row.linkimg}
                                                style={{
                                                  width: "100%",
                                                  height: "200px",
                                                }}
                                              />
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            {row.istwitter == 1 &&
                                            row.havetwitterimg == 0 ? (
                                              <div className="uk-card-media-top">
                                                <img
                                                  src={
                                                    app_url +
                                                    "assets/twitterbackground.png"
                                                  }
                                                  style={{
                                                    width: "100%",
                                                    height: "200px",
                                                  }}
                                                />
                                              </div>
                                            ) : (
                                              <div className="uk-card-media-top">
                                                <img
                                                  src={
                                                    app_url +
                                                    "assets/noimagefound.png"
                                                  }
                                                  style={{
                                                    width: "100%",
                                                    height: "200px",
                                                  }}
                                                />
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                                <h5>
                                  <a
                                    href={row.singlelink}
                                    style={{ textDecoration: "none" }}
                                    className="fontnunito"
                                    target="_blank"
                                  >
                                    {removeTags(row.singletitle)}
                                  </a>
                                </h5>
                                <div className="uk-flex uk-flex-middle">
                                  <div>
                                    <i className="fas fa-clock fa-xs" />
                                  </div>
                                  <div>
                                    <span className="uk-text-small uk-text-uppercase uk-text-muted uk-margin-small-left">
                                      Date: {row.date}
                                    </span>
                                  </div>
                                </div>
                              </article>
                            )}
                          </>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="uk-section uk-section-primary uk-preserve-color in-equity-14 uk-background-contain uk-background-top-center"
            data-src="img/in-equity-14-bg.svg"
            data-uk-img
            style={{
              backgroundImage:
                'url("https://www.indonez.com/html-demo/Equity/img/in-equity-14-bg.svg")',
            }}
          >
            <div className="uk-container uk-margin-small-bottom mobilerespadding">
              <div
                className="uk-child-width-1-3@m uk-flex uk-flex-middle uk-grid"
                data-uk-grid
              >
                <div className="uk-first-column">
                  <div className="uk-flex uk-flex-left uk-flex-middle">
                    <div className="uk-margin-right in-award-logo">
                      <img
                        src={app_url + "assets/img/in-equity-5-award-3.svg"}
                        alt="award"
                        width={128}
                        data-height
                        data-uk-img
                      />
                    </div>
                    <div className="in-award-badge">
                      <h3 className="uk-margin-remove">Traders #1 Choice</h3>
                      {/* <h4 className="uk-margin-remove-top uk-margin-small-bottom uk-text-uppercase in-heading-thin">True ECN Account</h4> */}
                      <p className="uk-text-small uk-margin-remove uk-visible@m">
                        Preferred by industry professionals
                      </p>
                    </div>
                  </div>
                </div>
                <div className="uk-flex uk-flex-right uk-flex-middle right-content p-0">
                  <ul className="uk-list in-list-check uk-margin-remove-bottom uk-margin-right">
                    <li>Fast and Intuitive Platform & Tools</li>
                    <li>Zero Brokerage on Equity Delivery</li>
                    <li>Lowest brokerage Share Broker in India</li>
                    <li>Quick, Paperless Sign-up, And much more</li>
                  </ul>
                </div>

                <div
                  className="uk-flex uk-flex-right uk-flex-middle right-content"
                  style={{ paddingLeft: "0px" }}
                  onClick={demathandleShow}
                >
                  <span className="uk-margin-large-left">
                    <div className="uk-button uk-button-secondary uk-button-large uk-border-rounded">
                      GET FREE DEMAT ACCOUNT
                      <i className="fas fa-arrow-circle-right uk-margin-small-left" />
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Modal
          show={show}
          onHide={hidemodal}
          size="lg"
          className="supportformhome d-flex align-items-center h-100"
        >
          <Modal.Header
            closeButton
            className="text-dark"
            style={{ border: "none" }}
          >
            <Modal.Title className="">Let us help you</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0 pb-0 pe-0">
            <div className="container-fluid" style={{ paddingLeft: "0px" }}>
              <div className="row">
                <div className="col-6">
                  <Lottie animationData={helpyou} style={w300} />
                </div>
                <div className="col-6">
                  <Form>
                    <Form.Group
                      className="my-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      {/* <Form.Label>Name</Form.Label> */}
                      <Form.Control
                        type="text"
                        placeholder="Full Name"
                        autoFocus
                        className="infoPlaceholder"
                        value={supportname}
                        onChange={(event) => {
                          setsupportname(event.target.value);
                        }}
                        style={{ backgroundColor: "#e9ecef" }}
                      />
                      {supportname == "" && validateshow == true ? (
                        <span style={{ color: "red" }}>
                          This Field Required
                        </span>
                      ) : (
                        <></>
                      )}
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      {/* <Form.Label>Email</Form.Label> */}
                      <Form.Control
                        type="email"
                        placeholder="Email Address"
                        autoFocus
                        value={supportemail}
                        onChange={(event) => {
                          setsupportemail(event.target.value);
                        }}
                        style={{ backgroundColor: "#e9ecef" }}
                      />
                      {supportemail == "" && validateshow == true ? (
                        <span style={{ color: "red" }}>
                          This Field Required
                        </span>
                      ) : (
                        <></>
                      )}
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      {/* <Form.Label>Mobile Number</Form.Label> */}
                      <Form.Control
                        type="number"
                        placeholder="Mobile Number"
                        autoFocus
                        value={supportmobile}
                        onChange={(event) => {
                          setsupportmobile(event.target.value);
                        }}
                        style={{ backgroundColor: "#e9ecef" }}
                      />
                      {checkcontact == true && validateshow == true ? (
                        <span style={{ color: "red" }}>
                          Contact Only 10 Digits Allowed
                        </span>
                      ) : (
                        <></>
                      )}
                    </Form.Group>

                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      {/* <Form.Label>Example textarea</Form.Label> */}
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Your Message...."
                        value={supportmessage}
                        onChange={(event) => {
                          setsupportmessage(event.target.value);
                        }}
                        style={{ backgroundColor: "#e9ecef" }}
                      />
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer
            className="justify-content-end"
            style={{ border: "none" }}
          >
            <button
              type="submit"
              className="uk-button uk-button-primary uk-border-rounded text-uppercase"
              onClick={handleClose}
            >
              Submit
            </button>
          </Modal.Footer>
        </Modal>
        {/* GET FREE DEMAT ACCOUNT MODAL */}

        <Modal
          show={dematshow}
          onHide={demathidemodal}
          size="lg"
          className="supportformhome d-flex align-items-center h-100"
        >
          <Modal.Header
            closeButton
            className="text-dark"
            style={{ border: "none" }}
          >
            <Modal.Title className="">Get Free Demat Account</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0 pb-0 pe-0">
            <div className="container-fluid" style={{ paddingLeft: "0px" }}>
              <div className="row">
                <div className="col-6">
                  <Lottie animationData={demataccount} style={w300} />
                </div>
                <div className="col-6">
                  <div className="d-flex align-items-center h-100">
                    <div>
                      <Form>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          {/* <Form.Label>Email</Form.Label> */}
                          <Form.Control
                            type="email"
                            placeholder="Email Address"
                            autoFocus
                            value={dematemail}
                            onChange={(event) => {
                              setdematemail(event.target.value);
                            }}
                            style={{ backgroundColor: "#e9ecef" }}
                          />
                          {dematemail == "" && dematvalidateshow == true ? (
                            <span style={{ color: "red" }}>
                              This Field Required
                            </span>
                          ) : (
                            <></>
                          )}
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          {/* <Form.Label>Mobile Number</Form.Label> */}
                          <Form.Control
                            type="number"
                            placeholder="Mobile Number"
                            autoFocus
                            value={dematmobile}
                            onChange={(event) => {
                              setdematmobile(event.target.value);
                            }}
                            style={{ backgroundColor: "#e9ecef" }}
                          />
                          {dematcheckcontact == true &&
                          dematvalidateshow == true ? (
                            <span style={{ color: "red" }}>
                              Contact Only 10 Digits Allowed
                            </span>
                          ) : (
                            <></>
                          )}
                        </Form.Group>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer
            className="justify-content-end"
            style={{ border: "none" }}
          >
            <button
              type="submit"
              className="uk-button uk-button-primary uk-border-rounded text-uppercase"
              onClick={demathandleClose}
            >
              Submit
            </button>
          </Modal.Footer>
        </Modal>
        {/* GET FREE DEMAT ACCOUNT MODAL */}
        <Footer />
      </div>
      <div className="sticky-ad cursor-pointer" onClick={demathandleShow}>
        {/* Your ad content goes here */}
        Open a Zerodha Demat and Trading account with no registration fee!
      </div>
      <div
        class="float glow one hover-pointer"
        target="_blank"
        onClick={handleShow}
      >
        <div className="d-flex align-items-center justify-content-center h-100 flex-column">
          <h2 className="helptext my-0">Let us Help You !</h2>
        </div>
      </div>
    </>
  );
}
