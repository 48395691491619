import React, {useState,useEffect} from 'react';
import { api_url,app_url ,isEmail} from '../common/Helpers';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate ,Link} from 'react-router-dom';
import Lottie from "lottie-react";
import userwelcome from '../lottie/userwelcome.json';
import Header from '../common/Header';
import FacebookLogin from 'react-facebook-login';
import { GoogleOAuthProvider,GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
export default function Login() {
// GOOGLE
const onSuccess = (email,name) => {
  signupgoogle(email,name)
};
  
  // FACEBOOK
  const responseFacebook = (response) => {
    if (response.accessToken) {
      signupgoogle(response.userID,response.name)
    } else {
      return false;
    }
  }
  const navigate = useNavigate();
  const [submit, setsubmit] = useState(false)
  const [uname, setuname] = useState("")
  const [uemail, setuemail] = useState("")
  const [pass, setpass] = useState("")
  const [cpass, setcpass] = useState("")
  const userlogin = async (e) => {
        e.preventDefault();
        setsubmit(true);
        if(uname == "" || uemail == "" || pass == "" || cpass == ""){
          Swal.fire({
            icon:"error",
            text:"All Fields Required"
          })
          setsubmit(false);
          return false;
        }
        if(pass != cpass){
          Swal.fire({
            icon:"error",
            text:"Confirm Password Not Matched"
          })
          setsubmit(false);
          return false;
        }
      if(isEmail(uemail)){
        const formData = new FormData()
        formData.append('name', uname)
        formData.append('uemail', uemail)
        formData.append('pass', pass)
        await axios.post(api_url+`userregister`, formData).then(({data})=>{
          if(data !== null && data !== 0){
              setsubmit(false)
              localStorage.setItem("uid",data.id);
              localStorage.setItem("email",data.uemail);
              localStorage.setItem("name",data.name);
              localStorage.setItem("type",data.type);
              localStorage.setItem("myroleid",data.roleid);
              localStorage.setItem("rolename",data.rolename);
              Swal.fire({
                icon:"success",
                text:"Registration Successful"
              })
              navigate(app_url+'login');
          }else if(data == 0){
            Swal.fire({
              icon:"error",
              text:"Already Registred"
            })
            setsubmit(false);
          }
          else{
              Swal.fire({
                icon:"error",
                text:"User Not Found"
              })
              setsubmit(false);
          }
        }).catch(({response})=>{
          if(response.status===422){
            Swal.fire({
              text:response.data.errors,
              icon:"error"
            })
            setsubmit(false);
          }else{
            Swal.fire({
              text:response.data.message,
              icon:"error"
            })
            setsubmit(false);
          }
          setsubmit(false)
        })
      }
      else{
        Swal.fire({
          text:'Email is not valid',
          icon:"error"
        })
        setsubmit(false);
        return false;
    }
  }
  const signupgoogle = (uemail,uname) =>{
    const formData = new FormData()
        formData.append('uemail', uemail)
        formData.append('name', uname)
        formData.append('from', 'google')
        axios.post(api_url+`userregister`, formData).then(({data})=>{
          if(data !== null && data !== 0){
              Swal.fire({
                icon:"success",
                text:"Registration Successful"
              })
              localStorage.setItem("uid",data.id);
              localStorage.setItem("email",data.uemail);
              localStorage.setItem("name",data.name);
              localStorage.setItem("type",data.type);  
              localStorage.setItem("myroleid",data.roleid);
              localStorage.setItem("rolename",data.rolename);
              navigate(app_url);
          }else if(data == 0){
            Swal.fire({
              icon:"error",
              text:"Already Registred"
            })
          }
        }).catch(({response})=>{
          if(response.status===422){
            console.log(response.data.errors)
          }else{
            console.log(response.data.message)
          }
        })
  }
  return (
    <>
    <Header />
 <main>
 <section id='loginsection' style={{backgroundImage: 'url("assets/loginbackground.jpg")',backgroundSize: 'cover'}}>

<div className="container">
      <div className="form-box" style={{minHeight: '610px'}}>
        <div className="header-form">
          <h4 className="text-primary text-center"><i className="fas fa-user-circle" style={{fontSize:"110px",color: '#fcb42d'}}></i></h4>
          <div className="image">
          </div>
        </div>
        <div className="body-form">
         
        <form className="uk-grid uk-form" onSubmit={userlogin}>
                <div className="uk-margin-small uk-width-1-1 uk-inline">
                  <span className="uk-form-icon uk-form-icon-flip fas fa-user fa-sm" />
                  <input className="uk-input uk-border-rounded" id="username" type="name" placeholder="Full Name" 
                  value={uname}
                  onChange={(event)=>{
                    setuname(event.target.value)
                  }}/>
                </div>
                <div className="uk-margin-small uk-width-1-1 uk-inline">
                  <span className="uk-form-icon uk-form-icon-flip fas fa-user fa-sm" />
                  <input className="uk-input uk-border-rounded" id="username" type="email" placeholder="Email" 
                  value={uemail}
                  onChange={(event)=>{
                    setuemail(event.target.value)
                  }}/>
                </div>
                <div className="uk-margin-small uk-width-1-1 uk-inline">
                  <span className="uk-form-icon uk-form-icon-flip fas fa-lock fa-sm" />
                  <input className="uk-input uk-border-rounded" id="password" type="text" placeholder="Password" 
                   value={pass}
                   onChange={(event)=>{
                    setpass(event.target.value)
                  }}/>
                </div>
                <div className="uk-margin-small uk-width-1-1 uk-inline">
                  <span className="uk-form-icon uk-form-icon-flip fas fa-lock fa-sm" />
                  <input className="uk-input uk-border-rounded" id="password" type="text" placeholder="Confirm Password" 
                   value={cpass}
                   onChange={(event)=>{
                    setcpass(event.target.value)
                  }}/>
                </div>
                <div className="uk-margin-small uk-width-1-1">
                  <button className="uk-button uk-width-1-1 uk-button-primary uk-border-rounded uk-float-left" type="submit" name="submit">Sign Up</button>
                </div>
                <div className="uk-margin-small uk-width-1-1 uk-inline">
                <div className="message">
                <div></div>
                <div><a href="#">Forgot your password</a></div>
                </div>
                </div>
              </form>
              <div className="uk-margin-small-bottom d-flex justify-content-around mt-1 mb-4" id='facebooklogin'>              
              <GoogleOAuthProvider clientId="648329680987-nfkddecl25japtgek8gkp9qg41bbso6q.apps.googleusercontent.com">
                <GoogleLogin
                  onSuccess={credentialResponse => {
                    var token = credentialResponse.credential;
                    var decoded = jwt_decode(token);
                    onSuccess(decoded.email,decoded.name);
                  }}
                  onError={() => {
                    console.log('Login Failed');
                  }}
                />;
              </GoogleOAuthProvider>;
                <FacebookLogin
                  appId="669324677978543"
                  callback={responseFacebook}
                  textButton="Facebook"
                  cssClass="my-facebook-button-class"
                  icon="fas fa-facebook"
                  render={renderProps => (
                    <button onClick={renderProps.onClick}>Facebook</button>
                  )}
                />
              </div>
              <div className='text-start w-100'>
              <span className="uk-text-small text-white">Already have an account? <Link className="uk-button uk-button-text" style={{color: '#fcb42d'}} to={app_url+'login'}>Login here</Link></span>
              </div>
        </div>
      </div>
     </div> 

     </section>
</main>

    </>
  )
}