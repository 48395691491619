import React, { useEffect, useState } from "react";
import Header from "../../common/Header";
import axios from "axios";
import { api_url, formatdmY } from "../../common/Helpers";
import "../../common/style.css";
import $ from "jquery";
import Swal from "sweetalert2";
import Footer from "../../common/Footer";
import Highcharts from "highcharts/highstock";
import Select from "react-select";
import moment from "moment-timezone";
import momentTZ from "moment-timezone/builds/moment-timezone-with-data-2012-2022.min.js";
export default function Contact() {
  const [submit, setsubmit] = useState(false);
  const [load, setload] = useState(false);
  const [counter, setCounter] = useState(0);
  const [expload, setexpload] = useState(true);
  //// THIS SECTION IS FOR NIFTY50
  const [nifyt50data, setnifyt50data] = useState([]);
  const [nifyt50expiry, setnifyt50expiry] = useState([
    { value: "", label: "" },
  ]);
  //// THIS SECTION IS FOR BANK NIFTY
  const [bankniftydata, setbankniftydata] = useState([]);
  const [bankniftyexpiry, setbankniftyexpiry] = useState([
    { value: "", label: "" },
  ]);
  // THIS SECTION IS FOR FINNIFTY
  // Step 3: Create state for Finnifty data and expiry date
  const [finniftyExpiry, setfinniftyExpiry] = useState([
    { value: "", label: "" },
  ]);
  // useEffect(()=>{
  //   fetchdatas();
  // },[])
  const fetchexpdatas = async () => {
    await axios.get(api_url + `getalloptionchainexplist`).then(({ data }) => {
      // setexpirevalue(data[0].label)
      // fetchdataslatest(data[0].value)
      let arr = [];
      if (data.FINNIFTY.length > 0) {
        for (let x in data.FINNIFTY) {
          // if(x == 0){
          //   getOptionChainFinniftyLatest(data.FINNIFTY[x])
          // }
          let obj = {
            value: data.FINNIFTY[x],
            label: formatdmY(data.FINNIFTY[x]),
          };
          arr.push(obj);
        }
      }
      setfinniftyExpiry(arr);
      let arr1 = [];
      if (data.NIFTY.length > 0) {
        for (let x in data.NIFTY) {
          // if(x == 0){
          //   getOptionChainnifty50Latest(data.NIFTY[x])
          // }
          let obj = { value: data.NIFTY[x], label: formatdmY(data.NIFTY[x]) };
          arr1.push(obj);
        }
      }
      setnifyt50expiry(arr1);
      let arr2 = [];
      if (data.BANKNIFTY.length > 0) {
        for (let x in data.BANKNIFTY) {
          // if(x == 0){
          //   getoptionchainbankniftyLatest(data.BANKNIFTY[x])
          // }
          let obj = {
            value: data.BANKNIFTY[x],
            label: formatdmY(data.BANKNIFTY[x]),
          };
          arr2.push(obj);
        }
      }
      setbankniftyexpiry(arr2);
    });
    setexpload(false);
  };
  // Step 4: Fetch Finnifty option chain data with a specific expiry date
  const getOptionChainnifty50Latest = async (expirydate) => {
    await axios
      .get(api_url + `getoptionchainlatests/` + expirydate)
      .then(({ data }) => {
        console.log(data);
      });
  };
  const getoptionchainbankniftyLatest = async (expirydate) => {
    await axios
      .get(api_url + `getoptionchainbankniftylatest/` + expirydate)
      .then(({ data }) => {
        console.log(data);
      });
  };
  const getOptionChainFinniftyLatest = async (expirydate) => {
    await axios
      .get(api_url + `getoptionchainfinniftylatest/` + expirydate)
      .then(({ data }) => {
        console.log(data);
      });
  };

  useEffect(() => {
    fetchexpdatas();
    fetchdata();
    const timer = setTimeout(() => {
      setCounter(counter + 1);
      fetchdata();
    }, 50000);

    return () => {
      clearTimeout(timer);
    };
  }, [counter]);
  const fetchdata = async () => {
    await axios.get(api_url + "getextraoptionchain").then(({ data }) => {
      console.log(data.banknifty);
      let allivnifty = [];
      let allpricenifty = [];
      let allivbanknifty = [];
      let allpricebanknifty = [];
      let timestamp = [];
      let banktimestamp = [];
      for (let x in data.nifty50) {
        timestamp.push(data.nifty50[x].time);
        allivnifty.push(Number(data.nifty50[x].iv));
        allpricenifty.push(Number(data.nifty50[x].price));
      }
      for (let x in data.banknifty) {
        banktimestamp.push(data.banknifty[x].time);
        allivbanknifty.push(Number(data.banknifty[x].iv));
        allpricebanknifty.push(Number(data.banknifty[x].price));
      }
      Highcharts.chart("containerniftyimp", {
        chart: {
          type: "spline",
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: timestamp,
        },
        yAxis: [
          {
            // Primary yAxis
            labels: {
              format: "{value}",
            },
            title: {
              text: "IV",
            },
            opposite: false,
          },
          {
            // Secondary yAxis
            gridLineWidth: 0,
            title: {
              text: "Spot Price",
            },
            labels: {
              format: "{value}",
            },
            opposite: true,
          },
        ],
        tooltip: {
          shared: true,
          pointFormatter: function () {
            var seriesName = this.series.name;
            var valuePrefix = seriesName === "Spot Price" ? "" : "";
            var valueSuffix = seriesName === "IV" ? " " : "";
            return (
              '<div style="color:' +
              this.color +
              '">' +
              seriesName +
              "</div>: </b>" +
              valuePrefix +
              Highcharts.numberFormat(this.y, 2) +
              valueSuffix +
              "</b>"
            );
          },
        },
        plotOptions: {
          line: {
            marker: {
              enabled: false,
            },
          },
          spline: {
            marker: {
              enabled: false,
            },
          },
        },
        series: [
          {
            name: "IV",
            type: "spline", // Modified type from 'line' to 'spline'
            yAxis: 0,
            data: allivnifty,
            tooltip: {
              valueSuffix: " %",
            },
          },
          {
            name: "Spot Price",
            type: "spline", // Modified type from 'line' to 'spline'
            yAxis: 1,
            data: allpricenifty,
            tooltip: {
              valuePrefix: "$",
            },
          },
        ],
      });

      Highcharts.chart("containerbankimp", {
        chart: {
          type: "spline",
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: banktimestamp,
        },
        yAxis: [
          {
            // Primary yAxis
            labels: {
              format: "{value}",
            },
            title: {
              text: "IV",
            },
            opposite: false,
          },
          {
            // Secondary yAxis
            gridLineWidth: 0,
            title: {
              text: "Spot Price",
            },
            labels: {
              format: "{value}",
            },
            opposite: true,
          },
        ],
        tooltip: {
          shared: true,
          pointFormatter: function () {
            var seriesName = this.series.name;
            var valuePrefix = seriesName === "Spot Price" ? "" : "";
            var valueSuffix = seriesName === "IV" ? " " : "";
            return (
              '<div style="color:' +
              this.color +
              '">' +
              seriesName +
              "</div>: </b>" +
              valuePrefix +
              Highcharts.numberFormat(this.y, 2) +
              valueSuffix +
              "</b>"
            );
          },
        },
        plotOptions: {
          line: {
            marker: {
              enabled: false,
            },
          },
          spline: {
            marker: {
              enabled: false,
            },
          },
        },
        series: [
          {
            name: "IV",
            type: "spline", // Modified type from 'line' to 'spline'
            yAxis: 0,
            data: allivbanknifty,
            tooltip: {
              valueSuffix: " %",
            },
          },
          {
            name: "Spot Price",
            type: "spline", // Modified type from 'spline' to 'line'
            yAxis: 1,
            data: allpricebanknifty,
            tooltip: {
              valuePrefix: "$",
            },
          },
        ],
      });
    });
  };

  return (
    <>
      <div>
        <Header />
        <main>
          {/* section content begin */}
          <div className="uk-section uk-margin-small-top">
            <div
              className="uk-container"
              style={{ marginTop: "1.4rem", maxWidth: "100%" ,maxWidth: '1400px'}}
            >
              <div className="row">
                <div className="col-12 mb-1">
                  <div className="uk-text-center">
                    <h2
                      style={{
                        background: "#FDD965",
                        padding: "3px",
                        marginBottom: "0px",
                      }}
                    >
                      Implied Volatility (ATM)
                    </h2>
                  </div>
                </div>
                <div className="col-12">
                  {load ? (
                    <div className="linear-background w-100"> </div>
                  ) : (
                    <div
                      className="uk-card uk-card-default uk-card-body uk-border-rounded"
                      style={{
                        margin: 0,
                        padding: 0,
                        border: "1px solid #C17951",
                        borderRadius: "0px",
                      }}
                    >
                      <div style={{ overflow: "hidden" }}>
                        {expload ? (
                          <div
                            className="linear-background w-100"
                            style={{ height: "30px" }}
                          >
                            {" "}
                          </div>
                        ) : (
                          <div
                            className="row"
                            style={{ background: "#FDF1CC" }}
                          >
                            <div className="col-4"></div>
                            <div className="col-4 text-center">
                              <h2
                                style={{
                                  background: "#FDF1CC",
                                  margin: 0,
                                  textAlign: "center",
                                }}
                              >
                                NIFTY 50
                              </h2>
                            </div>
                            <div className="col-4 text-end">
                              <div style={{ fontWeight: "600" }}>
                                <label
                                  htmlFor=""
                                  style={{
                                    display: "inline-block",
                                    marginRight: "10px",
                                  }}
                                >
                                  Expiry
                                </label>
                                <Select
                                  style={{ display: "inline-block" }}
                                  defaultValue={{
                                    label: nifyt50expiry[0].label,
                                    value: nifyt50expiry[0].value,
                                  }}
                                  className="text-dark"
                                  required
                                  name="expiredate"
                                  id="expiredate"
                                  onChange={(e) =>
                                    getOptionChainnifty50Latest(e.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <figure class="highcharts-figure">
                        <div
                          id="containerniftyimp"
                          style={{ height: "500px" }}
                        ></div>
                      </figure>
                    </div>
                  )}
                </div>
                <div className="col-12 mt-5">
                  {load ? (
                    <div className="linear-background w-100"> </div>
                  ) : (
                    <div
                      className="uk-card uk-card-default uk-card-body uk-border-rounded"
                      style={{
                        margin: 0,
                        padding: 0,
                        border: "1px solid #C17951",
                        borderRadius: "0px",
                      }}
                    >
                      <div style={{ overflow: "hidden" }}>
                        {expload ? (
                          <div
                            className="linear-background w-100"
                            style={{ height: "30px" }}
                          >
                            {" "}
                          </div>
                        ) : (
                          <div
                            className="row"
                            style={{ background: "#FDF1CC" }}
                          >
                            <div className="col-4"></div>
                            <div className="col-4 text-center">
                              <h2
                                style={{
                                  margin: 0,
                                  textAlign: "center",
                                }}
                              >
                                BANK NIFTY
                              </h2>
                            </div>
                            <div className="col-4 text-end">
                              <div style={{ fontWeight: "600" }}>
                                <label
                                  htmlFor=""
                                  style={{
                                    display: "inline-block",
                                    marginRight: "10px",
                                  }}
                                >
                                  Expiry
                                </label>
                                <Select
                                  style={{ display: "inline-block" }}
                                  defaultValue={{
                                    label: bankniftyexpiry[0].label,
                                    value: bankniftyexpiry[0].value,
                                  }}
                                  className="text-dark"
                                  required
                                  name="expiredate"
                                  id="expiredate"
                                  onChange={(e) =>
                                    getoptionchainbankniftyLatest(e.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <figure class="highcharts-figure">
                        <div
                          id="containerbankimp"
                          style={{ height: "500px" }}
                        ></div>
                      </figure>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
}
