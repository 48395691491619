import React, { useEffect,useState } from 'react'
import Header from '../../common/Header';
import axios from 'axios';
import {app_url,api_url,api_asseturl,fullappurl} from '../../common/Helpers';
import $ from 'jquery';
import Footer from '../../common/Footer';
import {Pagination} from 'react-laravel-paginex'
import {ShareSocial} from 'react-share-social' 
export default function Contact(){
  const [products, setProducts] = useState([])
  const [load, setload] = useState(false)
  useEffect(()=>{
    fetchdatas();
  },[])
  const fetchdatas = async () => {
    setload(true);
    await axios.get(api_url + 'getmarketnews').then(({ data }) => {
      // Filter out titles that contain '?' anywhere in the title
      const filteredData = data.filter(item => !item.singletitle.includes('?'));
      setProducts(filteredData);
      setload(false);
    });
    
  }
  
function removeTags(str) {
  if ((str===null) || (str===''))
      return false;
  else
      str = str.toString();
        
  // Regular expression to identify HTML tags in 
  // the input string. Replacing the identified 
  // HTML tag with a null string.
  return str.replace( /(<([^>]+)>)/ig, '');
}
return (
      <>
     <div>
  <Header />
 <main>
  {/* section content begin */}
  <div className="uk-section uk-margin-small-top">
    <div className="uk-container">
    <h1 class="uk-margin-small-top uk-text-center"><span class="in-highlight">News & Updates</span></h1>
      <div className="uk-grid" data-uk-grid style={{marginTop: '50px'}}>
        <div className="uk-width-2-4@m uk-first-column">
          <div className="in-blog-1 uk-grid uk-grid-stack" data-uk-grid>
          {load ?
                      <div className="linear-background w-100"> </div>
                        :
                        <>
                        <div className="uk-section in-offset-top-60 in-offset-top-50@s" style={{width: '100%'}}>
  <div className="uk-container">
    <div className="uk-grid uk-child-width-1-2@m in-testimonial-7" data-uk-grid style={{flexDirection: 'column',alignItems: 'center'}}>

{
                  products.length > 0 && (
                    products.map((row, key)=>(
                  <div className="uk-first-column" style={{position: 'relative'}}>
                    <div className="uk-card uk-card-default uk-box-shadow-small uk-border-rounded" style={{paddingTop: '0',minHeight: '100%',height: 'auto',overflow: 'hidden'}}>
                           {row.linkimg != null && row.haverssimg == 0 && row.havetwitterimg == 0 ?
                              <div className="uk-card-media-top">
                                <img src={api_asseturl+'marketnews/'+row.linkimg} style={{width: '100%',height: '70%'}}/>
                              </div>
                            :
                            <>
                            {row.istwitter == 1 && row.havetwitterimg == 1? 
                              <>
                                <div className="uk-card-media-top">
                                  <img src={row.linkimg} style={{width: '100%',height: '70%'}}/>
                                </div>
                              </>
                            :
                              <>
                              {row.haverssimg == 1 ?
                                <>
                                <div className="uk-card-media-top">
                                  <img src={row.linkimg} style={{width: '100%',height: '70%'}}/>
                                </div>
                                </>
                                :
                              <>
                              {row.istwitter == 1 && row.havetwitterimg == 0?
                              <></>
                              // <div className="uk-card-media-top">
                              //   <img src={app_url+'assets/twitterbackground.png'} style={{width: '100%',height: '70%'}}/>
                              // </div>
                              :
                              <div className="uk-card-media-top">
                                  {/* <img src={app_url+'assets/noimagefound.png'} style={{width: '100%',height: '70%'}}/> */}
                                </div>
                              } 
                                
                              </>
                            }
                            </>
                          }
                          </>
                            }
                        <div className="uk-card-body" style={{padding: '0.5rem'}}>
                          <h5>
                          {row.istwitter == 1 ?
                          <a href={'#'} style={{textDecoration: 'none',fontSize: '22px'}} className="fontnunito">{removeTags(row.singletitle)}</a>
                          :
                          <a href={row.singlelink} style={{textDecoration: 'none',fontSize: '22px'}} className="fontnunito" target="_blank">{removeTags(row.singletitle)}</a>
                          }
                            
                            </h5>
                            <div className="uk-flex uk-flex-middle">
                            <div>
                              <i className="fas fa-clock fa-xs" />
                            </div>
                            <div>
                              <span className="uk-text-small uk-text-uppercase uk-text-muted uk-margin-small-left">Date: {row.date}</span>
                              <span className="uk-text-small uk-text-uppercase uk-text-muted uk-margin-small-left">Time: {row.time}</span>
                            </div>
                          </div>
                        </div>
                        {/* <div className="uk-card-footer" style={{padding: '0.5rem'}}>
                            <footer>{removeTags(row.singledes)}</footer>
                        </div> */}
                    </div>

                  </div>
                ))
    )
}                  
    </div>
  </div>
</div></>
          }



        </div>
          {/* <Pagination changePage={getData} data={products} options={options}/> */}
        </div>
        {/* <div className="uk-width-expand@m">
          <aside className="uk-margin-medium-bottom">
            <div className="uk-card uk-card-default uk-card-body uk-border-rounded">
              <h5 className="uk-heading-bullet uk-text-uppercase uk-margin-remove-bottom">Categories</h5>
              <ul className="uk-list in-widget-category"><li><a href="find.html?category=crypto">Crypto<span className="uk-label in-label-small uk-float-right">3</span></a></li><li><a href="find.html?category=health">Health<span className="uk-label in-label-small uk-float-right">2</span></a></li><li><a href="find.html?category=markets">Markets<span className="uk-label in-label-small uk-float-right">4</span></a></li><li><a href="find.html?category=politics">Politics<span className="uk-label in-label-small uk-float-right">2</span></a></li><li><a href="find.html?category=technology">Technology<span className="uk-label in-label-small uk-float-right">3</span></a></li></ul>
            </div>
          </aside>
          <aside className="uk-margin-medium-bottom">
            <div className="uk-card uk-card-default uk-card-body uk-border-rounded">
              <h5 className="uk-heading-bullet uk-text-uppercase uk-margin-remove-bottom">Latest News</h5>
              <ul className="uk-list uk-list-divider uk-list-large in-widget-latest">
                <li>
                  <a href="blackrock-responds-to-george-soros-criticism-over.html">BlackRock responds to George Soros’ criticism over China ...</a>
                  <span className="uk-article-meta uk-text-small"><br /><i className="fas fa-clock fa-sm uk-margin-small-right" />October 19, 2021</span>
                </li>
                <li>
                  <a href="india-is-better-equipped-to-deal-with-a-potential.html">India is ‘better equipped’ to deal with a potential Covid ...</a>
                  <span className="uk-article-meta uk-text-small"><br /><i className="fas fa-clock fa-sm uk-margin-small-right" />October 19, 2021</span>
                </li>
                <li>
                  <a href="china-portrays-the-us-as-weak-and-unreliable-to.html">China portrays the U.S. as ‘weak and unreliable’ to draw ...</a>
                  <span className="uk-article-meta uk-text-small"><br /><i className="fas fa-clock fa-sm uk-margin-small-right" />October 19, 2021</span>
                </li>
              </ul>
            </div>
          </aside>
          <aside className="uk-margin-medium-bottom">
            <div className="uk-card uk-card-default uk-card-body uk-border-rounded">
              <h5 className="uk-heading-bullet uk-text-uppercase uk-margin-remove-bottom">Tags</h5>
              <div className="uk-margin-top in-widget-tag"><a href="find.html?tag=asia"><span className="uk-label uk-border-pill">asia</span></a><a href="find.html?tag=crypto"><span className="uk-label uk-border-pill">crypto</span></a><a href="find.html?tag=economics"><span className="uk-label uk-border-pill">economics</span></a><a href="find.html?tag=europe"><span className="uk-label uk-border-pill">europe</span></a><a href="find.html?tag=health"><span className="uk-label uk-border-pill">health</span></a><a href="find.html?tag=investment"><span className="uk-label uk-border-pill">investment</span></a><a href="find.html?tag=politics"><span className="uk-label uk-border-pill">politics</span></a><a href="find.html?tag=technology"><span className="uk-label uk-border-pill">technology</span></a><a href="find.html?tag=usa"><span className="uk-label uk-border-pill">usa</span></a></div>
            </div>
          </aside>
        </div> */}
      </div>
    </div>
  </div>
</main>



  <Footer />
  </div>
      </>
    )
  }
