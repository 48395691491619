import React, { useEffect,useState } from 'react'
import {Link,useNavigate} from 'react-router-dom';
import {app_url,api_url, api_asseturl} from '../common/Helpers'; 
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import { googleLogout } from '@react-oauth/google';
export default function Header() {
  const [ profile, setProfile ] = useState([]);
  const navigate = useNavigate();
  const uid = localStorage.getItem("uid");
  const uname = localStorage.getItem("name");
  const email = localStorage.getItem("email");
  const clientId = '386932037035-k8v833noqjk7m4auae0t83vnkrqvvg3t.apps.googleusercontent.com';
  const logOut = () => {
    setProfile(null);
  };
  const userlogout = () =>{
    localStorage.clear();
    googleLogout();
    navigate(app_url)
  }
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const containerStyle = {
    justifyContent: isMobile ? "start" : "center",
    marginRight: isMobile ? "0px" : "0",
  };
  
  const [showmobmenu, setshowmobmenu] = useState(false);
  const showbar = () =>{
    setshowmobmenu(false)
  }
  const showmodal = () =>{
    setshowmobmenu(true)
  }
    return (
      <>
  <header>
    <div className="uk-section uk-padding-remove-vertical">
      <nav className="uk-navbar-container uk-navbar-transparent" style={{background: '#fff'}} data-uk-sticky="show-on-up: true; animation: uk-animation-slide-top;">
        
        <div className="uk-container-fluid" data-uk-navbar style={containerStyle}>
          <div className="row">
            <div className="d-flex align-items-center justify-content-center">
          <div className="uk-navbar-item in-mobile-nav uk-hidden@m" style={{justifyContent: 'end'}}>
            <button onClick={e=>{showmodal()}} className="uk-button"><i className="fas fa-bars" /></button>
          </div>
              <Link to={app_url}><img src={'https://finakin.com/logowhitebg.png'} style={{height: '70px',paddingLeft: '10px'}} alt="" /></Link>
            </div>
          </div>
          <div className="uk-navbar-left">
            <div className="uk-navbar-item">
              <ul className="uk-navbar-nav uk-visible@m" id='mainnav'>
                <li><Link to={app_url}>Home</Link></li>
                <li><Link to={'#'}>Markets Data<i className="fas fa-chevron-down" /></Link>
                  <div className="uk-navbar-dropdown">
                    <ul className="uk-nav uk-navbar-dropdown-nav">
                      <li><Link to={app_url+'premarketdata'}>Pre Market Data</Link></li>
                      <li><Link to={app_url+'flldlldata'}>FII / DII Data</Link></li>
                      <li><Link to={app_url+'gmfutures'}>Global Markets Futures</Link></li>
                    </ul>
                  </div>
                </li>
                <li><Link to={app_url+'#'}>Markets Today<i className="fas fa-chevron-down" /></Link>
                  <div className="uk-navbar-dropdown">
                    <ul className="uk-nav uk-navbar-dropdown-nav">
                      <li><Link to={app_url+'indexcontributors'}>Index Contributors</Link></li>
                      <li><Link to={app_url+'multiindex'}>Multi-Index Chart</Link></li>
                      <li><Link to={app_url+'advancedeclineratio'}>Advance/Decline Ratio</Link></li>
                      <li><Link to={app_url+'topgainers'}>Top Gainers</Link></li>
                      <li><Link to={app_url+'toplosers'}>Top Losers</Link></li>
                      <li><Link to={app_url+'mostactivestocks'}>Most active stocks</Link></li>
                      <li><Link to={app_url+'volumegainers'}>Volume Gainers</Link></li>
                      <li><Link to={app_url+'52weekhigh'}>New 52-Week High</Link></li>
                      <li><Link to={app_url+'52weeklow'}>New 52-Week Low</Link></li>
                      <li><Link to={app_url+'bulkdeals'}>Bulk deals</Link></li>
                      <li><Link to={app_url+'blockdeals'}>Block deals</Link></li>
                      <li><Link to={app_url+'stockscreener'}>Stock screener</Link></li>
                    </ul>
                  </div>
                </li>
                <li><Link to={app_url+'news&updates'}>News & Updates</Link></li>
                <li><Link to={app_url+'#'}>F&O<i className="fas fa-chevron-down" /></Link>
                  <div className="uk-navbar-dropdown uk-navbar-dropdown-width-2" style={{width: '550px'}}>
                    <div className="uk-navbar-dropdown-grid uk-child-width-1-2" data-uk-grid>
                      <div>
                        <h6>Futures</h6>
                        <ul className="uk-nav uk-navbar-dropdown-nav">
                          <li><Link to={app_url+'futurevsoi'}>Future Price Vs OI </Link></li>
                        </ul>
                      </div>
                      <div>
                        <h6>Options</h6>
                        <ul className="uk-nav uk-navbar-dropdown-nav" style={{minWidth: '550px'}}>
                          <li><Link to={app_url+'optionchain'}>Option Chain</Link></li>
                          <li><Link to={app_url+'oianalysis'}>OI Analysis</Link></li>
                          <li><Link to={app_url+'trendingoi'}>Multi-Strike Trending OI</Link></li>
                          <li><Link to={app_url+'pcr'}>PCR</Link></li>
                          <li><Link to={app_url+'impliedvolatility'}>Implied Volatility</Link></li>
                          <li><Link to={app_url+'cepevsoi'}>CE & PE Price Vs Ol</Link></li>
                          <li><Link to={app_url+'maxpain'}>Max Pain</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                <li><Link to={'#'}>Strategies<i className="fas fa-chevron-down" /></Link>
                  <div className="uk-navbar-dropdown" style={{width: '225px'}}>
                    <ul className="uk-nav uk-navbar-dropdown-nav" style={{minWidth: '205px'}}>
                    <li><Link to={app_url+'straddle'}>Straddle</Link></li>
                    <li><Link to={app_url+'strangle'}>Strangle</Link></li>
                    <li><Link to={app_url+'straddlesnapshot'}>Straddle Snapshot</Link></li>
                    <li><Link to={app_url+'straddleweekdays'}>Straddle Weekdays Price</Link></li>
                    </ul>
                  </div>
                </li>
                <li><Link to={'#'}>Calendars & Holidays<i className="fas fa-chevron-down" /></Link>
                  <div className="uk-navbar-dropdown" style={{width: '225px'}}>
                    <ul className="uk-nav uk-navbar-dropdown-nav" style={{width: '192px'}}>
                      <li><Link to={app_url+'economiccalendar'}>Economic Calendar</Link></li>
                      <li><Link to={app_url+'resultscalendar'}>Results Calendar</Link></li>
                      <li><Link to={app_url+'tcholidays'}>Trading and Clearing Holidays</Link></li>
                    </ul>
                  </div>
                </li>
                <li><Link to={'#'}>News & Analysis<i className="fas fa-chevron-down" /></Link>
                  <div className="uk-navbar-dropdown">
                    <ul className="uk-nav uk-navbar-dropdown-nav">
                      <li><Link to={app_url+'markets'}>Markets</Link></li>
                      <li><Link to={app_url+'money'}>Money</Link></li>
                      <li><Link to={app_url+'analysis'}>Analysis</Link></li>
                    </ul>
                  </div>
                </li>
                <li><Link to={app_url+'education'}>Education</Link></li>  
                <li><Link to={app_url+'about'}>About Us</Link></li>
                <li><Link to={app_url+'contact'}>Contact Us</Link></li>
              </ul>
            </div>
          </div>
          {Number(uid)>0?
          <>
          {isMobile ?
          <>
          <div onClick={e=>{showmodal()}} className="uk-navbar-item in-mobile-nav uk-hidden@m">
            {/* <button className="uk-button"><i className="fas fa-bars" /></button> */}
          </div>
          </>
          :
          <div className="row">
    <div className="col-lg-12 col-md-12 col-12 d-flex align-items-center">
      <ul className="list-unstyled mb-0">
        <li className="dropdown ml-2">
          <a className="rounded-circle " href="#" role="button" id="dropdownUser" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <div className="avatar avatar-md avatar-indicators avatar-online">
              <div style={{background: '#000',height: '47px',weight: '40px',borderRadius: '25px'}}></div>
            </div>
          </a> 
          <div className="dropdown-menu pb-2" aria-labelledby="dropdownUser" style={{right: '1rem'}}>
            <div className="dropdown-item">
              <div className="d-flex py-2">
                <div className="avatar avatar-md avatar-indicators avatar-online">
                <div style={{background: '#000',height: '47px',weight: '40px',borderRadius: '25px'}}></div>
                </div>
                <div className="ml-3 lh-1 ms-3">
                  <h5 className="mb-0">{uname}</h5>
                  <p className="mb-0">{email}</p>
                </div>
              </div>
            </div>
            <div className="dropdown-divider" />
            <div className>
              <ul className="list-unstyled">
              </ul>
            </div>
            <ul className="list-unstyled">
              <li>
                <a className="dropdown-item text-center" href="#" onClick={userlogout}>
                  <span className="mr-2" style={{paddingRight: '10px'}}>
                    <svg  xmlns="http://www.w3.org/2000/svg" width={14} height={14} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-power">
                      <path d="M18.36 6.64a9 9 0 1 1-12.73 0" />
                      <line x1={12} y1={2} x2={12} y2={12} />
                    </svg>
                  </span> 
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
           </div> 
          }
          </>
          :
          <>
          <div className="ml-4">
            <div className="uk-navbar-item uk-visible@m in-optional-nav">
              <Link to={app_url+'login'} className="uk-button uk-button-text" style={{color: '#000'}}>Log in<i className="fas fa-arrow-circle-right uk-margin-small-left" /></Link>
              <Link to={app_url+'signup'} className="uk-button uk-button-primary uk-border-rounded">Sign up<i className="fas fa-arrow-circle-right uk-margin-small-left" /></Link>
            </div>
          </div>
          </>}
        </div>

      </nav>
    </div>
  </header>

  {/* MOBILE MODAL */}
  {isMobile? 
  <>
  {showmobmenu ? 
  <>
  <div id="modal-full" className="uk-modal-full uk-modal uk-open" data-uk-modal style={{display: 'block'}}>
  <div className="uk-modal-dialog uk-flex uk-flex-center uk-flex-middle" data-uk-height-viewport style={{minHeight: 'calc(100vh)'}}>
    <a className="uk-modal-close-full uk-button" onClick={e=>{showbar()}}><i className="fas fa-times" /></a>
    <div className="uk-width-large uk-padding-large">
      <ul className="uk-nav-default uk-nav-parent-icon uk-nav" data-uk-nav>
        <li><Link to={app_url}>Home</Link></li>
        <li className="uk-parent">
          <Link to={'#'}>Markets Data</Link>
          <ul className="uk-nav-sub" hidden>
          <li><Link to={app_url+'premarketdata'}>Pre Market Data</Link></li>
          <li><Link to={app_url+'flldlldata'}>FII / DII Data</Link></li>
          <li><Link to={app_url+'gmfutures'}>Global Markets Futures</Link></li>
          </ul>
        </li>
        <li className="uk-parent"><a href="#" aria-expanded="false">Markets Today</a>
          <ul className="uk-nav-sub" hidden>
          <li><Link to={app_url+'indexcontributors'}>Index Contributors</Link></li>
          <li><Link to={app_url+'multiindex'}>Multi-Index Chart</Link></li>
          <li><Link to={app_url+'advancedeclineratio'}>Advance/Decline Ratio</Link></li>
          <li><Link to={app_url+'topgainers'}>Top Gainers</Link></li>
          <li><Link to={app_url+'toplosers'}>Top Losers</Link></li>
          <li><Link to={app_url+'mostactivestocks'}>Most active stocks</Link></li>
          <li><Link to={app_url+'volumegainers'}>Volume Gainers</Link></li>
          <li><Link to={app_url+'52weekhigh'}>New 52-Week High</Link></li>
          <li><Link to={app_url+'52weeklow'}>New 52-Week Low</Link></li>
          <li><Link to={app_url+'bulkdeals'}>Bulk deals</Link></li>
          <li><Link to={app_url+'blockdeals'}>Block deals</Link></li>
          <li><Link to={app_url+'stockscreener'}>Stock screener</Link></li>
          </ul>
        </li>
        <li><Link to={app_url+'news&updates'}>News & Updates</Link></li>
        <li className="uk-parent"><a href="#" aria-expanded="false">F&O</a>
          <ul className="uk-nav-sub" hidden>
          <li><Link to={app_url+'futurevsoi'}>Future Price Vs OI </Link></li>
          <li><Link to={app_url+'optionchain'}>Option Chain</Link></li>
          <li><Link to={app_url+'oianalysis'}>OI Analysis</Link></li>
          <li><Link to={app_url+'trendingoi'}>Multi-Strike Trending OI</Link></li>
          <li><Link to={app_url+'pcr'}>PCR</Link></li>
          <li><Link to={app_url+'impliedvolatility'}>Implied Volatility</Link></li>
          <li><Link to={app_url+'cepevsoi'}>CE & PE Price Vs Ol</Link></li>
          <li><Link to={app_url+'maxpain'}>Max Pain</Link></li>
          </ul>
        </li>
        <li className="uk-parent"><a href="#" aria-expanded="false">Strategies</a>
          <ul className="uk-nav-sub" hidden>
          <li><Link to={app_url+'straddle'}>Straddle</Link></li>
          <li><Link to={app_url+'strangle'}>Strangle</Link></li>
          <li><Link to={app_url+'straddlesnapshot'}>Straddle Snapshot</Link></li>
          <li><Link to={app_url+'straddleweekdays'}>Straddle Weekdays Price</Link></li>
          </ul>
        </li>
        <li className="uk-parent"><a href="#" aria-expanded="false">Calendars & Holidays</a>
          <ul className="uk-nav-sub" hidden>
          <li><Link to={app_url+'economiccalendar'}>Economic Calendar</Link></li>
          <li><Link to={app_url+'resultscalendar'}>Results Calendar</Link></li>
          <li><Link to={app_url+'tcholidays'}>Trading and Clearing Holidays</Link></li>
          </ul>
        </li>
        <li className="uk-parent"><a href="#" aria-expanded="false">News & Analysis</a>
          <ul className="uk-nav-sub" hidden>
          <li><Link to={app_url+'markets'}>Markets</Link></li>
          <li><Link to={app_url+'money'}>Money</Link></li>
          <li><Link to={app_url+'analysis'}>Analysis</Link></li>
          </ul>
        </li>
        <li><Link to={app_url+'education'}>Education</Link></li>  
        <li><Link to={app_url+'about'}>About Us</Link></li>
        <li><Link to={app_url+'contact'}>Contact Us</Link></li>
      </ul>
      <Link to={app_url+'login'} className="uk-button uk-button-primary uk-border-rounded uk-align-center" style={{marginBottom : '-12px'}}>Log in<i className="fas fa-sign-in-alt uk-margin-small-left" /></Link>
    </div>
  </div></div>
  </>
  :
  <></>
  }
  </>
  :
  <></>  
}

      </>
    )
}
