import React, { useEffect,useState } from 'react'
import {Link,useNavigate} from 'react-router-dom';
import Header from '../../common/Header';
import axios from 'axios';
import {app_url,asseturl,api_url,fullappurl} from '../../common/Helpers';
import Select from "react-select";
import Slider from "react-slick";
import $ from 'jquery';
import Swal from 'sweetalert2';
import Footer from '../../common/Footer'; 
export default function Contact(){
  const [resultcalendardata, setresultcalendardata] = useState([])
  const [submit, setsubmit] = useState(false)
  const [load, setload] = useState(false)
  useEffect(()=>{
    fetchdatas();
  },[])
  const fetchdatas = async () =>{
    setload(true)
    await axios.get(api_url+`getresultcalendar`).then(({data})=>{
      console.log(data)
      setresultcalendardata(data)
      setload(false)
    })
  }
return (
      <>
     <div>
  <Header />
 <main>
  {/* section content begin */}
  <div className="uk-section uk-margin-small-top">
    <div className="uk-container">
    <div className="uk-container" style={{marginTop: '1.4rem'}}>
    <div className='uk-text-center' >
      <h2 style={{background: '#FDD965',padding: '3px'}} className='mb-1'>Results Calendar</h2>
    </div>
    <div className="uk-card uk-card-default uk-card-body uk-border-rounded" style={{margin: 0,padding: 0,border: '1px solid #C17951',borderRadius: '0px',}}>
      {load ?
        <div className="linear-background w-100"> </div>
          :
        <>
          <div className="table-responsive">
          <table class="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center" style={{margin: 0}}>
              <thead>
                  <tr>
                  <th class="uk-text-center">Date</th>
                      <th class="uk-text-center">Time</th>
                      <th class="uk-text-center">Company</th>
                  </tr>
              </thead>
              <tbody>
              {
                                            resultcalendardata.length > 0 && (
                                              resultcalendardata.map((row, key)=>(
                                                  <tr>
                                                  <td className="text-center pe-0">
                                                    <div>{ row.date }</div>
                                                  </td>
                                                  <td className="text-center pe-0">
                                                    <div>{ row.time }</div>
                                                  </td>
                                                  <td className="text-center pe-0">
                                                    <div>{ row.stock }</div>
                                                  </td>
                                                </tr>
                                                ))
                                            )
        }
              </tbody>
          </table>
          </div>
        </>
}
    </div>
    </div>

    </div>
  </div>
  {/* section content end */}
</main>



  <Footer />
  </div>
      </>
    )
  }
