import React, { useEffect,useState } from 'react'
import Header from '../../common/Header';
import axios from 'axios';
import {api_url} from '../../common/Helpers';
import Footer from '../../common/Footer';
export default function Contact(){
  const [allpremarketdata, setallpremarketdata] = useState([])
  const [load, setload] = useState(false)
  const [prevData, setPrevData] = useState(
    [
      {
          "id": 1,
          "date": "02-03-2023",
          "mindate": "Dow Jones",
          "time": "04:42:02 pm",
          "mintime": "1677755522",
          "name": "Dow Jones",
          "ltp": "\n\n32,246.55\n",
          "change": "371.98",
          "changepercent": "1.17%"
      },
      {
          "id": 2,
          "date": "02-03-2023",
          "mindate": "S&P 500",
          "time": "04:42:02 pm",
          "mintime": "1677755522",
          "name": "S&P 500",
          "ltp": "\n\n3,960.28\n",
          "change": "68.35",
          "changepercent": "1.76%"
      },
      {
          "id": 3,
          "date": "02-03-2023",
          "mindate": "Nasdaq",
          "time": "04:42:02 pm",
          "mintime": "1677755522",
          "name": "Nasdaq",
          "ltp": "\n\n11,717.28\n",
          "change": "283.22",
          "changepercent": "2.48%"
      },
      {
          "id": 4,
          "date": "02-03-2023",
          "mindate": "India VIX",
          "time": "04:42:02 pm",
          "mintime": "1677755522",
          "name": "India VIX",
          "ltp": "14.76",
          "change": "1.46",
          "changepercent": "-9.00%"
      },
      {
          "id": 5,
          "date": "02-03-2023",
          "mindate": "USD-INR",
          "time": "04:42:02 pm",
          "mintime": "1677755522",
          "name": "USD-INR",
          "ltp": "\n₹\n82.56\n",
          "change": "-0.05",
          "changepercent": "-0.06%"
      },
      {
          "id": 6,
          "date": "02-03-2023",
          "mindate": "Brent Crude",
          "time": "04:42:02 pm",
          "mintime": "1677755522",
          "name": "Brent Crude",
          "ltp": "\n$\n75.73\n",
          "change": "1.03",
          "changepercent": "1.38%"
      },
      {
          "id": 7,
          "date": "02-03-2023",
          "mindate": "Dollar Index",
          "time": "04:42:02 pm",
          "mintime": "1677755522",
          "name": "Dollar Index",
          "ltp": "\n\n104.15\n",
          "change": "-0.27",
          "changepercent": "-0.26%"
      },
      {
          "id": 8,
          "date": "02-03-2023",
          "mindate": "U.S. 10 Year Bond",
          "time": "04:42:02 pm",
          "mintime": "1677755522",
          "name": "U.S. 10 Year Bond",
          "ltp": "3.553",
          "change": "-0.031",
          "changepercent": ""
      },
      {
          "id": 9,
          "date": "02-03-2023",
          "mindate": "India 10 Year Bond",
          "time": "04:42:02 pm",
          "mintime": "1677755522",
          "name": "India 10 Year Bond",
          "ltp": "7.366",
          "change": "0.003",
          "changepercent": ""
      },
      {
          "id": 10,
          "date": "02-03-2023",
          "mindate": "Gold",
          "time": "04:42:02 pm",
          "mintime": "1677755522",
          "name": "Gold",
          "ltp": "\n$\n1,936.50\n",
          "change": "13.50",
          "changepercent": "0.70%"
      },
      {
          "id": 11,
          "date": "02-03-2023",
          "mindate": "Silver",
          "time": "04:42:02 pm",
          "mintime": "1677755522",
          "name": "Silver",
          "ltp": "\n$\n22.005\n",
          "change": "0.313",
          "changepercent": "1.44%"
      },
      {
          "id": 12,
          "date": "02-03-2023",
          "mindate": "Copper",
          "time": "04:42:02 pm",
          "mintime": "1677755522",
          "name": "Copper",
          "ltp": "\n$\n3.9440\n",
          "change": "0.0795",
          "changepercent": "2.06%"
      }
  ]
  );
  useEffect(()=>{
    fetchdatas();
  },[])
  const fetchdatas = async () =>{
    setload(true)
    await axios.get(api_url+`getpremarketdata`).then(({data})=>{
      let filter = []
      for (let x in data) {
        if (!prevData[x] || prevData[x].ltp !== data[x].ltp) {
          data[x].highlight = true;
        }
        filter.push(data[x]);
      }
      setallpremarketdata(filter);
      setPrevData(data);
    })
    setload(false)
  }
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    const timer = setTimeout(() => {
      setCounter(counter + 1);
      fetchdata()
    }, 20000);
    
    return () => {
      clearTimeout(timer);
    };
  }, [counter]);

  const fetchdata = async () => {
    await axios.get(api_url + `getpremarketdata`).then(({ data }) => {
      let filter = []
      for (let x in data) {
        if (!prevData[x] || prevData[x].ltp !== data[x].ltp) {
          data[x].highlight = true;
        }
        filter.push(data[x]);
      }
      setallpremarketdata(filter);
      setPrevData(data);
    });
  };
  
  
return (
      <>
     <div>
  <Header />
 <main>
  {/* section content begin */}
  <div className="uk-section uk-margin-small-top">
    <div className="uk-container" style={{marginTop: '1rem'}}>
    <div className="uk-card uk-card-default uk-card-body uk-border-rounded" style={{
      margin: 0,
      padding: 0,
      border: '1px solid #C17951',
      borderRadius: '0px',
      }}>
    <div className='uk-text-center'>
    <h2 style={{background: '#FDD965',margin: 0}}>Pre Market Data</h2>
    </div>
    {load?
    <div className="linear-background w-100"> </div>
    :
    <div className="table-responsive">
      <table class="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center" style={{marginTop: '0'}}>
      <thead>
          <tr>
              <th class="uk-text-center">Name</th>
              <th class="uk-text-center">Last Value</th>
              <th class="uk-text-center">Change</th>
              <th class="uk-text-center">Change%</th>
          </tr>
      </thead>
      <tbody>
      {
                                 allpremarketdata.length > 0 && (
                                  allpremarketdata.map((row, key) => (
                                    <tr key={key}>
                                      <td className="text-center pe-0">
                                        <div>{row.name}</div>
                                      </td>
                                      <td className="text-center pe-0">
                                        <div style={{ fontWeight: row.highlight ? 900 : 'normal' }}>{row.ltp}</div>
                                      </td>
                                      {Math.sign(parseFloat(row.change)) == -1 ? (
                                        <td className="text-center pe-0">
                                          <div style={{ color: 'red' }}>{row.change}</div>
                                        </td>
                                      ) : (
                                        <td className="text-center pe-0">
                                          <div style={{ color: 'green' }}>{row.change}</div>
                                        </td>
                                      )}
                                      {Math.sign(parseFloat(row.changepercent)) == -1 ? (
                                        <td className="text-center pe-0">
                                          <div style={{ color: 'red' }}>{row.changepercent}</div>
                                        </td>
                                      ) : (
                                        <td className="text-center pe-0">
                                          <div style={{ color: 'green' }}>{row.changepercent}</div>
                                        </td>
                                      )}
                                    </tr>
                                  ))
                                )
                                
}
      </tbody>
    </table>
    </div>
    
    }
    </div>
    </div>
  </div>
  {/* section content end */}
</main>



  <Footer />
  </div>
      </>
    )
  }
