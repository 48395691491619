import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Header from "../../common/Header";
import axios from "axios";
import { api_url, formatdmY } from "../../common/Helpers";
import Select from "react-select";
import Footer from "../../common/Footer";
export default function Contact() {
  const [load, setload] = useState(false);
  const [key, setKey] = useState("nifty50");
  const [homedata, sethomedata] = useState([]);
  const [thehighestnum, setthehighestnum] = useState(0);
  const [thehighestnumput, setthehighestnumput] = useState(0);
  const [thehighestnumbank, setthehighestnumbank] = useState(0);
  const [thehighestnumputbank, setthehighestnumputbank] = useState(0);
  const [oichangehighestNumber, setoichangehighestNumber] = useState(0);
  const [oichangehighestNumberput, setoichangehighestNumberput] = useState(0);
  const [oichangehighestNumberbank, setoichangehighestNumberbank] = useState(0);
  const [oichangehighestNumberputbank, setoichangehighestNumberputbank] =
    useState(0);
  const [allexpiredate, setallexpiredate] = useState([
    { value: "", label: "" },
  ]);
  const [expirevalue, setexpirevalue] = useState("");
  //// THIS SECTION IS FOR NIFTY50
  const [nifyt50data, setnifyt50data] = useState([]);
  const [nifyt50expiry, setnifyt50expiry] = useState([
    { value: "", label: "" },
  ]);
  //// THIS SECTION IS FOR BANK NIFTY
  const [bankniftydata, setbankniftydata] = useState([]);
  const [bankniftyexpiry, setbankniftyexpiry] = useState([
    { value: "", label: "" },
  ]);
  // THIS SECTION IS FOR FINNIFTY
  // Step 3: Create state for Finnifty data and expiry date
  const [finniftyData, setFinniftyData] = useState([]);
  const [finniftyExpiry, setfinniftyExpiry] = useState([
    { value: "", label: "" },
  ]);
  // Step 4: Fetch Finnifty option chain data with a specific expiry date
  const getOptionChainnifty50Latest = async (expirydate) => {
    const endpoint = counter === 0 ? 'getoptionchain/' : 'getoptionchainlatests/';
    const { data } = await axios.get(api_url + endpoint + expirydate);
  
    let highestNumber = 0, highestNumberput = 0;
    let oichangehighestNumberbank = 0, oichangehighestNumberputbank = 0;
  
    data.forEach((item) => {
      highestNumber = Math.max(highestNumber, Number(item.oi));
      highestNumberput = Math.max(highestNumberput, Number(item.poi));
      oichangehighestNumberbank = Math.max(oichangehighestNumberbank, Number(item.changeinoi));
      oichangehighestNumberputbank = Math.max(oichangehighestNumberputbank, Number(item.poichangee));
    });
  
    setthehighestnum(highestNumber);
    setthehighestnumput(highestNumberput);
    setoichangehighestNumber(oichangehighestNumberbank);
    setoichangehighestNumberput(oichangehighestNumberputbank);
    setnifyt50data(data);
    setload(false);
  };
  

  const getoptionchainbankniftyLatest = async (expirydate) => {
    const endpoint = counter === 0 ? 'getoptionchainbanknifty/' : 'getoptionchainbankniftylatests/';
    const { data } = await axios.get(api_url + endpoint + expirydate);
  
    let highestNumber = 0, highestNumberput = 0;
    let oichangehighestNumberbank = 0, oichangehighestNumberputbank = 0;
  
    data.forEach((item) => {
      highestNumber = Math.max(highestNumber, Number(item.oi));
      highestNumberput = Math.max(highestNumberput, Number(item.poi));
      oichangehighestNumberbank = Math.max(oichangehighestNumberbank, Number(item.changeinoi));
      oichangehighestNumberputbank = Math.max(oichangehighestNumberputbank, Number(item.poichangee));
    });
  
    setthehighestnumbank(highestNumber);
    setthehighestnumputbank(highestNumberput);
    setoichangehighestNumberbank(oichangehighestNumberbank);
    setoichangehighestNumberputbank(oichangehighestNumberputbank);
    setbankniftydata(data);
    setload(false);
  };
  
  const getOptionChainFinniftyLatest = async (expirydate) => {
    const endpoint = counter === 0 ? 'getoptionchainfinnifty/' : 'getoptionchainfinniftylatests/';
    const { data } = await axios.get(api_url + endpoint + expirydate);
  
    let highestNumber = 0, highestNumberput = 0;
    let oichangehighestNumberbank = 0, oichangehighestNumberputbank = 0;
  
    data.forEach((item) => {
      highestNumber = Math.max(highestNumber, Number(item.oi));
      highestNumberput = Math.max(highestNumberput, Number(item.poi));
      oichangehighestNumberbank = Math.max(oichangehighestNumberbank, Number(item.changeinoi));
      oichangehighestNumberputbank = Math.max(oichangehighestNumberputbank, Number(item.poichangee));
    });
  
    setthehighestnumbank(highestNumber);
    setthehighestnumputbank(highestNumberput);
    setoichangehighestNumberbank(oichangehighestNumberbank);
    setoichangehighestNumberputbank(oichangehighestNumberputbank);
    setFinniftyData(data);
    setload(false);
  };
  
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    const timer = setTimeout(() => {
      setCounter(counter + 1);
      fetchdatas();
    }, 10000);

    return () => {
      clearTimeout(timer);
    };
  }, [counter]);
  const fetchdatas = async () => {
    await axios.get(api_url + `getalloptionchainexplist`).then(({ data }) => {
      // setexpirevalue(data[0].label)
      // fetchdataslatest(data[0].value)
      let arr = [];
      if (data.FINNIFTY.length > 0) {
        for (let x in data.FINNIFTY) {
          if (x == 0) {
            getOptionChainFinniftyLatest(data.FINNIFTY[x]);
          }
          let obj = {
            value: data.FINNIFTY[x],
            label: formatdmY(data.FINNIFTY[x]),
          };
          arr.push(obj);
        }
      }
      setfinniftyExpiry(arr);
      let arr1 = [];
      if (data.NIFTY.length > 0) {
        for (let x in data.NIFTY) {
          if (x == 0) {
            getOptionChainnifty50Latest(data.NIFTY[x]);
          }
          let obj = { value: data.NIFTY[x], label: formatdmY(data.NIFTY[x]) };
          arr1.push(obj);
        }
      }
      setnifyt50expiry(arr1);
      let arr2 = [];
      if (data.BANKNIFTY.length > 0) {
        for (let x in data.BANKNIFTY) {
          if (x == 0) {
            getoptionchainbankniftyLatest(data.BANKNIFTY[x]);
          }
          let obj = {
            value: data.BANKNIFTY[x],
            label: formatdmY(data.BANKNIFTY[x]),
          };
          arr2.push(obj);
        }
      }
      setbankniftyexpiry(arr2);
    });
    await axios.get(api_url + "gethomedata").then(({ data }) => {
      sethomedata(data);
    });
    setload(false);
  };
  // const [date, setDate] = useState(new Date());

  // const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  //   "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  // ];

  // const monthName = monthNames[date.getMonth()];
  // const day = date.getDate();
  // const year = date.getFullYear();

  function ChartProgressBar(oivalue) {
    const percentage = (oivalue / thehighestnum) * 100;
    const containerStyle = {
      position: "relative",
      height: "20px",
      color: "#000",
      zIndex: "1",
    };

    const progressStyle = {
      position: "absolute",
      top: 0,
      left: 0,
      width: `${percentage}%`,
      height: "100%",
      backgroundColor: "#9CCAE8",
      zIndex: "-1",
    };

    return (
      <div style={containerStyle}>
        <div style={progressStyle}></div>
        <p style={{ margin: "0", zIndex: "2" }}>{oivalue}</p>
      </div>
    );
  }
  function ChartProgressBarPut(oivalue) {
    const percentage = (oivalue / thehighestnumput) * 50;

    const containerStyle = {
      position: "relative",
      height: "20px",
      color: "#000",
      zIndex: "1",
    };

    const progressStyle = {
      position: "absolute",
      top: 0,
      right: 0,
      width: `${percentage}%`,
      height: "100%",
      backgroundColor: "rgb(255 255 1)",
      zIndex: "-1",
    };

    return (
      <div style={containerStyle}>
        <div style={progressStyle}></div>
        <p style={{ margin: "0", zIndex: "2" }}>{oivalue}</p>
      </div>
    );
  }
  function ChartProgressBarBank(oivalue) {
    const percentage = (oivalue / thehighestnumbank) * 100;

    const containerStyle = {
      position: "relative",
      height: "20px",
      color: "#000",
      zIndex: "1",
    };

    const progressStyle = {
      position: "absolute",
      top: 0,
      left: 0,
      width: `${percentage}%`,
      height: "100%",
      backgroundColor: "#9CCAE8",
      zIndex: "-1",
      maxWidth: "50%",
    };

    return (
      <div style={containerStyle}>
        <div style={progressStyle}></div>
        <p style={{ margin: "0", zIndex: "2" }}>{oivalue}</p>
      </div>
    );
  }
  function ChartProgressBarPutBank(oivalue) {
    const percentage = (oivalue / thehighestnumputbank) * 50;

    const containerStyle = {
      position: "relative",
      height: "20px",
      color: "#000",
      zIndex: "1",
    };

    const progressStyle = {
      position: "absolute",
      top: 0,
      right: 0,
      width: `${percentage}%`,
      height: "100%",
      backgroundColor: "rgb(255 255 1)",
      zIndex: "-1",
      maxWidth: "50%",
    };

    return (
      <div style={containerStyle}>
        <div style={progressStyle}></div>
        <p style={{ margin: "0", zIndex: "2" }}>{oivalue}</p>
      </div>
    );
  }
  // OI CHANGE SECTION
  function ChartProgressOiChange(value) {
    if (isNaN(value)) {
      return null;
    }
    const percentage = Math.abs((value / oichangehighestNumber) * 50);

    const containerStyle = {
      position: "relative",
      height: "20px",
      color: "#000",
      zIndex: "1",
    };

    const progressStyle = {
      position: "absolute",
      bottom: value < 0 ? 0 : null,
      top: value >= 0 ? 0 : null,
      left: value >= 0 ? "50%" : null,
      right: value < 0 ? "50%" : null,
      width: `${percentage}%`,
      height: "100%",
      backgroundColor: value < 0 ? "red" : "green",
      zIndex: "-1",
      maxWidth: "50%",
    };
    return (
      <div style={containerStyle}>
        <div style={progressStyle}></div>
        <p style={{ margin: "0", zIndex: "2" }}>{value}</p>
      </div>
    );
  }

  function ChartProgressPutOiChange(value) {
    if (isNaN(value)) {
      return null;
    }
    const percentage = Math.abs((value / oichangehighestNumberput) * 50);

    const containerStyle = {
      position: "relative",
      height: "20px",
      color: "#000",
      zIndex: "1",
    };
    const progressStyle = {
      position: "absolute",
      right: value >= 0 ? "50%" : null,
      left: value < 0 ? "50%" : null,
      width: `${percentage}%`,
      height: "100%",
      backgroundColor: value < 0 ? "red" : "green",
      zIndex: "-1",
      maxWidth: "50%",
    };

    return (
      <div style={containerStyle}>
        <div style={progressStyle}></div>
        <p style={{ margin: "0", zIndex: "2" }}>{value}</p>
      </div>
    );
  }

  function ChartProgressOiChangeBank(value) {
    if (isNaN(value)) {
      return null;
    }

    const percentage = Math.abs((value / oichangehighestNumberbank) * 50);

    const containerStyle = {
      position: "relative",
      height: "20px",
      color: "#000",
      zIndex: "1",
    };

    const progressStyle = {
      position: "absolute",
      bottom: value < 0 ? 0 : null,
      top: value >= 0 ? 0 : null,
      left: value >= 0 ? "50%" : null,
      right: value < 0 ? "50%" : null,
      width: `${percentage}%`,
      height: "100%",
      backgroundColor: value < 0 ? "red" : "green",
      zIndex: "-1",
      maxWidth: "50%",
    };
    return (
      <div style={containerStyle}>
        <div style={progressStyle}></div>
        <p style={{ margin: "0", zIndex: "2" }}>{value}</p>
      </div>
    );
  }
  function ChartProgressPutOiChangeBank(value) {
    if (isNaN(value)) {
      return null;
    }
    const percentage = Math.abs((value / oichangehighestNumberputbank) * 50);

    const containerStyle = {
      position: "relative",
      height: "20px",
      color: "#000",
      zIndex: "1",
    };

    const progressStyle = {
      position: "absolute",
      right: value >= 0 ? "50%" : null,
      left: value < 0 ? "50%" : null,
      width: `${percentage}%`,
      height: "100%",
      backgroundColor: value < 0 ? "red" : "green",
      zIndex: "-1",
      maxWidth: "50%",
    };
    return (
      <div style={containerStyle}>
        <div style={progressStyle}></div>
        <p style={{ margin: "0", zIndex: "2" }}>{value}</p>
      </div>
    );
  }
  return (
    <>
      <div>
        <Header />
        <main>
          {/* section content begin */}
          <div className="uk-section uk-margin-small-top">
            <div
              className="uk-container"
              style={{ marginTop: "1rem", minWidth: "1400px" }}
            >
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
              >
                <Tab eventKey="nifty50" title="NIFTY 50">
                  {load ? (
                    <div className="linear-background w-100"> </div>
                  ) : (
                    <>
                      {nifyt50data.length > 0 ? (
                        <>
                          <div
                            className="d-flex justify-content-between"
                            style={{ padding: "2px 10px" }}
                          >
                            <div
                              style={{ fontWeight: "600", marginTop: "5px" }}
                            >
                              <span>{"Spot Price : " + homedata.nifty50}</span>
                              <span style={{ marginLeft: "10px" }}>
                                {"Future Price : " + homedata.niftyfuture}
                              </span>
                            </div>
                            <div
                              style={{ fontWeight: "600", maxWidth: "300px" }}
                            >
                              <label
                                htmlFor=""
                                style={{
                                  display: "inline-block",
                                  marginRight: "10px",
                                }}
                              >
                                Expiry
                              </label>
                              {/* <Select style={{display: 'inline-block'}} defaultValue={{ label: expirevalue, value: expirevalue }} className="text-dark" required name="expiredate" id="expiredate" options={allexpiredate} onChange={e => getexpiredate(e)} /> */}
                              <Select
                                style={{ display: "inline-block" }}
                                defaultValue={{
                                  label: nifyt50expiry[0].label,
                                  value: nifyt50expiry[0].value,
                                }}
                                className="text-dark"
                                required
                                name="expiredate"
                                id="expiredate"
                                options={nifyt50expiry}
                                onChange={(e) =>
                                  getOptionChainnifty50Latest(e.value)
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="uk-card uk-card-default uk-card-body uk-border-rounded"
                            style={{
                              margin: 0,
                              padding: 0,
                              border: "1px solid #C17951",
                              borderRadius: "0px",
                            }}
                          >
                            <div className="uk-text-center">
                              <h2 style={{ background: "#FDF1CC", margin: 0 }}>
                                OPTION CHAIN (NIFTY 50)
                              </h2>
                            </div>
                            <div className="table-responsive">
                              <table
                                class="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center"
                                style={{ margin: 0 }}
                              >
                                <thead>
                                  <tr>
                                    <th
                                      class="uk-text-center"
                                      style={{ minWidth: "75px" }}
                                    ></th>
                                    <th
                                      class="uk-text-center"
                                      style={{ minWidth: "50px" }}
                                    ></th>
                                    <th class="uk-text-center"></th>
                                    <th class="uk-text-center">CALLS</th>
                                    <th class="uk-text-center"></th>
                                    <th class="uk-text-center"></th>
                                    <th class="uk-text-center"></th>
                                    <th class="uk-text-center"></th>
                                    <th class="uk-text-center"></th>
                                    <th class="uk-text-center"></th>
                                    <th class="uk-text-center">PUTS</th>
                                    <th
                                      class="uk-text-center"
                                      style={{ minWidth: "75px" }}
                                    ></th>
                                    <th
                                      class="uk-text-center"
                                      style={{ minWidth: "50px" }}
                                    ></th>
                                    <th
                                      class="uk-text-center"
                                      style={{ minWidth: "75px" }}
                                    ></th>
                                    <th
                                      class="uk-text-center"
                                      style={{ minWidth: "50px" }}
                                    ></th>
                                  </tr>

                                  <tr>
                                    <th
                                      class="uk-text-center"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "50px",
                                        padding: "0",
                                      }}
                                    >
                                      OI <br /> (in lakhs){" "}
                                    </th>
                                    <th
                                      class="uk-text-center"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      OI Change <br /> (in lakhs)
                                    </th>
                                    <th
                                      class="uk-text-center"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      Volume
                                    </th>
                                    <th
                                      class="uk-text-center"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      IV
                                    </th>
                                    <th
                                      class="uk-text-center"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      Chg in LTP
                                    </th>
                                    <th
                                      class="uk-text-center"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0px 0px 0px 40px",
                                      }}
                                    >
                                      LTP
                                    </th>
                                    <th
                                      class="uk-text-center"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      Strike
                                    </th>
                                    <th
                                      class="uk-text-left"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0px 0px 0px 5px",
                                      }}
                                    >
                                      LTP
                                    </th>
                                    <th
                                      class="uk-text-left"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      Chg in LTP
                                    </th>
                                    <th
                                      class="uk-text-center"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      Volume
                                    </th>
                                    <th
                                      class="uk-text-center"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      IV
                                    </th>
                                    <th
                                      class="uk-text-center"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      OI Change <br /> (in lakhs)
                                    </th>
                                    <th
                                      class="uk-text-center"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      OI <br /> (in lakhs){" "}
                                    </th>
                                    <th
                                      class="uk-text-left"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      PCR-OI
                                    </th>
                                    <th
                                      class="uk-text-left"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      PCR-Vol
                                    </th>
                                  </tr>
                                </thead>
                              </table>
                            </div>
                            <div className="tableFixHead">
                              <div className="table-responsive">
                                <table
                                  class="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center"
                                  style={{ margin: 0 }}
                                >
                                  <tbody>
                                    {nifyt50data.length > 0 &&
                                      nifyt50data.map((row, key) => (
                                        <>
                                          {row.currentstrike == 1 ? (
                                            <tr
                                              style={{ background: "#fcb42d" }}
                                            >
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                  minWidth: "50px",
                                                }}
                                              >
                                                {ChartProgressBar(row.oi)}
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {ChartProgressOiChange(
                                                  row.changeinoi
                                                )}
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>{row.volume}</div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>{row.civ}</div>
                                              </td>
                                              {Math.sign(
                                                parseFloat(row.changeinltp)
                                              ) == -1 ? (
                                                <td
                                                  className="text-center px-0"
                                                  style={{
                                                    padding: "10px 0px",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  <div style={{ color: "red" }}>
                                                    {row.changeinltp}
                                                    <br />
                                                    {"(" +
                                                      row.change_percent +
                                                      "%" +
                                                      ")"}
                                                  </div>
                                                </td>
                                              ) : (
                                                <td
                                                  className="text-center px-0"
                                                  style={{
                                                    padding: "10px 0px",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  <div
                                                    style={{ color: "green" }}
                                                  >
                                                    {row.changeinltp}
                                                    <br />
                                                    {"(" +
                                                      row.change_percent +
                                                      "%" +
                                                      ")"}
                                                  </div>
                                                </td>
                                              )}
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>{row.cltp}</div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>
                                                  {row.strikeprice.replace(
                                                    ".0",
                                                    ""
                                                  )}
                                                </div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>{row.pltpe}</div>
                                              </td>
                                              {Math.sign(
                                                parseFloat(row.pcltpe)
                                              ) == -1 ? (
                                                <td
                                                  className="text-center px-0"
                                                  style={{
                                                    padding: "10px 0px",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  <div style={{ color: "red" }}>
                                                    {row.pcltpe}
                                                    <br />
                                                    {"(" +
                                                      row.pcltpe_percent +
                                                      "%" +
                                                      ")"}
                                                  </div>
                                                </td>
                                              ) : (
                                                <td
                                                  className="text-center px-0"
                                                  style={{
                                                    padding: "10px 0px",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  <div
                                                    style={{ color: "green" }}
                                                  >
                                                    {row.pcltpe}
                                                    <br />
                                                    {"(" +
                                                      row.pcltpe_percent +
                                                      "%" +
                                                      ")"}
                                                  </div>
                                                </td>
                                              )}

                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                  minWidth: "30px",
                                                }}
                                              >
                                                <div>{row.pvole}</div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>{row.piv}</div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {ChartProgressPutOiChange(
                                                  row.poichangee
                                                )}
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                  minWidth: "30px",
                                                }}
                                              >
                                                {ChartProgressBarPut(row.poi)}
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>
                                                  {Number(row.oi) > 0 &&
                                                  !isNaN(row.poi) &&
                                                  !isNaN(row.oi)
                                                    ? (
                                                        row.poi / row.oi
                                                      ).toFixed(2)
                                                    : "-"}
                                                </div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>
                                                  {Number(row.volume) > 0
                                                    ? (
                                                        Number(row.pvole) /
                                                        Number(row.volume)
                                                      ).toFixed(2)
                                                    : "-"}
                                                </div>
                                              </td>
                                            </tr>
                                          ) : (
                                            <tr>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {ChartProgressBar(row.oi)}
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {ChartProgressOiChange(
                                                  row.changeinoi
                                                )}
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>{row.volume}</div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>{row.civ}</div>
                                              </td>
                                              {Math.sign(
                                                parseFloat(row.changeinltp)
                                              ) == -1 ? (
                                                <td
                                                  className="text-center px-0"
                                                  style={{
                                                    padding: "10px 0px",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  <div style={{ color: "red" }}>
                                                    {row.changeinltp}
                                                    <br />
                                                    {"(" +
                                                      row.change_percent +
                                                      "%" +
                                                      ")"}
                                                  </div>
                                                </td>
                                              ) : (
                                                <td
                                                  className="text-center px-0"
                                                  style={{
                                                    padding: "10px 0px",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  <div
                                                    style={{ color: "green" }}
                                                  >
                                                    {row.changeinltp}
                                                    <br />
                                                    {"(" +
                                                      row.change_percent +
                                                      "%" +
                                                      ")"}
                                                  </div>
                                                </td>
                                              )}
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>{row.cltp}</div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>
                                                  {row.strikeprice.replace(
                                                    ".0",
                                                    ""
                                                  )}
                                                </div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>{row.pltpe}</div>
                                              </td>
                                              {Math.sign(
                                                parseFloat(row.pcltpe)
                                              ) == -1 ? (
                                                <td
                                                  className="text-center px-0"
                                                  style={{
                                                    padding: "10px 0px",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  <div style={{ color: "red" }}>
                                                    {row.pcltpe}
                                                    <br />
                                                    {"(" +
                                                      row.pcltpe_percent +
                                                      "%" +
                                                      ")"}
                                                  </div>
                                                </td>
                                              ) : (
                                                <td
                                                  className="text-center px-0"
                                                  style={{
                                                    padding: "10px 0px",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  <div
                                                    style={{ color: "green" }}
                                                  >
                                                    {row.pcltpe}
                                                    <br />
                                                    {"(" +
                                                      row.pcltpe_percent +
                                                      "%" +
                                                      ")"}
                                                  </div>
                                                </td>
                                              )}

                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                  minWidth: "30px",
                                                }}
                                              >
                                                <div>{row.pvole}</div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>{row.piv}</div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {ChartProgressPutOiChange(
                                                  row.poichangee
                                                )}
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {ChartProgressBarPut(row.poi)}
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>
                                                  {Number(row.oi) > 0 &&
                                                  !isNaN(row.poi) &&
                                                  !isNaN(row.oi)
                                                    ? (
                                                        row.poi / row.oi
                                                      ).toFixed(2)
                                                    : "-"}
                                                </div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>
                                                  {Number(row.volume) > 0
                                                    ? (
                                                        Number(row.pvole) /
                                                        Number(row.volume)
                                                      ).toFixed(2)
                                                    : "-"}
                                                </div>
                                              </td>
                                            </tr>
                                          )}
                                        </>
                                      ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        // Loading or no data message here
                        <div className="linear-background w-100"> </div>
                      )}
                    </>
                  )}
                </Tab>
                <Tab eventKey="banknifty" title="BANK NIFTY">
                  {load ? (
                    <div className="linear-background w-100"> </div>
                  ) : (
                    <>
                      {bankniftydata.length > 0 ? (
                        <>
                          <div
                            className="d-flex justify-content-between"
                            style={{ padding: "2px 10px" }}
                          >
                            <div style={{ fontWeight: "600" }}>
                              {" "}
                              <span>
                                {"Spot Price : " + homedata.banknifty}
                              </span>{" "}
                              <span style={{ marginLeft: "10px" }}>
                                {"Future Price : " + homedata.bankfuture}
                              </span>
                            </div>
                            <div
                              style={{ fontWeight: "600", maxWidth: "300px" }}
                            >
                              <label
                                htmlFor=""
                                style={{
                                  display: "inline-block",
                                  marginRight: "10px",
                                }}
                              >
                                Expiry
                              </label>
                              {/* <Select style={{display: 'inline-block'}} defaultValue={{ label: expirevalue, value: expirevalue }} className="text-dark" required name="expiredate" id="expiredate" options={allexpiredate} onChange={e => getexpiredate(e)} /> */}
                              <Select
                                style={{ display: "inline-block" }}
                                defaultValue={{
                                  label: bankniftyexpiry[0].label,
                                  value: bankniftyexpiry[0].value,
                                }}
                                className="text-dark"
                                required
                                name="expiredate"
                                id="expiredate"
                                options={bankniftyexpiry}
                                onChange={(e) =>
                                  getoptionchainbankniftyLatest(e.value)
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="uk-card uk-card-default uk-card-body uk-border-rounded"
                            style={{
                              margin: 0,
                              padding: 0,
                              border: "1px solid #C17951",
                              borderRadius: "0px",
                            }}
                          >
                            <div className="uk-text-center">
                              <h2 style={{ background: "#FDF1CC", margin: 0 }}>
                                OPTION CHAIN (BANK NIFTY)
                              </h2>
                            </div>
                            <div className="table-responsive">
                              <table
                                class="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center"
                                style={{ margin: 0 }}
                              >
                                <thead>
                                  <tr>
                                    <th
                                      class="uk-text-center"
                                      style={{ minWidth: "75px" }}
                                    ></th>
                                    <th
                                      class="uk-text-center"
                                      style={{ minWidth: "50px" }}
                                    ></th>
                                    <th class="uk-text-center"></th>
                                    <th class="uk-text-center">CALLS</th>
                                    <th class="uk-text-center"></th>
                                    <th class="uk-text-center"></th>
                                    <th class="uk-text-center"></th>
                                    <th class="uk-text-center"></th>
                                    <th class="uk-text-center"></th>
                                    <th class="uk-text-center"></th>
                                    <th class="uk-text-center">PUTS</th>
                                    <th
                                      class="uk-text-center"
                                      style={{ minWidth: "75px" }}
                                    ></th>
                                    <th
                                      class="uk-text-center"
                                      style={{ minWidth: "50px" }}
                                    ></th>
                                    <th
                                      class="uk-text-center"
                                      style={{ minWidth: "75px" }}
                                    ></th>
                                    <th
                                      class="uk-text-center"
                                      style={{ minWidth: "50px" }}
                                    ></th>
                                  </tr>

                                  <tr>
                                    <th
                                      class="uk-text-center"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "50px",
                                        padding: "0",
                                      }}
                                    >
                                      OI <br /> (in lakhs){" "}
                                    </th>
                                    <th
                                      class="uk-text-center"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      OI Change <br /> (in lakhs)
                                    </th>
                                    <th
                                      class="uk-text-center"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      Volume
                                    </th>
                                    <th
                                      class="uk-text-center"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      IV
                                    </th>
                                    <th
                                      class="uk-text-center"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      Chg in LTP
                                    </th>
                                    <th
                                      class="uk-text-center"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0px 0px 0px 40px",
                                      }}
                                    >
                                      LTP
                                    </th>
                                    <th
                                      class="uk-text-center"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      Strike
                                    </th>
                                    <th
                                      class="uk-text-left"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0px 0px 0px 5px",
                                      }}
                                    >
                                      LTP
                                    </th>
                                    <th
                                      class="uk-text-left"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      Chg in LTP
                                    </th>
                                    <th
                                      class="uk-text-center"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      Volume
                                    </th>
                                    <th
                                      class="uk-text-center"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      IV
                                    </th>
                                    <th
                                      class="uk-text-center"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      OI Change <br /> (in lakhs)
                                    </th>
                                    <th
                                      class="uk-text-center"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      OI <br /> (in lakhs){" "}
                                    </th>
                                    <th
                                      class="uk-text-left"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      PCR-OI
                                    </th>
                                    <th
                                      class="uk-text-left"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      PCR-Vol
                                    </th>
                                  </tr>
                                </thead>
                              </table>
                            </div>
                            <div className="tableFixHead">
                              <div className="table-responsive">
                                <table
                                  class="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center"
                                  style={{ margin: 0 }}
                                >
                                  <tbody>
                                    {bankniftydata.length > 0 &&
                                      bankniftydata.map((row, key) => (
                                        <>
                                          {row.currentstrike == 1 ? (
                                            <tr
                                              style={{ background: "#fcb42d" }}
                                            >
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                  minWidth: "50px",
                                                }}
                                              >
                                                {ChartProgressBarBank(row.oi)}
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {ChartProgressOiChangeBank(
                                                  row.changeinoi
                                                )}
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>{row.volume}</div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>{row.civ}</div>
                                              </td>
                                              {Math.sign(
                                                parseFloat(row.changeinltp)
                                              ) == -1 ? (
                                                <td
                                                  className="text-center px-0"
                                                  style={{
                                                    padding: "10px 0px",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  <div style={{ color: "red" }}>
                                                    {row.changeinltp}
                                                    <br />
                                                    {"(" +
                                                      row.change_percent +
                                                      "%" +
                                                      ")"}
                                                  </div>
                                                </td>
                                              ) : (
                                                <td
                                                  className="text-center px-0"
                                                  style={{
                                                    padding: "10px 0px",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  <div
                                                    style={{ color: "green" }}
                                                  >
                                                    {row.changeinltp}
                                                    <br />
                                                    {"(" +
                                                      row.change_percent +
                                                      "%" +
                                                      ")"}
                                                  </div>
                                                </td>
                                              )}
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>{row.cltp}</div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>
                                                  {row.strikeprice.replace(
                                                    ".0",
                                                    ""
                                                  )}
                                                </div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>{row.pltpe}</div>
                                              </td>
                                              {Math.sign(
                                                parseFloat(row.pcltpe)
                                              ) == -1 ? (
                                                <td
                                                  className="text-center px-0"
                                                  style={{
                                                    padding: "10px 0px",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  <div style={{ color: "red" }}>
                                                    {row.pcltpe}
                                                    <br />
                                                    {"(" +
                                                      row.pcltpe_percent +
                                                      "%" +
                                                      ")"}
                                                  </div>
                                                </td>
                                              ) : (
                                                <td
                                                  className="text-center px-0"
                                                  style={{
                                                    padding: "10px 0px",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  <div
                                                    style={{ color: "green" }}
                                                  >
                                                    {row.pcltpe}
                                                    <br />
                                                    {"(" +
                                                      row.pcltpe_percent +
                                                      "%" +
                                                      ")"}
                                                  </div>
                                                </td>
                                              )}

                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                  minWidth: "30px",
                                                }}
                                              >
                                                <div>{row.pvole}</div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>{row.piv}</div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {ChartProgressPutOiChangeBank(
                                                  row.poichangee
                                                )}
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                  minWidth: "30px",
                                                }}
                                              >
                                                {ChartProgressBarPutBank(
                                                  row.poi
                                                )}
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>
                                                  {Number(row.oi) > 0 &&
                                                  !isNaN(row.poi) &&
                                                  !isNaN(row.oi)
                                                    ? (
                                                        row.poi / row.oi
                                                      ).toFixed(2)
                                                    : "-"}
                                                </div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>
                                                  {Number(row.volume) > 0
                                                    ? (
                                                        Number(row.pvole) /
                                                        Number(row.volume)
                                                      ).toFixed(2)
                                                    : "-"}
                                                </div>
                                              </td>
                                            </tr>
                                          ) : (
                                            <tr>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {ChartProgressBarBank(row.oi)}
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {ChartProgressOiChangeBank(
                                                  row.changeinoi
                                                )}
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>{row.volume}</div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>{row.civ}</div>
                                              </td>
                                              {Math.sign(
                                                parseFloat(row.changeinltp)
                                              ) == -1 ? (
                                                <td
                                                  className="text-center px-0"
                                                  style={{
                                                    padding: "10px 0px",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  <div style={{ color: "red" }}>
                                                    {row.changeinltp}
                                                    <br />
                                                    {"(" +
                                                      row.change_percent +
                                                      "%" +
                                                      ")"}
                                                  </div>
                                                </td>
                                              ) : (
                                                <td
                                                  className="text-center px-0"
                                                  style={{
                                                    padding: "10px 0px",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  <div
                                                    style={{ color: "green" }}
                                                  >
                                                    {row.changeinltp}
                                                    <br />
                                                    {"(" +
                                                      row.change_percent +
                                                      "%" +
                                                      ")"}
                                                  </div>
                                                </td>
                                              )}
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>{row.cltp}</div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>
                                                  {row.strikeprice.replace(
                                                    ".0",
                                                    ""
                                                  )}
                                                </div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>{row.pltpe}</div>
                                              </td>
                                              {Math.sign(
                                                parseFloat(row.pcltpe)
                                              ) == -1 ? (
                                                <td
                                                  className="text-center px-0"
                                                  style={{
                                                    padding: "10px 0px",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  <div style={{ color: "red" }}>
                                                    {row.pcltpe}
                                                    <br />
                                                    {"(" +
                                                      row.pcltpe_percent +
                                                      "%" +
                                                      ")"}
                                                  </div>
                                                </td>
                                              ) : (
                                                <td
                                                  className="text-center px-0"
                                                  style={{
                                                    padding: "10px 0px",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  <div
                                                    style={{ color: "green" }}
                                                  >
                                                    {row.pcltpe}
                                                    <br />
                                                    {"(" +
                                                      row.pcltpe_percent +
                                                      "%" +
                                                      ")"}
                                                  </div>
                                                </td>
                                              )}

                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                  minWidth: "30px",
                                                }}
                                              >
                                                <div>{row.pvole}</div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>{row.piv}</div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {ChartProgressPutOiChangeBank(
                                                  row.poichangee
                                                )}
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {ChartProgressBarPutBank(
                                                  row.poi
                                                )}
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>
                                                  {Number(row.oi) > 0 &&
                                                  !isNaN(row.poi) &&
                                                  !isNaN(row.oi)
                                                    ? (
                                                        row.poi / row.oi
                                                      ).toFixed(2)
                                                    : "-"}
                                                </div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>
                                                  {Number(row.volume) > 0
                                                    ? (
                                                        Number(row.pvole) /
                                                        Number(row.volume)
                                                      ).toFixed(2)
                                                    : "-"}
                                                </div>
                                              </td>
                                            </tr>
                                          )}
                                        </>
                                      ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        // Loading or no data message here
                        <div className="linear-background w-100"> </div>
                      )}
                    </>
                  )}
                </Tab>
                {/* Step 2: Add a new tab for Finnifty */}
                <Tab eventKey="finnifty" title="FIN NIFTY">
                  {load ? (
                    <div className="linear-background w-100"> </div>
                  ) : (
                    <>
                      {finniftyData.length > 0 ? (
                        <>
                          <div
                            className="d-flex justify-content-between"
                            style={{ padding: "2px 10px" }}
                          >
                            <div style={{ fontWeight: "600" }}>
                              {" "}
                              <span>
                                {"Spot Price : " + homedata.finnifty}
                              </span>{" "}
                            </div>
                            <div
                              style={{ fontWeight: "600", maxWidth: "300px" }}
                            >
                              <label
                                htmlFor=""
                                style={{
                                  display: "inline-block",
                                  marginRight: "10px",
                                }}
                              >
                                Expiry
                              </label>
                              {/* <Select style={{display: 'inline-block'}} defaultValue={{ label: expirevalue, value: expirevalue }} className="text-dark" required name="expiredate" id="expiredate" options={allexpiredate} onChange={e => getexpiredate(e)} /> */}
                              <Select
                                style={{ display: "inline-block" }}
                                defaultValue={{
                                  label: finniftyExpiry[0].label,
                                  value: finniftyExpiry[0].value,
                                }}
                                className="text-dark"
                                required
                                name="expiredate"
                                id="expiredate"
                                options={finniftyExpiry}
                                onChange={(e) =>
                                  getOptionChainFinniftyLatest(e.value)
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="uk-card uk-card-default uk-card-body uk-border-rounded"
                            style={{
                              margin: 0,
                              padding: 0,
                              border: "1px solid #C17951",
                              borderRadius: "0px",
                            }}
                          >
                            <div className="uk-text-center">
                              <h2 style={{ background: "#FDF1CC", margin: 0 }}>
                                OPTION CHAIN (FIN NIFTY)
                              </h2>
                            </div>
                            <div className="table-responsive">
                              <table
                                class="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center"
                                style={{ margin: 0 }}
                              >
                                <thead>
                                  <tr>
                                    <th
                                      class="uk-text-center"
                                      style={{ minWidth: "75px" }}
                                    ></th>
                                    <th
                                      class="uk-text-center"
                                      style={{ minWidth: "50px" }}
                                    ></th>
                                    <th class="uk-text-center"></th>
                                    <th class="uk-text-center">CALLS</th>
                                    <th class="uk-text-center"></th>
                                    <th class="uk-text-center"></th>
                                    <th class="uk-text-center"></th>
                                    <th class="uk-text-center"></th>
                                    <th class="uk-text-center"></th>
                                    <th class="uk-text-center"></th>
                                    <th class="uk-text-center">PUTS</th>
                                    <th
                                      class="uk-text-center"
                                      style={{ minWidth: "75px" }}
                                    ></th>
                                    <th
                                      class="uk-text-center"
                                      style={{ minWidth: "50px" }}
                                    ></th>
                                    <th
                                      class="uk-text-center"
                                      style={{ minWidth: "75px" }}
                                    ></th>
                                    <th
                                      class="uk-text-center"
                                      style={{ minWidth: "50px" }}
                                    ></th>
                                  </tr>

                                  <tr>
                                    <th
                                      class="uk-text-center"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "50px",
                                        padding: "0",
                                      }}
                                    >
                                      OI <br /> (in lakhs){" "}
                                    </th>
                                    <th
                                      class="uk-text-center"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      OI Change <br /> (in lakhs)
                                    </th>
                                    <th
                                      class="uk-text-center"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      Volume
                                    </th>
                                    <th
                                      class="uk-text-center"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      IV
                                    </th>
                                    <th
                                      class="uk-text-center"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      Chg in LTP
                                    </th>
                                    <th
                                      class="uk-text-center"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0px 0px 0px 40px",
                                      }}
                                    >
                                      LTP
                                    </th>
                                    <th
                                      class="uk-text-center"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      Strike
                                    </th>
                                    <th
                                      class="uk-text-left"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0px 0px 0px 5px",
                                      }}
                                    >
                                      LTP
                                    </th>
                                    <th
                                      class="uk-text-left"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      Chg in LTP
                                    </th>
                                    <th
                                      class="uk-text-center"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      Volume
                                    </th>
                                    <th
                                      class="uk-text-center"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      IV
                                    </th>
                                    <th
                                      class="uk-text-center"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      OI Change <br /> (in lakhs)
                                    </th>
                                    <th
                                      class="uk-text-center"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      OI <br /> (in lakhs){" "}
                                    </th>
                                    <th
                                      class="uk-text-left"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      PCR-OI
                                    </th>
                                    <th
                                      class="uk-text-left"
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "75px",
                                        padding: "0",
                                      }}
                                    >
                                      PCR-Vol
                                    </th>
                                  </tr>
                                </thead>
                              </table>
                            </div>
                            <div className="tableFixHead">
                              <div className="table-responsive">
                                <table
                                  class="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center"
                                  style={{ margin: 0 }}
                                >
                                  <tbody>
                                    {finniftyData.length > 0 &&
                                      finniftyData.map((row, key) => (
                                        <>
                                          {row.currentstrike == 1 ? (
                                            <tr
                                              style={{ background: "#fcb42d" }}
                                            >
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                  minWidth: "50px",
                                                }}
                                              >
                                                {ChartProgressBarBank(row.oi)}
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {ChartProgressOiChangeBank(
                                                  row.changeinoi
                                                )}
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>{row.volume}</div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>{row.civ}</div>
                                              </td>
                                              {Math.sign(
                                                parseFloat(row.changeinltp)
                                              ) == -1 ? (
                                                <td
                                                  className="text-center px-0"
                                                  style={{
                                                    padding: "10px 0px",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  <div style={{ color: "red" }}>
                                                    {row.changeinltp}
                                                    <br />
                                                    {"(" +
                                                      row.change_percent +
                                                      "%" +
                                                      ")"}
                                                  </div>
                                                </td>
                                              ) : (
                                                <td
                                                  className="text-center px-0"
                                                  style={{
                                                    padding: "10px 0px",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  <div
                                                    style={{ color: "green" }}
                                                  >
                                                    {row.changeinltp}
                                                    <br />
                                                    {"(" +
                                                      row.change_percent +
                                                      "%" +
                                                      ")"}
                                                  </div>
                                                </td>
                                              )}
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>{row.cltp}</div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>
                                                  {row.strikeprice.replace(
                                                    ".0",
                                                    ""
                                                  )}
                                                </div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>{row.pltpe}</div>
                                              </td>
                                              {Math.sign(
                                                parseFloat(row.pcltpe)
                                              ) == -1 ? (
                                                <td
                                                  className="text-center px-0"
                                                  style={{
                                                    padding: "10px 0px",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  <div style={{ color: "red" }}>
                                                    {row.pcltpe}
                                                    <br />
                                                    {"(" +
                                                      row.pcltpe_percent +
                                                      "%" +
                                                      ")"}
                                                  </div>
                                                </td>
                                              ) : (
                                                <td
                                                  className="text-center px-0"
                                                  style={{
                                                    padding: "10px 0px",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  <div
                                                    style={{ color: "green" }}
                                                  >
                                                    {row.pcltpe}
                                                    <br />
                                                    {"(" +
                                                      row.pcltpe_percent +
                                                      "%" +
                                                      ")"}
                                                  </div>
                                                </td>
                                              )}

                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                  minWidth: "30px",
                                                }}
                                              >
                                                <div>{row.pvole}</div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>{row.piv}</div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {ChartProgressPutOiChangeBank(
                                                  row.poichangee
                                                )}
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                  minWidth: "30px",
                                                }}
                                              >
                                                {ChartProgressBarPutBank(
                                                  row.poi
                                                )}
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>
                                                  {Number(row.oi) > 0 &&
                                                  !isNaN(row.poi) &&
                                                  !isNaN(row.oi)
                                                    ? (
                                                        row.poi / row.oi
                                                      ).toFixed(2)
                                                    : "-"}
                                                </div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>
                                                  {Number(row.volume) > 0
                                                    ? (
                                                        Number(row.pvole) /
                                                        Number(row.volume)
                                                      ).toFixed(2)
                                                    : "-"}
                                                </div>
                                              </td>
                                            </tr>
                                          ) : (
                                            <tr>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {ChartProgressBarBank(row.oi)}
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {ChartProgressOiChangeBank(
                                                  row.changeinoi
                                                )}
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>{row.volume}</div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>{row.civ}</div>
                                              </td>
                                              {Math.sign(
                                                parseFloat(row.changeinltp)
                                              ) == -1 ? (
                                                <td
                                                  className="text-center px-0"
                                                  style={{
                                                    padding: "10px 0px",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  <div style={{ color: "red" }}>
                                                    {row.changeinltp}
                                                    <br />
                                                    {"(" +
                                                      row.change_percent +
                                                      "%" +
                                                      ")"}
                                                  </div>
                                                </td>
                                              ) : (
                                                <td
                                                  className="text-center px-0"
                                                  style={{
                                                    padding: "10px 0px",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  <div
                                                    style={{ color: "green" }}
                                                  >
                                                    {row.changeinltp}
                                                    <br />
                                                    {"(" +
                                                      row.change_percent +
                                                      "%" +
                                                      ")"}
                                                  </div>
                                                </td>
                                              )}
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>{row.cltp}</div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>
                                                  {row.strikeprice.replace(
                                                    ".0",
                                                    ""
                                                  )}
                                                </div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>{row.pltpe}</div>
                                              </td>
                                              {Math.sign(
                                                parseFloat(row.pcltpe)
                                              ) == -1 ? (
                                                <td
                                                  className="text-center px-0"
                                                  style={{
                                                    padding: "10px 0px",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  <div style={{ color: "red" }}>
                                                    {row.pcltpe}
                                                    <br />
                                                    {"(" +
                                                      row.pcltpe_percent +
                                                      "%" +
                                                      ")"}
                                                  </div>
                                                </td>
                                              ) : (
                                                <td
                                                  className="text-center px-0"
                                                  style={{
                                                    padding: "10px 0px",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  <div
                                                    style={{ color: "green" }}
                                                  >
                                                    {row.pcltpe}
                                                    <br />
                                                    {"(" +
                                                      row.pcltpe_percent +
                                                      "%" +
                                                      ")"}
                                                  </div>
                                                </td>
                                              )}

                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                  minWidth: "30px",
                                                }}
                                              >
                                                <div>{row.pvole}</div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>{row.piv}</div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {ChartProgressPutOiChangeBank(
                                                  row.poichangee
                                                )}
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {ChartProgressBarPutBank(
                                                  row.poi
                                                )}
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>
                                                  {Number(row.oi) > 0 &&
                                                  !isNaN(row.poi) &&
                                                  !isNaN(row.oi)
                                                    ? (
                                                        row.poi / row.oi
                                                      ).toFixed(2)
                                                    : "-"}
                                                </div>
                                              </td>
                                              <td
                                                className="text-center px-0"
                                                style={{
                                                  padding: "18px 10px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <div>
                                                  {Number(row.volume) > 0
                                                    ? (
                                                        Number(row.pvole) /
                                                        Number(row.volume)
                                                      ).toFixed(2)
                                                    : "-"}
                                                </div>
                                              </td>
                                            </tr>
                                          )}
                                        </>
                                      ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        // Loading or no data message here
                        <div className="linear-background w-100"> </div>
                      )}
                    </>
                  )}
                </Tab>
              </Tabs>
            </div>
          </div>

          {/* section content end */}
        </main>

        <Footer />
      </div>
    </>
  );
}
