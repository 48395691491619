import React, { useEffect,useState } from 'react'
import Header from '../../common/Header';
import axios from 'axios';
import {api_url,MasterTimeline} from '../../common/Helpers';
import '../../common/style.css'
import $ from 'jquery';
import Swal from 'sweetalert2';
import Footer from '../../common/Footer';
import Highcharts from 'highcharts/highstock'
import Select from "react-select";
import moment from 'moment-timezone';
export default function Contact(){
  const [submit, setsubmit] = useState(false);
  const [load, setload] = useState(false);
  const [countchart, setcountchart] = useState(0);
  const [multiindexoptions, setmultiindexoptions] = useState([{ value:'', label:'' }]);
  const [chart, setChart] = useState(null);
  const [seriesCount, setSeriesCount] = useState(0);

  useEffect(() => {
    fetchdata();
  }, []);
  const fetchdata = async () => {
    await axios.get(api_url + `getmultiindexoptions`).then(({ data }) => {
      let arr = [];
      if (data.length > 0) {
        for (let x in data) {
          let obj = { value: data[x].id, label: data[x].name };
          arr.push(obj);
        }
      }
      setmultiindexoptions(arr);
    });
    await axios.get(api_url+'getmultiindexchartlatest/50').then(({ data }) => {
      var newData = [];
      for (var i = data.length - 1; i >= 0; i--) {
        var innerData = data[i];
        var timestamp = moment.tz(innerData.time, 'DD-MM-YYYY HH:mm', 'Asia/Kolkata').valueOf() / 1000;
        newData.push([timestamp, Number(innerData.close)]);
      }
      newData.sort(function(a, b) {
        return a[0] - b[0];
      });
      var latestTimestamp = newData[newData.length - 1][0];
      createChart([newData], ['NIFTY 50', '', '', ''], latestTimestamp);
    });   
  };
  function createChart(data, names, latestTimestamp) {
    let alltime = MasterTimeline(data[0].length)
    let charttime = [];
    for(let x in alltime){
      charttime.push(alltime[x])
    }
    const tempChart = Highcharts.stockChart('container', {
      chart: {
        spacingBottom: 0 // Remove the space at the bottom of the chart
      },
      navigator: {
        enabled: false // Disable the navigator
      },
      xAxis: {
        visible: false // Hide the x-axis labels and ticks
      },
      rangeSelector: {
        selected: 4,
        inputEnabled: false,
        buttonTheme: {
          width: 60, // Set the width to fit "Today" text
          style: {
            textTransform: 'none', // Disable uppercase text
            fontSize: '14px', // Set the font size
            fontWeight: 'normal', // Set the font weight
            lineHeight: '20px' // Set the line height
          },
          states: {
            hover: {
              fill: '#ddd' // Set the background color on hover
            },
            select: {
              fill: '#ddd' // Set the background color on select
            }
          },
          text: 'Today' // Set the text for the button
        },
        buttons: [{
          type: 'day',
          count: 1,
          text: 'Today'
        }]
      },
      yAxis: [{
        opposite: false,
        labels: {
          formatter: function () {
            return Highcharts.numberFormat(this.value);
          }
        },
        title: {
          text: 'Change'
        },
        plotLines: [{
          value: 0,
          width: 2,
          color: 'silver'
        }]
      }, {
        opposite: true,
        labels: {
          formatter: function () {
            return (this.value > 0 ? ' + ' : '') + this.value + '%';
          },
          style: {
            color: 'red' // Set the text color of the labels to red
          }
        },
        title: {
          text: ''
        }
      }],      
      plotOptions: {
        series: {
          compare: 'percent',
          showInNavigator: true
        }
      },
      tooltip: {
        formatter: function () {
          var s = ''; 
          for (var k = 0; k < this.points.length; k++) {
            var point = this.points[k];
            var timeIndex = point.point.index;
            s += '';
            s += point.series.name == 'NIFTY 50' ? charttime[timeIndex] : '';
            s += '</b><br/>'+'<span style="color:' + point.series.color + '">' + point.series.name + '</span>: <b>'+ Highcharts.numberFormat(point.y, 2) + '</b><br/>'; // Add <br/> to add a line break after each series
          }          
          return s;
        },
        valueDecimals: 2,
        split: true
      },                
      series: [
        {
          name: names[0],
          data: data[0],
          color: 'rgb(110, 207, 246)'
        },
        {
          name: names[1],
          data: data[1],
          color: 'rgb(153, 153, 153)'
        },
        {
          name: names[2],
          data: data[2],
          color: 'rgb(0, 0, 0)'
        },
        {
          name: names[3],
          data: data[3],
          color: 'rgb(0, 0, 255)'
        }
      ], 
    });
    setChart(tempChart);
  }
  const updateChartWithNewData = async (valuelabel, index) => {
    if (chart) {
      var newData = [];
      await axios.get(api_url+'getmultiindexchartlatest/'+valuelabel).then(({ data }) => {
        for (var i = 0; i < data.length; i++) {
            var innerData = data[i];
            var timestamp = moment(innerData.time,'DD-MM-YYYY hh:mm').valueOf() / 1000;
            newData.push([timestamp, Number(innerData.close)]);
        }
      });
  
      const seriesList = chart.series;
      if (seriesList.length < 4) {
        // add new series if less than 4
        chart.addSeries({
          name: valuelabel,
          data: newData,
          color: index === 1 ? 'rgb(102, 45, 145)' : index === 2 ? 'rgb(153, 153, 153)' : 'rgb(0, 0, 0)'
        });
      } else {
        // replace series at desired index if 4
        chart.series[index].update({
          name: valuelabel,
          data: newData,
          color: index === 1 ? 'rgb(102, 45, 145)' : index === 2 ? 'rgb(153, 153, 153)' : 'rgb(0, 0, 0)'
        });
      }
    }
  };
  
  
  const selectbox = (e, index) => {
    const valuelabel = e.label || '50'; // set default to '50' if label is falsy
    updateChartWithNewData(valuelabel, index);
  };
  
return (
      <>
     <div>
  <Header />
 <main>
  {/* section content begin */}
  <div className="uk-section uk-margin-small-top">
    <div className="uk-container">
    <div className="uk-container" style={{marginTop: '1.4rem'}}>
      <div className="row">
        <div className="col-12">
            {load ?
              <div className="linear-background w-100"> </div>
            :
            <div className="uk-card uk-card-default uk-card-body uk-border-rounded" style={{
              margin: 0,
              padding: 0,
              border: '1px solid #C17951',
              borderRadius: '0px',
            }}>
              <h2 style={{background: '#FDD965',margin: 0,textAlign: 'center'}}>Multi-Index Chart</h2>
              <figure class="highcharts-figure">
              <div id="container" style={{height: '500px'}}></div>
              </figure>
              <div className="d-flex justify-content-around align-items-center pb-3">
                <div>Compare Indices</div>
                <div>
                <span id="mainInner_C008_selectedindex" class="indicator blue" style={{backgroundColor: '#6ecff6',padding: '0.5rem 1.3rem',marginRight: '0.5rem'}}></span>
                <span id="mainInner_C008_selectedindexname" class="selectedindexname">NIFTY 50</span>
                </div>
                <div style={{minWidth: '120px'}}>
                <span id="mainInner_C008_selectedindex" class="indicator blue" style={{backgroundColor: '#662d91',padding: '0.5rem 1.3rem',marginRight: '0.5rem'}}></span>
                <Select className="text-dark d-inline-block my-custom-class" required name="branchname" options={multiindexoptions} onChange={e => selectbox(e,1)} />
                </div>
                <div>
                <span id="mainInner_C008_selectedindex" class="indicator blue" style={{backgroundColor: '#999',padding: '0.5rem 1.3rem',marginRight: '0.5rem'}}></span>
                <Select className="text-dark d-inline-block" required name="branchname" options={multiindexoptions} onChange={e => selectbox(e,2)} />
                </div>
                <div>
                <span id="mainInner_C008_selectedindex" class="indicator blue" style={{backgroundColor: '#000',padding: '0.5rem 1.3rem',marginRight: '0.5rem'}}></span>
                <Select className="text-dark d-inline-block" required name="branchname" options={multiindexoptions} onChange={e => selectbox(e,3)} />
                </div>
              </div>
          </div>
          }
        
        </div>
      </div>
    </div>
    </div>
  </div>
</main>



  <Footer />
  </div>
      </>
    )
  }
