import React, { useEffect,useState } from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Header from '../../common/Header';
import axios from 'axios';
import {api_url,formatdmY} from '../../common/Helpers';
import '../../common/style.css'
import Footer from '../../common/Footer';
import Highcharts from 'highcharts/highstock'
import Select from "react-select";
export default function Contact(){
  const [load, setload] = useState(false);
  const [key, setKey] = useState('home');
  const [allexpiredatenifty50, setallexpiredatenifty50]= useState([{ value:'',label:''}]);
  const [allexpiredatebanknifty, setallexpiredatebanknifty]= useState([{ value:'',label:''}]);
  const [allexpiredatefinnifty, setallexpiredatefinnifty]= useState([{ value:'',label:''}]);
  const [homedata,sethomedata] = useState([])
  useEffect(() => {
    fetchdata();
  }, []);
  const fetchdata = async () => {
    setload(true);
    await axios.get(api_url+`getalloptionchainexplist`).then(({data})=>{
      getOptionChainnifty50Latest(data.NIFTY[0])
      getOptionChainbankniftyLatest(data.BANKNIFTY[0])
      getOptionChainfinniftyLatest(data.FINNIFTY[0])
      let arr = [];
      if(data.NIFTY.length > 0){
        for(let x in data.NIFTY){ 
          let obj = { value:data.NIFTY[x],label:formatdmY(data.NIFTY[x])};
          arr.push(obj);
        }
      }
      setallexpiredatenifty50(arr);
      let arr1 = [];
      if(data.BANKNIFTY.length > 0){
        for(let x in data.BANKNIFTY){ 
          let obj = { value:data.BANKNIFTY[x],label:formatdmY(data.BANKNIFTY[x])};
          arr1.push(obj);
        }
      }
      setallexpiredatebanknifty(arr1)
      let arr2 = [];
      if(data.FINNIFTY.length > 0){
        for(let x in data.FINNIFTY){ 
          let obj = { value:data.FINNIFTY[x],label:formatdmY(data.FINNIFTY[x])};
          arr2.push(obj);
        }
      }
      setallexpiredatefinnifty(arr2)
      return false;
    })
    await axios.get(api_url+'gethomedata').then(({data})=>{
      sethomedata(data)
    })
    setload(false);
  };
  const getOptionChainnifty50Latest = async (expirydate) =>{
    await axios.get(api_url+`getoptionchainlatests/`+expirydate).then(({data})=>{
      let allstrike = [];
      let allputOI = [];
      let allcallOI = [];
      let allputChangeInOI = [];
      let allcallChangeInOI = [];

      if(data.length > 0){
        for(let x in data){ 
          let obj000 = data[x].poi;
          allputOI.push(isNaN(obj000) ? 0 : Number(obj000));
          let obj00 = data[x].oi;
          allcallOI.push(isNaN(obj00) ? 0 : Number(obj00));
          let obj0 = data[x].strikeprice;
          allstrike.push(isNaN(obj0) ? 0 : Number(obj0));
          let obj = data[x].poichangee;
          allputChangeInOI.push(isNaN(obj) ? 0 : Number(obj));
          let obj2 = data[x].changeinoi;
          allcallChangeInOI.push(isNaN(obj2) ? 0 : Number(obj2));
        }
      }
      // Open Interest
      Highcharts.chart('openinterest', {
        chart: {
            type: 'column'
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: allstrike.reverse()
        },
        yAxis: [{
            min: 0,
            title: {
                text: 'Open Interest'
            }
        }, {
            title: {
                text: ''
            },
            opposite: true
        }],
        legend: {
            shadow: false
        },
        tooltip: {
            shared: true
        },
        plotOptions: {
            column: {
                grouping: false,
                shadow: false,
                borderWidth: 0
            }
        },
        series: [{
          name: 'Put Option OI',
          color: 'red',
          data: allputOI.reverse(),
          pointPadding: 0.4,
          pointWidth: 10,
          pointPlacement: -0.2
      }, {
          name: 'Call Option OI',
          color: 'green',
          data: allcallOI.reverse(),
          pointPadding: 0.3,
          pointWidth: 5,
          pointPlacement: -0.2
      }]
      
    });
        // Change in Open Interest
        Highcharts.chart('changeopeninterest', {
          chart: {
            type: 'column'
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: allstrike
          },
          yAxis: [{
            title: {
              text: 'Change in Open Interest'
            }
          }, {
            title: {
              text: ''
            },
            opposite: true
          }],
          legend: {
            shadow: false
          },
          tooltip: {
            shared: true
          },
          plotOptions: {
            column: {
              grouping: false,
              shadow: false,
              borderWidth: 0
            }
          },
          series: [{
            name: 'Put OI Change',
            color: '#FF4F52',
            data: allputChangeInOI.reverse(),
            pointPadding: 0.3,
            pointWidth: 10,
            pointPlacement: -0.2
          },{
            name: 'Call OI Change',
            color: '#70AB0A',
            data: allcallChangeInOI.reverse(), 
            pointPadding: 0.4,
            pointWidth: 5,
            pointPlacement: -0.2
          }]
        });
        
    })
  }
  const getOptionChainbankniftyLatest = async (expirydate) =>{
    await axios.get(api_url+`getoptionchainbankniftylatests/`+expirydate).then(({data})=>{
      let allstrike = [];
      let allputOI = [];
      let allcallOI = [];
      let allputChangeInOI = [];
      let allcallChangeInOI = [];

      if(data.length > 0){
        for(let x in data){ 
          let obj000 = data[x].poi;
          allputOI.push(isNaN(obj000) ? 0 : Number(obj000));
          let obj00 = data[x].oi;
          allcallOI.push(isNaN(obj00) ? 0 : Number(obj00));
          let obj0 = data[x].strikeprice;
          allstrike.push(isNaN(obj0) ? 0 : Number(obj0));
          let obj = data[x].poichangee;
          allputChangeInOI.push(isNaN(obj) ? 0 : Number(obj));
          let obj2 = data[x].changeinoi;
          allcallChangeInOI.push(isNaN(obj2) ? 0 : Number(obj2));
        }
      }
      // Open Interest
      Highcharts.chart('openinterestbank', {
        chart: {
            type: 'column'
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: allstrike.reverse()
        },
        yAxis: [{
            min: 0,
            title: {
                text: 'Open Interest'
            }
        }, {
            title: {
                text: ''
            },
            opposite: true
        }],
        legend: {
            shadow: false
        },
        tooltip: {
            shared: true
        },
        plotOptions: {
            column: {
                grouping: false,
                shadow: false,
                borderWidth: 0
            }
        },
        series: [{
          name: 'Put Option OI',
          color: 'red',
          data: allputOI.reverse(),
          pointPadding: 0.4,
          pointWidth: 10,
          pointPlacement: -0.2
      }, {
          name: 'Call Option OI',
          color: 'green',
          data: allcallOI.reverse(),
          pointPadding: 0.3,
          pointWidth: 5,
          pointPlacement: -0.2
      }]
    });
    
        // Change in Open Interest
        Highcharts.chart('changeopeninterestbank', {
          chart: {
            type: 'column'
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: allstrike
          },
          yAxis: [{
            title: {
              text: 'Change in Open Interest'
            }
          }, {
            title: {
              text: ''
            },
            opposite: true
          }],
          legend: {
            shadow: false
          },
          tooltip: {
            shared: true
          },
          plotOptions: {
            column: {
              grouping: false,
              shadow: false,
              borderWidth: 0
            }
          },
          series: [{
            name: 'Put OI Change',
            color: '#FF4F52',
            data: allputChangeInOI.reverse(),
            pointPadding: 0.3,
            pointWidth: 10,
            pointPlacement: -0.2
          },{
            name: 'Call OI Change',
            color: '#70AB0A',
            data: allcallChangeInOI.reverse(), 
            pointPadding: 0.4,
            pointWidth: 5,
            pointPlacement: -0.2
          }]
        });
    })
  }
  const getOptionChainfinniftyLatest = async (expirydate) =>{
    await axios.get(api_url+`getoptionchainfinniftylatests/`+expirydate).then(({data})=>{
      let allstrike = [];
      let allputOI = [];
      let allcallOI = [];
      let allputChangeInOI = [];
      let allcallChangeInOI = [];

      if(data.length > 0){
        for(let x in data){ 
          let obj000 = data[x].poi;
          allputOI.push(isNaN(obj000) ? 0 : Number(obj000));
          let obj00 = data[x].oi;
          allcallOI.push(isNaN(obj00) ? 0 : Number(obj00));
          let obj0 = data[x].strikeprice;
          allstrike.push(isNaN(obj0) ? 0 : Number(obj0));
          let obj = data[x].poichangee;
          allputChangeInOI.push(isNaN(obj) ? 0 : Number(obj));
          let obj2 = data[x].changeinoi;
          allcallChangeInOI.push(isNaN(obj2) ? 0 : Number(obj2));
        }
      }
      // Open Interest
      Highcharts.chart('openinterestfinnifty', {
        chart: {
            type: 'column'
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: allstrike.reverse()
        },
        yAxis: [{
            min: 0,
            title: {
                text: 'Open Interest'
            }
        }, {
            title: {
                text: ''
            },
            opposite: true
        }],
        legend: {
            shadow: false
        },
        tooltip: {
            shared: true
        },
        plotOptions: {
            column: {
                grouping: false,
                shadow: false,
                borderWidth: 0
            }
        },
        series: [{
          name: 'Put Option OI',
          color: 'red',
          data: allputOI.reverse(),
          pointPadding: 0.4,
          pointWidth: 10,
          pointPlacement: -0.2
      }, {
          name: 'Call Option OI',
          color: 'green',
          data: allcallOI.reverse(),
          pointPadding: 0.3,
          pointWidth: 5,
          pointPlacement: -0.2
      }]
    });
    
        // Change in Open Interest
        Highcharts.chart('changeopeninterestfinnifty', {
          chart: {
            type: 'column'
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: allstrike
          },
          yAxis: [{
            title: {
              text: 'Change in Open Interest'
            }
          }, {
            title: {
              text: ''
            },
            opposite: true
          }],
          legend: {
            shadow: false
          },
          tooltip: {
            shared: true
          },
          plotOptions: {
            column: {
              grouping: false,
              shadow: false,
              borderWidth: 0
            }
          },
          series: [{
            name: 'Put OI Change',
            color: '#FF4F52',
            data: allputChangeInOI.reverse(),
            pointPadding: 0.3,
            pointWidth: 10,
            pointPlacement: -0.2
          },{
            name: 'Call OI Change',
            color: '#70AB0A',
            data: allcallChangeInOI.reverse(), 
            pointPadding: 0.4,
            pointWidth: 5,
            pointPlacement: -0.2
          }]
        });
    })
  }
return (
  <>
  <div>
<Header />
<main>
{/* section content begin */}
<div className="uk-section uk-margin-small-top">
 <div className="uk-container" style={{marginTop: '1rem',minWidth: '1400px'}}>
 <Tabs
   id="controlled-tab-example"
   activeKey={key}
   onSelect={(k) => setKey(k)}
 >
 
 <Tab eventKey="home" title="NIFTY 50">
 {load?
 <div className="linear-background w-100"> </div>
 :
 <>  
 <div className="row">
        <div className="col-12">
            <div className="uk-card uk-card-default uk-card-body uk-border-rounded" style={{
              margin: 0,
              padding: 0,
              border: '1px solid #C17951',
              borderRadius: '0px',
            }}>
          <div>    
          <div className='row' style={{padding: '2px 10px'}}>
                <div className="col-12" style={{background: 'rgb(253, 241, 204)',width: '99.7%',marginLeft: '2px'}}>
                <div className="row">
                <div className='col-4' style={{fontWeight: '600'}}></div>
                <div className="col-4">
                <h2 style={{margin: 0,textAlign: 'center'}}>Open Interest</h2>
                </div>
                <div className='col-4 text-end' style={{fontWeight: '600'}} >
                  <label htmlFor="" style={{display: 'inline-block',marginRight: '10px'}}>Expiry</label>
                  <Select
                    style={{ display: 'inline-block' }}
                    defaultValue={{ label: allexpiredatenifty50[0].label, value: allexpiredatenifty50[0].value }}
                    className="text-dark"
                    required
                    name="expiredate"
                    id="expiredate"
                    options={allexpiredatenifty50}
                    onChange={(e) => getOptionChainnifty50Latest(e.value)}
                  />
                </div>
                </div>
                </div>
              </div> 
              </div>
              <figure class="highcharts-figure">
              <div id="openinterest" style={{height: '500px'}}></div>
              </figure>
            </div>
            <div className="uk-card uk-card-default uk-card-body uk-border-rounded mt-5" style={{
              margin: 0,
              padding: 0,
              border: '1px solid #C17951',
              borderRadius: '0px',
            }}>
              <h2 style={{background: '#FDF1CC',margin: 0,textAlign: 'center'}}>Change in Open Interest</h2>
              <figure class="highcharts-figure">
              <div id="changeopeninterest" style={{height: '500px'}}></div>
              </figure>
          </div>
          
        
        </div>
      </div>
 </>
 }
 </Tab>
 <Tab eventKey="profile" title="BANK NIFTY">
   {load?
 <div className="linear-background w-100"> </div>
 :
 <>  
 <div className="row">
        <div className="col-12">
            <div className="uk-card uk-card-default uk-card-body uk-border-rounded" style={{
              margin: 0,
              padding: 0,
              border: '1px solid #C17951',
              borderRadius: '0px',
            }}>
              <div className='row' style={{padding: '2px 10px'}}>
                <div className="col-12" style={{background: 'rgb(253, 241, 204)',width: '99.7%',marginLeft: '2px'}}>
                <div className="row">
                <div className='col-4' style={{fontWeight: '600'}}></div>
                <div className="col-4">
                <h2 style={{margin: 0,textAlign: 'center'}}>Open Interest</h2>
                </div>
                <div className='col-4 text-end' style={{fontWeight: '600'}} >
                  <label htmlFor="" style={{display: 'inline-block',marginRight: '10px'}}>Expiry</label>
                  <Select
                    style={{ display: 'inline-block' }}
                    defaultValue={{ label: allexpiredatebanknifty[0].label, value: allexpiredatebanknifty[0].value }}
                    className="text-dark"
                    required
                    name="expiredate"
                    id="expiredate"
                    options={allexpiredatebanknifty}
                    onChange={(e) => getOptionChainbankniftyLatest(e.value)}
                  />
                </div>
                </div>
                </div>
              </div> 
              <figure class="highcharts-figure">
              <div id="openinterestbank" style={{height: '500px'}}></div>
              </figure>
            </div>
            <div className="uk-card uk-card-default uk-card-body uk-border-rounded mt-5" style={{
              margin: 0,
              padding: 0,
              border: '1px solid #C17951',
              borderRadius: '0px',
            }}>
              <h2 style={{background: '#FDF1CC',margin: 0,textAlign: 'center'}}>Change in Open Interest</h2>
              <figure class="highcharts-figure">
              <div id="changeopeninterestbank" style={{height: '500px'}}></div>
              </figure>
          </div>
          
        
        </div>
      </div>
 </>
 }
 </Tab>
 <Tab eventKey="finnifty" title="FIN NIFTY"> {/* Add a new tab for FINNIFTY */}
                  {load ? (
                    <div className="linear-background w-100"></div>
                  ) : (
                    <>  
                      <div className="row">
                              <div className="col-12">
                                  <div className="uk-card uk-card-default uk-card-body uk-border-rounded" style={{
                                    margin: 0,
                                    padding: 0,
                                    border: '1px solid #C17951',
                                    borderRadius: '0px',
                                  }}>
                                    
                                  <div className='row' style={{padding: '2px 10px'}}>
                                    <div className="col-12" style={{background: 'rgb(253, 241, 204)',width: '99.7%',marginLeft: '2px'}}>
                                    <div className="row">
                                    <div className='col-4' style={{fontWeight: '600'}}></div>
                                    <div className="col-4">
                                    <h2 style={{margin: 0,textAlign: 'center'}}>Open Interest</h2>
                                    </div>
                                    <div className='col-4 text-end' style={{fontWeight: '600'}} >
                                      <label htmlFor="" style={{display: 'inline-block',marginRight: '10px'}}>Expiry</label>
                                      <Select
                                        style={{ display: 'inline-block' }}
                                        defaultValue={{ label: allexpiredatefinnifty[0].label, value: allexpiredatefinnifty[0].value }}
                                        className="text-dark"
                                        required
                                        name="expiredate"
                                        id="expiredate"
                                        options={allexpiredatefinnifty}
                                        onChange={(e) => getOptionChainfinniftyLatest(e.value)}
                                      />
                                    </div>
                                    </div>
                                    </div>
                                  </div> 
                                    <figure class="highcharts-figure">
                                    <div id="openinterestfinnifty" style={{height: '500px'}}></div>
                                    </figure>
                                  </div>
                                  <div className="uk-card uk-card-default uk-card-body uk-border-rounded mt-5" style={{
                                    margin: 0,
                                    padding: 0,
                                    border: '1px solid #C17951',
                                    borderRadius: '0px',
                                  }}>
                                    <h2 style={{background: '#FDF1CC',margin: 0,textAlign: 'center'}}>Change in Open Interest</h2>
                                    <figure class="highcharts-figure">
                                    <div id="changeopeninterestfinnifty" style={{height: '500px'}}></div>
                                    </figure>
                                </div>
                                
                              
                              </div>
                            </div>
                      </>
                  )}
 </Tab>
 </Tabs>
 </div>
</div>

{/* section content end */}
</main>



<Footer />
</div>
   </>
    )
  }
