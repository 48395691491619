import React, { useEffect,useState } from 'react'
import {Link,useNavigate} from 'react-router-dom';
import Header from './Header';
import axios from 'axios';
import {app_url,asseturl} from './Helpers';
import Select from "react-select";
import Slider from "react-slick";
import $ from 'jquery';
import Swal from 'sweetalert2';
import Footer from './Footer';
export default function Contact(){
  const [submit, setsubmit] = useState(false)
  useEffect(()=>{
    },[])
return (
      <>
     <div>
  <Header />
<main>
  {/* section content begin */}
  <div className="uk-section uk-padding-remove-vertical uk-margin-medium-bottom">
    <div className="uk-container">
      <div className="uk-grid">
        <div className="uk-width-1-1">
          <div className="uk-card uk-card-default uk-border-rounded uk-background-center uk-background-contain uk-background-image@m" style={{backgroundImage: 'url(img/blockit/in-team-background-1.png)'}} data-uk-parallax="bgy: -100">
            <div className="uk-card-body">
              <div className="uk-grid uk-flex uk-flex-center">
                <div className="uk-width-3-4@m uk-text-left">
                  <h2>TERMS AND CONDITIONS</h2>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>Introduction</h3>
                  <p>
                  These terms and conditions govern the use of the Finakin website (https://www.finakin.com) and any related products, services, or applications offered by Finakin. By accessing or using the website or any of our products or services, you agree to be bound by these terms and conditions.
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>Use of the Website</h3>
                  <p>
                  You may use the Finakin website for personal, non-commercial purposes only. You may not use the website for any unlawful or unauthorized purpose, and you may not interfere with or disrupt the website or any related services. You agree to comply with all applicable laws and regulations while using the website.
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>Ownership and Intellectual Property</h3>
                  <p>
                  The Finakin website and all related products, services, and applications are owned and operated by Finakin. All content, trademarks, logos, and other intellectual property displayed on the website are the property of Finakin or its licensors. You may not copy, modify, distribute, or use any of this content without the express written permission of Finakin.
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>Data Privacy</h3>
                  <p>
                  Finakin respects your privacy and is committed to protecting your personal information. Our privacy policy, which can be found on our website, outlines how we collect, use, and protect your personal data.
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>Disclaimer of Warranties</h3>
                  <p>
                  Finakin makes no representations or warranties of any kind, express or implied, regarding the website or any related products, services, or applications. We do not guarantee the accuracy or completeness of any information provided on the website, and we are not responsible for any errors or omissions in such information.
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>Limitation of Liability</h3>
                  <p>
                  Finakin shall not be liable for any damages, whether direct or indirect, arising out of or in connection with the use of the website or any related products, services, or applications. This includes, but is not limited to, any loss of profits, revenue, data, or other intangible losses.
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>Indemnification</h3>
                  <p>
                  You agree to indemnify and hold Finakin harmless from any claims, losses, or damages arising out of or in connection with your use of the website or any related products, services, or applications.
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>Modification of Terms and Conditions</h3>
                  <p>
                  Finakin reserves the right to modify these terms and conditions at any time without prior notice. Your continued use of the website or any related products, services, or applications following such modifications constitutes your acceptance of the revised terms and conditions.
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>Governing Law and Jurisdiction</h3>
                  <p>
                  These terms and conditions shall be governed by and construed in accordance with the laws of India. Any disputes arising out of or in connection with these terms and conditions shall be subject to the exclusive jurisdiction of the courts in Mumbai, India.
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>Contact Information</h3>
                  <p>
                  If you have any questions or concerns regarding our privacy policy, please contact us at support@finakin.com.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* section content end */}
  {/* section content begin */}
  {/* <div className="uk-section in-counter-2">
    <div className="uk-container">
      <div className="uk-grid uk-flex uk-flex-left">
        <div className="uk-width-3-4@m">
          <div className="uk-grid uk-flex uk-flex-middle" data-uk-grid>
            <div className="uk-width-1-2@m">
              <h4 className="uk-text-muted">Number speaks</h4>
              <h1 className="uk-margin-medium-bottom">We always ready<br />for a challenge.</h1>
              <a href="#" className="uk-button uk-button-primary uk-border-rounded">Learn more<i className="fas fa-arrow-circle-right uk-margin-small-left" /></a>
            </div>
            <div className="uk-width-1-2@m">
              <div className="uk-margin-large" data-uk-grid>
                <div className="uk-width-1-3@m">
                  <h1 className="uk-text-primary uk-text-right@m">
                    <span className="count" data-counter-end={410}>0</span>
                  </h1>
                  <hr className="uk-divider-small uk-text-right@m" />
                </div>
                <div className="uk-width-expand@m">
                  <h3>Trading instruments</h3>
                  <p>Reprehenderit qui in svoluptate velit esse quam molestiae vel illum qui dolorem eum fugiat quo.</p>
                </div>
              </div>
              <div className="uk-margin-large" data-uk-grid>
                <div className="uk-width-1-3@m">
                  <h1 className="uk-text-primary uk-text-right@m">
                    <span className="count" data-counter-end={27}>0</span>
                  </h1>
                  <hr className="uk-divider-small uk-text-right@m" />
                </div>
                <div className="uk-width-expand@m">
                  <h3>Countries covered</h3>
                  <p>At vero eos et accusamus iusto dignissimos ducimus qui blanditiis praesentium voluta deleniti.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> */}
  {/* section content end */}
</main>


  <Footer />
  </div>
      </>
    )
  }
