import React, { useEffect,useState } from 'react'
import Header from '../../common/Header';
import axios from 'axios';
import {api_url} from '../../common/Helpers';
import $ from 'jquery';
import Swal from 'sweetalert2';
import Footer from '../../common/Footer';
import Highcharts from 'highcharts';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import FeatherIcon from 'feather-icons-react';
export default function Contact(){
  const [key, setKey] = useState('home');
  const [submit, setsubmit] = useState(false)
  const [load, setload] = useState(false)
  const [postitivecount, setpostitivecount] = useState(0)
  const [positivecountpoint, setpositivecountpoint] = useState(0)
  const [negativecount, setnegativecount] = useState(0)
  const [negativecountpoint, setnegativecountpoint] = useState(0)
  const [finalpoint, setfinalpoint] = useState(0)

  const [postitivecountbank, setpostitivecountbank] = useState(0)
  const [positivecountpointbank, setpositivecountpointbank] = useState(0)
  const [negativecountbank, setnegativecountbank] = useState(0)
  const [negativecountpointbank, setnegativecountpointbank] = useState(0)
  const [finalpointbank, setfinalpointbank] = useState(0)
  useEffect(()=>{
    fetchdatas();
  },[])
  const fetchdatas = async () =>{
    await axios.get(api_url+'gethomedata').then(({data})=>{
      setfinalpoint(Number(data.nchange))
      setfinalpointbank(Number(data.bnchange))
    })
    await axios.get(api_url+`getniftycontributornew`).then(({data})=>{
      let maleData = [];
      let femaleData = [];

      let positiveSymbols = [];
      let negativeSymbols = [];

      let maleDataAll = [];
      let femaleDataAll = [];

      let positivecount = 0;
      let negativecount = 0;
       for (let i = 0; i < data.length; i++) {
        let symbolObj = data[i];
        if (symbolObj.pointchange > 0) {
          positiveSymbols.push(symbolObj.symbol);
          maleData.push(symbolObj.pointchange);
          positivecount = positivecount+symbolObj.pointchange;
          maleDataAll.push(symbolObj);
        }else{
          negativeSymbols.push(symbolObj.symbol);
          femaleData.push(Math.abs(symbolObj.pointchange));
          negativecount = negativecount+symbolObj.pointchange;
          // console.log(symbolObj)
          femaleDataAll.push(symbolObj);
        }
      }
      console.log(femaleDataAll)
      const maxLength = Math.max(maleData.length, femaleData.length);
      setpostitivecount(positiveSymbols.length)
      setpositivecountpoint(positivecount);
      setnegativecount(negativeSymbols.length)
      setnegativecountpoint(negativecount)
      while (maleData.length < maxLength) {
        maleData.push('');
        maleDataAll.push('');
      }
      // console.log(femaleDataAll)
      while (femaleData.length < maxLength) {
        femaleData.push('');
        femaleDataAll.push('');
      }
      positiveSymbols.reverse()
      // Add a dummy value of 0 to femaleData if it's shorter than maleData
      if (maleData.length > femaleData.length) {
        femaleData.push('');
      }
      femaleData = femaleData.map(value => -1 * value);
      // Sort both arrays in descending order
      maleData.sort((a, b) => b - a);
      femaleData.sort((a, b) => b - a);

      if (maleData.length > femaleData.length) {
        maleData.reverse();
      } else {
        femaleData.reverse();
      }
      const maxLength2 = Math.max(positiveSymbols.length, negativeSymbols.length);
      const diff1 = maxLength2 - positiveSymbols.length;
      const diff2 = maxLength2 - negativeSymbols.length;

      for (let i = 0; i < diff1; i++) {
        positiveSymbols.unshift('');
      }

      for (let i = 0; i < diff2; i++) {
        negativeSymbols.unshift('');
      }
      maleDataAll.reverse()

      Highcharts.chart('container', {
      chart: {
          type: 'bar'
      },
      title: {
          text: '',
          align: 'center'
      },
      accessibility: {
          point: {
              valueDescriptionFormat: '{index}. Age {xDescription}, {value}%.'
          }
      },
      xAxis: [{
          categories: positiveSymbols,
          reversed: false,
          labels: {
              step: 1
          },
          accessibility: {
              description: 'Age (male)'
          }
      }, { // mirror axis on right side
          opposite: true,
          reversed: false,
          categories: negativeSymbols,
          linkedTo: 0,
          labels: {
              step: 1
          },
          accessibility: {
              description: 'Age (female)'
          }
      }],
      yAxis: {
        reversed: true,
          title: {
              text: null
          },
          labels: {
              formatter: function () {
                  return Math.abs(this.value);
              }
          },
          accessibility: {
              description: 'Percentage population',
              rangeDescription: 'Range: 0 to 5%'
          }
      },

      plotOptions: {
          series: {
              stacking: 'normal'
          }
      },
      tooltip: {
        formatter: function() {
          const isNegative = this.point.y > 0;
          const symbolObj = isNegative ? maleDataAll[this.point.index] : femaleDataAll[this.point.index];
          const symbolName = isNegative ? positiveSymbols[this.point.index] : negativeSymbols[this.point.index];
          return '<b>' + symbolName + '</b><br/>' +
            'Contribution: ' + (isNegative ? '' : '-') + Highcharts.numberFormat(Math.abs(this.point.y), 2) + '<br/>';
        }
      },          
      series: [{
        name: 'Positive',
        data: maleData.reverse(),
        color: 'green'
      }, {
        name: 'Negative',
        data: femaleData.reverse(),
        color: 'red'
      }]
      });
        })
    await axios.get(api_url+`getbankniftycontributornew`).then(({data})=>{
        let maleData = [];
        let femaleData = [];

        let positiveSymbols = [];
        let negativeSymbols = [];

        let positivecount = 0;
        let negativecount = 0;
        for (let i = 0; i < data.length; i++) {
          let symbolObj = data[i];

          if (symbolObj.pointchange > 0) {
            positiveSymbols.push(symbolObj.symbol);
            maleData.push(symbolObj.pointchange);
            positivecount = positivecount+symbolObj.pointchange;
          } else {
            negativeSymbols.push(symbolObj.symbol);
            femaleData.push(Math.abs(symbolObj.pointchange));
            negativecount = negativecount+symbolObj.pointchange;
          }
        }
      let bankconlen = negativeSymbols.length;  
      const maxLength2 = Math.max(positiveSymbols.length, negativeSymbols.length);
      const diff1 = maxLength2 - positiveSymbols.length;
      const diff2 = maxLength2 - negativeSymbols.length;

      setpostitivecountbank(positiveSymbols.length);
        setpositivecountpointbank(positivecount);
        setnegativecountbank(bankconlen)
        setnegativecountpointbank(negativecount)
        // setfinalpointbank(Number(positivecount-Math.abs(negativecount).toFixed(2)))
      for (let i = 0; i < diff1; i++) {
        positiveSymbols.unshift('');
      }

      for (let i = 0; i < diff2; i++) {
        negativeSymbols.unshift('');
      }
        // Make the lengths of the two arrays equal
        const maxLength = Math.max(maleData.length, femaleData.length);
        while (maleData.length < maxLength) {
          maleData.push(0);
        }
        while (femaleData.length < maxLength) {
          femaleData.push(0);
        }
        // Add a dummy value of 0 to femaleData if it's shorter than maleData
        if (maleData.length > femaleData.length) {
          femaleData.push(0);
        }
        femaleData = femaleData.map(value => -1 * value);
        // Sort both arrays in descending order
        maleData.sort((a, b) => b - a);
        femaleData.sort((a, b) => b - a);

        if (maleData.length > femaleData.length) {
          maleData.reverse();
        } else {
          femaleData.reverse();
        }

        
Highcharts.chart('containerbanknifty', {
  chart: {
      type: 'bar'
  },
  title: {
      text: '',
      align: 'left'
  },
  accessibility: {
      point: {
          valueDescriptionFormat: '{index}. Age {xDescription}, {value}%.'
      }
  },
  xAxis: [{
      categories: positiveSymbols,
      reversed: false,
      labels: {
          step: 1
      },
      accessibility: {
          description: 'Age (male)'
      }
  }, { // mirror axis on right side
      opposite: true,
      reversed: false,
      categories: negativeSymbols,
      linkedTo: 0,
      labels: {
          step: 1
      },
      accessibility: {
          description: 'Age (female)'
      }
  }],
  yAxis: {
    reversed: true,
      title: {
          text: null
      },
      labels: {
          formatter: function () {
              return Math.abs(this.value);
          }
      },
      accessibility: {
          description: 'Percentage population',
          rangeDescription: 'Range: 0 to 5%'
      }
  },

  plotOptions: {
      series: {
          stacking: 'normal'
      }
  },
  tooltip: {
    formatter: function() {
      const isNegative = this.point.y > 0;
      const symbolObj = data[this.point.index];
      const symbolName = isNegative ? positiveSymbols[this.point.index] : negativeSymbols[this.point.index];
      return '<b>' + symbolName + '</b><br/>' +
        'Contribution: ' + (isNegative ? '' : '-') + Highcharts.numberFormat(Math.abs(this.point.y), 2) + '<br/>' ;
    }
  },  
  series: [{
    name: 'Positive',
    data: maleData.reverse(),
    color: 'green'
  }, {
    name: 'Negative',
    data: femaleData.reverse(),
    color: 'red'
  }]
});
    })
  }
return (
      <>
     <div>
  <Header />
 <main>
  {/* section content begin */}
  <div className="uk-section uk-margin-small-top">
    <div className="uk-container">
    <div className="uk-container" style={{marginTop: '1.4rem'}}>
      <div className="row">
        <div className="col-12">
          
          <div className="uk-card uk-card-default uk-card-body uk-border-rounded" style={{
            margin: 0,
            padding: 0,
            border: '1px solid #C17951',
            borderRadius: '0px',
            }}>
          <div className="uk-text-center">
            <h2 style={{background: "#FDD965",padding: "3px",marginBottom: "0px"}}>Index Contributors</h2>
          </div>
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="ps-1"
          >
          <Tab eventKey="home" title="Nifty 50">
          <div className='uk-text-center d-flex justify-content-between px-3 my-3'>
          <div><h5 style={{margin: 0,color: 'green',border: '1px solid rgb(23,96,129)',borderRadius: '5px',padding: '10px 8px'}}>
          Positive Contributors <span style={{color: 'black'}}>({parseFloat(postitivecount.toFixed(2))})</span> +{parseFloat(positivecountpoint.toFixed(2))}</h5></div>
          <div><h3 style={{margin: 0}}>NIFTY 50 ({Math.sign(finalpoint) == -1 ?
                                               <span style={{color: 'red'}}>{finalpoint}</span> 
                                              :
                                              <span style={{color: 'green'}}>+{finalpoint}</span> 
                                              })</h3></div>
          <div><h5 style={{margin: 0,color: 'red',border: '1px solid rgb(23,96,129)',borderRadius: '5px',padding: '10px 8px'}}>
          Negative Contributors <span style={{color: 'black'}}>({parseFloat(negativecount.toFixed(2))})</span> {parseFloat(negativecountpoint.toFixed(2))}</h5></div>
          </div>
              <figure class="highcharts-figure">
                <div id="container" style={{height: '1500px'}}></div>
              </figure>
          </Tab>
          <Tab eventKey="profile" title="Bank Nifty">
          <div className='uk-text-center d-flex justify-content-between px-3 my-3'>
          <div><h5 style={{margin: 0,color: 'green',border: '1px solid rgb(23,96,129)',borderRadius: '5px',padding: '10px 8px'}}>
          Positive Contributors <span style={{color: 'black'}}>({parseFloat(postitivecountbank.toFixed(2))})</span> +{parseFloat(positivecountpointbank.toFixed(2))}</h5></div>
          <div><h3 style={{margin: 0}}>NIFTY BANK ({Math.sign(finalpointbank) == -1 ?
                                               <span style={{color: 'red'}}>{finalpointbank}</span> 
                                              :
                                              <span style={{color: 'green'}}>+{finalpointbank}</span> 
                                              })
              </h3>
          </div>
          <div><h5 style={{margin: 0,color: 'red',border: '1px solid rgb(23,96,129)',borderRadius: '5px',padding: '10px 8px'}}>
          Negative Contributors <span style={{color: 'black'}}>({parseFloat(negativecountbank.toFixed(2))})</span> {parseFloat(negativecountpointbank.toFixed(2))}</h5></div>
          </div>
              <figure class="highcharts-figure">
                <div id="containerbanknifty" style={{height: '530px'}}></div>
              </figure>
          </Tab>
        </Tabs>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</main>



  <Footer />
  </div>
      </>
    )
  }
