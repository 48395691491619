import React, { useEffect,useState } from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Header from '../../common/Header';
import axios from 'axios';
import {api_url,formatdmY} from '../../common/Helpers';
import '../../common/style.css'
import $ from 'jquery';
import Swal from 'sweetalert2';
import Footer from '../../common/Footer';
import Highcharts from 'highcharts/highstock'
import Select from "react-select";
export default function Straddle(){
  const [submit, setsubmit] = useState(false);
  const [key, setKey] = useState('home');
  const [load, setload] = useState(false);
  const [counter, setCounter] = useState(0);
  const [expirevalue,setexpirevalue] = useState("")
  const [expirevaluebank,setexpirevaluebank] = useState("")
  const [allstrikenifty, setallstrikenifty]= useState([{ value:'',label:''}]);
  const [allstrikebanknifty, setallstrikebanknifty]= useState([{ value:'',label:''}]);
   // Add state variable for FINNIFTY
  const [expirevalueFinnifty, setExpirevalueFinnifty] = useState('');
  const [allstrikefinnifty, setallstrikefinnifty] = useState([{ value: '', label: '' }]);
  const [expload, setexpload] = useState(true);
  const [nifyt50expiry, setnifyt50expiry] = useState([
    { value: "", label: "" },
  ]);
  //// THIS SECTION IS FOR BANK NIFTY
  const [bankniftyexpiry, setbankniftyexpiry] = useState([
    { value: "", label: "" },
  ]);
  // THIS SECTION IS FOR FINNIFTY
  // Step 3: Create state for Finnifty data and expiry date
  const [finniftyExpiry, setfinniftyExpiry] = useState([
    { value: "", label: "" },
  ]);
  const fetchexpdatas = async () => {
    await axios.get(api_url + `getalloptionchainexplist`).then(({ data }) => {
      // setexpirevalue(data[0].label)
      // fetchdataslatest(data[0].value)
      let arr = [];
      if (data.FINNIFTY.length > 0) {
        for (let x in data.FINNIFTY) {
          // if(x == 0){
          //   getOptionChainFinniftyLatest(data.FINNIFTY[x])
          // }
          let obj = {
            value: data.FINNIFTY[x],
            label: formatdmY(data.FINNIFTY[x]),
          };
          arr.push(obj);
        }
      }
      setfinniftyExpiry(arr);
      let arr1 = [];
      if (data.NIFTY.length > 0) {
        for (let x in data.NIFTY) {
          // if(x == 0){
          //   getOptionChainnifty50Latest(data.NIFTY[x])
          // }
          let obj = { value: data.NIFTY[x], label: formatdmY(data.NIFTY[x]) };
          arr1.push(obj);
        }
      }
      setnifyt50expiry(arr1);
      let arr2 = [];
      if (data.BANKNIFTY.length > 0) {
        for (let x in data.BANKNIFTY) {
          // if(x == 0){
          //   getoptionchainbankniftyLatest(data.BANKNIFTY[x])
          // }
          let obj = {
            value: data.BANKNIFTY[x],
            label: formatdmY(data.BANKNIFTY[x]),
          };
          arr2.push(obj);
        }
      }
      setbankniftyexpiry(arr2);
    });
    setexpload(false);
  };
  // Step 4: Fetch Finnifty option chain data with a specific expiry date
  const getOptionChainnifty50Latest = async (expirydate) => {
    await axios
      .get(api_url + `getoptionchainlatests/` + expirydate)
      .then(({ data }) => {
        console.log(data);
      });
  };
  const getoptionchainbankniftyLatest = async (expirydate) => {
    await axios
      .get(api_url + `getoptionchainbankniftylatest/` + expirydate)
      .then(({ data }) => {
        console.log(data);
      });
  };
  const getOptionChainFinniftyLatest = async (expirydate) => {
    await axios
      .get(api_url + `getoptionchainfinniftylatest/` + expirydate)
      .then(({ data }) => {
        console.log(data);
      });
  };
  
  useEffect(() => {
    fetchexpdatas();
  }, []);
  useEffect(() => {
    fetchdata()
    const timer = setTimeout(() => {
      setCounter(counter + 1);
      fetchdata()
    }, 120000);

    return () => {
      clearTimeout(timer);
    };

  }, [counter]);
  const fetchdata = async () => {
    setload(true);
    await axios.get(api_url + 'getallstrikewithspot').then(async ({ data }) => {
      setexpirevalue(data.niftycurrentstrike.replace('.00', ''));
      fetchdataslatestupdate(data.niftycurrentstrike, 'nifty50');
      setexpirevaluebank(data.bankniftycurrentstrike.replace('.00', ''));
      fetchdataslatestupdate(data.bankniftycurrentstrike, 'banknifty');
      setExpirevalueFinnifty(data.finniftycurrentstrike.replace('.00', '')); // Update FINNIFTY strike
      fetchdataslatestupdate(data.finniftycurrentstrike, 'finnifty'); // Fetch data for FINNIFTY
  
      let arr = [];
      let arrbank = [];
      let arrfin = [];
  
      if (data.allnifty50strike.length > 0) {
        for (let x in data.allnifty50strike) {
          let obj = { value: data.allnifty50strike[x].strike, label: data.allnifty50strike[x].strike.replace('.00', '') };
          arr.push(obj);
        }
      }
  
      if (data.allbankniftystrike.length > 0) {
        for (let x in data.allbankniftystrike) {
          let obj2 = { value: data.allbankniftystrike[x].strike, label: data.allbankniftystrike[x].strike.replace('.00', '') };
          arrbank.push(obj2);
        }
      }
  
      if (data.allfinniftystrike.length > 0) { // Check if FINNIFTY data exists
        for (let x in data.allfinniftystrike) {
          let obj3 = { value: data.allfinniftystrike[x].strike, label: data.allfinniftystrike[x].strike.replace('.00', '') };
          arrfin.push(obj3);
        }
      }
  
      setallstrikenifty(arr);
      setallstrikebanknifty(arrbank);
      setallstrikefinnifty(arrfin); // Set FINNIFTY strike options
    });
  
    setload(false);
  };
  
  const getexpiredate=(e,indexname)=>{
    const dataid= e.value;
    fetchdataslatestupdate(dataid,indexname)
  }
  const fetchdataslatestupdate = async (cstrike,indexname) =>{
    if(indexname == 'nifty50'){
      await axios.get(api_url+'getstraddledata/'+indexname+'/'+cstrike).then(({ data }) => {
        let allnifty50price = [];
        let allnifty50spotprice = [];
        let alltime = [];
        const priceLength = data.nifty50.length;
        const spotpriceLength = data.spotprice.length;
        const pricelength = data.time.length;
        const maxLength = Math.min(priceLength, spotpriceLength);

        // Push the latest data from both arrays
        allnifty50price.push(...data.nifty50.slice(priceLength - maxLength));
        allnifty50spotprice.push(...data.spotprice.slice(spotpriceLength - maxLength));
        alltime.push(...data.time.slice(pricelength - maxLength));
        // If one of the arrays is longer than the other, remove elements from the beginning until both have the same length
        while (allnifty50price.length > allnifty50spotprice.length) {
          allnifty50price.splice(0, 1);
        }
        while (allnifty50spotprice.length > allnifty50price.length) {
          allnifty50spotprice.splice(0, 1);
        }
        while (alltime.length > allnifty50price.length) {
          alltime.splice(0, 1);
        }
        allnifty50price = allnifty50price.map(Number);        
        allnifty50spotprice = allnifty50spotprice.map(Number);

        Highcharts.chart('containerniftystraddle', {
          chart: {
            type: 'spline'
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: alltime
          },
          yAxis: [{ // Primary yAxis
            labels: {
              format: '{value}'
            },
            title: {
              text: 'Price',
            },
            opposite: false
          }, { // Secondary yAxis
            gridLineWidth: 0,
            title: {
              text: 'Spot Price',
            },
            labels: {
              format: '{value}'
            },
            opposite: true
          }],
          tooltip: {
            shared: true,
            pointFormatter: function() {
              var seriesName = this.series.name;
              var valuePrefix = seriesName === 'Spot Price' ? '' : '';
              var valueSuffix = seriesName === 'Price' ? ' ' : '';
              return '<div style="color:' + this.color + '">' + seriesName + '</div>: </b>' + valuePrefix+ Highcharts.numberFormat(this.y, 2)+'<br>' + valueSuffix + '</b>';
            }
          },
          plotOptions: {
            line: {
              marker: {
                enabled: false
              }
            },
            spline: {
              marker: {
                enabled: false
              }
            }
          },
          series: [{
            name: 'Price',
            type: 'spline', // Modified type from 'line' to 'spline'
            yAxis: 0,
            data: allnifty50price,
            tooltip: {
              valueSuffix: ' %'
            }
          },
          {
            name: 'Spot Price',
            type: 'spline',
            yAxis: 1,
            data: allnifty50spotprice,
            tooltip: {
              valuePrefix: '$'
            }
          }]
        });
    });
    }
    else if (indexname === 'banknifty') {
      await axios.get(api_url + 'getstraddledata/' + indexname + '/' + cstrike).then(({ data }) => {
        let allbankniftytime = [];
        let allbankniftyprice = [];
        let allbankniftyspotprice = [];
        const priceLength = data.banknifty.length;
        const spotpriceLength = data.spotprice.length;
        const pricelength = data.time.length;
        const maxLength = Math.min(priceLength, spotpriceLength);
    
        // Push the latest data from both arrays
        allbankniftyprice.push(...data.banknifty.slice(priceLength - maxLength));
        allbankniftyspotprice.push(...data.spotprice.slice(spotpriceLength - maxLength));
        allbankniftytime.push(...data.time.slice(pricelength - maxLength));
    
        // If one of the arrays is longer than the other, remove elements from the beginning until both have the same length
        while (allbankniftyprice.length > allbankniftyspotprice.length) {
          allbankniftyprice.splice(0, 1);
        }
        while (allbankniftyspotprice.length > allbankniftyprice.length) {
          allbankniftyspotprice.splice(0, 1);
        }
        while (allbankniftytime.length > allbankniftyprice.length) {
          allbankniftytime.splice(0, 1);
        }
    
        allbankniftyprice = allbankniftyprice.map(Number);
        allbankniftyspotprice = allbankniftyspotprice.map(Number);
    
        Highcharts.chart('containerbankniftystraddle', {
          chart: {
            type: 'spline'
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: allbankniftytime
          },
          yAxis: [
            {
              labels: {
                format: '{value}'
              },
              title: {
                text: 'Price'
              },
              opposite: false
            },
            {
              gridLineWidth: 0,
              title: {
                text: 'Spot Price'
              },
              labels: {
                format: '{value}'
              },
              opposite: true
            }
          ],
          tooltip: {
            shared: true,
            pointFormatter: function () {
              var seriesName = this.series.name;
              var valuePrefix = seriesName === 'Spot Price' ? '' : '';
              var valueSuffix = seriesName === 'Price' ? ' ' : '';
              return (
                '<div style="color:' +
                this.color +
                '">' +
                seriesName +
                '</div>: </b>' +
                valuePrefix +
                Highcharts.numberFormat(this.y, 2) +
                '<br>' +
                valueSuffix +
                '</b>'
              );
            }
          },
          plotOptions: {
            line: {
              marker: {
                enabled: false
              }
            },
            spline: {
              marker: {
                enabled: false
              }
            }
          },
          series: [
            {
              name: 'Price',
              type: 'spline',
              yAxis: 0,
              data: allbankniftyprice,
              tooltip: {
                valueSuffix: ' %'
              }
            },
            {
              name: 'Spot Price',
              type: 'spline',
              yAxis: 1,
              data: allbankniftyspotprice,
              tooltip: {
                valuePrefix: '$'
              }
            }
          ]
        });
      });
    }

    else if (indexname === 'finnifty') {
      await axios.get(api_url + 'getstraddledata/' + indexname + '/' + cstrike).then(({ data }) => {
        let allfinniftytime = [];
        let allfinniftyprice = [];
        let allfinniftyspotprice = [];
        const priceLength = data.finnifty.length;
        const spotpriceLength = data.spotprice.length;
        const pricelength = data.time.length;
        const maxLength = Math.min(priceLength, spotpriceLength);
    
        // Push the latest data from both arrays
        allfinniftyprice.push(...data.finnifty.slice(priceLength - maxLength));
        allfinniftyspotprice.push(...data.spotprice.slice(spotpriceLength - maxLength));
        allfinniftytime.push(...data.time.slice(pricelength - maxLength));
    
        // If one of the arrays is longer than the other, remove elements from the beginning until both have the same length
        while (allfinniftyprice.length > allfinniftyspotprice.length) {
          allfinniftyprice.splice(0, 1);
        }
        while (allfinniftyspotprice.length > allfinniftyprice.length) {
          allfinniftyspotprice.splice(0, 1);
        }
        while (allfinniftytime.length > allfinniftyprice.length) {
          allfinniftytime.splice(0, 1);
        }
    
        allfinniftyprice = allfinniftyprice.map(Number);
        allfinniftyspotprice = allfinniftyspotprice.map(Number);
    
        Highcharts.chart('containerfinniftystraddle', {
          chart: {
            type: 'spline'
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: allfinniftytime
          },
          yAxis: [
            {
              labels: {
                format: '{value}'
              },
              title: {
                text: 'Price'
              },
              opposite: false
            },
            {
              gridLineWidth: 0,
              title: {
                text: 'Spot Price'
              },
              labels: {
                format: '{value}'
              },
              opposite: true
            }
          ],
          tooltip: {
            shared: true,
            pointFormatter: function () {
              var seriesName = this.series.name;
              var valuePrefix = seriesName === 'Spot Price' ? '' : '';
              var valueSuffix = seriesName === 'Price' ? ' ' : '';
              return (
                '<div style="color:' +
                this.color +
                '">' +
                seriesName +
                '</div>: </b>' +
                valuePrefix +
                Highcharts.numberFormat(this.y, 2) +
                '<br>' +
                valueSuffix +
                '</b>'
              );
            }
          },
          plotOptions: {
            line: {
              marker: {
                enabled: false
              }
            },
            spline: {
              marker: {
                enabled: false
              }
            }
          },
          series: [
            {
              name: 'Price',
              type: 'spline',
              yAxis: 0,
              data: allfinniftyprice,
              tooltip: {
                valueSuffix: ' %'
              }
            },
            {
              name: 'Spot Price',
              type: 'spline',
              yAxis: 1,
              data: allfinniftyspotprice,
              tooltip: {
                valuePrefix: '$'
              }
            }
          ]
        });
      });
    }
    
      
  }
  
return (
      <>
     <div>
  <Header />
  <main>
{/* section content begin */}
<div className="uk-section uk-margin-small-top">
 <div className="uk-container" style={{marginTop: '1rem',maxWidth: '100%',maxWidth: '1400px'}}>
 <Tabs
   id="controlled-tab-example"
   activeKey={key}
   onSelect={(k) => setKey(k)}
 >
 
 <Tab eventKey="home" title="Nifty 50">
 {load?
 <div className="linear-background w-100"> </div>
 :
 <>  
 <div
                        className="d-flex justify-content-between"
                        style={{ padding: "2px 10px" }}
                      >
                        <div style={{ fontWeight: "600" }}></div>
                        
                        <div style={{ fontWeight: "600", minWidth: "410px" }}>
                          {expload ? (
                              <div
                                className="linear-background w-100"
                                style={{ height: "30px" }}
                              >
                                {" "}
                              </div>
                            ) : (
                              <div className="row my-1">
                                <div className="col-8 text-end">
                                  <div style={{ fontWeight: "600" }}>
                                    <label
                                      htmlFor=""
                                      style={{
                                        display: "inline-block",
                                        marginRight: "10px",
                                      }}
                                    >
                                      Expiry
                                    </label>
                                    <Select
                                      style={{ display: "inline-block" }}
                                      defaultValue={{
                                        label: nifyt50expiry[0].label,
                                        value: nifyt50expiry[0].value,
                                      }}
                                      className="text-dark"
                                      required
                                      name="expiredate"
                                      id="cepeexpiredate"
                                      onChange={(e) =>
                                        getOptionChainnifty50Latest(e.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-4">
                                <label
                                  htmlFor=""
                                  style={{
                                    display: "inline-block",
                                    marginRight: "10px",
                                  }}
                                ></label>
                                <Select
                                  style={{ display: "inline-block" }}
                                  defaultValue={{
                                    label: expirevalue,
                                    value: expirevalue,
                                  }}
                                  className="text-dark"
                                  required
                                  name="expiredate"
                                  id="straddleexpiredate"
                                  options={allstrikenifty}
                                  onChange={(e) => getexpiredate(e, "nifty50")}
                                />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

 <div className="row">
        <div className="col-12">
            <div className="uk-card uk-card-default uk-card-body uk-border-rounded" style={{
              margin: 0,
              padding: 0,
              border: '1px solid #C17951',
              borderRadius: '0px',
            }}>
              <h2 style={{background: '#FDF1CC',margin: 0,textAlign: 'center'}}>Straddle</h2>
              <figure class="highcharts-figure">
              <div id="containerniftystraddle" style={{height: '500px'}}></div>
              </figure>
            </div>
        </div>
      </div>
 </>
 }
 </Tab>
   <Tab eventKey="profile" title="Bank Nifty">
   {load?
 <div className="linear-background w-100"> </div>
 :
 <>  
<div
                        className="d-flex justify-content-between"
                        style={{ padding: "2px 10px" }}
                      >
                        <div style={{ fontWeight: "600" }}></div>
                        <div style={{ fontWeight: "600", minWidth: "410px" }}>
                          {expload ? (
                              <div
                                className="linear-background w-100"
                                style={{ height: "30px" }}
                              >
                                {" "}
                              </div>
                            ) : (
                              <div className="row my-1">
                                <div className="col-8 text-end">
                                <div style={{ fontWeight: "600" }}>
                                    <label
                                      htmlFor=""
                                      style={{
                                        display: "inline-block",
                                        marginRight: "10px",
                                      }}
                                    >
                                      Expiry
                                    </label>
                                    <Select
                                      style={{ display: "inline-block" }}
                                      defaultValue={{
                                        label: bankniftyexpiry[0].label,
                                        value: bankniftyexpiry[0].value,
                                      }}
                                      className="text-dark"
                                      required
                                      name="expiredate"
                                      id="expiredate"
                                      onChange={(e) =>
                                        getoptionchainbankniftyLatest(e.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-4">
                                <label
                                    htmlFor=""
                                    style={{
                                      display: "inline-block",
                                      marginRight: "10px",
                                    }}
                                  ></label>
                                  <Select
                                    style={{ display: "inline-block" }}
                                    defaultValue={{
                                      label: expirevaluebank,
                                      value: expirevaluebank,
                                    }}
                                    className="text-dark"
                                    required
                                    name="expiredate"
                                    id="straddleexpiredate"
                                    options={allstrikebanknifty}
                                    onChange={(e) => getexpiredate(e, "banknifty")}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                      </div>
 <div className="row">
        <div className="col-12">
            <div className="uk-card uk-card-default uk-card-body uk-border-rounded" style={{
              margin: 0,
              padding: 0,
              border: '1px solid #C17951',
              borderRadius: '0px',
            }}>
              <h2 style={{background: '#FDF1CC',margin: 0,textAlign: 'center'}}>Straddle</h2>
              <figure class="highcharts-figure">
              <div id="containerbankniftystraddle" style={{height: '500px'}}></div>
              </figure>
            </div>
        </div>
      </div>
 </>
 }
   </Tab>
   <Tab eventKey="finnifty" title="FINNIFTY">
  {load ? (
    <div className="linear-background w-100"> </div>
  ) : (
    <>
<div
                        className="d-flex justify-content-between"
                        style={{ padding: "2px 10px" }}
                      >
                        <div style={{ fontWeight: "600" }}></div>
                        <div style={{ fontWeight: "600", minWidth: "410px" }}>
                          {expload ? (
                              <div
                                className="linear-background w-100"
                                style={{ height: "30px" }}
                              >
                                {" "}
                              </div>
                            ) : (
                              <div className="row my-1">
                                <div className="col-8 text-end">
                                <div style={{ fontWeight: "600" }}>
                                      <label
                                        htmlFor=""
                                        style={{
                                          display: "inline-block",
                                          marginRight: "10px",
                                        }}
                                      >
                                        Expiry
                                      </label>
                                      <Select
                                        style={{ display: "inline-block" }}
                                        defaultValue={{
                                          label: finniftyExpiry[0].label,
                                          value: finniftyExpiry[0].value,
                                        }}
                                        className="text-dark"
                                        required
                                        name="expiredate"
                                        id="expiredate"
                                        onChange={(e) =>
                                          getOptionChainFinniftyLatest(e.value)
                                        }
                                      />
                                  </div>
                                </div>
                                <div className="col-4">
                                <label
                                  htmlFor=""
                                  style={{
                                    display: "inline-block",
                                    marginRight: "10px",
                                  }}
                                ></label>
                                <Select
                                  style={{ display: "inline-block" }}
                                  defaultValue={{
                                    label: expirevalueFinnifty,
                                    value: expirevalueFinnifty,
                                  }}
                                  className="text-dark"
                                  required
                                  name="expiredate"
                                  id="straddleexpiredate"
                                  options={allstrikefinnifty}
                                  onChange={(e) => getexpiredate(e, "finnifty")}
                                />
                                </div>
                              </div>
                            )}
                          </div>
                      </div>
      <div className="row">
        <div className="col-12">
          <div className="uk-card uk-card-default uk-card-body uk-border-rounded" style={{
            margin: 0,
            padding: 0,
            border: '1px solid #C17951',
            borderRadius: '0px',
          }}>
            <h2 style={{ background: '#FDF1CC', margin: 0, textAlign: 'center' }}>Straddle</h2>
            <figure class="highcharts-figure">
              <div id="containerfinniftystraddle" style={{ height: '500px' }}></div>
            </figure>
          </div>
        </div>
      </div>
    </>
  )}
</Tab>

 </Tabs>
 </div>
</div>

{/* section content end */}
</main>
  <Footer />
  </div>
      </>
    )
  }
