import React, { useEffect,useState } from 'react'
import Header from '../common/Header';
import {Link} from 'react-router-dom';
import axios from 'axios';
import {app_url,asseturl,api_url,api_asseturl} from '../common/Helpers';
import $ from 'jquery';
import Footer from '../common/Footer';
import {Pagination} from 'react-laravel-paginex'
import {ShareSocial} from 'react-share-social' 
export default function Contact(){
  const [products, setProducts] = useState([])  
  const [submit, setsubmit] = useState(false)
  const [load, setload] = useState(false)
  const [shownow, setshownow] = useState(false)
  useEffect(()=>{
    fetchdatas();
    // setInterval(fetchdatas, 60000);
  },[])
  const fetchdatas = async () =>{
    setload(true);
    await axios.get(api_url+'getweducation?page=' + 1).then(({data})=>{
      setProducts(data)
      setload(false);
      setshownow(true);
    })
  }
const getData = (data)=>{
  setload(true);
  axios.get(api_url+'getweducation?page=' + data.page).then(({data})=>{
    setProducts(data)
    setload(false);
    setshownow(true);
  })
  $("html, body").animate({scrollTop: 0}, 1000);
}
const options = {
  containerClass: "uk-pagination uk-flex-center uk-margin-medium-top",
  prevButtonClass: "prev-button-class",
}
const socialstyle = {
  // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  borderRadius: 10,
  border: 0,
  color: 'white',
  padding: '0',
  // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  zIndex: 1,
  width: '250px',
  marginTop: '-36px',
  marginLeft: '-212px',
};
function removeTags(str) {
  if ((str===null) || (str===''))
      return false;
  else
      str = str.toString();
  // Regular expression to identify HTML tags in 
  // the input string. Replacing the identified 
  // HTML tag with a null string.
  return str.replace( /(<([^>]+)>)/ig, '');
}
return (
      <>
     <div>
  <Header />
 <main>
  {/* section content begin */}
  <div className="uk-section uk-margin-small-top">
    <div className="uk-container">
    <h1 class="uk-margin-small-top uk-text-left"><span class="in-highlight">Education</span></h1>
      <div className="uk-grid" data-uk-grid style={{marginTop: '50px'}}>
        <div className="uk-width-2-4@m uk-first-column">
          <div className="in-blog-1 uk-grid uk-grid-stack" data-uk-grid>
          {load ?
                      <div className="linear-background w-100"> </div>
                        :
                        <>
                        <div className="uk-section in-offset-top-60 in-offset-top-50@s">
  <div className="uk-container">
    <div className="uk-grid uk-child-width-1-4@m in-testimonial-7" data-uk-grid>

                        {
                                    shownow == true && (
                                        products.data.map((row, key)=>(
                                          <a href={row.singlelink} style={{textDecoration: 'none'}} target="_blank">
                                          <div className="uk-first-column" style={{position: 'relative'}}>
                                            <div className="uk-card uk-card-default uk-box-shadow-small uk-border-rounded" style={{paddingTop: '0',height: '100%',overflow: 'hidden'}}>
                          {row.linkimg != null && row.haverssimg == 0 && row.havetwitterimg == 0 ?
                              <div className="uk-card-media-top">
                                <img src={api_asseturl+'education/'+row.linkimg} style={{width: '100%',height: '200px'}}/>
                              </div>
                            :
                            <>
                            {row.istwitter == 1 && row.havetwitterimg == 1? 
                              <>
                                <div className="uk-card-media-top">
                                  <img src={row.linkimg} style={{width: '100%',height: '200px'}}/>
                                </div>
                              </>
                            :
                              <>
                              {row.haverssimg == 1 ?
                                <>
                                <div className="uk-card-media-top">
                                  <img src={row.linkimg} style={{width: '100%',height: '200px'}}/>
                                </div>
                                </>
                                :
                              <>
                              {row.istwitter == 1 && row.havetwitterimg == 0?
                              <div className="uk-card-media-top">
                                <img src={app_url+'assets/twitterbackground.png'} style={{width: '100%',height: '200px'}}/>
                              </div>
                              :
                              <div className="uk-card-media-top">
                                  {/* <img src={app_url+'assets/noimagefound.png'} style={{width: '100%',height: '200px'}}/> */}
                                </div>
                              } 
                                
                              </>
                            }
                            </>
                          }
                          </>
                          }
                                                <div className="uk-card-body" style={{padding: '0.5rem'}}>
                                                <h5>
                                              <a href={row.singlelink} style={{textDecoration: 'none'}} className="fontnunito" target="_blank">{removeTags(row.singletitle)}</a>
                                              </h5>
                                              <div className="uk-flex uk-flex-middle">
                                              <div>
                                                <i className="fas fa-clock fa-xs" />
                                              </div>
                                              <div>
                                                <span className="uk-text-small uk-text-uppercase uk-text-muted uk-margin-small-left">Date: {row.date}</span>
                                              </div>
                                            </div>
                                                </div>
                                                <div className="uk-card-footer" style={{padding: '0.5rem'}}>
                                                    <footer>{removeTags(row.singledes)}</footer>
                                                </div>
                                            </div>
                                                  {/* <div style={{position: 'absolute',top: '5px',right: '5px'}}>
                                                    <div className="social-share">
                                                      <label className="toggle" htmlFor={'toggle'+key}>
                                                        <input type="checkbox" id={'toggle'+key} />
                                                        <div className="sharebtn">
                                                          <i className="fas fa-share-alt" />
                                                          <i className="fas fa-times" />
                                                          <div className="sharesocial">
                                                          <ShareSocial 
                                                            style={socialstyle}
                                                            url ={row.singlelink}
                                                            socialTypes={['facebook','twitter','reddit','linkedin','line']}
                                                          />
                                                          </div>
                                                        </div>
                                                      </label>
                                                    </div>
                                                  </div> */}
                                          </div>
                                          </a>
                                        ))
                                    )
}                  
    </div>
  </div>
</div></>
          }



          </div> {/* pagination begin */}
          <Pagination changePage={getData} data={products} options={options}/>
          {/* pagination end */}
        </div>
      </div>
    </div>
  </div>
</main>



  <Footer />
  </div>
      </>
    )
  }
