import React, { useEffect,useState } from 'react'
import {Link,useNavigate} from 'react-router-dom';
import Header from './Header';
import axios from 'axios';
import {app_url,asseturl} from './Helpers';
import Select from "react-select";
import Slider from "react-slick";
import $ from 'jquery';
import Swal from 'sweetalert2';
import Footer from './Footer';
export default function Contact(){
  const [submit, setsubmit] = useState(false)
  useEffect(()=>{
    },[])
return (
      <>
     <div>
  <Header />
<main>
  {/* section content begin */}
  <div className="uk-section uk-padding-remove-vertical uk-margin-medium-bottom">
    <div className="uk-container">
      <div className="uk-grid">
        <div className="uk-width-1-1">
          <div className="uk-card uk-card-default uk-border-rounded uk-background-center uk-background-contain uk-background-image@m" style={{backgroundImage: 'url(img/blockit/in-team-background-1.png)'}} data-uk-parallax="bgy: -100">
            <div className="uk-card-body">
              <div className="uk-grid uk-flex uk-flex-center">
                <div className="uk-width-3-4@m uk-text-left">
                  <h2>DISCLAIMER</h2>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>Introduction</h3>
                  <p>
                    The information provided on the Finakin website, products, and services is for educational and informational purposes only. This disclaimer outlines the limitations and disclaimers of liability related to the use of our website, products, and services.
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>No Investment Advice</h3>
                  <p>
                  The information provided on our website, products, and services is not intended to be investment advice. We do not provide personalized investment advice, and you should consult a professional financial advisor before making any investment decisions.
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>No Guarantee of Results</h3>
                  <p>
                  We do not guarantee any specific investment results, and past performance is not indicative of future results. The information provided on our website, products, and services is not a guarantee of success or profitability.
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>No Endorsement or Recommendation</h3>
                  <p>
                  We do not endorse or recommend any specific investments, products, or services. The information provided on our website, products, and services is for educational and informational purposes only, and should not be construed as a recommendation or endorsement of any particular investment.
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>No Warranty of Accuracy or Completeness</h3>
                  <p>
                  We may use cookies and other similar technologies to collect and store information about your preferences and usage of our website. You may disable cookies in your browser settings, but this may affect your ability to use our website and access certain features.
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>No Liability for Third-Party Content</h3>
                  <p>
                  Our website, products, and services may contain links to third-party websites and content. We are not responsible for the content or accuracy of any third-party websites or content, and we do not endorse or recommend any third-party products or services.
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>Limitation of Liability</h3>
                  <p>
                  We are not liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with the use of our website, products, or services. This includes, but is not limited to, damages for loss of profits, business interruption, or loss of information.
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>Changes to Disclaimer</h3>
                  <p>
                  We may update this disclaimer from time to time. Any changes will be posted on our website, and your continued use of our website, products, or services after such changes constitutes your acceptance of the revised disclaimer.
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>Contact Information</h3>
                  <p>
                  If you have any questions or concerns regarding our privacy policy, please contact us at support@finakin.com.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* section content end */}
  {/* section content begin */}
  {/* <div className="uk-section in-counter-2">
    <div className="uk-container">
      <div className="uk-grid uk-flex uk-flex-left">
        <div className="uk-width-3-4@m">
          <div className="uk-grid uk-flex uk-flex-middle" data-uk-grid>
            <div className="uk-width-1-2@m">
              <h4 className="uk-text-muted">Number speaks</h4>
              <h1 className="uk-margin-medium-bottom">We always ready<br />for a challenge.</h1>
              <a href="#" className="uk-button uk-button-primary uk-border-rounded">Learn more<i className="fas fa-arrow-circle-right uk-margin-small-left" /></a>
            </div>
            <div className="uk-width-1-2@m">
              <div className="uk-margin-large" data-uk-grid>
                <div className="uk-width-1-3@m">
                  <h1 className="uk-text-primary uk-text-right@m">
                    <span className="count" data-counter-end={410}>0</span>
                  </h1>
                  <hr className="uk-divider-small uk-text-right@m" />
                </div>
                <div className="uk-width-expand@m">
                  <h3>Trading instruments</h3>
                  <p>Reprehenderit qui in svoluptate velit esse quam molestiae vel illum qui dolorem eum fugiat quo.</p>
                </div>
              </div>
              <div className="uk-margin-large" data-uk-grid>
                <div className="uk-width-1-3@m">
                  <h1 className="uk-text-primary uk-text-right@m">
                    <span className="count" data-counter-end={27}>0</span>
                  </h1>
                  <hr className="uk-divider-small uk-text-right@m" />
                </div>
                <div className="uk-width-expand@m">
                  <h3>Countries covered</h3>
                  <p>At vero eos et accusamus iusto dignissimos ducimus qui blanditiis praesentium voluta deleniti.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> */}
  {/* section content end */}
</main>


  <Footer />
  </div>
      </>
    )
  }
