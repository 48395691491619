import React, { useEffect,useState } from 'react'
import {Link,useNavigate} from 'react-router-dom';
import Header from '../../common/Header';
import axios from 'axios';
import {api_url} from '../../common/Helpers';
import Footer from '../../common/Footer';
export default function Contact(){
  const [submit, setsubmit] = useState(false)
  const [load, setload] = useState(false)
  const [alldata, setalldata] = useState([])

  useEffect(()=>{
    fetchdatas();
  },[])
  const fetchdatas = async () =>{
    setload(true);
    await axios.get(api_url+`getmostactivestocks`).then(({data})=>{
      setalldata(data)
      setload(false);
      // fetchdata();
    })
  }
  const fetchdata = async () =>{
    await axios.get(api_url+`getmostactivestockslatest`).then(({data})=>{
      setalldata(data)
    })
  }
return (
      <>
     <div>
  <Header />
 <main>
  {/* section content begin */}
  <div className="uk-section uk-margin-small-top">
    <div className="uk-container">
    <div className="uk-container" style={{marginTop: '1.4rem'}}>
      <div className="row">
        <div className="col-12">
        <div className="uk-card uk-card-default uk-card-body uk-border-rounded" style={{
          margin: 0,
          padding: 0,
          border: '1px solid #C17951',
          borderRadius: '0px',
          }}>
        <div className='uk-text-center'>
        <h2 style={{background: '#FDD965',margin: 0}}>Most Active Stocks</h2>
        </div>
        {load ?
                      <div className="linear-background w-100"> </div>
                        :
                        <>
        <div className="table-responsive">
        <table class="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center" style={{margin: 0}}>
          <thead>
              <tr>
                  <th class="uk-text-center">Company</th>
                  <th class="uk-text-center">Last Price</th>
                  <th class="uk-text-center">High</th>
                  <th class="uk-text-center">Low</th>
                  <th class="uk-text-center">Change Percent</th>
                  <th class="uk-text-center">Value</th>
              </tr>
          </thead>
          <tbody>
          {
                                        alldata.length > 0 && (
                                          alldata.map((row, key)=>(
                                              <tr>
                                              <td className="text-center pe-0">
                                                <div>{ row.company }</div>
                                              </td>
                                              <td className="text-center pe-0">
                                                <div>{ row.lastprice }</div>
                                              </td>
                                              <td className="text-center pe-0">
                                                <div>{ row.high }</div>
                                              </td>
                                              <td className="text-center pe-0">
                                                <div>{ row.low }</div>
                                              </td>
                                              {Math.sign(row.changepercent) == -1 ?
                                              <td className="text-center pe-0">
                                                <div style={{color: 'red'}}>{ row.changepercent }</div>
                                              </td> 
                                              :
                                              <td className="text-center pe-0">
                                                <div style={{color: 'green'}}>{ row.changepercent }</div>
                                              </td>
                                              }
                                              <td className="text-center pe-0">
                                                <div>{ row.value }</div>
                                              </td>
                                            </tr>
                                            ))
                                        )
    }
          </tbody>
        </table>
        </div>
        </>
        }
        </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</main>



  <Footer />
  </div>
      </>
    )
  }
