import React from 'react'
import {useNavigate} from 'react-router-dom';
import { app_url } from './Helpers';
import FeatherIcon from 'feather-icons-react';
export default function Error() {
  let navigate = useNavigate();
    return (
		<><div className="d-flex flex-column flex-root" id="kt_app_root">
  <style dangerouslySetInnerHTML={{__html: "body {background - image}: url('../../assets/media/auth/bg1.jpg') }[data-theme=\"dark\"]body {background - image}: url('../../assets/media/auth/bg1-dark.jpg'); }" }} />
  <div className="d-flex flex-column flex-center flex-column-fluid">
    <div className="d-flex flex-column flex-center text-center p-10">
      <div className="card card-flush w-lg-650px py-5">
        <div className="card-body py-15 py-lg-20">
          <h1 className="fw-bolder fs-2hx text-gray-900 mb-4">Oops!</h1>
          <div className="fw-semibold fs-6 text-gray-500 mb-7">We can't find that page.</div>
          <div className="mb-3">
            <img src={'/../..'+app_url+'assets/media/auth/404-error.png'} className="mw-100 mh-300px theme-light-show" alt />
            <img src={'/../..'+app_url+'assets/media/auth/404-error-dark.png'} className="mw-100 mh-300px theme-dark-show" alt />
          </div>
          <div className="mb-0">
          <button onClick={() => navigate(-1)} class="btn btn-primary btn-sm">Back <FeatherIcon icon="corner-up-left"  height="18"/></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
        </>
    )
  }
