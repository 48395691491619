import React, { useEffect,useState } from 'react'
import {Link,useNavigate} from 'react-router-dom';
import Header from '../../common/Header';
import axios from 'axios';
import {app_url,asseturl,api_url} from '../../common/Helpers';
import Select from "react-select";
import Slider from "react-slick";
import $ from 'jquery';
import Swal from 'sweetalert2';
import Footer from '../../common/Footer';
import { CSVLink, CSVDownload } from "react-csv";
import FeatherIcon from 'feather-icons-react';
export default function Contact(){
  const [allpremarketdata, setallpremarketdata] = useState([])
  const [tholidaysdata, settholidaysdata] = useState([])
  const [cholidaysdata, setcholidaysdata] = useState([])
  const [submit, setsubmit] = useState(false)
  const [load, setload] = useState(false)
  useEffect(()=>{
    fetchdatas();
  },[])
  const fetchdatas = async () =>{
    await axios.get(api_url+`gettcholidays`).then(({data})=>{
      settholidaysdata(data.tholiday)
      setcholidaysdata(data.choliday)
    })
  }
return (
      <>
     <div>
  <Header />
 <main>
  {/* section content begin */}
  <div className="uk-section uk-margin-small-top">
    <div className="uk-container">
    <div className="row">
                  <div className="col-12">
                  <div className='d-flex justify-content-between' style={{padding: '2px 10px'}}>
                  <div style={{fontWeight: '600'}}></div>
                  <div style={{fontWeight: '600',maxWidth: '300px'}} >
                  </div>
                </div> 
                  </div>
                  <div className="col-12">
                    <div className="row mt-1">
                      <div className="col-6">
                      <div
                      className="uk-card uk-card-default uk-card-body uk-border-rounded"
                      style={{
                        margin: 0,
                        padding: 0,
                        border: "1px solid #C17951",
                        borderRadius: "0px",
                      }}
                    >
                      <div className="uk-text-center">
                        <h2 style={{ background: "#FDD965", margin: 0 }}>
                        Trading Holidays
                        </h2>
                      </div>
                      <table
                        class="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center"
                        style={{ margin: 0 }}
                      >
                        <thead>
                          <tr>
                              <th class="uk-text-center">#</th>
                              <th class="uk-text-center">DATE</th>
                              <th class="uk-text-center">DAY</th>
                              <th class="uk-text-center">DESCRIPTION</th>
                          </tr>
                      </thead>
                      <tbody>
      {
                                    tholidaysdata.length > 0 && (
                                      tholidaysdata.map((row, key)=>(
                                          <tr>
                                          <td data-kt-ecommerce-order-filter="order_id">
                                            <a href="#" className="text-gray-800 text-hover-primary fw-bold">{key+1}</a>
                                          </td>
                                          <td className="text-start pe-0">
                                            <div>{ row.holidaydate }</div>
                                          </td>
                                          <td className="text-start pe-0">
                                            <div>{ row.holidayday }</div>
                                          </td>
                                          <td className="text-start pe-0">
                                            <div>{ row.description }</div>
                                          </td>
                                        </tr>
                                        ))
                                    )
}
      </tbody>
                      </table>
                    </div>
                      </div>
                      <div className="col-6">
                      <div
                      className="uk-card uk-card-default uk-card-body uk-border-rounded"
                      style={{
                        margin: 0,
                        padding: 0,
                        border: "1px solid #C17951",
                        borderRadius: "0px",
                      }}
                    >
                      <div className="uk-text-center">
                        <h2 style={{ background: "#FDD965", margin: 0 }}>
                        Clearing Holidays
                        </h2>
                      </div>
                      <table
                        class="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center"
                        style={{ margin: 0 }}
                      >
                        <thead>
          <tr>
              <th class="uk-text-center">#</th>
              <th class="uk-text-center">DATE</th>
              <th class="uk-text-center">DAY</th>
              <th class="uk-text-center">DESCRIPTION</th>
          </tr>
      </thead>
      <tbody>
      {
                                    cholidaysdata.length > 0 && (
                                      cholidaysdata.map((row, key)=>(
                                          <tr>
                                          <td data-kt-ecommerce-order-filter="order_id">
                                            <a href="#" className="text-gray-800 text-hover-primary fw-bold">{key+1}</a>
                                          </td>
                                          <td className="text-start pe-0">
                                            <div>{ row.holidaydate }</div>
                                          </td>
                                          <td className="text-start pe-0">
                                            <div>{ row.holidayday }</div>
                                          </td>
                                          <td className="text-start pe-0">
                                            <div>{ row.description }</div>
                                          </td>
                                        </tr>
                                        ))
                                    )
      }
      </tbody>
                      </table>
                    </div>
                      </div>
                    </div>
                  </div>
                </div>
  
    </div>
  </div>
  {/* section content end */}
</main>



  <Footer />
  </div>
      </>
    )
  }
