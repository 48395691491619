import React, { useEffect,useState } from 'react'
import {Link,useNavigate} from 'react-router-dom';
import Header from '../../common/Header';
import axios from 'axios';
import {app_url,asseturl,api_url} from '../../common/Helpers';
import Select from "react-select";
import Slider from "react-slick";
import $ from 'jquery';
import Swal from 'sweetalert2';
import Footer from '../../common/Footer';
export default function Home() {
  const [products, setProducts] = useState([])  
  const [load, setload] = useState(false)
  useEffect(()=>{
    fetchdatas();
  //   setInterval(function(){ 
  //     let mainframe = document.getElementsByClassName('inlineblock')
  //     console.log(mainframe);
  // }, 5000);
  },[])
  const fetchdatas = async () =>{
    setload(true)
    axios.get(api_url+`geteconomiccalendar`).then(({data})=>{
      setProducts(data)
      setload(false)
    })
  }
  const today = new Date();
  const options = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };
  const formattedDate = today.toLocaleDateString('en-IN', options);
return (
      <>
     <div>
  <Header />
  <main>
   <div className="uk-section uk-margin-small-top">
    <div className="uk-container">
    <div className="uk-container" style={{marginTop: '1.4rem'}}>
    <div className='uk-text-center' >
    <h2 style={{
          background: '#FDD965',
          padding: '3px',
          margin: 0
    }}>Economic Calendar</h2>
    </div>
    <div className="uk-card uk-card-default uk-card-body uk-border-rounded" style={{
      margin: 0,
      padding: 0,
      border: '1px solid #C17951',
      borderRadius: '0px',
      }}>

        <div className='d-flex justify-content-between px-3'>
          <div>{formattedDate}</div>
          <div></div>
        </div>
{load ?
        <div className="linear-background w-100"> </div>
          :
        <>
         <div className="table-responsive">
         <table class="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center" style={{margin: 0}}>
              <thead>
                  <tr>
                      <th class="uk-text-center">Time</th>
                      <th class="uk-text-center">Country</th>
                      <th class="uk-text-center">Today's Events</th>
                      <th class="uk-text-center">Actual</th>
                      <th class="uk-text-center">Previous</th>
                      <th class="uk-text-center">Consensus</th>
                  </tr>
              </thead>
              <tbody>
              {
                                            products.length > 0 && (
                                              products.map((row, key)=>(
                                                  <tr>
                                                  <td className="text-center pe-0">
                                                    <div>{ row.time }</div>
                                                  </td>
                                                  <td className="text-center pe-0">
                                                    <div>{ row.country }</div>
                                                  </td>
                                                  <td className="text-center pe-0">
                                                    <div>{ row.event }</div>
                                                  </td>
                                                  <td className="text-center pe-0">
                                                    <div>{ row.actual }</div>
                                                  </td>
                                                  <td className="text-center pe-0">
                                                    <div>{ row.previous }</div>
                                                  </td>
                                                  <td className="text-center pe-0">
                                                    <div>{ row.consensus }</div>
                                                  </td>
                                                </tr>
                                                ))
                                            )
        }
              </tbody>
          </table>
         </div>
        </>
}
    </div>
    </div>

    </div>
  </div>
</main>

  <Footer />
  </div>
      </>
      
    )
  }
