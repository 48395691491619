import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Header from "../../common/Header";
import axios from "axios";
import { api_url, MasterTimeline, formatdmY } from "../../common/Helpers";
import Select from "react-select";
import "../../common/style.css";
import $ from "jquery";
import Swal from "sweetalert2";
import Footer from "../../common/Footer";
import Highcharts from "highcharts/highstock";
export default function Contact() {
  const [key, setKey] = useState("home");
  const [submit, setsubmit] = useState(false);
  const [load, setload] = useState(false);
  const [counter, setCounter] = useState(0);
  const [finniftyData, setFinniftyData] = useState([]);
  const [ipcrnifty50, setipcrnifty50] = useState([]);
  const [ipcrbanknifty, setipcrbanknifty] = useState([]);
  const [expload, setexpload] = useState(true);
  //// THIS SECTION IS FOR NIFTY50
  const [nifyt50data, setnifyt50data] = useState([]);
  const [nifyt50expiry, setnifyt50expiry] = useState([
    { value: "", label: "" },
  ]);
  //// THIS SECTION IS FOR BANK NIFTY
  const [bankniftydata, setbankniftydata] = useState([]);
  const [bankniftyexpiry, setbankniftyexpiry] = useState([
    { value: "", label: "" },
  ]);
  // THIS SECTION IS FOR FINNIFTY
  // Step 3: Create state for Finnifty data and expiry date
  const [finniftyExpiry, setfinniftyExpiry] = useState([
    { value: "", label: "" },
  ]);
  // useEffect(()=>{
  //   fetchdatas();
  // },[])
  const fetchexpdatas = async () => {
    await axios.get(api_url + `getalloptionchainexplist`).then(({ data }) => {
      // setexpirevalue(data[0].label)
      // fetchdataslatest(data[0].value)
      let arr = [];
      if (data.FINNIFTY.length > 0) {
        for (let x in data.FINNIFTY) {
          // if(x == 0){
          //   getOptionChainFinniftyLatest(data.FINNIFTY[x])
          // }
          let obj = {
            value: data.FINNIFTY[x],
            label: formatdmY(data.FINNIFTY[x]),
          };
          arr.push(obj);
        }
      }
      setfinniftyExpiry(arr);
      let arr1 = [];
      if (data.NIFTY.length > 0) {
        for (let x in data.NIFTY) {
          // if(x == 0){
          //   getOptionChainnifty50Latest(data.NIFTY[x])
          // }
          let obj = { value: data.NIFTY[x], label: formatdmY(data.NIFTY[x]) };
          arr1.push(obj);
        }
      }
      setnifyt50expiry(arr1);
      let arr2 = [];
      if (data.BANKNIFTY.length > 0) {
        for (let x in data.BANKNIFTY) {
          // if(x == 0){
          //   getoptionchainbankniftyLatest(data.BANKNIFTY[x])
          // }
          let obj = {
            value: data.BANKNIFTY[x],
            label: formatdmY(data.BANKNIFTY[x]),
          };
          arr2.push(obj);
        }
      }
      setbankniftyexpiry(arr2);
    });
    setexpload(false);
  };
  // Step 4: Fetch Finnifty option chain data with a specific expiry date
  const getOptionChainnifty50Latest = async (expirydate) => {
    await axios
      .get(api_url + `getoptionchainlatests/` + expirydate)
      .then(({ data }) => {
        console.log(data);
      });
  };
  const getoptionchainbankniftyLatest = async (expirydate) => {
    await axios
      .get(api_url + `getoptionchainbankniftylatest/` + expirydate)
      .then(({ data }) => {
        console.log(data);
      });
  };
  const getOptionChainFinniftyLatest = async (expirydate) => {
    await axios
      .get(api_url + `getoptionchainfinniftylatest/` + expirydate)
      .then(({ data }) => {
        console.log(data);
      });
  };

  useEffect(() => {
    fetchdata();
    fetchexpdatas();
    const timer = setTimeout(() => {
      setCounter(counter + 1);
      fetchdata();
    }, 180000);

    return () => {
      clearTimeout(timer);
    };
  }, [counter]);
  const fetchdatas = async (indexname) => {
    await axios
      .get(api_url + `getintradaypcr/` + indexname)
      .then(({ data }) => {
        if (indexname == "nifty50") {
          setipcrnifty50(data);
        } else if (indexname == "finnifty") {
          setFinniftyData(data);
        } else {
          setipcrbanknifty(data);
        }
      });
  };
  const fetchdata = async () => {
    await axios.get(api_url + "getpcr").then(({ data }) => {
      if (data.nifty50) {
        Highcharts.chart("containerniftypcr", {
          chart: {
            type: "spline",
          },
          title: {
            text: "",
          },
          xAxis: {
            categories: data.nifty50.time,
          },
          yAxis: [
            {
              // Primary yAxis
              labels: {
                format: "{value}",
              },
              title: {
                text: "PCR",
              },
              opposite: false,
            },
            {
              // Secondary yAxis
              gridLineWidth: 0,
              title: {
                text: "Spot Price",
              },
              labels: {
                format: "{value}",
              },
              opposite: true,
            },
          ],
          tooltip: {
            shared: true,
            useHTML: true,
            pointFormatter: function () {
              var seriesName = this.series.name;
              var valuePrefix = seriesName === "Spot Price" ? "" : "";
              var valueSuffix = seriesName === "PCR" ? " " : "";
              return (
                '<tr><td style="color:' +
                this.color +
                '">' +
                seriesName +
                ': </td><td style="text-align: right"><b>' +
                valuePrefix +
                Highcharts.numberFormat(this.y, 2) +
                valueSuffix +
                "</b></td></tr>"
              );
            },
            headerFormat: "<table>",
            footerFormat: "</table>",
            valueDecimals: 2,
          },
          plotOptions: {
            line: {
              marker: {
                enabled: false,
              },
            },
            spline: {
              marker: {
                enabled: false,
              },
            },
          },
          series: [
            {
              name: "PCR",
              type: "spline",
              yAxis: 0,
              data: data.nifty50.pcr,
              tooltip: {
                valueSuffix: " %",
              },
            },
            {
              name: "Spot Price",
              type: "spline",
              yAxis: 1,
              data: data.nifty50.price,
              tooltip: {
                valuePrefix: "$",
              },
            },
          ],
        });
      }
      if (data.banknifty) {
        Highcharts.chart("containerbank", {
          chart: {
            type: "spline",
          },
          title: {
            text: "",
          },
          xAxis: {
            categories: data.banknifty.time,
          },
          yAxis: [
            {
              // Primary yAxis
              labels: {
                format: "{value}",
              },
              title: {
                text: "PCR",
              },
              opposite: false,
            },
            {
              // Secondary yAxis
              gridLineWidth: 0,
              title: {
                text: "Spot Price",
              },
              labels: {
                format: "{value}",
              },
              opposite: true,
            },
          ],
          tooltip: {
            shared: true,
            pointFormatter: function () {
              var seriesName = this.series.name;
              var valuePrefix = seriesName === "Spot Price" ? "" : "";
              var valueSuffix = seriesName === "PCR" ? " " : "";
              return (
                '<span style="color:' +
                this.color +
                '">' +
                seriesName +
                "</span>: <b>" +
                valuePrefix +
                Highcharts.numberFormat(this.y, 2) +
                valueSuffix +
                "</b>"
              );
            },
          },
          plotOptions: {
            line: {
              marker: {
                enabled: false,
              },
            },
            spline: {
              marker: {
                enabled: false,
              },
            },
          },
          series: [
            {
              name: "PCR",
              type: "spline",
              yAxis: 0,
              data: data.banknifty.pcr,
              tooltip: {
                valueSuffix: " %",
              },
            },
            {
              name: "Spot Price",
              type: "spline",
              yAxis: 1,
              data: data.banknifty.price,
              tooltip: {
                valuePrefix: "$",
              },
            },
          ],
        });
      }
      if (data.finnifty) {
        Highcharts.chart("containerfinnifty", {
          chart: {
            type: "spline",
          },
          title: {
            text: "",
          },
          xAxis: {
            categories: data.finnifty.time,
          },
          yAxis: [
            {
              // Primary yAxis
              labels: {
                format: "{value}",
              },
              title: {
                text: "PCR",
              },
              opposite: false,
            },
            {
              // Secondary yAxis
              gridLineWidth: 0,
              title: {
                text: "Spot Price",
              },
              labels: {
                format: "{value}",
              },
              opposite: true,
            },
          ],
          tooltip: {
            shared: true,
            pointFormatter: function () {
              var seriesName = this.series.name;
              var valuePrefix = seriesName === "Spot Price" ? "" : "";
              var valueSuffix = seriesName === "PCR" ? " " : "";
              return (
                '<span style="color:' +
                this.color +
                '">' +
                seriesName +
                "</span>: <b>" +
                valuePrefix +
                Highcharts.numberFormat(this.y, 2) +
                valueSuffix +
                "</b>"
              );
            },
          },
          plotOptions: {
            line: {
              marker: {
                enabled: false,
              },
            },
            spline: {
              marker: {
                enabled: false,
              },
            },
          },
          series: [
            {
              name: "PCR",
              type: "spline",
              yAxis: 0,
              data: data.finnifty.pcr,
              tooltip: {
                valueSuffix: " %",
              },
            },
            {
              name: "Spot Price",
              type: "spline",
              yAxis: 1,
              data: data.finnifty.price,
              tooltip: {
                valuePrefix: "$",
              },
            },
          ],
        });
      }
      // INTRADAY PCR
    });
    fetchdatas("nifty50");
    fetchdatas("banknifty");
    fetchdatas("finnifty");
  };

  return (
    <>
      <div>
        <Header />
        <main>
          {/* section content begin */}
          <div className="uk-section uk-margin-small-top">
            <div
              className="uk-container"
              style={{ marginTop: "1.4rem", maxWidth: "100%" ,maxWidth: '1400px'}}
            >
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
              >
                <Tab eventKey="home" title="PCR">
                  {load ? (
                    <div className="linear-background w-100"> </div>
                  ) : (
                    <div className="row">
                      <div className="col-12">
                        {load ? (
                          <div className="linear-background w-100"> </div>
                        ) : (
                          <>
                            <div
                              className="uk-card uk-card-default uk-card-body uk-border-rounded"
                              style={{
                                margin: 0,
                                padding: 0,
                                border: "1px solid #C17951",
                                borderRadius: "0px",
                              }}
                            >
                              <div style={{overflow: 'hidden'}}>
                          
                          {expload ? (
                                <div
                                  className="linear-background w-100"
                                  style={{ height: "30px" }}
                                >
                                  {" "}
                                </div>
                              ) : (
                                <div className="row" style={{background: "#FDF1CC"}}>
                                  <div className="col-4">
                                  </div>
                                  <div className="col-4 text-center">
      
                                  <h2
                                    style={{
                                      background: "#FDF1CC",
                                      margin: 0,
                                      textAlign: "center",
                                    }}
                                  >
                                    NIFTY 50
                                  </h2>
                                  </div>
                                  <div className="col-4 text-end">
                                    <div style={{ fontWeight: "600" }}>
                                      <label
                                        htmlFor=""
                                        style={{
                                          display: "inline-block",
                                          marginRight: "10px",
                                        }}
                                      >
                                        Expiry
                                      </label>
                                      <Select
                                        style={{ display: "inline-block" }}
                                        defaultValue={{
                                          label: nifyt50expiry[0].label,
                                          value: nifyt50expiry[0].value,
                                        }}
                                        className="text-dark"
                                        required
                                        name="expiredate"
                                        id="expiredate"
                                        onChange={(e) =>
                                          getOptionChainnifty50Latest(e.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                                </div>
                              <figure class="highcharts-figure">
                                <div
                                  id="containerniftypcr"
                                  style={{ height: "500px" }}
                                ></div>
                              </figure>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="col-12">
                        {load ? (
                          <div className="linear-background w-100"> </div>
                        ) : (
                          <>
                            <div
                              className="uk-card uk-card-default uk-card-body uk-border-rounded my-3"
                              style={{
                                margin: 0,
                                padding: 0,
                                border: "1px solid #C17951",
                                borderRadius: "0px",
                              }}
                            >
                              <div style={{overflow: 'hidden'}}>
                          
                          {expload ? (
                          <div
                            className="linear-background w-100"
                            style={{ height: "30px" }}
                          >
                            {" "}
                          </div>
                        ) : (
                          <div className="row" style={{background: "#FDF1CC"}}>
                            <div className="col-4"></div>
                            <div className="col-4 text-center">
                              
                          <h2
                            style={{
                              margin: 0,
                              textAlign: "center",
                            }}
                          >
                            BANK NIFTY
                          </h2>
                            </div>
                            <div className="col-4 text-end">
                              <div style={{ fontWeight: "600" }}>
                                <label
                                  htmlFor=""
                                  style={{
                                    display: "inline-block",
                                    marginRight: "10px",
                                  }}
                                >
                                  Expiry
                                </label>
                                <Select
                                  style={{ display: "inline-block" }}
                                  defaultValue={{
                                    label: bankniftyexpiry[0].label,
                                    value: bankniftyexpiry[0].value,
                                  }}
                                  className="text-dark"
                                  required
                                  name="expiredate"
                                  id="expiredate"
                                  onChange={(e) =>
                                    getoptionchainbankniftyLatest(e.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        )}
                          </div>
                              <figure class="highcharts-figure">
                                <div
                                  id="containerbank"
                                  style={{ height: "500px" }}
                                ></div>
                              </figure>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="col-12">
                        {load ? (
                          <div className="linear-background w-100"> </div>
                        ) : (
                          <>
                            <div
                              className="uk-card uk-card-default uk-card-body uk-border-rounded"
                              style={{
                                margin: 0,
                                padding: 0,
                                border: "1px solid #C17951",
                                borderRadius: "0px",
                              }}
                            >
                              
                        <div style={{overflow: 'hidden'}}>
                          
                          {expload ? (
                          <div
                            className="linear-background w-100"
                            style={{ height: "30px" }}
                          >
                            {" "}
                          </div>
                        ) : (
                          <div className="row" style={{background: "#FDF1CC"}}>
                            <div className="col-4"></div>
                            <div className="col-4 text-center">
                              
                        <h2
                          style={{
                            background: "#FDF1CC",
                            margin: 0,
                            textAlign: "center",
                          }}
                        >
                          FINNIFTY
                        </h2>
                            </div>
                            <div className="col-4 text-end">
                              <div style={{ fontWeight: "600" }}>
                                <label
                                  htmlFor=""
                                  style={{
                                    display: "inline-block",
                                    marginRight: "10px",
                                  }}
                                >
                                  Expiry
                                </label>
                                <Select
                                style={{ display: "inline-block" }}
                                defaultValue={{
                                  label: finniftyExpiry[0].label,
                                  value: finniftyExpiry[0].value,
                                }}
                                className="text-dark"
                                required
                                name="expiredate"
                                id="expiredate"
                                onChange={(e) =>
                                  getOptionChainFinniftyLatest(e.value)
                                }
                              />
                              </div>
                            </div>
                          </div>
                        )}
                          </div>
                              <figure class="highcharts-figure">
                                <div
                                  id="containerfinnifty"
                                  style={{ height: "500px" }}
                                ></div>
                              </figure>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </Tab>
                <Tab eventKey="profile" title="Intraday PCR">
                  {load ? (
                    <div className="linear-background w-100"> </div>
                  ) : (
                    <>
                      <div
                        className="uk-container"
                        style={{ marginTop: "1.4rem", maxWidth: "100%" ,maxWidth: '1400px'}}
                      >
                        <div className="row">
                          <div className="col-12">
                            {load ? (
                              <div className="linear-background w-100"> </div>
                            ) : (
                              <div className="row">
                                <div className="col-12">
                                  <div
                                    className="d-flex justify-content-between"
                                    style={{ padding: "2px 10px" }}
                                  >
                                    <div style={{ fontWeight: "600" }}></div>
                                    <div
                                      style={{
                                        fontWeight: "600",
                                        maxWidth: "300px",
                                      }}
                                    ></div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="row mt-1">
                                    <div className="col-4">
                                      <div
                                        className="uk-card uk-card-default uk-card-body uk-border-rounded"
                                        style={{
                                          margin: 0,
                                          padding: 0,
                                          border: "1px solid #C17951",
                                          borderRadius: "0px",
                                        }}
                                      >
                                        <div className="uk-text-center">
                                          <h2
                                            style={{
                                              background: "#FDF1CC",
                                              margin: 0,
                                            }}
                                          >
                                            Nifty
                                          </h2>
                                        </div>
                                        <table
                                          class="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center"
                                          style={{ margin: 0 }}
                                        >
                                          <thead>
                                            <tr>
                                              <th class="uk-text-center">
                                                Time
                                              </th>
                                              <th class="uk-text-center">
                                                Call
                                              </th>
                                              <th class="uk-text-center">
                                                Put
                                              </th>
                                              <th class="uk-text-center">
                                                Diff
                                              </th>
                                              <th class="uk-text-center">
                                                PCR
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {ipcrnifty50.length > 0 &&
                                              ipcrnifty50.map((row, key) => (
                                                <tr>
                                                  <td className="text-center pe-0">
                                                    <div>{row.time}</div>
                                                  </td>
                                                  <td className="text-center pe-0">
                                                    <div>
                                                      {Math.abs(row.oichange)}
                                                    </div>
                                                  </td>
                                                  <td className="text-center pe-0">
                                                    <div>
                                                      {Math.abs(row.poichange)}
                                                    </div>
                                                  </td>
                                                  {Math.sign(
                                                    parseFloat(
                                                      Number(
                                                        Math.abs(
                                                          row.poichange
                                                        ) -
                                                          Math.abs(row.oichange)
                                                      ).toFixed(2)
                                                    )
                                                  ) == -1 ? (
                                                    <td className="text-center px-0">
                                                      <div
                                                        style={{ color: "red" }}
                                                      >
                                                        {Number(
                                                          Math.abs(
                                                            row.poichange
                                                          ) -
                                                            Math.abs(
                                                              row.oichange
                                                            )
                                                        ).toFixed(2)}
                                                      </div>
                                                    </td>
                                                  ) : (
                                                    <td className="text-center px-0">
                                                      <div
                                                        style={{
                                                          color: "green",
                                                        }}
                                                      >
                                                        {Number(
                                                          Math.abs(
                                                            row.poichange
                                                          ) -
                                                            Math.abs(
                                                              row.oichange
                                                            )
                                                        ).toFixed(2)}
                                                      </div>
                                                    </td>
                                                  )}
                                                  <td className="text-center pe-0">
                                                    <div>
                                                      {Number(
                                                        Math.abs(
                                                          row.poichange
                                                        ) /
                                                          Math.abs(row.oichange)
                                                      ).toFixed(2)}
                                                    </div>
                                                  </td>
                                                </tr>
                                              ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>

                                    <div className="col-4">
                                      <div
                                        className="uk-card uk-card-default uk-card-body uk-border-rounded"
                                        style={{
                                          margin: 0,
                                          padding: 0,
                                          border: "1px solid #C17951",
                                          borderRadius: "0px",
                                        }}
                                      >
                                        <div className="uk-text-center">
                                          <h2
                                            style={{
                                              background: "#FDF1CC",
                                              margin: 0,
                                            }}
                                          >
                                            Bank Nifty
                                          </h2>
                                        </div>
                                        <table
                                          class="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center"
                                          style={{ margin: 0 }}
                                        >
                                          <thead>
                                            <tr>
                                              <th class="uk-text-center">
                                                Time
                                              </th>
                                              <th class="uk-text-center">
                                                Call
                                              </th>
                                              <th class="uk-text-center">
                                                Put
                                              </th>
                                              <th class="uk-text-center">
                                                Diff
                                              </th>
                                              <th class="uk-text-center">
                                                PCR
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {ipcrbanknifty.length > 0 &&
                                              ipcrbanknifty.map((row, key) => (
                                                <tr>
                                                  <td className="text-center pe-0">
                                                    <div>{row.time}</div>
                                                  </td>
                                                  <td className="text-center pe-0">
                                                    <div>{row.oichange}</div>
                                                  </td>
                                                  <td className="text-center pe-0">
                                                    <div>{row.poichange}</div>
                                                  </td>
                                                  {Math.sign(
                                                    parseFloat(
                                                      Number(
                                                        Math.abs(
                                                          row.poichange
                                                        ) -
                                                          Math.abs(row.oichange)
                                                      ).toFixed(2)
                                                    )
                                                  ) == -1 ? (
                                                    <td
                                                      className="text-center px-0"
                                                      style={{
                                                        padding: "10px 0px",
                                                        fontSize: "12px",
                                                      }}
                                                    >
                                                      <div
                                                        style={{ color: "red" }}
                                                      >
                                                        {Number(
                                                          Math.abs(
                                                            row.poichange
                                                          ) -
                                                            Math.abs(
                                                              row.oichange
                                                            )
                                                        ).toFixed(2)}
                                                      </div>
                                                    </td>
                                                  ) : (
                                                    <td
                                                      className="text-center px-0"
                                                      style={{
                                                        padding: "10px 0px",
                                                        fontSize: "12px",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          color: "green",
                                                        }}
                                                      >
                                                        {Number(
                                                          Math.abs(
                                                            row.poichange
                                                          ) -
                                                            Math.abs(
                                                              row.oichange
                                                            )
                                                        ).toFixed(2)}
                                                      </div>
                                                    </td>
                                                  )}
                                                  <td className="text-center pe-0">
                                                    <div>
                                                      {Number(
                                                        Math.abs(
                                                          row.poichange
                                                        ) /
                                                          Math.abs(row.oichange)
                                                      ).toFixed(2)}
                                                    </div>
                                                  </td>
                                                </tr>
                                              ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>

                                    <div className="col-4">
                                      <div
                                        className="uk-card uk-card-default uk-card-body uk-border-rounded"
                                        style={{
                                          margin: 0,
                                          padding: 0,
                                          border: "1px solid #C17951",
                                          borderRadius: "0px",
                                        }}
                                      >
                                        <div className="uk-text-center">
                                          <h2
                                            style={{
                                              background: "#FDF1CC",
                                              margin: 0,
                                            }}
                                          >
                                            FIN Nifty
                                          </h2>
                                        </div>
                                        <table
                                          class="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center"
                                          style={{ margin: 0 }}
                                        >
                                          <thead>
                                            <tr>
                                              <th class="uk-text-center">
                                                Time
                                              </th>
                                              <th class="uk-text-center">
                                                Call
                                              </th>
                                              <th class="uk-text-center">
                                                Put
                                              </th>
                                              <th class="uk-text-center">
                                                Diff
                                              </th>
                                              <th class="uk-text-center">
                                                PCR
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {finniftyData.length > 0 &&
                                              finniftyData.map((row, key) => (
                                                <tr>
                                                  <td className="text-center pe-0">
                                                    <div>{row.time}</div>
                                                  </td>
                                                  <td className="text-center pe-0">
                                                    <div>{row.oichange}</div>
                                                  </td>
                                                  <td className="text-center pe-0">
                                                    <div>{row.poichange}</div>
                                                  </td>
                                                  {Math.sign(
                                                    parseFloat(
                                                      Number(
                                                        Math.abs(
                                                          row.poichange
                                                        ) -
                                                          Math.abs(row.oichange)
                                                      ).toFixed(2)
                                                    )
                                                  ) == -1 ? (
                                                    <td
                                                      className="text-center px-0"
                                                      style={{
                                                        padding: "10px 0px",
                                                        fontSize: "12px",
                                                      }}
                                                    >
                                                      <div
                                                        style={{ color: "red" }}
                                                      >
                                                        {Number(
                                                          Math.abs(
                                                            row.poichange
                                                          ) -
                                                            Math.abs(
                                                              row.oichange
                                                            )
                                                        ).toFixed(2)}
                                                      </div>
                                                    </td>
                                                  ) : (
                                                    <td
                                                      className="text-center px-0"
                                                      style={{
                                                        padding: "10px 0px",
                                                        fontSize: "12px",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          color: "green",
                                                        }}
                                                      >
                                                        {Number(
                                                          Math.abs(
                                                            row.poichange
                                                          ) -
                                                            Math.abs(
                                                              row.oichange
                                                            )
                                                        ).toFixed(2)}
                                                      </div>
                                                    </td>
                                                  )}
                                                  <td className="text-center pe-0">
                                                    <div>
                                                      {Number(
                                                        Math.abs(
                                                          row.poichange
                                                        ) /
                                                          Math.abs(row.oichange)
                                                      ).toFixed(2)}
                                                    </div>
                                                  </td>
                                                </tr>
                                              ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </Tab>
              </Tabs>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
}
