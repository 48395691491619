import React, { useEffect,useState } from 'react'
import {Link,useNavigate} from 'react-router-dom';
import Header from '../../common/Header';
import axios from 'axios';
import {app_url,asseturl,api_url} from '../../common/Helpers';
import Select from "react-select";
import Slider from "react-slick";
import $ from 'jquery';
import Swal from 'sweetalert2';
import Footer from '../../common/Footer';
import { CSVLink, CSVDownload } from "react-csv";
import FeatherIcon from 'feather-icons-react';
export default function Contact(){
  const [clientdata, setclientdata] = useState([])
  const [dlldata, setdlldata] = useState([])
  const [flldata, setflldata] = useState([])
  const [prodata, setprodata] = useState([])
  useEffect(()=>{
    fetchdatas();
  },[])
  const fetchdatas = async () =>{
    await axios.get(api_url+`getfoparticipant`).then(({data})=>{
      setclientdata(data.client)
      setdlldata(data.dll)
      setflldata(data.fll)
      setprodata(data.pro)
    })
  }
return (
      <>
     <div>
  <Header />
 <main>
  {/* section content begin */}
  <div className="uk-section uk-margin-small-top">
    <div className="uk-container">
    <div className='uk-text-center' >
    <h2 style={{
          background: '#FDD965',
          padding: '3px',
    }}>Participant Wise Open Interest(Unit Contact's)</h2>
    </div>
    <div className="uk-card uk-card-default uk-card-body uk-border-rounded" style={{
      margin: 0,
      padding: 0,
      border: '1px solid #C17951',
      borderRadius: '0px',
      }}>
    <div className='uk-text-center'>
    <h2 style={{background: '#FDF1CC',margin: 0}}>FII</h2>
    </div>
    <table class="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center" style={{margin: 0}}>
      <thead>
          <tr>
              <th class="uk-text-center">CATEGORY</th>
              <th class="uk-text-center">Long Contact</th>
              <th class="uk-text-center">Short Contact</th>
              <th class="uk-text-center">Net Contact</th>
              <th class="uk-text-center">Previous Day</th>
          </tr>
      </thead>
      <tbody>
      {
                                    flldata.length > 0 && (
                                      flldata.map((row, key)=>(
                                          <tr>
                                          <td className="text-start pe-0">
                                            <div>{ row.subcategory }</div>
                                          </td>
                                          <td className="text-start pe-0">
                                            <div>{ row.longcontract }</div>
                                          </td>
                                          <td className="text-start pe-0">
                                            <div>{ row.shortcontract }</div>
                                          </td>
                                          {Math.sign(row.netcontract) == -1 ?
                                          <td className="text-start pe-0">
                                            <div style={{color: 'red'}}>{ row.netcontract }</div>
                                          </td> 
                                          :
                                          <td className="text-start pe-0">
                                            <div style={{color: 'green'}}>{ row.netcontract }</div>
                                          </td>
                                          }
                                          {Math.sign(row.previousday) == -1 ?
                                          <td className="text-start pe-0">
                                            <div style={{color: 'red'}}>{ row.previousday }</div>
                                          </td> 
                                          :
                                          <td className="text-start pe-0">
                                            <div style={{color: 'green'}}>{ row.previousday }</div>
                                          </td>
                                          }
                                        </tr>
                                        ))
                                    )
}
      </tbody>
    </table>
    </div>

    <div className="uk-card uk-card-default uk-card-body uk-border-rounded" style={{
      margin: 0,
      padding: 0,
      border: '1px solid #C17951',
      borderRadius: '0px',
      marginTop: '10px',
      }}>
    <div className='text-'></div>
    <div className='uk-text-center'>
    <h2 style={{background: '#FDF1CC',margin: 0}}>DII</h2>
    </div>
    <table class="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center" style={{margin: 0}}>
      <thead>
          <tr>
              <th class="uk-text-center">CATEGORY</th>
              <th class="uk-text-center">Long Contact</th>
              <th class="uk-text-center">Short Contact</th>
              <th class="uk-text-center">Net Contact</th>
              <th class="uk-text-center">Previous Day</th>
          </tr>
      </thead>
      <tbody>
      {
                                    dlldata.length > 0 && (
                                      dlldata.map((row, key)=>(
                                          <tr>
                                          <td className="text-start pe-0">
                                            <div>{ row.subcategory }</div>
                                          </td>
                                          <td className="text-start pe-0">
                                            <div>{ row.longcontract }</div>
                                          </td>
                                          <td className="text-start pe-0">
                                            <div>{ row.shortcontract }</div>
                                          </td>
                                          {Math.sign(row.netcontract) == -1 ?
                                          <td className="text-start pe-0">
                                            <div style={{color: 'red'}}>{ row.netcontract }</div>
                                          </td> 
                                          :
                                          <td className="text-start pe-0">
                                            <div style={{color: 'green'}}>{ row.netcontract }</div>
                                          </td>
                                          }
                                          {Math.sign(row.previousday) == -1 ?
                                          <td className="text-start pe-0">
                                            <div style={{color: 'red'}}>{ row.previousday }</div>
                                          </td> 
                                          :
                                          <td className="text-start pe-0">
                                            <div style={{color: 'green'}}>{ row.previousday }</div>
                                          </td>
                                          }
                                        </tr>
                                        ))
                                    )
}
      </tbody>
    </table>
    </div>
    <div className="uk-card uk-card-default uk-card-body uk-border-rounded" style={{
      margin: 0,
      padding: 0,
      border: '1px solid #C17951',
      borderRadius: '0px',
      marginTop: '10px',
      }}>
    <div className='text-'></div>
    <div className='uk-text-center'>
    <h2 style={{background: '#FDF1CC',margin: 0}}>CLIENT</h2>
    </div>
    <table class="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center" style={{margin: 0}}>
      <thead>
          <tr>
              <th class="uk-text-center">CATEGORY</th>
              <th class="uk-text-center">Long Contact</th>
              <th class="uk-text-center">Short Contact</th>
              <th class="uk-text-center">Net Contact</th>
              <th class="uk-text-center">Previous Day</th>
          </tr>
      </thead>
      <tbody>
      {
                                    clientdata.length > 0 && (
                                      clientdata.map((row, key)=>(
                                          <tr>
                                          <td className="text-start pe-0">
                                            <div>{ row.subcategory }</div>
                                          </td>
                                          <td className="text-start pe-0">
                                            <div>{ row.longcontract }</div>
                                          </td>
                                          <td className="text-start pe-0">
                                            <div>{ row.shortcontract }</div>
                                          </td>
                                          {Math.sign(row.netcontract) == -1 ?
                                          <td className="text-start pe-0">
                                            <div style={{color: 'red'}}>{ row.netcontract }</div>
                                          </td> 
                                          :
                                          <td className="text-start pe-0">
                                            <div style={{color: 'green'}}>{ row.netcontract }</div>
                                          </td>
                                          }
                                          {Math.sign(row.previousday) == -1 ?
                                          <td className="text-start pe-0">
                                            <div style={{color: 'red'}}>{ row.previousday }</div>
                                          </td> 
                                          :
                                          <td className="text-start pe-0">
                                            <div style={{color: 'green'}}>{ row.previousday }</div>
                                          </td>
                                          }
                                        </tr>
                                        ))
                                    )
}
      </tbody>
    </table>
    </div>
    <div className="uk-card uk-card-default uk-card-body uk-border-rounded" style={{
      margin: 0,
      padding: 0,
      border: '1px solid #C17951',
      borderRadius: '0px',
      marginTop: '10px',
      }}>
    <div className='uk-text-center'>
    <h2 style={{background: '#FDF1CC',margin: 0}}>Pro</h2>
    </div>
    <table class="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center" style={{margin: 0}}>
      <thead>
          <tr>
              <th class="uk-text-center">CATEGORY</th>
              <th class="uk-text-center">Long Contact</th>
              <th class="uk-text-center">Short Contact</th>
              <th class="uk-text-center">Net Contact</th>
              <th class="uk-text-center">Previous Day</th>
          </tr>
      </thead>
      <tbody>
      {
                                    prodata.length > 0 && (
                                      prodata.map((row, key)=>(
                                          <tr>
                                          <td className="text-start pe-0">
                                            <div>{ row.subcategory }</div>
                                          </td>
                                          <td className="text-start pe-0">
                                            <div>{ row.longcontract }</div>
                                          </td>
                                          <td className="text-start pe-0">
                                            <div>{ row.shortcontract }</div>
                                          </td>
                                          {Math.sign(row.netcontract) == -1 ?
                                          <td className="text-start pe-0">
                                            <div style={{color: 'red'}}>{ row.netcontract }</div>
                                          </td> 
                                          :
                                          <td className="text-start pe-0">
                                            <div style={{color: 'green'}}>{ row.netcontract }</div>
                                          </td>
                                          }
                                          {Math.sign(row.previousday) == -1 ?
                                          <td className="text-start pe-0">
                                            <div style={{color: 'red'}}>{ row.previousday }</div>
                                          </td> 
                                          :
                                          <td className="text-start pe-0">
                                            <div style={{color: 'green'}}>{ row.previousday }</div>
                                          </td>
                                          }
                                        </tr>
                                        ))
                                    )
}
      </tbody>
    </table>
    </div>
    </div>
  </div>
  {/* section content end */}
</main>



  <Footer />
  </div>
      </>
    )
  }
