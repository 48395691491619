import React, { useEffect,useState } from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Header from '../../common/Header';
import axios from 'axios';
import {api_url} from '../../common/Helpers';
import '../../common/style.css'
import Footer from '../../common/Footer';
import Highcharts from 'highcharts/highstock'
import Select from "react-select";
export default function Contact(){
  const [load, setload] = useState(false);
  
  const [niftyExpirevalue, setNiftyExpirevalue] = useState("");
  const [niftyAllexpiredate, setNiftyAllexpiredate] = useState([]);
  
  const [bankniftyExpirevalue, setBankniftyExpirevalue] = useState("");
  const [bankniftyAllexpiredate, setBankniftyAllexpiredate] = useState([]);
  
  
  const [finniftyExpirevalue, setFinniftyExpirevalue] = useState("");
  const [finniftyAllexpiredate, setFinniftyAllexpiredate] = useState([]);
  // Add more state variables and functions for other indices as needed
  
  useEffect(() => {
    fetchData('NIFTY');
    fetchData('BANKNIFTY');
    fetchData('FINNIFTY');
    // Fetch data for other indices here
  }, []);
  
  const fetchData = async (indexName) => {
    setload(true);
    try {
      const response = await axios.get(api_url + `getmaxpainexpire/${indexName}`);
      const data = response.data;
      
      // Determine which state variable to update based on indexName
      switch (indexName) {
        case 'NIFTY':
          setNiftyExpirevalue(data[0].label)
          fetchdataslatest(data[0].value,'NIFTY')
          setNiftyAllexpiredate(data.map(item => ({ value: item.value, label: item.label })));
          break;
        case 'BANKNIFTY':
          setBankniftyExpirevalue(data[0].label)
          fetchdataslatest(data[0].value,'BANKNIFTY')
          setBankniftyAllexpiredate(data.map(item => ({ value: item.value, label: item.label })));
          break;
        case 'FINNIFTY':
          setFinniftyExpirevalue(data[0].label)
          fetchdataslatest(data[0].value,'FINNIFTY')
          setFinniftyAllexpiredate(data.map(item => ({ value: item.value, label: item.label })));
          break;
        default:
          break;
      }
  
      setload(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setload(false);
    }
  };
  
  
  const getexpiredate=(e,symbol)=>{
    const dataid= e.value;
    console.log(dataid)
    fetchdataslatest(dataid,symbol)
  }
  const fetchdataslatest = async (expiredate,symbol) =>{
    if(symbol == 'NIFTY'){
      await axios.get(api_url+'getmaxpain/'+symbol+'/'+expiredate).then(({data})=>{
      // NIFTY
      let allstrike = [];
      let calldata = []; // sample data for put option OI
      let putdata = []; // sample data for call option OI
      for (let x in data['apidata']) {
        allstrike.push(data['apidata'][x].strike)
        calldata.push(data['apidata'][x].callOptionValue)
        putdata.push(data['apidata'][x].putOptionValue)
      }
      
      Highcharts.chart('maxpainnifty', {
        chart: {
            type: 'column',
        },
        title: {
          text: 'Max Pain ' + data['strike'],
          align: 'center',
          y: 10,
          style: {
              fontSize: '16px'
          }
      },
        xAxis: [{
            categories: allstrike,
            labels: {
                style: {
                    color: '#000' // changing x-axis label color to white
                }
            },
            lineWidth: 0, // removing x-axis line
            tickWidth: 0 // removing x-axis tick marks
        }, {
            categories: allstrike,
            opposite: true,
            labels: {
                enabled: false // disabling x-axis labels for the second object
            }
        }],
        yAxis: {
          title: {
            text: 'Option Value'
         },
          labels: {
            formatter: function() {
              if (this.value >= 1e9) {
                return (this.value / 1e9) + 'B';
              } else if (this.value >= 1e6) {
                return (this.value / 1e6) + 'M';
              }
              return this.value;
            }
          }
        },
        tooltip: {
            shared: true
        },
        plotOptions: {
            column: {
                grouping: false,
                shadow: false,
                borderWidth: 0,
                pointPadding: 0,
                groupPadding: 0,
                dataLabels: {
                    enabled: false // removing data labels
                }
            }
        },
        series: [{
            name: 'Put Pain',
            color: '#FF3333', // changing put option OI color to red
            data: putdata,
            pointWidth: 10,
            pointPlacement: 0
        }, {
            name: 'Call Pain',
            color: '#33CC33', // changing call option OI color to green
            data: calldata,
            pointWidth: 10,
            pointPlacement: 0
        }]
    });
})
    }
    if(symbol == 'BANKNIFTY'){
      await axios.get(api_url+'getmaxpain/'+symbol+'/'+expiredate).then(({data})=>{
      
      //BANKNIFTY 
      let allstrikebank = [];
      let calldatabank = []; // sample data for put option OI
      let putdatabank = []; // sample data for call option OI
      for (let x in data['apidata']) {
        allstrikebank.push(data['apidata'][x].strike)
        calldatabank.push(data['apidata'][x].callOptionValue)
        putdatabank.push(data['apidata'][x].putOptionValue)
    }
      Highcharts.chart('maxpainbanknifty', {
          chart: {
              type: 'column',
          },
          title: {
            text: 'Max Pain ' + data['strike'],
            align: 'center',
            y: 10,
            style: {
                fontSize: '16px'
            }
        },
          xAxis: [{
              categories: allstrikebank,
              labels: {
                  style: {
                      color: '#000' // changing x-axis label color to white
                  }
              },
              lineWidth: 0, // removing x-axis line
              tickWidth: 0 // removing x-axis tick marks
          }, {
              categories: allstrikebank,
              opposite: true,
              labels: {
                  enabled: false // disabling x-axis labels for the second object
              }
          }],
          yAxis: {
            title: {
              text: 'Option Value'
           },
            labels: {
              formatter: function() {
                if (this.value >= 1e9) {
                  return (this.value / 1e9) + 'B';
                } else if (this.value >= 1e6) {
                  return (this.value / 1e6) + 'M';
                }
                return this.value;
              }
            }
          },
          tooltip: {
              shared: true
          },
          plotOptions: {
              column: {
                  grouping: false,
                  shadow: false,
                  borderWidth: 0,
                  pointPadding: 0,
                  groupPadding: 0,
                  dataLabels: {
                      enabled: false // removing data labels
                  }
              }
          },
          series: [{
              name: 'Put Pain',
              color: '#FF3333', // changing put option OI color to red
              data: putdatabank,
              pointWidth: 10,
              pointPlacement: 0
          }, {
              name: 'Call Pain',
              color: '#33CC33', // changing call option OI color to green
              data: calldatabank,
              pointWidth: 10,
              pointPlacement: 0
          }]
      });
    })
  }

  if(symbol == 'FINNIFTY'){
    await axios.get(api_url+'getmaxpain/'+symbol+'/'+expiredate).then(({data})=>{
      // FINNIFTY
      let allstrikefinnifty = [];
      let calldatafinnifty = [];
      let putdatafinnifty = [];
      for (let x in data['apidata']) {
        allstrikefinnifty.push(data['apidata'][x].strike);
        calldatafinnifty.push(data['apidata'][x].callOptionValue);
        putdatafinnifty.push(data['apidata'][x].putOptionValue);
      }
      Highcharts.chart('maxpainfinnifty', {
        chart: {
          type: 'column',
        },
        title: {
          text: 'Max Pain ' + data['strike'],
          align: 'center',
          y: 10,
          style: {
            fontSize: '16px',
          },
        },
        xAxis: [
          {
            categories: allstrikefinnifty,
            labels: {
              style: {
                color: '#000', // changing x-axis label color to white
              },
            },
            lineWidth: 0, // removing x-axis line
            tickWidth: 0, // removing x-axis tick marks
          },
          {
            categories: allstrikefinnifty,
            opposite: true,
            labels: {
              enabled: false, // disabling x-axis labels for the second object
            },
          },
        ],
        yAxis: {
          title: {
            text: 'Option Value',
          },
          labels: {
            formatter: function () {
              if (this.value >= 1e9) {
                return (this.value / 1e9) + 'B';
              } else if (this.value >= 1e6) {
                return (this.value / 1e6) + 'M';
              }
              return this.value;
            },
          },
        },
        tooltip: {
          shared: true,
        },
        plotOptions: {
          column: {
            grouping: false,
            shadow: false,
            borderWidth: 0,
            pointPadding: 0,
            groupPadding: 0,
            dataLabels: {
              enabled: false, // removing data labels
            },
          },
        },
        series: [
          {
            name: 'Put Pain',
            color: '#FF3333', // changing put option OI color to red
            data: putdatafinnifty,
            pointWidth: 10,
            pointPlacement: 0,
          },
          {
            name: 'Call Pain',
            color: '#33CC33', // changing call option OI color to green
            data: calldatafinnifty,
            pointWidth: 10,
            pointPlacement: 0,
          },
        ],
      });

    })
  }
  }

return (
  <>
  <div>
<Header />
<main>
{/* section content begin */}
<div className="uk-section uk-margin-small-top">
 <div className="uk-container" style={{marginTop: '1rem',minWidth: '1400px'}}>
 {load?
 <div className="linear-background w-100"> </div>
 :
 <>  
 <div style={{padding: '2px 10px'}}>
      <div className="row">
        <div className="col-4">

        </div>
        <div className="col-4 text-center">
        <div style={{fontWeight: '600'}}>
        <h2 className='mb-0'>Max Pain</h2>
      </div>
        </div>
        <div className="col-4 text-end">
      <div style={{fontWeight: '600'}} >
        <label htmlFor="" style={{display: 'inline-block',marginRight: '10px'}}>Expiry</label>
        <Select style={{display: 'inline-block'}} defaultValue={{ label: niftyExpirevalue, value: niftyExpirevalue }} className="text-dark" required name="expiredate" id="expiredate" options={niftyAllexpiredate} onChange={e => getexpiredate(e,'NIFTY')} />
      </div>

        </div>
      </div>
      
 </div> 
 <div className="row">
        <div className="col-12">
          <div className="uk-card uk-card-default uk-card-body uk-border-rounded" style={{
            margin: 0,
            padding: 0,
            border: '1px solid #C17951',
            borderRadius: '0px',
          }}>
            <h3 style={{background: '#FDF1CC',margin: 0,textAlign: 'center'}}>NIFTY 50 </h3>
            <figure class="highcharts-figure">
            <div id="maxpainnifty" style={{height: '500px'}}></div>
            </figure>
          </div>
          <div style={{padding: '2px 10px'}} className='my-2'>
            <div className="row">
              <div className="col-4">

              </div>
              <div className="col-4 text-center">
                
              </div>
              <div className="col-4 text-end">
            <div style={{fontWeight: '600'}} >
              <label htmlFor="" style={{display: 'inline-block',marginRight: '10px'}}>Expiry</label>
              <Select style={{display: 'inline-block'}} defaultValue={{ label: bankniftyExpirevalue, value: bankniftyExpirevalue }} className="text-dark" required name="expiredate" id="expiredate" options={niftyAllexpiredate} onChange={e => getexpiredate(e,'BANKNIFTY')} />
            </div>

              </div>
            </div>
          </div>
           
          <div className="uk-card uk-card-default uk-card-body uk-border-rounded" style={{
              margin: 0,
              padding: 0,
              border: '1px solid #C17951',
              borderRadius: '0px',
            }}>
              <h3 style={{background: '#FDF1CC',margin: 0,textAlign: 'center'}}>BANK NIFTY</h3>
              <figure class="highcharts-figure">
              <div id="maxpainbanknifty" style={{height: '500px'}}></div>
              </figure>
          </div>

          <div style={{padding: '2px 10px'}} className='my-2'>
            <div className="row">
              <div className="col-4">

              </div>
              <div className="col-4 text-center">
                
              </div>
              <div className="col-4 text-end">
            <div style={{fontWeight: '600'}} >
              <label htmlFor="" style={{display: 'inline-block',marginRight: '10px'}}>Expiry</label>
              <Select style={{display: 'inline-block'}} defaultValue={{ label: finniftyExpirevalue, value: finniftyExpirevalue }} className="text-dark" required name="expiredate" id="expiredate" options={finniftyAllexpiredate} onChange={e => getexpiredate(e,'FINNIFTY')} />
            </div>

              </div>
            </div>
          </div>
          <div className="uk-card uk-card-default uk-card-body uk-border-rounded" style={{
              margin: 0,
              padding: 0,
              border: '1px solid #C17951',
              borderRadius: '0px',
            }}>
              <h3 style={{background: '#FDF1CC',margin: 0,textAlign: 'center'}}>FIN NIFTY</h3>
              <figure class="highcharts-figure">
              <div id="maxpainfinnifty" style={{height: '500px'}}></div>
              </figure>
          </div>
        
        </div>
      </div>
      </>
      }
 </div>
</div>

{/* section content end */}
</main>



<Footer />
</div>
   </>
    )
  }
