import React, { useEffect, useState } from "react";
import Header from "../../common/Header";
import axios from "axios";
import { api_url, formatdmY } from "../../common/Helpers";
import demataccount from "../../lottie/demataccount.json";
import "../../common/style.css";
import Highcharts from "highcharts/highstock";
import Footer from "../../common/Footer";
import Select from "react-select";
import Swal from "sweetalert2";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Lottie from "lottie-react";
export default function Contact() {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const [expload, setexpload] = useState(true);
  //// THIS SECTION IS FOR NIFTY50
  const [nifyt50data, setnifyt50data] = useState([]);
  const [nifyt50expiry, setnifyt50expiry] = useState([
    { value: "", label: "" },
  ]);
  //// THIS SECTION IS FOR BANK NIFTY
  const [bankniftydata, setbankniftydata] = useState([]);
  const [bankniftyexpiry, setbankniftyexpiry] = useState([
    { value: "", label: "" },
  ]);
  // THIS SECTION IS FOR FINNIFTY
  // Step 3: Create state for Finnifty data and expiry date
  const [finniftyData, setFinniftyData] = useState([]);
  const [finniftyExpiry, setfinniftyExpiry] = useState([
    { value: "", label: "" },
  ]);
  // useEffect(()=>{
  //   fetchdatas();
  // },[])
  const fetchdatas = async () => {
    await axios.get(api_url + `getalloptionchainexplist`).then(({ data }) => {
      // setexpirevalue(data[0].label)
      // fetchdataslatest(data[0].value)
      let arr = [];
      if (data.FINNIFTY.length > 0) {
        for (let x in data.FINNIFTY) {
          // if(x == 0){
          //   getOptionChainFinniftyLatest(data.FINNIFTY[x])
          // }
          let obj = {
            value: data.FINNIFTY[x],
            label: formatdmY(data.FINNIFTY[x]),
          };
          arr.push(obj);
        }
      }
      setfinniftyExpiry(arr);
      let arr1 = [];
      if (data.NIFTY.length > 0) {
        for (let x in data.NIFTY) {
          // if(x == 0){
          //   getOptionChainnifty50Latest(data.NIFTY[x])
          // }
          let obj = { value: data.NIFTY[x], label: formatdmY(data.NIFTY[x]) };
          arr1.push(obj);
        }
      }
      setnifyt50expiry(arr1);
      let arr2 = [];
      if (data.BANKNIFTY.length > 0) {
        for (let x in data.BANKNIFTY) {
          // if(x == 0){
          //   getoptionchainbankniftyLatest(data.BANKNIFTY[x])
          // }
          let obj = {
            value: data.BANKNIFTY[x],
            label: formatdmY(data.BANKNIFTY[x]),
          };
          arr2.push(obj);
        }
      }
      setbankniftyexpiry(arr2);
    });
    setexpload(false);
  };
  // Step 4: Fetch Finnifty option chain data with a specific expiry date
  const getOptionChainnifty50Latest = async (expirydate) => {
    await axios
      .get(api_url + `getoptionchainlatests/` + expirydate)
      .then(({ data }) => {
        console.log(data);
      });
  };
  const getoptionchainbankniftyLatest = async (expirydate) => {
    await axios
      .get(api_url + `getoptionchainbankniftylatest/` + expirydate)
      .then(({ data }) => {
        console.log(data);
      });
  };
  const getOptionChainFinniftyLatest = async (expirydate) => {
    await axios
      .get(api_url + `getoptionchainfinniftylatest/` + expirydate)
      .then(({ data }) => {
        console.log(data);
      });
  };
  useEffect(() => {
    fetchData();
    const timer = setInterval(fetchData, 60000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const fetchData = async () => {
    // Fetch data for NIFTY 50, BANK NIFTY, and FINNIFTY
    const niftyData = await fetchDataForTab("nifty");
    const bankNiftyData = await fetchDataForTab("banknifty");
    const finniftyData = await fetchDataForTab("finnifty");

    setData({
      nifty: niftyData,
      bankNifty: bankNiftyData,
      finnifty: finniftyData,
    });
    fetchdatas();
    setLoad(false);
  };

  const fetchDataForTab = async (tab) => {
    const apiUrl = getApiUrlForTab(tab);
    const { data } = await axios.get(apiUrl);

    Highcharts.chart(`container${tab}trending`, {
      chart: {
        type: "spline",
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: data.time,
      },
      yAxis: [
        {
          // Primary yAxis
          labels: {
            format: "{value}",
          },
          title: {
            text: "OI",
          },
          opposite: false,
        },
        {
          // Secondary yAxis
          gridLineWidth: 0,
          title: {
            text: "Spot Price",
          },
          labels: {
            format: "{value}",
          },
          opposite: true,
        },
      ],
      tooltip: {
        shared: true,
        useHTML: true,
        pointFormatter: function () {
          var seriesName = this.series.name;
          var valuePrefix = "";
          var valueSuffix = "";
          if (seriesName === "Spot Price") {
            valuePrefix = "";
            valueSuffix = "";
          } else {
            valueSuffix = " ";
          }
          return (
            '<tr><td style="color: ' +
            this.color +
            '">' +
            seriesName +
            ': </td><td style="text-align: right"><b>' +
            valuePrefix +
            Highcharts.numberFormat(this.y, 2) +
            valueSuffix +
            "</b></td></tr>"
          );
        },
        footerFormat: "</table>",
        headerFormat: '<table><tr><th colspan="2">' + "</th></tr>",
        valueDecimals: 2,
      },
      plotOptions: {
        line: {
          marker: {
            enabled: false,
          },
        },
        spline: {
          marker: {
            enabled: false,
          },
        },
      },
      series: data.series,
    });

    return data;
  };

  const getApiUrlForTab = (tab) => {
    switch (tab) {
      case "nifty":
        return api_url + "trendingoi/optionchaindata";
      case "banknifty":
        return api_url + "trendingoi/optionchainbankniftydata";
      case "finnifty":
        return api_url + "trendingoi/optionchaindatafinniftydata";
      default:
        return "";
    }
  };
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (
      /android/i.test(userAgent) ||
      (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
    ) {
      setIsMobile(true);
    }
  }, []);
  const cardStyle = {
    background:
      "linear-gradient(209deg, rgb(50 141 225) 0%, rgba(223, 239, 255, 0) 100%)",
    border: "2px solid #f0f0f0", // You can adjust border styles as needed
    borderRadius: "10px", // Adjust the border-radius as needed
    padding: "20px", // Adjust padding as needed
    cursor: "pointer",
  };

  const lineStyle = {
    borderLeft: "4px solid #007BFF", // Adjust the border color as needed
    borderRadius: "10px", // Adjust the border-radius as needed
    height: "12px",
    position: "absolute",
    left: 0,
    top: "50%",
    transform: "translateY(-50%)",
  };

  // FREE DEMAT ACCOUNT HANDLE
  const [dematvalidateshow, setdematvalidateshow] = useState(false);
  const [dematshow, setdematShow] = useState(false);
  const [dematemail, setdematemail] = useState("");
  const [dematmobile, setdematmobile] = useState("");
  const [dematcheckcontact, setdematcheckcontact] = useState(false);
  const demathidemodal = () => {
    setdematShow(false);
  };
  const demathandleClose = () => {
    if (!dematmobile.match("[0-9]{10}") || dematmobile.length > 10) {
      // Swal.fire({
      //   icon:"error",
      //   text:"Contact Only 10 Digit Allow",
      // })
      setdematvalidateshow(true);
      setdematcheckcontact(true);
      return false;
    } else if (dematemail == "" || dematmobile == "") {
      setdematvalidateshow(true);
      return false;
    } else {
      const formData = new FormData();
      formData.append("supportemail", dematemail);
      formData.append("supportmobile", dematmobile);
      axios
        .post(api_url + `addsupport`, formData)
        .then(({ data }) => {
          setdematemail("");
          setdematmobile("");
          Swal.fire({
            icon: "success",
            text: "Thank you for your interest, we will get back to you very soon !",
          });
        })
        .catch(({ response }) => {
          if (response.status === 422) {
            console.log(response.data.errors);
          } else {
            Swal.fire({
              text: response.data.message,
              icon: "error",
            });
          }
        });
      setdematShow(false);
    }
  };

  const handleShow = () => setShow(true);
  const demathandleShow = () => setdematShow(true);
  const w300 = {
    width: 300,
    maxWidth: "100%",
    display: "block",
  };
  return (
    <>
      <div>
        <Header />
        <main>
          <div className="uk-section uk-margin-small-top">
            <div
              className="uk-container"
              style={{
                marginTop: "1.4rem",
                maxWidth: "100%",
                maxWidth: "1400px",
                paddingRight: isMobile ? 0 : null,
              }}
            >
              {load ? (
                <div className="linear-background w-100"> </div>
              ) : (
                <>
                  <div className="row">
                    <div className={"col-" + (isMobile ? 10 : 10)}>
                      <div className="col-12 mb-1">
                        <div className="uk-text-center">
                          <h2
                            style={{
                              background: "#FDD965",
                              padding: "3px",
                              marginBottom: "0px",
                            }}
                          >
                            Multi-Strike Trending OI
                          </h2>
                        </div>
                      </div>
                      <div className="col-12 mt-1">
                        <div
                          className="uk-card uk-card-default uk-card-body uk-border-rounded"
                          style={{
                            margin: 0,
                            padding: 0,
                            border: "1px solid #C17951",
                            borderRadius: "0px",
                          }}
                        >
                          <div style={{ overflow: "hidden" }}>
                            {expload ? (
                              <div
                                className="linear-background w-100"
                                style={{ height: "30px" }}
                              >
                                {" "}
                              </div>
                            ) : (
                              <div
                                className="row"
                                style={{ background: "#FDF1CC" }}
                              >
                                <div className="col-4"></div>
                                <div className="col-4 text-center">
                                  <h2
                                    style={{
                                      background: "#FDF1CC",
                                      margin: 0,
                                      textAlign: "center",
                                    }}
                                  >
                                    NIFTY 50
                                  </h2>
                                </div>
                                <div className="col-4 text-end">
                                  <div style={{ fontWeight: "600" }}>
                                    <label
                                      htmlFor=""
                                      style={{
                                        display: "inline-block",
                                        marginRight: "10px",
                                      }}
                                    >
                                      Expiry
                                    </label>
                                    <Select
                                      style={{ display: "inline-block" }}
                                      defaultValue={{
                                        label: nifyt50expiry[0].label,
                                        value: nifyt50expiry[0].value,
                                      }}
                                      className="text-dark"
                                      required
                                      name="expiredate"
                                      id="expiredate"
                                      onChange={(e) =>
                                        getOptionChainnifty50Latest(e.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <figure className="highcharts-figure">
                            <div
                              id="containerniftytrending"
                              style={{ height: "500px" }}
                            ></div>
                          </figure>
                        </div>
                        <div
                          className="uk-card uk-card-default uk-card-body uk-border-rounded my-3"
                          style={{
                            margin: 0,
                            padding: 0,
                            border: "1px solid #C17951",
                            borderRadius: "0px",
                          }}
                        >
                          <div style={{ overflow: "hidden" }}>
                            {expload ? (
                              <div
                                className="linear-background w-100"
                                style={{ height: "30px" }}
                              >
                                {" "}
                              </div>
                            ) : (
                              <div
                                className="row"
                                style={{ background: "#FDF1CC" }}
                              >
                                <div className="col-4"></div>
                                <div className="col-4 text-center">
                                  <h2
                                    style={{
                                      margin: 0,
                                      textAlign: "center",
                                    }}
                                  >
                                    BANK NIFTY
                                  </h2>
                                </div>
                                <div className="col-4 text-end">
                                  <div style={{ fontWeight: "600" }}>
                                    <label
                                      htmlFor=""
                                      style={{
                                        display: "inline-block",
                                        marginRight: "10px",
                                      }}
                                    >
                                      Expiry
                                    </label>
                                    <Select
                                      style={{ display: "inline-block" }}
                                      defaultValue={{
                                        label: bankniftyexpiry[0].label,
                                        value: bankniftyexpiry[0].value,
                                      }}
                                      className="text-dark"
                                      required
                                      name="expiredate"
                                      id="expiredate"
                                      onChange={(e) =>
                                        getoptionchainbankniftyLatest(e.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <figure className="highcharts-figure">
                            <div
                              id="containerbankniftytrending"
                              style={{ height: "500px" }}
                            ></div>
                          </figure>
                        </div>
                        <div
                          className="uk-card uk-card-default uk-card-body uk-border-rounded"
                          style={{
                            margin: 0,
                            padding: 0,
                            border: "1px solid #C17951",
                            borderRadius: "0px",
                          }}
                        >
                          <div style={{ overflow: "hidden" }}>
                            {expload ? (
                              <div
                                className="linear-background w-100"
                                style={{ height: "30px" }}
                              >
                                {" "}
                              </div>
                            ) : (
                              <div
                                className="row"
                                style={{ background: "#FDF1CC" }}
                              >
                                <div className="col-4"></div>
                                <div className="col-4 text-center">
                                  <h2
                                    style={{
                                      background: "#FDF1CC",
                                      margin: 0,
                                      textAlign: "center",
                                    }}
                                  >
                                    FINNIFTY
                                  </h2>
                                </div>
                                <div className="col-4 text-end">
                                  <div style={{ fontWeight: "600" }}>
                                    <label
                                      htmlFor=""
                                      style={{
                                        display: "inline-block",
                                        marginRight: "10px",
                                      }}
                                    >
                                      Expiry
                                    </label>
                                    <Select
                                      style={{ display: "inline-block" }}
                                      defaultValue={{
                                        label: finniftyExpiry[0].label,
                                        value: finniftyExpiry[0].value,
                                      }}
                                      className="text-dark"
                                      required
                                      name="expiredate"
                                      id="expiredate"
                                      onChange={(e) =>
                                        getOptionChainFinniftyLatest(e.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <figure className="highcharts-figure">
                            <div
                              id="containerfinniftytrending"
                              style={{ height: "500px" }}
                            ></div>
                          </figure>
                        </div>
                      </div>
                    </div>
                    <div className="col-2">
                      <div style={cardStyle} onClick={() => setdematShow(true)}>
                        <div className="flex flex-col gap-5">
                          <div className="flex gap-5 relative">
                            <div style={lineStyle}></div>
                            <div className="ml-6">
                              <h6
                                className="font-bold text-neutral-black/80"
                                style={{
                                  color: "#fbff00",
                                  textShadow: "rgb(237 255 0 / 80%) 0px 0px 10px",
                                }}
                              >
                                Open a Zerodha Demat and Trading account{" "}
                                <span className="text-uppercase">
                                  (No Registration fee)
                                </span>
                              </h6>
                            </div>
                          </div>
                          <div className="flex gap-5 relative">
                            <div style={lineStyle}></div>
                            <div className="ml-6">
                              <p className="text-neutral-black/60 lg:text-16">
                                * Zero Maintenance Charges for the First year
                              </p>
                            </div>
                          </div>
                          <div className="flex gap-5 relative">
                            <div style={lineStyle}></div>
                            <div className="ml-6">
                              <p className="text-neutral-black/60 lg:text-16">
                                * 0 Rs Brokerage on Equity delivery.
                              </p>
                            </div>
                          </div>
                          <div className="flex gap-5 relative">
                            <div style={lineStyle}></div>
                            <div className="ml-6">
                              <p className="text-neutral-black/60 lg:text-16">
                                * Flat 20 Rs/0.03 Rs on Intraday and F&O trades.
                                Equity/ F&0/ Commodity/Currency.
                              </p>
                              <p className="text-neutral-black/60 lg:text-16">
                                * 0 Rs on Direct mutual fund investments.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </main>
        <Footer />
      </div>
      <Modal
        show={dematshow}
        onHide={demathidemodal}
        size="lg"
        className="supportformhome d-flex align-items-center h-100"
      >
        <Modal.Header
          closeButton
          className="text-dark"
          style={{ border: "none" }}
        >
          <Modal.Title className="">Get Free Demat Account</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0 pb-0 pe-0">
          <div className="container-fluid" style={{ paddingLeft: "0px" }}>
            <div className="row">
              <div className="col-6">
                <Lottie animationData={demataccount} style={w300} />
              </div>
              <div className="col-6">
                <div className="d-flex align-items-center h-100">
                  <div>
                    <Form>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        {/* <Form.Label>Email</Form.Label> */}
                        <Form.Control
                          type="email"
                          placeholder="Email Address"
                          autoFocus
                          value={dematemail}
                          onChange={(event) => {
                            setdematemail(event.target.value);
                          }}
                          style={{ backgroundColor: "#e9ecef" }}
                        />
                        {dematemail == "" && dematvalidateshow == true ? (
                          <span style={{ color: "red" }}>
                            This Field Required
                          </span>
                        ) : (
                          <></>
                        )}
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        {/* <Form.Label>Mobile Number</Form.Label> */}
                        <Form.Control
                          type="number"
                          placeholder="Mobile Number"
                          autoFocus
                          value={dematmobile}
                          onChange={(event) => {
                            setdematmobile(event.target.value);
                          }}
                          style={{ backgroundColor: "#e9ecef" }}
                        />
                        {dematcheckcontact == true &&
                        dematvalidateshow == true ? (
                          <span style={{ color: "red" }}>
                            Contact Only 10 Digits Allowed
                          </span>
                        ) : (
                          <></>
                        )}
                      </Form.Group>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          className="justify-content-end"
          style={{ border: "none" }}
        >
          <button
            type="submit"
            className="uk-button uk-button-primary uk-border-rounded text-uppercase"
            onClick={demathandleClose}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
