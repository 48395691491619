import React, {useEffect,useState} from 'react'
import {BrowserRouter,Routes,Route} from 'react-router-dom';
import $ from 'jquery';
import './App.css';
import Error from "./common/Error";
import {app_url} from './common/Helpers';
import Login from './auth/Login';
import SignUp from './auth/SignUp';
import Home from './common/Home';
// MARKET
import FllDllData from './pages/MarketData/FllDllData';
import FOParticipant from './pages/MarketData/FOParticipant';
import PreMarketData from './pages/MarketData/PreMarketData';
import NewsUpdate from './pages/MarketData/NewsUpdate';
import CommingSoon from './pages/MarketData/CommingSoon';
import GlobalMarketFeatures from './pages/MarketData/GlobalMarketFeatures';
import IndexContributors from './pages/MarketToday/IndexContributors';
import MultiIndex from './pages/MarketToday/MultiIndex';
import AdvanceDeclineCharts from './pages/MarketToday/AdvanceDeclineCharts';
import TopGainers from './pages/MarketToday/TopGainers';
import TopLosers from './pages/MarketToday/TopLosers';
import MostActiveStocks from './pages/MarketToday/MostActiveStocks';
import VolumeGainer from './pages/MarketToday/VolumeGainer';
import WeekHigh52 from './pages/MarketToday/WeekHigh52';
import WeekLow52 from './pages/MarketToday/WeekLow52';
import BulkDeals from './pages/MarketToday/BulkDeals';
import BlockDeals from './pages/MarketToday/BlockDeals';
import StockScreener from './pages/MarketToday/StockScreener';
import Market from './pages/Market/Market';
import Money from './pages/Market/Money';
import Analysis from './pages/Market/Analysis';
// FO
import TrendingOi from './pages/FO/TrendingOi';
import FutureOi from './pages/FO/FutureOi';
import OptionChain from './pages/FO/OptionChain';
import OiAnalysis from './pages/FO/OiAnalysis';
import MaxPain from './pages/FO/MaxPain';
import PCR from './pages/FO/PCR';
import CePeOI from './pages/FO/CePeOI';
import ImpliedVolatility from './pages/FO/ImpliedVolatility';
// Strategies
import Straddle from './pages/Strategies/Straddle';
import Strangle from './pages/Strategies/Strangle';
import StraddleSnapshot from './pages/Strategies/StraddleSnapshot';
import StraddleWeekDays from './pages/Strategies/StraddleWeekDays';
// OTHERS
import Education from './common/Education';
import EducationView from './common/EducationView';
import News from './common/News';
import About from './common/About';
import Terms from './common/Terms';
import Refund from './common/Refund';
import Privacy from './common/Privacy';
import Disclaimer from './common/Disclaimer';
import Cookies from './common/Cookies';
import Contact from './common/Contact';
import EconomicCalendar from './pages/Calendar/EconomicCalendar';
import ResultsCalendar from './pages/Calendar/ResultsCalendar';
import TcHolidays from './pages/Calendar/TcHolidays';
function App() {
  const uid = localStorage.getItem("uid");
  const myroleid = localStorage.getItem("myroleid");
  const [admindashboard, setadmindashboard] = useState(false)
  const [specialcontrol, setspecialcontrol] = useState(false)
  useEffect(()=>{
    setTimeout(
      fetchDatas()
      ,1000);
  },[])
  const fetchDatas = () => {
            if(uid == 1 && myroleid == "super"){
              setadmindashboard(true);
            }
            if(myroleid == 2 || myroleid == "super"){
              setspecialcontrol(true);
            }
  }
  return (
  <>
    <div className="App">      
      <BrowserRouter>
        <Routes>
        {/* <Route exact path={app_url} element={<Onboard />} /> */}
          <Route exact path={app_url} element={<Home />} />
          <Route exact path={app_url+'login'} element={<Login />} />
          <Route exact path={app_url+'signup'} element={<SignUp />} />
          <Route exact path={app_url+'flldlldata'} element={<FllDllData />} />
          <Route exact path={app_url+'foparticipant'} element={<FOParticipant />} />
          <Route exact path={app_url+'premarketdata'} element={<PreMarketData />} />
          <Route exact path={app_url+'news&updates'} element={<NewsUpdate />} />
          <Route exact path={app_url+'trendingoi'} element={<TrendingOi />} />
          <Route exact path={app_url+'cepevsoi'} element={<CePeOI />} />
          <Route exact path={app_url+'futurevsoi'} element={<FutureOi />} />
          <Route exact path={app_url+'optionchain'} element={<OptionChain />} />
          <Route exact path={app_url+'oianalysis'} element={<OiAnalysis />} />
          <Route exact path={app_url+'maxpain'} element={<MaxPain />} />
          <Route exact path={app_url+'pcr'} element={<PCR />} />
          <Route exact path={app_url+'impliedvolatility'} element={<ImpliedVolatility />} />
          <Route exact path={app_url+'straddle'} element={<Straddle />} />
          <Route exact path={app_url+'strangle'} element={<Strangle />} />
          <Route exact path={app_url+'straddlesnapshot'} element={<StraddleSnapshot />} />
          <Route exact path={app_url+'straddleweekdays'} element={<StraddleWeekDays />} />
          <Route exact path={app_url+'stockscreener'} element={<StockScreener />} />
          <Route exact path={app_url+'comingsoon'} element={<CommingSoon />} />
          <Route exact path={app_url+'gmfutures'} element={<GlobalMarketFeatures />} />
          <Route exact path={app_url+'indexcontributors'} element={<IndexContributors />} />
          <Route exact path={app_url+'multiindex'} element={<MultiIndex />} />
          <Route exact path={app_url+'advancedeclineratio'} element={<AdvanceDeclineCharts />} />
          <Route exact path={app_url+'topgainers'} element={<TopGainers />} />
          <Route exact path={app_url+'toplosers'} element={<TopLosers />} />
          <Route exact path={app_url+'mostactivestocks'} element={<MostActiveStocks />} />
          <Route exact path={app_url+'volumegainers'} element={<VolumeGainer />} />
          <Route exact path={app_url+'52weekhigh'} element={<WeekHigh52 />} />
          <Route exact path={app_url+'52weeklow'} element={<WeekLow52 />} />
          <Route exact path={app_url+'bulkdeals'} element={<BulkDeals />} />
          <Route exact path={app_url+'blockdeals'} element={<BlockDeals />} />
          <Route exact path={app_url+'markets'} element={<Market />} />
          <Route exact path={app_url+'money'} element={<Money />} />
          <Route exact path={app_url+'analysis'} element={<Analysis />} />
          <Route exact path={app_url+'economiccalendar'} element={<EconomicCalendar />} />
          <Route exact path={app_url+'resultscalendar'} element={<ResultsCalendar />} />
          <Route exact path={app_url+'tcholidays'} element={<TcHolidays />} />
          <Route exact path={app_url+'education'} element={<Education />} />
          <Route exact path={app_url+'education/:eduid'} element={<EducationView />} />
          <Route exact path={app_url+'news'} element={<News />} />
          <Route exact path={app_url+'about'} element={<About />} />
          <Route exact path={app_url+'refund'} element={<Refund />} />
          <Route exact path={app_url+'terms'} element={<Terms />} />
          <Route exact path={app_url+'privacy'} element={<Privacy />} />
          <Route exact path={app_url+'disclaimer'} element={<Disclaimer />} />
          <Route exact path={app_url+'cookies'} element={<Cookies />} />
          <Route exact path={app_url+'contact'} element={<Contact />} />
          <Route exact path="*" element={<Error />} />
        </Routes>  
      </BrowserRouter>
      </div>      
  </>
  );
}
export default App;
