import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Header from "../../common/Header";
import axios from "axios";
import { app_url, asseturl, api_url, formatdmY } from "../../common/Helpers";
import Select from "react-select";
import Slider from "react-slick";
import $ from "jquery";
import Swal from "sweetalert2";
import Footer from "../../common/Footer";
import copy from "copy-to-clipboard";
import FeatherIcon from "feather-icons-react";
import { Chart } from "react-google-charts";
export default function Contact() {
  const [load, setload] = useState(false);
  const [key, setKey] = useState('home');
  const [submit, setsubmit] = useState(false);
  const [spreviousweek, setspreviousweek] = useState([]);
  const [scurrentweek, setscurrentweek] = useState([]);
  const [spreviousweekbank, setspreviousweekbank] = useState([]);
  const [scurrentweekbank, setscurrentweekbank] = useState([]);
  const [spreviousweekfin, setspreviousweekfin] = useState([]);
  const [scurrentweekfin, setscurrentweekfin] = useState([]);
  const [expload, setexpload] = useState(true);
  const [nifyt50expiry, setnifyt50expiry] = useState([
    { value: "", label: "" },
  ]);
  //// THIS SECTION IS FOR BANK NIFTY
  const [bankniftyexpiry, setbankniftyexpiry] = useState([
    { value: "", label: "" },
  ]);
  // THIS SECTION IS FOR FINNIFTY
  // Step 3: Create state for Finnifty data and expiry date
  const [finniftyExpiry, setfinniftyExpiry] = useState([
    { value: "", label: "" },
  ]);
  const fetchexpdatas = async () => {
    await axios.get(api_url + `getalloptionchainexplist`).then(({ data }) => {
      // setexpirevalue(data[0].label)
      // fetchdataslatest(data[0].value)
      let arr = [];
      if (data.FINNIFTY.length > 0) {
        for (let x in data.FINNIFTY) {
          // if(x == 0){
          //   getOptionChainFinniftyLatest(data.FINNIFTY[x])
          // }
          let obj = {
            value: data.FINNIFTY[x],
            label: formatdmY(data.FINNIFTY[x]),
          };
          arr.push(obj);
        }
      }
      setfinniftyExpiry(arr);
      let arr1 = [];
      if (data.NIFTY.length > 0) {
        for (let x in data.NIFTY) {
          // if(x == 0){
          //   getOptionChainnifty50Latest(data.NIFTY[x])
          // }
          let obj = { value: data.NIFTY[x], label: formatdmY(data.NIFTY[x]) };
          arr1.push(obj);
        }
      }
      setnifyt50expiry(arr1);
      let arr2 = [];
      if (data.BANKNIFTY.length > 0) {
        for (let x in data.BANKNIFTY) {
          // if(x == 0){
          //   getoptionchainbankniftyLatest(data.BANKNIFTY[x])
          // }
          let obj = {
            value: data.BANKNIFTY[x],
            label: formatdmY(data.BANKNIFTY[x]),
          };
          arr2.push(obj);
        }
      }
      setbankniftyexpiry(arr2);
    });
    setexpload(false);
  };
  // Step 4: Fetch Finnifty option chain data with a specific expiry date
  const getOptionChainnifty50Latest = async (expirydate) => {
    await axios
      .get(api_url + `getoptionchainlatests/` + expirydate)
      .then(({ data }) => {
        console.log(data);
      });
  };
  const getoptionchainbankniftyLatest = async (expirydate) => {
    await axios
      .get(api_url + `getoptionchainbankniftylatest/` + expirydate)
      .then(({ data }) => {
        console.log(data);
      });
  };
  const getOptionChainFinniftyLatest = async (expirydate) => {
    await axios
      .get(api_url + `getoptionchainfinniftylatest/` + expirydate)
      .then(({ data }) => {
        console.log(data);
      });
  };

  useEffect(() => {
    fetchexpdatas();
  }, []);

  useEffect(() => {
    fetchdatas()
  }, []);
  const fetchdatas = async () => {
    await axios.get(api_url + `getstraddleweekdays/nifty50`).then(({ data }) => {
      setspreviousweek(data.previousweek);
      setscurrentweek(data.currentweek);
    });
    await axios.get(api_url + `getstraddleweekdays/banknifty`).then(({ data }) => {
      setspreviousweekbank(data.previousweek);
      setscurrentweekbank(data.currentweek);
    });
    await axios.get(api_url + `getstraddleweekdays/finnifty`).then(({ data }) => {
      setspreviousweekfin(data.previousweek);
      setscurrentweekfin(data.currentweek);
    });
  };
  return (
    <>
      <div>
        <Header />
        
        
  <main>
{/* section content begin */}
<div className="uk-section uk-margin-small-top">
 <div className="uk-container" style={{marginTop: '1rem',maxWidth: '100%',maxWidth: '1400px'}}>
 <Tabs
   id="controlled-tab-example"
   activeKey={key}
   onSelect={(k) => setKey(k)}
 >
 
 <Tab eventKey="home" title="NIFTY 50">
 {load?
 <div className="linear-background w-100"> </div>
 :
 <>  
 <div className="row">
                  <div className="col-12">
                    <div style={{ overflow: "hidden" }}>
                              {expload ? (
                                <div
                                  className="linear-background w-100"
                                  style={{ height: "30px" }}
                                >
                                  {" "}
                                </div>
                              ) : (
                                <div
                                  className="row"
                                  style={{ background: "#FDD965", }}
                                >
                                  <div className="col-3"></div>
                                  <div className="col-5 text-center">
                                    <h2
                                      style={{
                                        margin: 0,
                                        textAlign: "center",
                                      }}
                                    >
                                     Straddle Weekdays Price (ATM)
                                    </h2>
                                  </div>
                                  <div className="col-4 text-end">
                                    <div style={{ fontWeight: "600" }}>
                                      <label
                                        htmlFor=""
                                        style={{
                                          display: "inline-block",
                                          marginRight: "10px",
                                        }}
                                      >
                                        Expiry
                                      </label>
                                      <Select
                                        style={{ display: "inline-block" }}
                                        defaultValue={{
                                          label: nifyt50expiry[0].label,
                                          value: nifyt50expiry[0].value,
                                        }}
                                        className="text-dark"
                                        required
                                        name="expiredate"
                                        id="expiredate"
                                        onChange={(e) =>
                                          getOptionChainnifty50Latest(e.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                    <div className="row mt-1">
                      <div className="col-6">
                      <div
                      className="uk-card uk-card-default uk-card-body uk-border-rounded"
                      style={{
                        margin: 0,
                        padding: 0,
                        border: "1px solid #C17951",
                        borderRadius: "0px",
                      }}
                    >
                      <div className="uk-text-center">
                        <h2 style={{ background: "#FDF1CC", margin: 0 }}>
                          Previous Week
                        </h2>
                      </div>
                      <table
                        class="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center"
                        style={{ margin: 0 }}
                      >
                        <thead>
                          <tr>
                            <th class="uk-text-center">Day</th>
                            <th class="uk-text-center">Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {spreviousweek.length > 0 &&
                            spreviousweek.map((row, key) => (
                              <tr>
                                <td className="text-center pe-0">
                                  <div>{row.day}</div>
                                </td>
                                <td className="text-center pe-0">
                                  <div>{row.price}</div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                      </div>
                      <div className="col-6">
                      <div
                      className="uk-card uk-card-default uk-card-body uk-border-rounded"
                      style={{
                        margin: 0,
                        padding: 0,
                        border: "1px solid #C17951",
                        borderRadius: "0px",
                      }}
                    >
                      <div className="uk-text-center">
                        <h2 style={{ background: "#FDF1CC", margin: 0 }}>
                          Current Week
                        </h2>
                      </div>
                      <table
                        class="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center"
                        style={{ margin: 0 }}
                      >
                        <thead>
                          <tr>
                            <th class="uk-text-center">Day</th>
                            <th class="uk-text-center">Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {scurrentweek.length > 0 &&
                            scurrentweek.map((row, key) => (
                              <tr>
                                <td className="text-center pe-0">
                                  <div>{row.day}</div>
                                </td>
                                <td className="text-center pe-0">
                                  <div>{row.price}</div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                      </div>
                    </div>
                  </div>
                </div>
 </>
 }
 </Tab>

<Tab eventKey="profile" title="BANK NIFTY">
   {load?
 <div className="linear-background w-100"> </div>
 :
 <>  
 <div className="row">
                  <div className="col-12">
                    <div style={{ overflow: "hidden" }}>
                              {expload ? (
                                <div
                                  className="linear-background w-100"
                                  style={{ height: "30px" }}
                                >
                                  {" "}
                                </div>
                              ) : (
                                <div
                                  className="row"
                                  style={{ background: "#FDD965", }}
                                >
                                  <div className="col-3"></div>
                                  <div className="col-5 text-center">
                                    <h2
                                      style={{
                                        margin: 0,
                                        textAlign: "center",
                                      }}
                                    >
                                     Straddle Weekdays Price (ATM)
                                    </h2>
                                  </div>
                                  <div className="col-4 text-end">
                                    <div style={{ fontWeight: "600" }}>
                                      <label
                                        htmlFor=""
                                        style={{
                                          display: "inline-block",
                                          marginRight: "10px",
                                        }}
                                      >
                                        Expiry
                                      </label>
                                      <Select
                                        style={{ display: "inline-block" }}
                                        defaultValue={{
                                          label: bankniftyexpiry[0].label,
                                          value: bankniftyexpiry[0].value,
                                        }}
                                        className="text-dark"
                                        required
                                        name="expiredate"
                                        id="expiredate"
                                        onChange={(e) =>
                                          getoptionchainbankniftyLatest(e.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                    <div className="row mt-1">
                      <div className="col-6">
                      <div
                      className="uk-card uk-card-default uk-card-body uk-border-rounded"
                      style={{
                        margin: 0,
                        padding: 0,
                        border: "1px solid #C17951",
                        borderRadius: "0px",
                      }}
                    >
                      <div className="uk-text-center">
                        <h2 style={{ background: "#FDF1CC", margin: 0 }}>
                          Previous Week
                        </h2>
                      </div>
                      <table
                        class="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center"
                        style={{ margin: 0 }}
                      >
                        <thead>
                          <tr>
                            <th class="uk-text-center">Day</th>
                            <th class="uk-text-center">Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {spreviousweekbank.length > 0 &&
                            spreviousweekbank.map((row, key) => (
                              <tr>
                                <td className="text-center pe-0">
                                  <div>{row.day}</div>
                                </td>
                                <td className="text-center pe-0">
                                  <div>{row.price}</div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                      </div>
                      <div className="col-6">
                      <div
                      className="uk-card uk-card-default uk-card-body uk-border-rounded"
                      style={{
                        margin: 0,
                        padding: 0,
                        border: "1px solid #C17951",
                        borderRadius: "0px",
                      }}
                    >
                      <div className="uk-text-center">
                        <h2 style={{ background: "#FDF1CC", margin: 0 }}>
                          Current Week
                        </h2>
                      </div>
                      <table
                        class="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center"
                        style={{ margin: 0 }}
                      >
                        <thead>
                          <tr>
                            <th class="uk-text-center">Day</th>
                            <th class="uk-text-center">Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {scurrentweekbank.length > 0 &&
                            scurrentweekbank.map((row, key) => (
                              <tr>
                                <td className="text-center pe-0">
                                  <div>{row.day}</div>
                                </td>
                                <td className="text-center pe-0">
                                  <div>{row.price}</div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                      </div>
                    </div>
                  </div>
                </div>
 </>
 }
</Tab>
<Tab eventKey="finnifty" title="FIN NIFTY">
  {load ? (
    <div className="linear-background w-100"></div>
  ) : (
    <>
      <div className="row">
        <div className="col-12">
        <div style={{ overflow: "hidden" }}>
                              {expload ? (
                                <div
                                  className="linear-background w-100"
                                  style={{ height: "30px" }}
                                >
                                  {" "}
                                </div>
                              ) : (
                                <div
                                  className="row"
                                  style={{ background: "#FDD965", }}
                                >
                                  <div className="col-3"></div>
                                  <div className="col-5 text-center">
                                    <h2
                                      style={{
                                        margin: 0,
                                        textAlign: "center",
                                      }}
                                    >
                                     Straddle Weekdays Price (ATM)
                                    </h2>
                                  </div>
                                  <div className="col-4 text-end">
                                    <div style={{ fontWeight: "600" }}>
                                      <label
                                        htmlFor=""
                                        style={{
                                          display: "inline-block",
                                          marginRight: "10px",
                                        }}
                                      >
                                        Expiry
                                      </label>
                                      <Select
                                        style={{ display: "inline-block" }}
                                        defaultValue={{
                                          label: finniftyExpiry[0].label,
                                          value: finniftyExpiry[0].value,
                                        }}
                                        className="text-dark"
                                        required
                                        name="expiredate"
                                        id="expiredate"
                                        onChange={(e) =>
                                          getOptionChainFinniftyLatest(e.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
          <div className="row mt-1">
            <div className="col-6">
              <div
                className="uk-card uk-card-default uk-card-body uk-border-rounded"
                style={{
                  margin: 0,
                  padding: 0,
                  border: "1px solid #C17951",
                  borderRadius: "0px",
                }}
              >
                <div className="uk-text-center">
                  <h2 style={{ background: "#FDF1CC", margin: 0 }}>
                    Previous Week
                  </h2>
                </div>
                <table
                  className="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center"
                  style={{ margin: 0 }}
                >
                  <thead>
                    <tr>
                      <th className="uk-text-center">Day</th>
                      <th className="uk-text-center">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {spreviousweekfin.length > 0 &&
                      spreviousweekfin.map((row, key) => (
                        <tr>
                          <td className="text-center pe-0">
                            <div>{row.day}</div>
                          </td>
                          <td className="text-center pe-0">
                            <div>{row.price}</div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-6">
              <div
                className="uk-card uk-card-default uk-card-body uk-border-rounded"
                style={{
                  margin: 0,
                  padding: 0,
                  border: "1px solid #C17951",
                  borderRadius: "0px",
                }}
              >
                <div className="uk-text-center">
                  <h2 style={{ background: "#FDF1CC", margin: 0 }}>
                    Current Week
                  </h2>
                </div>
                <table
                  className="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center"
                  style={{ margin: 0 }}
                >
                  <thead>
                    <tr>
                      <th className="uk-text-center">Day</th>
                      <th className="uk-text-center">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {scurrentweekfin.length > 0 &&
                      scurrentweekfin.map((row, key) => (
                        <tr>
                          <td className="text-center pe-0">
                            <div>{row.day}</div>
                          </td>
                          <td className="text-center pe-0">
                            <div>{row.price}</div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )}
</Tab>

 </Tabs>
 </div>
</div>

{/* section content end */}
</main>
        <Footer />
      </div>
    </>
  );
}
