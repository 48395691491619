import React, {useState,useEffect} from 'react';
import { api_url,app_url ,isEmail} from '../common/Helpers';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate ,Link} from 'react-router-dom';
import Lottie from "lottie-react";
import userwelcome from '../lottie/userwelcome.json';
import Header from '../common/Header';
import Footer from '../common/Footer';
import { gapi } from 'gapi-script';
import FacebookLogin from 'react-facebook-login';
import { GoogleOAuthProvider,GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
export default function Login() {
// GOOGLE
 const onSuccess = (email,name) => {
  userlogingoogle(email,name)
};
// FACEBOOK
const responseFacebook = (response) => {
  console.log(response);
  if (response.accessToken) {
    userlogingoogle(response.userID,response.name)
  } else {

  }
}
  const navigate = useNavigate();
  const [submit, setsubmit] = useState(false)
  const [uemail, setuemail] = useState("")
  const [pass, setpass] = useState("")
  const userlogin = async (e) => {
        e.preventDefault();
        setsubmit(true);
      if(isEmail(uemail)){
        const formData = new FormData()
        formData.append('uemail', uemail)
        formData.append('pass', pass)
        await axios.post(api_url+`userlogin`, formData).then(({data})=>{
          if(data !== null && data !== 0 && data !== 'nm'){
              setsubmit(false)
              localStorage.setItem("uid",data.id);
              localStorage.setItem("email",data.uemail);
              localStorage.setItem("name",data.name);
              localStorage.setItem("type",data.type);  
              localStorage.setItem("myroleid",data.roleid);
              localStorage.setItem("rolename",data.rolename);
              navigate(app_url);
          }else if(data == 0){
            Swal.fire({
              icon:"error",
              text:"User Not Found"
            })
            setsubmit(false);
          }
          else if(data == 'nm'){
            Swal.fire({
              icon:"error",
              text:"Password Not Matched"
            })
            setsubmit(false);
          }
          else{
              Swal.fire({
                icon:"error",
                text:"User Not Found"
              })
              setsubmit(false);
          }
        }).catch(({response})=>{
          if(response.status===422){
            Swal.fire({
              text:response.data.errors,
              icon:"error"
            })
            setsubmit(false);
          }else{
            Swal.fire({
              text:response.data.message,
              icon:"error"
            })
            setsubmit(false);
          }
          setsubmit(false)
        })
      }
      else{
        Swal.fire({
          text:'Email is not valid',
          icon:"error"
        })
        setsubmit(false);
        return false;
    }
  }
  const userlogingoogle = (uemail) => {
    const formData = new FormData()
    formData.append('uemail', uemail)
    formData.append('from', 'google')
      axios.post(api_url+`userlogin`, formData).then(({data})=>{
      if(data !== null && data !== 0){
          localStorage.setItem("uid",data.id);
          localStorage.setItem("email",data.uemail);
          localStorage.setItem("name",data.name);
          localStorage.setItem("type",data.type);  
          localStorage.setItem("myroleid",data.roleid);
          localStorage.setItem("rolename",data.rolename);
          navigate(app_url);
      }else if(data == 0){
        Swal.fire({
          icon:"error",
          text:"User Not Found"
        })
        setsubmit(false);
      }
      else{
          Swal.fire({
            icon:"error",
            text:"User Not Found"
          })
          setsubmit(false);
      }
    }).catch(({response})=>{
      if(response.status===422){
        Swal.fire({
          text:response.data.errors,
          icon:"error"
        })
        setsubmit(false);
      }else{
        Swal.fire({
          text:response.data.message,
          icon:"error"
        })
        setsubmit(false);
      }
      setsubmit(false)
    })

}
  return (
    <>
    <Header />
 <main>
<section id='loginsection' style={{backgroundImage: 'url("assets/loginbackground.jpg")',backgroundSize: 'cover'}}>
  <div className="container">
        <div className="form-box">
          <div className="header-form">
            <h4 className="text-primary text-center"><i className="fas fa-user-circle" style={{fontSize:"110px",color: '#fcb42d'}}></i></h4>
            <div className="image">
            </div>
          </div>
          <div className="body-form">
           
          <form className="uk-grid uk-form" onSubmit={userlogin}>
                  <div className="uk-margin-small uk-width-1-1 uk-inline">
                    <span className="uk-form-icon uk-form-icon-flip fas fa-user fa-sm" />
                    <input className="uk-input uk-border-rounded" id="username" type="email" placeholder="Email" 
                    onChange={(event)=>{
                      setuemail(event.target.value)
                    }}/>
                  </div>
                  <div className="uk-margin-small uk-width-1-1 uk-inline">
                    <span className="uk-form-icon uk-form-icon-flip fas fa-lock fa-sm" />
                    <input className="uk-input uk-border-rounded" id="password" type="text" placeholder="Password" 
                     value={pass}
                     onChange={(event)=>{
                      setpass(event.target.value)
                    }}/>
                  </div>
                  <div className="uk-margin-small uk-width-1-1">
                    <button className="uk-button uk-width-1-1 uk-button-primary uk-border-rounded uk-float-left" type="submit" name="submit">Sign in</button>
                  </div>
                  <div className="uk-margin-small uk-width-1-1 uk-inline">
                  <div className="message">
                  <div></div>
                  <div><a href="#">Forgot your password</a></div>
                  </div>
                  </div>
                </form>
                <div className="uk-margin-small-bottom d-flex justify-content-around mt-1 mb-4" id='facebooklogin'>
              <GoogleOAuthProvider clientId="648329680987-nfkddecl25japtgek8gkp9qg41bbso6q.apps.googleusercontent.com">
                <GoogleLogin
                  onSuccess={credentialResponse => {
                    var token = credentialResponse.credential;
                    var decoded = jwt_decode(token);
                    onSuccess(decoded.email,decoded.name);
                  }}
                  onError={() => {
                    console.log('Login Failed');
                  }}
                />;
              </GoogleOAuthProvider>;
                {/* <GoogleLogin
                      clientId={clientId}
                      buttonText="Google"
                      onSuccess={onSuccess}
                      onFailure={onFailure}
                      cookiePolicy={'single_host_origin'}
                      isSignedIn={true}
                  /> */}
                  <FacebookLogin
                    appId="669324677978543"
                    callback={responseFacebook}
                    textButton="Facebook"
                    cssClass="my-facebook-button-class"
                    icon="fas fa-facebook"
                    render={renderProps => (
                      <button onClick={renderProps.onClick}>Facebook</button>
                    )}
                  />
                </div>
                <div className='text-start w-100'>
                <span className="uk-text-small text-white">Don't have an account? <Link className="uk-button uk-button-text" style={{color: '#fcb42d'}} to={app_url+'signup'}>Register here</Link></span>
                </div>
          </div>
        </div>
       </div> 
</section>
</main>
    </>
  )
}