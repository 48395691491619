import React, { useEffect,useState } from 'react'
import {Link,useNavigate} from 'react-router-dom';
import Header from '../common/Header';
import axios from 'axios';
import {app_url,asseturl,api_url,isEmail} from '../common/Helpers';
import Select from "react-select";
import Slider from "react-slick";
import $ from 'jquery';
import Swal from 'sweetalert2';
import Footer from './Footer';
export default function Contact(){
  const [submit, setsubmit] = useState(false)
  const [name, setname] = useState("")
  const [uemail, setuemail] = useState("")
  const [subject, setsubject] = useState("")
  const [message, setmessage] = useState("")
  console.log(`${name} ${uemail} ${subject} ${message} `)
  const emptyfield = () =>{
    setname("");
    setuemail("");
    setsubject("");
    setmessage("");
  }
  const contactmail = async (e) => {
    e.preventDefault();
    setsubmit(true);
  if(name == "" || uemail == "" || subject == "" || message == ""){
    Swal.fire({
      icon:"error",
      text:"All Field Required"
    })
    setsubmit(false);
    return false;
  }
  if(isEmail(uemail)){
    const formData = new FormData()
    formData.append('name', name)
    formData.append('uemail', uemail)
    formData.append('subject', subject)
    formData.append('message', message)
    await axios.post(api_url+`contactmail`, formData).then(({data})=>{
      if(data !== null && data !== 0){
          setsubmit(false)
          Swal.fire({
            icon:"success",
            text:"Mail Sent Successfully"
          })
          emptyfield()
        }
    }).catch(({response})=>{
      if(response.status===422){
        Swal.fire({
          text:response.data.errors,
          icon:"error"
        })
        setsubmit(false);
      }else{
        Swal.fire({
          text:response.data.message,
          icon:"error"
        })
        setsubmit(false);
      }
      setsubmit(false)
    })
  }
  else{
    Swal.fire({
      text:'Email is not valid',
      icon:"error"
    })
    setsubmit(false);
    return false;
}
}
  useEffect(()=>{
    },[])
return (
      <>
     <div>
  <Header />
 <main>
  {/* section content begin */}
  <div className="uk-section">
    <div className="uk-container uk-margin-small-top uk-margin-bottom">
      <div className="uk-grid uk-flex uk-flex-center in-contact-6">
        {/* <div className="uk-width-1-1">
          <iframe className="uk-width-1-1 uk-height-large uk-border-rounded"
           src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=mumbai+(FinAkin)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
          </iframe>
        </div> */}
        <div className="uk-width-3-5@m">
          {/* <div className="uk-grid uk-child-width-1-3@m uk-margin-medium-top uk-text-center" data-uk-grid>
            <div>
              <h5 className="uk-margin-remove-bottom"><i className="fas fa-map-marker-alt fa-sm uk-margin-small-right" />Address</h5>
              <p className="uk-margin-small-top">Satrio Tower 16th, Jl. Dr Satrio Kuningan, Jakarta</p>
            </div>
            <div>
              <h5 className="uk-margin-remove-bottom"><i className="fas fa-envelope fa-sm uk-margin-small-right" />Email</h5>
              <p className="uk-margin-small-top uk-margin-remove-bottom">halo@company.com</p>
              <p className="uk-text-small uk-text-muted uk-text-uppercase">for public inquiries</p>
            </div>
            <div>
              <h5 className="uk-margin-remove-bottom"><i className="fas fa-phone-alt fa-sm uk-margin-small-right" />Call</h5>
              <p className="uk-margin-small-top uk-margin-remove-bottom">(888)234-5686</p>
              <p className="uk-text-small uk-text-muted uk-text-uppercase">Mon - Fri, 9am - 5pm</p>
            </div>
          </div> */}
          {/* <hr className="uk-margin-medium" /> */}
          <p className="uk-margin-remove-bottom uk-text-lead uk-text-muted uk-text-center">Have a questions?</p>
          <h1 className="uk-margin-small-top uk-text-center">Let's <span className="in-highlight">get in touch</span></h1>
          <form id="contact-form" className="uk-form uk-grid-small uk-margin-medium-top" onSubmit={contactmail}>
            <div className="uk-width-1-2@s uk-inline">
              <span className="uk-form-icon fas fa-user fa-sm" />
              <input className="uk-input uk-border-rounded" type="text" 
                placeholder='Full Name' 
                autoComplete="off" 
                name="name" 
                value={name}
                onChange={(event)=>{
                setname(event.target.value)
              }}/>
            </div>
            <div className="uk-width-1-2@s uk-inline">
              <span className="uk-form-icon fas fa-envelope fa-sm" />
              <input className="uk-input uk-border-rounded" id="email" name="email" type="email" placeholder="Email address" 
              value={uemail}
              onChange={(event)=>{
                setuemail(event.target.value)
              }}/>
            </div>
            <div className="uk-width-1-1 uk-inline" style={{margin: '1rem 0rem'}}>
              <span className="uk-form-icon fas fa-pen fa-sm" />
              <input className="uk-input uk-border-rounded" id="subject" name="subject" type="text" placeholder="Subject" 
              value={subject}
              onChange={(event)=>{
                setsubject(event.target.value)
              }}/>
            </div>
            <div className="uk-width-1-1">
              <textarea className="uk-textarea uk-border-rounded" id="message" name="message" rows={6} placeholder="Message" defaultValue={""} 
              value={message}
              onChange={(event)=>{
                setmessage(event.target.value)
              }}/>
            </div>

            <div className="uk-width-1-1" style={{marginTop: '1rem'}}> 
              <button className="uk-width-1-1 uk-button uk-button-primary uk-border-rounded" id="sendemail" type="submit" name="submit">Send Message</button>
            </div>
            
          </form>
        </div>
      </div>
    </div>
  </div>
  {/* section content end */}
</main>


  <Footer />
  </div>
      </>
    )
  }
