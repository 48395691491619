import React, { useState } from 'react';
import Header from '../../common/Header';
import axios from 'axios';
import { api_url } from '../../common/Helpers';
import Footer from '../../common/Footer';

export default function StockScreener() {
  const [searchResults, setSearchResults] = useState([]);
  const [fetchedData, setFetchedData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const searchforcompany = async (url) => {
    const dataToSend = { url: url };
    setFetchedData(null); // Reset fetchedData before fetching new data
    await axios.post(api_url + 'getcompanydata', dataToSend)
      .then(({ data }) => {
        if (data) {
          console.log(data)
          setFetchedData(data);
          setSearchResults([]);
        }
      })
      .catch(error => {
        console.error("Error fetching data: ", error);
      });
  };
  
  

  const handleSearchChange = async (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value.length > 0) {
      await axios.get(api_url + `getsearchlist/${value}`).then(({ data }) => {
        if (data && data.length > 0) {
          setSearchResults(data.slice(0, -1));
        }
      });
    } else {
      setSearchResults([]);
    }
  };

  const clearSearch = () => {
    setSearchTerm("");
    setSearchResults([]);
  };
  function FinancialRatios(ratiosString) {
    const parseRatios = (str) => {
      const headerPattern = /^(.*?)Capital Adequacy Ratio/;
      const ratioPattern = /([a-zA-Z\s]+) - ([0-9.]+%)/g;
      const ratios = [];
      let headerMatch = headerPattern.exec(str);
      let header = '';
      if (headerMatch) {
        header = headerMatch[1].trim();
        str = str.replace(header, '');  // Remove the header from the string
      }
  
      let match;
      while ((match = ratioPattern.exec(str))) {
        ratios.push({ name: match[1].trim(), value: match[2] });
      }
  
      return { header, ratios };
    };
  
    const { header, ratios } = parseRatios(ratiosString);
  
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {header && <h4 style={{ width: '100%', textAlign: 'left' }}>{header} :</h4>}
        {ratios.map((ratio, index) => (
          <div key={index} className="ratio-card">
            <h5 style={{ margin: '0 0 10px 0' }}>{ratio.name}</h5>
            <p style={{ fontSize: '1.2em', fontWeight: 'bold', margin: '0' }}>{ratio.value}</p>
          </div>
        ))}
      </div>
    );
  }
  
  
  return (
    <>
      <div>
        <Header />
        <main>
          <div className="uk-section uk-margin-small-top">
            <div className="uk-container">
              <div
                className="flex flex-column"
                style={{
                  maxWidth: '650px',
                  margin: '96px auto',
                  minHeight: '60vh',
                  textAlign: 'center',
                  justifyContent: 'center',
                  padding: '16px',
                }}
              >
                <h1 style={{ marginBottom: 0 }}>
                  STOCK SCREENER
                </h1>
                <div style={{ marginTop: '3%' }}>
                  <div className="home-search" style={{ marginBottom: '1.5rem' }}>
                    <div className="has-addon left-addon dropdown-typeahead">
                      <i className="addon icon-search"></i>
                      <input
                        aria-label="Search for a company"
                        type="search"
                        autoComplete="off"
                        spellCheck="false"
                        placeholder="Search for a company"
                        className="form-control u-full-width"
                        autoFocus={true}
                        data-company-search="true"
                        style={{ marginBottom: '0px' }}
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />
                     <ul className="dropdown-content" style={{ 
                        listStyleType: 'none', 
                        padding: 0, 
                        backgroundColor: '#f9f9f9', 
                        border: '1px solid #ddd', 
                        borderRadius: '4px', 
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', 
                        marginTop: '8px', 
                        maxWidth: '100%', 
                        overflowY: 'auto' 
                      }}>
                        {searchResults.map((item) => (
                          <li key={item.id || item.name} className="list-item-hover" onClick={()=>{searchforcompany(item.url)}} style={{ 
                            padding: '8px 16px', 
                            cursor: 'pointer' 
                          }}>
                            <a onClick={clearSearch} href={'#'} style={{ 
                              textDecoration: 'none', 
                              color: 'black', 
                              display: 'block' 
                            }}>
                              {item.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  {fetchedData && (
                    <div className="uk-container uk-margin-top">
                      <div className="uk-card uk-card-default uk-card-body">
                        <h3 className="uk-card-title">{fetchedData['company_name']}</h3>
                        
                        <div className="uk-grid uk-grid-small uk-grid-match" data-uk-grid>
                          <div className="uk-width-1-2@m">
                            {/* <h4>Financials</h4> */}
                            <p><strong>Current Price:</strong> {fetchedData['current_price']}</p>
                            <p><strong>Market Cap:</strong> {fetchedData['Market Cap']}</p>
                            <p><strong>Stock P/E:</strong> {fetchedData['Stock P/E']}</p>
                            <p><strong>Book Value:</strong> {fetchedData['Book Value']}</p>
                            <p><strong>Dividend Yield:</strong> {fetchedData['Dividend Yield']}</p>
                          </div>

                          <div className="uk-width-1-2@m">
                            {/* <h4>Performance</h4> */}
                            <p><strong>52 Week High / Low:</strong> {fetchedData['High / Low']}</p>
                            <p><strong>ROCE:</strong> {fetchedData['ROCE']}</p>
                            <p><strong>ROE:</strong> {fetchedData['ROE']}</p>
                            <p><strong>Face Value:</strong> {fetchedData['Face Value']}</p>
                            <p><strong>Close Date:</strong> {fetchedData['close_date'].replace(' - close price', '')}</p>
                          </div>
                        </div>

                        
                        <div className='mt-3'>
                          <h4>Key Points</h4>
                          {FinancialRatios(fetchedData['key_ratios'])}
                        </div>
                        
                        <div className='mt-3'>
                          <h4>About the Company</h4>
                          <p>{fetchedData['about'].replace(/\[\d+\]/g, '')}</p>
                        </div>
                      </div>
                    </div>
                  )}


                
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
}
