import React, { useEffect,useState } from 'react'
import {Link,useNavigate} from 'react-router-dom';
import Header from '../../common/Header';
import axios from 'axios';
import {app_url,asseturl,api_url} from '../../common/Helpers';
import Footer from '../../common/Footer';
import Lottie from "lottie-react";
import comingsoon from '../../lottie/coming-soon.json';
export default function Contact(){
  const [allpremarketdata, setallpremarketdata] = useState([])
  const [submit, setsubmit] = useState(false)
  const [load, setload] = useState(false)
  useEffect(()=>{
    fetchdatas();
  },[])
  const fetchdatas = async () =>{
    // await axios.get(api_url+`getpremarketdata`).then(({data})=>{
    //   setallpremarketdata(data)
    //   console.log(data);
    // })
  }
  const w300 ={
    width: '100%',
    height: '500px'
  }
return (
      <>
     <div>
  <Header />
 <main>
  {/* section content begin */}
  <div className="uk-section uk-margin-small-top">
    <div className="uk-container">
    <div className="uk-card uk-card-default uk-card-body uk-border-rounded">
    <Lottie animationData={comingsoon} style={w300} />
    </div>
    </div>
  </div>
  {/* section content end */}
</main>



  <Footer />
  </div>
      </>
    )
  }
