import React, { useEffect,useState } from 'react'
import {Link,useNavigate} from 'react-router-dom';
import Header from '../../common/Header';
import axios from 'axios';
import {api_url} from '../../common/Helpers';
import Footer from '../../common/Footer';
import zIndex from '@material-ui/core/styles/zIndex';
export default function Contact(){
  const [submit, setsubmit] = useState(false)
  const [load, setload] = useState(false)
  const [allloopdata, setallloopdata] = useState([])
  const [prevData, setPrevData] = useState([]);
  const [values, setValues] = useState([
    { color: "green", value: 50 },
    { color: "red", value: 50 }
  ]);
  function shuffleValues() {
    setValues(
      values.sort(() => Math.random() - 0.5)
    );
  }

  useEffect(()=>{
    fetchdatas();
  },[])
  const fetchdatas = async () =>{
    setload(true);
    await axios.get(api_url+`getadvancedeclineratio`).then(({data})=>{
      setallloopdata(data)
      setPrevData(data);
      setload(false);
    })
  }

  const [counter, setCounter] = useState(0);
  useEffect(() => {
    const timer = setTimeout(() => {
      setCounter(counter + 1);
      fetchdata()
    }, 10000);
    
    return () => {
      clearTimeout(timer);
    };
  }, [counter]);
  const fetchdata = async () => {
    await axios.get(api_url + `getadvancedeclineratiolatest`).then(({ data }) => {
      setPrevData(data);
      let filter = []
      for (let x in data) {
        console.log(prevData[x].ltp +' '+ data[x].ltp)
        if (!prevData[x] || prevData[x].ltp !== data[x].ltp) {
          data[x].highlight = true;
        }
        filter.push(data[x]);
      }
      setallloopdata(filter);
    });
  };
function ChartProgressBar(greenValue,redValue) {
  const totalWidth = Number(greenValue) + Number(redValue);
  return (
    <div>
    <div style={{ position: "relative", width: "100%", height: "20px"}}>
      <div
        style={{
          position: "absolute",
          width: `${(greenValue/totalWidth)*100}%`,
          height: "20px",
          backgroundColor: "green",
          zIndex: '1'
        }}
      >
        {greenValue !== 0 ?
          <div style={{ 
            position: "absolute",
            top: "0",
            left: "50%",
            transform: "translateX(-50%)",
            color: "white"
          }}>
            {greenValue}
          </div>
          :
          <div style={{ 
            position: "absolute",
            top: "0",
            left: "50%",
            transform: "translateX(-50%)",
            color: "white"
          }}>
          </div>
          }
      </div>
      <div
        style={{
          position: "absolute",
          width: `${(redValue/totalWidth)*100}%`,
          height: "20px",
          backgroundColor: "red",
          right: 0
        }}
      >
        <div style={{ 
          position: "absolute",
          top: "0",
          left: "50%",
          transform: "translateX(-50%)",
          color: "white"
        }}>
          {redValue}
        </div>
      </div>
    </div>
  </div>
  );
}
return (
      <>
     <div>
  <Header />
 <main>
  {/* section content begin */}
  <div className="uk-section uk-margin-small-top">
    <div className="uk-container">
    <div className="uk-container" style={{marginTop: '1.4rem'}}>
      <div className="row">
        <div className="col-12">
        <div className="uk-card uk-card-default uk-card-body uk-border-rounded" style={{
          margin: 0,
          padding: 0,
          border: '1px solid #C17951',
          borderRadius: '0px',
          }}>
        <div className='uk-text-center'>
        <h2 style={{background: '#FDD965',margin: 0}}>Advance/Decline Ratio</h2>
        </div>
        {load ?
                      <div className="linear-background w-100"> </div>
                        :
                        <>
        <div className="table-responsive">
        <table class="uk-table uk-table-divider uk-table-striped uk-text-small uk-text-center" style={{margin: 0}}>
          <thead>
              <tr>
                  <th class="uk-text-center">Index Name</th>
                  <th class="uk-text-center">Last Value</th>
                  {/* <th class="uk-text-center">LTP</th> */}
                  <th class="uk-text-center">Change %</th>
                  <th class="uk-text-center">Chart</th>
                  <th class="uk-text-center">Advance</th>
                  <th class="uk-text-center">Decline</th>
              </tr>
          </thead>
          <tbody>
          {
                                        allloopdata.length > 0 && (
                                          allloopdata.map((row, key)=>(
                                            <>
                                              <tr>
                                              <td className="text-center pe-0">
                                                <div>{ row.indexName }</div>
                                              </td>
                                              <td className="text-center pe-0">
                                              <div style={{ fontWeight: row.highlight ? 900 : 'normal'}}>{row.ltp}</div>
                                              </td>
                                              {Math.sign(row.changePer) == -1 ?
                                              <td className="text-center pe-0">
                                                <div style={{color: 'red'}}>{ row.changePer }</div>
                                              </td> 
                                              :
                                              <td className="text-center pe-0">
                                                <div style={{color: 'green'}}>{ row.changePer }</div>
                                              </td>
                                              }
                                              <td className="text-center pe-0">
                                              {ChartProgressBar(row.advance,row.decline)}
                                              </td>
                                              {Math.sign(row.advance) == -1 ?
                                              <td className="text-center pe-0">
                                                <div style={{color: 'red'}}>{ row.advance }</div>
                                              </td> 
                                              :
                                              <td className="text-center pe-0">
                                                <div style={{color: 'green'}}>{ row.advance }</div>
                                              </td>
                                              }
                                              {Math.sign(row.decline) == -1 ?
                                              <td className="text-center pe-0">
                                                <div style={{color: 'red'}}>{ row.decline }</div>
                                              </td> 
                                              :
                                              <td className="text-center pe-0">
                                                <div style={{color: 'green'}}>{ row.decline }</div>
                                              </td>
                                              }
                                            </tr>
                                            </>
                                            ))
                                        )
    }
          </tbody>
        </table>
        </div>
        </>
        }
        </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</main>



  <Footer />
  </div>
      </>
    )
  }
