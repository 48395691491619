import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Header from "../../common/Header";
import axios from "axios";
import { api_url, formatdmY } from "../../common/Helpers";
import "../../common/style.css";
import $ from "jquery";
import Swal from "sweetalert2";
import Footer from "../../common/Footer";
import Highcharts from "highcharts/highstock";
import Select from "react-select";
export default function Contact() {
  const [submit, setsubmit] = useState(false);
  const [key, setKey] = useState("home");
  const [load, setload] = useState(false);
  const [counter, setCounter] = useState(0);
  const [expirevalue, setexpirevalue] = useState("");
  const [expirevaluebank, setexpirevaluebank] = useState("");
  const [allstrikenifty, setallstrikenifty] = useState([
    { value: "", label: "" },
  ]);
  const [allstrikebanknifty, setallstrikebanknifty] = useState([
    { value: "", label: "" },
  ]);
  const [allstrikefinnifty, setallstrikefinnifty] = useState([
    { value: "", label: "" },
  ]);
  const [expirevalueFinnifty, setExpirevalueFinnifty] = useState("");
  const [expload, setexpload] = useState(true);
  const [nifyt50expiry, setnifyt50expiry] = useState([
    { value: "", label: "" },
  ]);
  //// THIS SECTION IS FOR BANK NIFTY
  const [bankniftyexpiry, setbankniftyexpiry] = useState([
    { value: "", label: "" },
  ]);
  // THIS SECTION IS FOR FINNIFTY
  // Step 3: Create state for Finnifty data and expiry date
  const [finniftyExpiry, setfinniftyExpiry] = useState([
    { value: "", label: "" },
  ]);
  const fetchexpdatas = async () => {
    await axios.get(api_url + `getalloptionchainexplist`).then(({ data }) => {
      // setexpirevalue(data[0].label)
      // fetchdataslatest(data[0].value)
      let arr = [];
      if (data.FINNIFTY.length > 0) {
        for (let x in data.FINNIFTY) {
          // if(x == 0){
          //   getOptionChainFinniftyLatest(data.FINNIFTY[x])
          // }
          let obj = {
            value: data.FINNIFTY[x],
            label: formatdmY(data.FINNIFTY[x]),
          };
          arr.push(obj);
        }
      }
      setfinniftyExpiry(arr);
      let arr1 = [];
      if (data.NIFTY.length > 0) {
        for (let x in data.NIFTY) {
          // if(x == 0){
          //   getOptionChainnifty50Latest(data.NIFTY[x])
          // }
          let obj = { value: data.NIFTY[x], label: formatdmY(data.NIFTY[x]) };
          arr1.push(obj);
        }
      }
      setnifyt50expiry(arr1);
      let arr2 = [];
      if (data.BANKNIFTY.length > 0) {
        for (let x in data.BANKNIFTY) {
          // if(x == 0){
          //   getoptionchainbankniftyLatest(data.BANKNIFTY[x])
          // }
          let obj = {
            value: data.BANKNIFTY[x],
            label: formatdmY(data.BANKNIFTY[x]),
          };
          arr2.push(obj);
        }
      }
      setbankniftyexpiry(arr2);
    });
    setexpload(false);
  };
  // Step 4: Fetch Finnifty option chain data with a specific expiry date
  const getOptionChainnifty50Latest = async (expirydate) => {
    await axios
      .get(api_url + `getoptionchainlatests/` + expirydate)
      .then(({ data }) => {
        console.log(data);
      });
  };
  const getoptionchainbankniftyLatest = async (expirydate) => {
    await axios
      .get(api_url + `getoptionchainbankniftylatest/` + expirydate)
      .then(({ data }) => {
        console.log(data);
      });
  };
  const getOptionChainFinniftyLatest = async (expirydate) => {
    await axios
      .get(api_url + `getoptionchainfinniftylatest/` + expirydate)
      .then(({ data }) => {
        console.log(data);
      });
  };
  
  useEffect(() => {
    fetchexpdatas();
  }, []);
  useEffect(() => {
    fetchdata();
    const timer = setTimeout(() => {
      setCounter(counter + 1);
      fetchdata();
    }, 120000);

    return () => {
      clearTimeout(timer);
    };
  }, [counter]);
  const fetchdata = async () => {
    setload(true);
    await axios.get(api_url + "getallstrikewithspot").then(({ data }) => {
      // Process nifty50 data
      setexpirevalue(data.niftycurrentstrike.replace(".00", ""));
      fetchdataslatestupdate(data.niftycurrentstrike, "nifty50");

      // Process banknifty data
      setexpirevaluebank(data.bankniftycurrentstrike.replace(".00", ""));
      fetchdataslatestupdate(data.bankniftycurrentstrike, "banknifty");

      // Process finnifty data
      setExpirevalueFinnifty(data.finniftycurrentstrike.replace(".00", ""));
      fetchdataslatestupdate(data.finniftycurrentstrike, "finnifty");

      // Process allnifty50strike data
      let arr = [];
      if (data.allnifty50strike.length > 0) {
        for (let x in data.allnifty50strike) {
          let obj = {
            value: data.allnifty50strike[x].strike,
            label: data.allnifty50strike[x].strike.replace(".00", ""),
          };
          arr.push(obj);
        }
      }
      setallstrikenifty(arr);

      // Process allbankniftystrike data
      let arrbank = [];
      if (data.allbankniftystrike.length > 0) {
        for (let x in data.allbankniftystrike) {
          let obj2 = {
            value: data.allbankniftystrike[x].strike,
            label: data.allbankniftystrike[x].strike.replace(".00", ""),
          };
          arrbank.push(obj2);
        }
      }
      setallstrikebanknifty(arrbank);

      // Process allfinniftystrike data
      let arrfin = [];
      if (data.allfinniftystrike.length > 0) {
        for (let x in data.allfinniftystrike) {
          let obj3 = {
            value: data.allfinniftystrike[x].strike,
            label: data.allfinniftystrike[x].strike.replace(".00", ""),
          };
          arrfin.push(obj3);
        }
      }
      setallstrikefinnifty(arrfin);
    });

    setload(false);
  };
  const getexpiredate = (e, indexname) => {
    const dataid = e.value;
    fetchdataslatestupdate(dataid, indexname);
  };
  const fetchdataslatestupdate = async (cstrike, indexname) => {
    if (indexname == "nifty50") {
      await axios
        .get(api_url + "getcepeoi/" + indexname + "/" + cstrike)
        .then(({ data }) => {
          let allcedata = [];
          let allpedata = [];
          let alloidata = [];
          let allpoidata = [];
          allcedata = data.cedata.map(Number);
          allpedata = data.pedata.map(Number);
          alloidata = data.oidata.map(Number);
          allpoidata = data.poidata.map(Number);
          Highcharts.chart("containerniftyce", {
            chart: {
              type: "spline",
            },
            title: {
              text: "",
            },
            xAxis: {
              categories: data.time,
            },
            yAxis: [
              {
                title: {
                  text: "OI",
                },
                opposite: false,
              },
              {
                title: {
                  text: "Price",
                },
                opposite: true,
              },
            ],
            tooltip: {
              shared: true,
              useHTML: true,
              pointFormatter: function () {
                var seriesName = this.series.name;
                var valuePrefix = "";
                var valueSuffix = "";
                if (seriesName === "Spot Price") {
                  valuePrefix = "";
                  valueSuffix = "";
                } else {
                  valueSuffix = " ";
                }
                return (
                  '<tr><td style="color: ' +
                  this.color +
                  '">' +
                  seriesName +
                  ': </td><td style="text-align: right"><b>' +
                  valuePrefix +
                  Highcharts.numberFormat(this.y, 2) +
                  valueSuffix +
                  "</b></td></tr>"
                );
              },
              footerFormat: "</table>",
              headerFormat: "<table>",
              valueDecimals: 2,
            },
            plotOptions: {
              line: {
                marker: {
                  enabled: false,
                },
              },
              spline: {
                marker: {
                  enabled: false,
                },
              },
            },
            series: [
              {
                name: "OI",
                type: "line", // Modified type from 'line' to 'spline'
                yAxis: 0,
                data: alloidata,
                tooltip: {
                  valueSuffix: " %",
                },
              },
              {
                name: "Price",
                type: "line",
                yAxis: 1,
                data: allcedata,
                tooltip: {
                  valuePrefix: "$",
                },
              },
            ],
          });

          Highcharts.chart("containerniftype", {
            chart: {
              type: "spline",
            },
            title: {
              text: "",
            },
            xAxis: {
              categories: data.time,
            },
            yAxis: [
              {
                title: {
                  text: "OI",
                },
                opposite: false,
              },
              {
                title: {
                  text: "Price",
                },
                opposite: true,
              },
            ],
            tooltip: {
              shared: true,
              useHTML: true,
              pointFormatter: function () {
                var seriesName = this.series.name;
                var valuePrefix = "";
                var valueSuffix = "";
                if (seriesName === "Spot Price") {
                  valuePrefix = "";
                  valueSuffix = "";
                } else {
                  valueSuffix = " ";
                }
                return (
                  '<tr><td style="color: ' +
                  this.color +
                  '">' +
                  seriesName +
                  ': </td><td style="text-align: right"><b>' +
                  valuePrefix +
                  Highcharts.numberFormat(this.y, 2) +
                  valueSuffix +
                  "</b></td></tr>"
                );
              },
              footerFormat: "</table>",
              headerFormat: "<table>",
              valueDecimals: 2,
            },
            plotOptions: {
              line: {
                marker: {
                  enabled: false,
                },
              },
              spline: {
                marker: {
                  enabled: false,
                },
              },
            },
            series: [
              {
                name: "OI",
                type: "line", // Modified type from 'line' to 'spline'
                yAxis: 0,
                data: allpoidata,
                tooltip: {
                  valueSuffix: " %",
                },
              },
              {
                name: "Price",
                type: "line",
                yAxis: 1,
                data: allpedata,
                tooltip: {
                  valuePrefix: "$",
                },
              },
            ],
          });
        });
    } else if (indexname == "banknifty") {
      await axios
        .get(api_url + "getcepeoi/" + indexname + "/" + cstrike)
        .then(({ data }) => {
          let allcedatabank = [];
          let allpedatabank = [];
          let alloidatabank = [];
          let allpoidatabank = [];
          allcedatabank = data.cedata.map(Number);
          allpedatabank = data.pedata.map(Number);
          alloidatabank = data.oidata.map(Number);
          allpoidatabank = data.poidata.map(Number);
          Highcharts.chart("containerbankniftyce", {
            chart: {
              type: "spline",
            },
            title: {
              text: "",
            },
            xAxis: {
              categories: data.time,
            },
            yAxis: [
              {
                title: {
                  text: "OI",
                },
                opposite: false,
              },
              {
                title: {
                  text: "Price",
                },
                opposite: true,
              },
            ],
            tooltip: {
              shared: true,
              useHTML: true,
              pointFormatter: function () {
                var seriesName = this.series.name;
                var valuePrefix = "";
                var valueSuffix = "";
                if (seriesName === "Spot Price") {
                  valuePrefix = "";
                  valueSuffix = "";
                } else {
                  valueSuffix = " ";
                }
                return (
                  '<tr><td style="color: ' +
                  this.color +
                  '">' +
                  seriesName +
                  ': </td><td style="text-align: right"><b>' +
                  valuePrefix +
                  Highcharts.numberFormat(this.y, 2) +
                  valueSuffix +
                  "</b></td></tr>"
                );
              },
              footerFormat: "</table>",
              headerFormat: "<table>",
              valueDecimals: 2,
            },
            plotOptions: {
              line: {
                marker: {
                  enabled: false,
                },
              },
              spline: {
                marker: {
                  enabled: false,
                },
              },
            },
            series: [
              {
                name: "OI",
                type: "line", // Modified type from 'line' to 'spline'
                yAxis: 0,
                data: alloidatabank,
                tooltip: {
                  valueSuffix: " %",
                },
              },
              {
                name: "Price",
                type: "line",
                yAxis: 1,
                data: allcedatabank,
                tooltip: {
                  valuePrefix: "$",
                },
              },
            ],
          });

          Highcharts.chart("containerbankniftype", {
            chart: {
              type: "spline",
            },
            title: {
              text: "",
            },
            xAxis: {
              categories: data.time,
            },
            yAxis: [
              {
                title: {
                  text: "OI",
                },
                opposite: false,
              },
              {
                title: {
                  text: "Price",
                },
                opposite: true,
              },
            ],
            tooltip: {
              shared: true,
              useHTML: true,
              pointFormatter: function () {
                var seriesName = this.series.name;
                var valuePrefix = "";
                var valueSuffix = "";
                if (seriesName === "Spot Price") {
                  valuePrefix = "";
                  valueSuffix = "";
                } else {
                  valueSuffix = " ";
                }
                return (
                  '<tr><td style="color: ' +
                  this.color +
                  '">' +
                  seriesName +
                  ': </td><td style="text-align: right"><b>' +
                  valuePrefix +
                  Highcharts.numberFormat(this.y, 2) +
                  valueSuffix +
                  "</b></td></tr>"
                );
              },
              footerFormat: "</table>",
              headerFormat: "<table>",
              valueDecimals: 2,
            },
            plotOptions: {
              line: {
                marker: {
                  enabled: false,
                },
              },
              spline: {
                marker: {
                  enabled: false,
                },
              },
            },
            series: [
              {
                name: "OI",
                type: "line", // Modified type from 'line' to 'spline'
                yAxis: 0,
                data: allpoidatabank,
                tooltip: {
                  valueSuffix: " %",
                },
              },
              {
                name: "Price",
                type: "line",
                yAxis: 1,
                data: allpedatabank,
                tooltip: {
                  valuePrefix: "$",
                },
              },
            ],
          });
        });
    } else if (indexname == "finnifty") {
      await axios
        .get(api_url + "getcepeoi/" + indexname + "/" + cstrike)
        .then(({ data }) => {
          let allcedatafin = [];
          let allpedatafin = [];
          let alloidatafin = [];
          let allpoidatafin = [];          
          allcedatafin = data.cedata.map(Number);
          allpedatafin = data.pedata.map(Number);
          alloidatafin = data.oidata.map(Number);
          allpoidatafin = data.poidata.map(Number);
          Highcharts.chart("containerfinniftyce", {
            chart: {
              type: "spline",
            },
            title: {
              text: "",
            },
            xAxis: {
              categories: data.time,
            },
            yAxis: [
              {
                title: {
                  text: "OI",
                },
                opposite: false,
              },
              {
                title: {
                  text: "Price",
                },
                opposite: true,
              },
            ],
            tooltip: {
              shared: true,
              useHTML: true,
              pointFormatter: function () {
                var seriesName = this.series.name;
                var valuePrefix = "";
                var valueSuffix = "";
                if (seriesName === "Spot Price") {
                  valuePrefix = "";
                  valueSuffix = "";
                } else {
                  valueSuffix = " ";
                }
                return (
                  '<tr><td style="color: ' +
                  this.color +
                  '">' +
                  seriesName +
                  ': </td><td style="text-align: right"><b>' +
                  valuePrefix +
                  Highcharts.numberFormat(this.y, 2) +
                  valueSuffix +
                  "</b></td></tr>"
                );
              },
              footerFormat: "</table>",
              headerFormat: "<table>",
              valueDecimals: 2,
            },
            plotOptions: {
              line: {
                marker: {
                  enabled: false,
                },
              },
              spline: {
                marker: {
                  enabled: false,
                },
              },
            },
            series: [
              {
                name: "OI",
                type: "line", // Modified type from 'line' to 'spline'
                yAxis: 0,
                data: alloidatafin,
                tooltip: {
                  valueSuffix: " %",
                },
              },
              {
                name: "Price",
                type: "line",
                yAxis: 1,
                data: allcedatafin,
                tooltip: {
                  valuePrefix: "$",
                },
              },
            ],
          });

          Highcharts.chart("containerfinniftype", {
            chart: {
              type: "spline",
            },
            title: {
              text: "",
            },
            xAxis: {
              categories: data.time,
            },
            yAxis: [
              {
                title: {
                  text: "OI",
                },
                opposite: false,
              },
              {
                title: {
                  text: "Price",
                },
                opposite: true,
              },
            ],
            tooltip: {
              shared: true,
              useHTML: true,
              pointFormatter: function () {
                var seriesName = this.series.name;
                var valuePrefix = "";
                var valueSuffix = "";
                if (seriesName === "Spot Price") {
                  valuePrefix = "";
                  valueSuffix = "";
                } else {
                  valueSuffix = " ";
                }
                return (
                  '<tr><td style="color: ' +
                  this.color +
                  '">' +
                  seriesName +
                  ': </td><td style="text-align: right"><b>' +
                  valuePrefix +
                  Highcharts.numberFormat(this.y, 2) +
                  valueSuffix +
                  "</b></td></tr>"
                );
              },
              footerFormat: "</table>",
              headerFormat: "<table>",
              valueDecimals: 2,
            },
            plotOptions: {
              line: {
                marker: {
                  enabled: false,
                },
              },
              spline: {
                marker: {
                  enabled: false,
                },
              },
            },
            series: [
              {
                name: "OI",
                type: "line", // Modified type from 'line' to 'spline'
                yAxis: 0,
                data: allpoidatafin,
                tooltip: {
                  valueSuffix: " %",
                },
              },
              {
                name: "Price",
                type: "line",
                yAxis: 1,
                data: allpedatafin,
                tooltip: {
                  valuePrefix: "$",
                },
              },
            ],
          });
        });
    }
    setload(false);
  };

  return (
    <>
      <div>
        <Header />
        <main>
          {/* section content begin */}
          <div className="uk-section uk-margin-small-top">
            <div
              className="uk-container"
              style={{ marginTop: "1rem", maxWidth: "100%",maxWidth: '1400px' }}
            >
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
              >
                <Tab eventKey="home" title="Nifty 50">
                  {load ? (
                    <div className="linear-background w-100"> </div>
                  ) : (
                    <>
                      <div
                        className="d-flex justify-content-between"
                        style={{ padding: "2px 10px" }}
                      >
                        <div style={{ fontWeight: "600" }}></div>
                        
                        <div style={{ fontWeight: "600", minWidth: "410px" }}>
                          {expload ? (
                              <div
                                className="linear-background w-100"
                                style={{ height: "30px" }}
                              >
                                {" "}
                              </div>
                            ) : (
                              <div className="row my-1">
                                <div className="col-8 text-end">
                                  <div style={{ fontWeight: "600" }}>
                                    <label
                                      htmlFor=""
                                      style={{
                                        display: "inline-block",
                                        marginRight: "10px",
                                      }}
                                    >
                                      Expiry
                                    </label>
                                    <Select
                                      style={{ display: "inline-block" }}
                                      defaultValue={{
                                        label: nifyt50expiry[0].label,
                                        value: nifyt50expiry[0].value,
                                      }}
                                      className="text-dark"
                                      required
                                      name="expiredate"
                                      id="cepeexpiredate"
                                      onChange={(e) =>
                                        getOptionChainnifty50Latest(e.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-4">
                                <label
                                  htmlFor=""
                                  style={{
                                    display: "inline-block",
                                    marginRight: "10px",
                                  }}
                                ></label>
                                <Select
                                  style={{ display: "inline-block" }}
                                  defaultValue={{
                                    label: expirevalue,
                                    value: expirevalue,
                                  }}
                                  className="text-dark"
                                  required
                                  name="expiredate"
                                  id="straddleexpiredate"
                                  options={allstrikenifty}
                                  onChange={(e) => getexpiredate(e, "nifty50")}
                                />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      <div className="row">
                        <div className="col-md-6 col-sm-12">
                          <div
                            className="uk-card uk-card-default uk-card-body uk-border-rounded"
                            style={{
                              margin: 0,
                              padding: 0,
                              border: "1px solid #C17951",
                              borderRadius: "0px",
                            }}
                          >
                            <h2
                              style={{
                                background: "#FDF1CC",
                                margin: 0,
                                textAlign: "center",
                              }}
                            >
                              CE Price Vs OI
                            </h2>
                            <figure class="highcharts-figure">
                              <div
                                id="containerniftyce"
                                style={{ height: "500px" }}
                              ></div>
                            </figure>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <div
                            className="uk-card uk-card-default uk-card-body uk-border-rounded"
                            style={{
                              margin: 0,
                              padding: 0,
                              border: "1px solid #C17951",
                              borderRadius: "0px",
                            }}
                          >
                            <h2
                              style={{
                                background: "#FDF1CC",
                                margin: 0,
                                textAlign: "center",
                              }}
                            >
                              PE Price Vs OI
                            </h2>
                            <figure class="highcharts-figure">
                              <div
                                id="containerniftype"
                                style={{ height: "500px" }}
                              ></div>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </Tab>
                <Tab eventKey="profile" title="Bank Nifty">
                  {load ? (
                    <div className="linear-background w-100"> </div>
                  ) : (
                    <>
                      <div
                        className="d-flex justify-content-between"
                        style={{ padding: "2px 10px" }}
                      >
                        <div style={{ fontWeight: "600" }}></div>
                        <div style={{ fontWeight: "600", minWidth: "410px" }}>
                          {expload ? (
                              <div
                                className="linear-background w-100"
                                style={{ height: "30px" }}
                              >
                                {" "}
                              </div>
                            ) : (
                              <div className="row my-1">
                                <div className="col-8 text-end">
                                <div style={{ fontWeight: "600" }}>
                                    <label
                                      htmlFor=""
                                      style={{
                                        display: "inline-block",
                                        marginRight: "10px",
                                      }}
                                    >
                                      Expiry
                                    </label>
                                    <Select
                                      style={{ display: "inline-block" }}
                                      defaultValue={{
                                        label: bankniftyexpiry[0].label,
                                        value: bankniftyexpiry[0].value,
                                      }}
                                      className="text-dark"
                                      required
                                      name="expiredate"
                                      id="expiredate"
                                      onChange={(e) =>
                                        getoptionchainbankniftyLatest(e.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-4">
                                <label
                                    htmlFor=""
                                    style={{
                                      display: "inline-block",
                                      marginRight: "10px",
                                    }}
                                  ></label>
                                  <Select
                                    style={{ display: "inline-block" }}
                                    defaultValue={{
                                      label: expirevaluebank,
                                      value: expirevaluebank,
                                    }}
                                    className="text-dark"
                                    required
                                    name="expiredate"
                                    id="straddleexpiredate"
                                    options={allstrikebanknifty}
                                    onChange={(e) => getexpiredate(e, "banknifty")}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-sm-12">
                          <div
                            className="uk-card uk-card-default uk-card-body uk-border-rounded"
                            style={{
                              margin: 0,
                              padding: 0,
                              border: "1px solid #C17951",
                              borderRadius: "0px",
                            }}
                          >
                            <h2
                              style={{
                                background: "#FDF1CC",
                                margin: 0,
                                textAlign: "center",
                              }}
                            >
                              CE Price Vs OI
                            </h2>
                            <figure class="highcharts-figure">
                              <div
                                id="containerbankniftyce"
                                style={{ height: "500px" }}
                              ></div>
                            </figure>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <div
                            className="uk-card uk-card-default uk-card-body uk-border-rounded"
                            style={{
                              margin: 0,
                              padding: 0,
                              border: "1px solid #C17951",
                              borderRadius: "0px",
                            }}
                          >
                            <h2
                              style={{
                                background: "#FDF1CC",
                                margin: 0,
                                textAlign: "center",
                              }}
                            >
                              PE Price Vs OI
                            </h2>
                            <figure class="highcharts-figure">
                              <div
                                id="containerbankniftype"
                                style={{ height: "500px" }}
                              ></div>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </Tab>

                <Tab eventKey="finnifty" title="Fin Nifty">
                  {load ? (
                    <div className="linear-background w-100">Loading...</div>
                  ) : (
                    <>
                      <div
                        className="d-flex justify-content-between"
                        style={{ padding: "2px 10px" }}
                      >
                        <div style={{ fontWeight: "600" }}></div>
                        <div style={{ fontWeight: "600", minWidth: "410px" }}>
                          {expload ? (
                              <div
                                className="linear-background w-100"
                                style={{ height: "30px" }}
                              >
                                {" "}
                              </div>
                            ) : (
                              <div className="row my-1">
                                <div className="col-8 text-end">
                                <div style={{ fontWeight: "600" }}>
                                      <label
                                        htmlFor=""
                                        style={{
                                          display: "inline-block",
                                          marginRight: "10px",
                                        }}
                                      >
                                        Expiry
                                      </label>
                                      <Select
                                        style={{ display: "inline-block" }}
                                        defaultValue={{
                                          label: finniftyExpiry[0].label,
                                          value: finniftyExpiry[0].value,
                                        }}
                                        className="text-dark"
                                        required
                                        name="expiredate"
                                        id="expiredate"
                                        onChange={(e) =>
                                          getOptionChainFinniftyLatest(e.value)
                                        }
                                      />
                                  </div>
                                </div>
                                <div className="col-4">
                                <label
                                  htmlFor=""
                                  style={{
                                    display: "inline-block",
                                    marginRight: "10px",
                                  }}
                                ></label>
                                <Select
                                  style={{ display: "inline-block" }}
                                  defaultValue={{
                                    label: expirevalueFinnifty,
                                    value: expirevalueFinnifty,
                                  }}
                                  className="text-dark"
                                  required
                                  name="expiredate"
                                  id="straddleexpiredate"
                                  options={allstrikefinnifty}
                                  onChange={(e) => getexpiredate(e, "finnifty")}
                                />
                                </div>
                              </div>
                            )}
                          </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-sm-12">
                          <div
                            className="uk-card uk-card-default uk-card-body uk-border-rounded"
                            style={{
                              margin: 0,
                              padding: 0,
                              border: "1px solid #C17951",
                              borderRadius: "0px",
                            }}
                          >
                            <h2
                              style={{
                                background: "#FDF1CC",
                                margin: 0,
                                textAlign: "center",
                              }}
                            >
                              CE Price Vs OI
                            </h2>
                            <figure className="highcharts-figure">
                              <div
                                id="containerfinniftyce"
                                style={{ height: "500px" }}
                              ></div>
                            </figure>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <div
                            className="uk-card uk-card-default uk-card-body uk-border-rounded"
                            style={{
                              margin: 0,
                              padding: 0,
                              border: "1px solid #C17951",
                              borderRadius: "0px",
                            }}
                          >
                            <h2
                              style={{
                                background: "#FDF1CC",
                                margin: 0,
                                textAlign: "center",
                              }}
                            >
                              PE Price Vs OI
                            </h2>
                            <figure className="highcharts-figure">
                              <div
                                id="containerfinniftype"
                                style={{ height: "500px" }}
                              ></div>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </Tab>
              </Tabs>
            </div>
          </div>

          {/* section content end */}
        </main>
        <Footer />
      </div>
    </>
  );
}
