import React from 'react'
import Header from './Header';
import Footer from './Footer';
export default function Contact(){
return (
      <>
     <div>
  <Header />
<main>
  <div className="uk-section uk-padding-remove-vertical uk-margin-medium-bottom">
    <div className="uk-container">
      <div className="uk-grid">
        <div className="uk-width-1-1">
          <div className="uk-card uk-card-default uk-border-rounded uk-background-center uk-background-contain uk-background-image@m" style={{backgroundImage: 'url(img/blockit/in-team-background-1.png)'}} data-uk-parallax="bgy: -100">
            <div className="uk-card-body">
              <div className="uk-grid uk-flex uk-flex-center">
                <div className="uk-width-3-4@m uk-text-left">
                  <h2>REFUND AND CANCELLATION POLICY</h2>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>Refund Policy</h3>
                  <p>
                  At Finakin, we strive to provide our customers with the highest quality products and services. If you are not satisfied with your purchase, we offer a 14-day refund policy. If you request a refund within 14 days of your purchase, we will issue a full refund of the purchase price, minus any applicable transaction fees. After 14 days, all sales are final and no refunds will be issued.
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>Cancellation Policy</h3>
                  <p>
                  You may cancel your subscription to any of our products or services at any time by contacting our customer support team. If you cancel before the end of your current billing cycle, you will not be charged for the next cycle. If you cancel after the end of your current billing cycle, you will not be entitled to a refund for that cycle.
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>Processing Refunds</h3>
                  <p>
                  Refunds will be processed within 5-7 business days of your request. Refunds will be issued to the same payment method used for the original purchase.
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>Exceptions to Refund Policy</h3>
                  <h5>There are certain situations where we may not be able to issue a refund, including:</h5>
                  <p>
                  •	If you have breached these terms and conditions or violated any applicable laws or regulations <br/>
                  •	If we have reason to believe that you have engaged in fraudulent or illegal activities <br/>
                  •	If you have already received a refund for the same product or service <br/>
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>Contact Information</h3>
                  <p>
                  If you have any questions or concerns regarding our privacy policy, please contact us at support@finakin.com.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
  <Footer />
  </div>
      </>
    )
  }
