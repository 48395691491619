import React, { useEffect,useState } from 'react'
import {Link,useNavigate} from 'react-router-dom';
import Header from './Header';
import axios from 'axios';
import {app_url,asseturl} from './Helpers';
import Select from "react-select";
import Slider from "react-slick";
import $ from 'jquery';
import Swal from 'sweetalert2';
import Footer from './Footer';
export default function Contact(){
  const [submit, setsubmit] = useState(false)
  useEffect(()=>{
    },[])
return (
      <>
     <div>
  <Header />
<main>
  {/* section content begin */}
  <div className="uk-section uk-padding-remove-vertical uk-margin-medium-bottom">
    <div className="uk-container">
      <div className="uk-grid">
        <div className="uk-width-1-1">
          <div className="uk-card uk-card-default uk-border-rounded uk-background-center uk-background-contain uk-background-image@m" style={{backgroundImage: 'url(img/blockit/in-team-background-1.png)'}} data-uk-parallax="bgy: -100">
            <div className="uk-card-body">
              <div className="uk-grid uk-flex uk-flex-center">
                <div className="uk-width-3-4@m uk-text-left">
                  <h2>COOKIES POLICY</h2>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>Introduction</h3>
                  <p>
                  Finakin uses cookies and other similar technologies to improve your experience on our website and to analyze how our website is used. This policy explains what cookies are, how we use them, and your options for controlling them.
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>What are Cookies?</h3>
                  <p>Cookies are small text files that are stored on your device when you visit a website. They contain information about your visit, such as your preferences and usage of the website. Cookies can be either temporary or persistent and can be removed by clearing your browser cache.</p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>How We Use Cookies</h3>
                  <h6>We use cookies for the following purposes:</h6>
                  <p>
                  •	To authenticate your login session and maintain your preferences <br />
                  •	To analyze how our website is used and improve our website performance <br />
                  •	To serve personalized advertisements and promotions <br />
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>Types of Cookies We Use</h3>
                  <p>
                  We use the following types of cookies on our website:
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <p>
                  <b>Essential Cookies:</b>These cookies are necessary for the website to function properly, and cannot be disabled.
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <p>
                  <b>Analytical Cookies: </b>These cookies collect information about how our website is used, such as which pages are visited most frequently. This information helps us improve our website performance.
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <p>
                  <b>Advertising Cookies:  </b>These cookies are used to serve personalized advertisements and promotions based on your browsing behavior and preferences.
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>Your Options for Controlling Cookies</h3>
                  <p>
                  You can control the use of cookies on our website by adjusting your browser settings. Most browsers allow you to disable or limit the use of cookies. However, please note that disabling cookies may affect your ability to use certain features of our website.
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>Changes to the Cookies Policy</h3>
                  <p>
                    We may update this cookies policy from time to time. Any changes will be posted on our website, and your continued use of our website after such changes constitutes your acceptance of the revised cookies policy.
                  </p>
                </div>
                <div className="uk-width-3-4@m uk-text-left mt-4">
                  <h3>Contact Information</h3>
                  <p>
                  If you have any questions or concerns regarding our cookies policy, please contact us at support@finakin.com.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* section content end */}
  {/* section content begin */}
  {/* <div className="uk-section in-counter-2">
    <div className="uk-container">
      <div className="uk-grid uk-flex uk-flex-left">
        <div className="uk-width-3-4@m">
          <div className="uk-grid uk-flex uk-flex-middle" data-uk-grid>
            <div className="uk-width-1-2@m">
              <h4 className="uk-text-muted">Number speaks</h4>
              <h1 className="uk-margin-medium-bottom">We always ready<br />for a challenge.</h1>
              <a href="#" className="uk-button uk-button-primary uk-border-rounded">Learn more<i className="fas fa-arrow-circle-right uk-margin-small-left" /></a>
            </div>
            <div className="uk-width-1-2@m">
              <div className="uk-margin-large" data-uk-grid>
                <div className="uk-width-1-3@m">
                  <h1 className="uk-text-primary uk-text-right@m">
                    <span className="count" data-counter-end={410}>0</span>
                  </h1>
                  <hr className="uk-divider-small uk-text-right@m" />
                </div>
                <div className="uk-width-expand@m">
                  <h3>Trading instruments</h3>
                  <p>Reprehenderit qui in svoluptate velit esse quam molestiae vel illum qui dolorem eum fugiat quo.</p>
                </div>
              </div>
              <div className="uk-margin-large" data-uk-grid>
                <div className="uk-width-1-3@m">
                  <h1 className="uk-text-primary uk-text-right@m">
                    <span className="count" data-counter-end={27}>0</span>
                  </h1>
                  <hr className="uk-divider-small uk-text-right@m" />
                </div>
                <div className="uk-width-expand@m">
                  <h3>Countries covered</h3>
                  <p>At vero eos et accusamus iusto dignissimos ducimus qui blanditiis praesentium voluta deleniti.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> */}
  {/* section content end */}
</main>


  <Footer />
  </div>
      </>
    )
  }
